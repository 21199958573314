<template>
    <div class="px-1 mt-0">
      
        <v-row>
            <v-col lg="4" sm="12">
                <v-card>
                    <loading :active="loadingFlag" :is-full-page="false" :color="'#03c9d9'"/>
                    <v-card-title>Sensor Data</v-card-title>
                    <v-card-text>
                        <v-simple-table>
                            <template>
                                <tbody>
                                    <tr class="no-hover-action">
                                        <th>Device name:</th>
                                        <td>{{device.name}}</td>
                                    </tr>
                                    <tr class="no-hover-action">
                                        <th>Last update:</th>
                                        <td>{{showLastReport(device.fechaGps)}}</td>
                                    </tr>
                                    <tr class="no-hover-action">
                                        <th>Status (Since Last Report):</th>
                                        <td v-if="colorText == 'color:green'"><font-awesome-icon icon="check-circle" size="1x" :style="{ color: 'green' }"></font-awesome-icon><span v-if="totalReceived == 0" class="text--secondary"> (No Data Reported) </span></td>
                                        <td v-if="colorText == 'color:red'"><font-awesome-icon icon="times-circle" size="1x" :style="{ color: 'red' }"></font-awesome-icon></td>
                                    </tr>
                                    <tr class="no-hover-action">
                                        <th>Received Data Today:</th>
                                        <td>{{totalReceived}}</td>
                                    </tr>
                                    <tr class="no-hover-action">
                                        <th>Installed Capacity:</th>
                                        <td v-if="device.num_paneles == 0 || device.num_paneles == null"> 0 </td>
                                        <td v-else>{{device.num_paneles}}</td>
                                    </tr>
                                    <tr class="no-hover-action">
                                        <th>Total Expected Daily Production:</th>
                                        <td v-if="device.set_total_energy == 0 || device.set_total_energy == null"> 0 </td>
                                        <td v-else>{{device.set_total_energy}}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
                <v-card>
                    <loading :active="loadingFlag" :is-full-page="false" :color="'#03c9d9'"/>
                    <v-card-title>Map</v-card-title>
                    <v-card-text>
                        <v-row class="my-0 pa-3">
                            <div class="mx-auto" id="myMap" style='position:relative;width:100%;height:300px;' v-if="banderaMapa == 1">
                            </div>
                            <div v-else>
                                <span>No Location</span>
                            </div>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card>
                    <v-card-title>Configuration and Today Alerts</v-card-title>
                    <v-row class="mr-1">
                        <v-col lg="4" sm="12">
                            <v-card class="ml-3" style="background: #f05050">
                                <div class="pl-3 py-4">
                                    <font-awesome-icon icon="bell" size="2x" :style="{ color: 'white' }"></font-awesome-icon>
                                    <v-icon class="mr-3" size="20" :style="{ color: 'white' }">mdi-cog</v-icon>
                                </div>
                                <div class="pl-2 py-2 blue-grey darken-4">
                                    <v-dialog v-model="modalAlertConfig" transition="dialog-bottom-transition" max-width="600">
                                        <template v-slot:activator="{ on, attrs }">
                                            <span class="white--text mr-2"><a href="" @click.prevent="openAlertForm()" style="color:inherit" v-bind="attrs" v-on="on">Alerts</a></span>
                                        </template>
                                        <template v-slot:default="dialog">
                                            <v-card>                                               
                                                <v-toolbar color="primary" dark><span class="ml-2">Alerts Configuration</span><v-btn :plain="true" class="ml-auto" @click="cerrarAlerts()"><font-awesome-icon icon="times" size="2x" :style="{ color: 'white'}"/></v-btn></v-toolbar>                                                
                                                <Alerts :rules="rules" :device="device"></Alerts>                                                
                                            </v-card>
                                        </template> 
                                    </v-dialog>
                                    <span><font-awesome-icon icon="chevron-circle-right" size="1x" :style="{ color: 'white' }"></font-awesome-icon></span>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col lg="4" sm="12">
                            <v-card class="ml-1" style="background: #10a510">
                                <div class="pl-3 py-4">
                                    <v-icon class="mr-3" size="35" :style="{ color: 'white' }">mdi-sticker-alert</v-icon>
                                </div>
                                <div class="pl-2 py-2 blue-grey darken-4">
                                    <v-dialog v-model="modalSeeAlerts" persistent transition="dialog-bottom-transition" max-width="600">
                                        <template v-slot:activator="{ on, attrs }">
                                            <span class="white--text mr-2"><a href="" @click.prevent="consultarDeviceAlerts()" style="color:inherit" v-bind="attrs" v-on="on">See Alerts</a></span>
                                        </template>
                                        <template v-slot:default="dialog">
                                            <v-card>
                                                <v-toolbar color="primary" dark><span class="ml-2">Device Alerts</span><v-btn :plain="true" class="ml-auto" @click="cerrarTableAlerts()"><font-awesome-icon icon="times" size="2x" :style="{ color: 'white'}"/></v-btn></v-toolbar>
                                                <v-card-text>
                                                    <v-row>
                                                        <div class="pa-5">
                                                            <template>
                                                                <v-text-field v-model="searchAlerts" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
                                                                <v-data-table dense calculate-widths :items-per-page="5" :headers="headerDeviceAlerts" :items="dataDeviceAlerts" item-key="name" :search="searchAlerts" class="elevation-1" :loading="loadingFlagTableAlerts" loading-text="Loading... Please wait"></v-data-table>
                                                            </template>
                                                        </div>
                                                    </v-row>
                                                </v-card-text>
                                                <v-card-actions class="justify-end">
                                                <v-btn text @click="cerrarTableAlerts()">Cancel</v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </template> 
                                    </v-dialog>
                                    <span><font-awesome-icon icon="chevron-circle-right" size="1x" :style="{ color: 'white' }"></font-awesome-icon></span>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col lg="4" sm="12">
                            <v-card class="ml-1" style="background: #5d9cec">
                                <div class="pl-3 py-4">
                                    <font-awesome-icon icon="cog" size="2x" :style="{ color: 'white' }"></font-awesome-icon>
                                </div>                               
                                <div class="pl-2 py-2 blue-grey darken-4">
                                    <v-dialog v-model="modalDeviceConfig" transition="dialog-bottom-transition" max-width="600">
                                        <template v-slot:activator="{ on, attrs }">
                                            <span class="white--text mr-2"><a href="" @click.prevent="getRealData()" style="color:inherit" v-bind="attrs" v-on="on">Config</a></span>
                                        </template>
                                        <template v-slot:default="dialog">
                                            <v-card>
                                                <v-toolbar color="primary" dark><span class="ml-2">Configuration</span><v-btn :plain="true" class="ml-auto" @click="cerrar()"><font-awesome-icon icon="times" size="2x" :style="{ color: 'white'}"/></v-btn></v-toolbar>
                                                <v-card-text>
                                                    <div class="py-6 inline-block"> <span><strong>IMEI: </strong> </span> <span>{{device.imei}}</span> </div>
                                                    <v-text-field v-model="device.name" :counter="50" label="Asset" required></v-text-field>
                                                    <v-text-field v-model="device.address" :counter="50" label="Address" required></v-text-field>
                                                    <v-select :items="timezones_list" item-text="name" item-value="id" label="Offset" v-model="idTimeZone" @change="assignTimeZone($event)" required></v-select>
                                                    <v-select :items="itemsVoltage" item-text="name" item-value="value" label="Voltage" v-model="energyConfig.voltage"></v-select>
                                                    <v-select :items="itemsPhases" item-text="name" item-value="value" label="Phases" v-model="energyConfig.phases"></v-select>
                                                    <v-text-field v-model="energyConfig.KWhPrice" type="number" label="kWh Price" min=0 max=1000 :counter="4"></v-text-field>
                                                    <v-select :items="itemsCurrency" item-text="name" item-value="value" label="Currency" v-model="energyConfig.currency"></v-select>
                                                </v-card-text>
                                                <v-card-actions class="justify-end">
                                                <v-btn text @click="cerrar()">Cancel</v-btn>
                                                <v-btn text color="primary" @click="updateConfiguration()">Update</v-btn>
                                                <v-progress-circular indeterminate color="primary" size="20" v-if="loadingFlagFormConfig"></v-progress-circular>
                                                </v-card-actions>
                                            </v-card>
                                        </template> 
                                    </v-dialog>
                                     <span><font-awesome-icon icon="chevron-circle-right" size="1x" :style="{ color: 'white' }"></font-awesome-icon></span>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col lg="8" sm="12">
                <v-row> 
                    <v-col>
                        <v-card>
                            <v-simple-table >
                                <template>
                                    <tbody>
                                        <loading :active="loadingFlag" :is-full-page="false" :color="'#03c9d9'"/>
                                        <tr class="no-hover-action">
                                            <td class="showBorders pa-10">
                                                <v-row class="d-flex justify-center">
                                                    <div class="pr-10">
                                                        <font-awesome-icon icon="lightbulb" size="3x" :style="{ color: '#03c9d9' }"/>
                                                    </div>
                                                    <div v-if="energyData.powerNow != null">
                                                        <span><strong class="text-h7 font-weight-black">POWER NOW</strong></span>
                                                        <br>
                                                        <span class="font-weight-light text-subtitle-1">{{parseFloat(energyData.powerNow).toFixed(2)}} kW</span>
                                                    </div>
                                                    <div v-else>
                                                        <span><strong class="text-h7 font-weight-black">POWER NOW</strong></span>
                                                        <br>
                                                        <span class="font-weight-light text-subtitle-1">N/A</span>
                                                    </div>
                                                </v-row>
                                            </td>
                                            <td class="showBorders pa-10">
                                                <v-row class="d-flex justify-center">
                                                    <div class="pr-10">
                                                        <font-awesome-icon icon="clock" size="3x" :style="{ color: '#03c9d9' }"/>
                                                    </div>
                                                    <div v-if="energyData.todayEnergy != null">
                                                        <span><strong class="text-h7 font-weight-black">TODAY´S ENERGY</strong></span>
                                                        <br>
                                                        <span class="font-weight-light text-subtitle-1">{{parseFloat(energyData.todayEnergy).toFixed(2)}} kWh</span>
                                                    </div>
                                                    <div v-else>
                                                        <span><strong class="text-h7 font-weight-black">TODAY´S ENERGY</strong></span>
                                                        <br>
                                                        <span class="font-weight-light text-subtitle-1">N/A</span>
                                                    </div>
                                                </v-row>
                                            </td>
                                            <td class="showBorders pa-10">
                                                <v-row class="d-flex justify-center">
                                                    <div class="pr-10">
                                                        <font-awesome-icon icon="calendar" size="3x" :style="{ color: '#03c9d9' }"/>
                                                    </div>
                                                    <div v-if="energyData.monthlyEnergy != null">
                                                        <span><strong class="text-h7 font-weight-black">MONTHLY ENERGY</strong></span>
                                                        <br>
                                                        <span class="font-weight-light text-subtitle-1">{{parseFloat(energyData.monthlyEnergy).toFixed(2)}} kWh</span>
                                                    </div>
                                                    <div v-else>
                                                        <span><strong class="text-h7 font-weight-black">MONTHLY ENERGY</strong></span>
                                                        <br>
                                                        <span class="font-weight-light text-subtitle-1">N/A</span>
                                                    </div>
                                                </v-row>
                                            </td>
                                        </tr>
                                        <tr class="no-hover-action">
                                           <td class="showBorders pa-10">
                                                <v-row class="d-flex justify-center">
                                                    <div class="pr-10">
                                                        <font-awesome-icon icon="industry" size="3x" :style="{ color: '#03c9d9' }"/>
                                                    </div>
                                                    <div v-if="energyData.carbonOffset != null">
                                                        <span><strong class="text-h7 font-weight-black">MONTHLY</strong></span>
                                                        <br>
                                                        <span><strong class="text-h7 font-weight-black">CARBON OFFSET</strong></span>
                                                        <br>
                                                        <span class="font-weight-light text-subtitle-1">{{parseFloat(energyData.carbonOffset).toFixed(2)}} Tons</span>
                                                    </div>
                                                    <div v-else>
                                                        <span><strong class="text-h7 font-weight-black">MONTHLY CARBON OFFSET</strong></span>
                                                        <br>
                                                        <span class="font-weight-light text-subtitle-1">N/A</span>
                                                    </div>
                                                </v-row>
                                            </td>
                                            <td class="showBorders pa-10">
                                                <v-row class="d-flex justify-center">
                                                    <div class="pr-10">
                                                        <font-awesome-icon icon="tree" size="3x" :style="{ color: '#03c9d9' }"/>
                                                    </div>
                                                    <div v-if="energyData.treesPlanted != null">
                                                        <span><strong class="text-h7 font-weight-black">MONTHLY</strong></span>
                                                        <br>
                                                        <span><strong class="text-h7 font-weight-black">TREES PLANTED</strong></span>
                                                        <br>
                                                        <span class="font-weight-light text-subtitle-1">{{parseFloat(energyData.treesPlanted).toFixed(2)}}</span>
                                                    </div>
                                                    <div v-else>
                                                        <span><strong class="text-h7 font-weight-black">MONTHLY TREES PLANTED</strong></span>
                                                        <br>
                                                        <span class="font-weight-light text-subtitle-1">N/A</span>
                                                    </div>
                                                </v-row>
                                            </td>
                                            <td class="showBorders pa-10">
                                                <v-row class="d-flex justify-center">
                                                    <div class="pr-10">
                                                        <font-awesome-icon icon="donate" size="3x" :style="{ color: '#03c9d9' }"/>
                                                    </div>
                                                    <div v-if="energyData.income != null">
                                                        <span><strong class="text-h7 font-weight-black">MONTHLY INCOME</strong></span>
                                                        <br>
                                                        <span class="font-weight-light text-subtitle-1">{{parseFloat(energyData.income).toFixed(2)}} {{energyConfig.currency}}</span>
                                                    </div>
                                                    <div v-else>
                                                        <span><strong class="text-h7 font-weight-black">MONTHLY INCOME</strong></span>
                                                        <br>
                                                        <span class="font-weight-light text-subtitle-1">N/A</span>
                                                    </div>
                                                </v-row>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row class="pt-0">
                    <v-col>
                        <v-card>
                            <v-card-text>
                                <v-row>
                                    <v-dialog ref="dialogDatePicker" :return-value.sync="dateRangeReportSelected" v-model="modalDatePicker" width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="mx-2" v-model="formatDate" label="Select a date range..." prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker :active-picker="today" :max="today" v-model="dateRangeReportSelected" scrollable range locale="en-US">
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalDatePicker = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="save()">ACCEPT</v-btn>
                                    </v-date-picker>
                                    </v-dialog>
                                    <v-divider class="mx-4 my-2" vertical></v-divider>
                                    <!-- <v-switch v-model="switchFlag" class="mt-4" @change="switchchange()"></v-switch>
                                    <v-chip v-if="switchFlag == true" class=" mt-4 text--secondary">Real Time</v-chip>
                                    <v-chip v-else class="mt-4 text--secondary">Static Time</v-chip> -->
                                    <button v-if="switchFlag == true" @click="switchchange()" style="background-color:rgb(16, 165, 16); border-radius:20px; height: 50px; width: 11%; margin-top: 1%; margin-right: 1%; color:white;" >Real Time</button>
                                    <button v-else @click="switchchange()" style="background-color:rgb(240, 80, 80); border-radius:20px; height: 50px; width: 11%; margin-top: 1%; margin-right: 1%; color:white;" >Static Time</button>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-card>
                            <v-card-text>
                                <v-card-text>
                                    <v-row>
                                        <loading :active="loadingFlagHistorico" :is-full-page="false" :color="'#03c9d9'"/>
                                        <v-col>
                                            <div class="hightchart" :id="idHighchart"></div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import moment from 'moment'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Swal from 'sweetalert2';

import Alerts from "./component/c_alerts.vue"
import { mapGetters, mapActions } from "vuex";


export default {
    props: {
        dateRangeReportSelected: Array,
        device: Object,
        totalReceived: Number,
        energyConfig: Object,
        energyData: Object,
        energyAlertConfig: Object,
        colorText: String,
        idHighchart: String,
        typeConsulta: Number,
        loadingFlag: Boolean,
        loadingFlagHistorico: Boolean,
        loadingFlagFormConfig:Boolean,
        loadingFlagFormAlerts: Boolean,
        timezones_list: Array,
        idTimeZone: Number,
        variablesGlobales: Object,
        posicion: Object,
        rules: Object,
        modalActualizarConfiguracion: Boolean,
        modalActualizarAlerts: Boolean,
        dataDeviceAlerts: Array,
        loadingFlagTableAlerts: Boolean,
        switchFlag: Boolean
    },
    data(){
        return {
            modalDatePicker: false,
            today: moment().format("YYYY-MM-DD"),
            items: ['Normal', 'Monitoreo'],
            local_idTimeZone: this.idTimeZone,
            mapCredentials: "Apl2ciQ_uUYkh2WtZrsJAiHsal-ddm0weOKb7u2eTkBNXvaMc4f15x2dN0Tf4hxb",
            mapOptions: {

            },
            mapVisible: true,
            arrayPosicion: {
                latitud: 0.0,
                latitud: 0.0
            },
            banderaMapa: 0,

            itemsVoltage:[
                {name: '120', value: 120},
                {name: '240', value: 240},
                {name: '440', value: 440}
            ],
            itemsPhases:[
                {name: '1', value: 1},
                {name: '2', value: 2},
                {name: '3', value: 3}
            ],

            itemsCurrency:[
                {name: 'MXN', value: 'MXN'},
                {name: 'USD', value: 'USD'}
            ],

            voltageTest: '',
            phasesTest: '',
            KWhPriceTest: '',
            currencyTest: '',

            
            modalSeeAlerts: false,
            modalAlertConfig: false,
            modalDeviceConfig: false,
            searchAlerts: '',

            headerDeviceAlerts: [
                { text: 'Date', align: 'start', sortable: true, value: 'fechaReplica', width: '35%'},
                { text: 'Message', align: 'start', sortable: true ,value: 'parametros', width: '65%' },
            ],
        }
    },
    components:{
        Alerts,
        Loading        
    },
    watch: {
        getCloseModal(val){            
            if (val == "close") {
                this.cerrarAlerts()
            }            
        }
    }, 
    computed:{
        ...mapGetters({
            getCloseModal: 'getCloseModal'
        }),
        formatDate(){
            var arreglotemporal = []
            var fechaPart1
            var fechaPart2
            var fechaPart1String
            var fechaPart2String
            if(this.dateRangeReportSelected.length == 2){
                console.log("BANDERA RANGO");

                fechaPart1 = new Date(this.dateRangeReportSelected[0]);
                fechaPart2 = new Date(this.dateRangeReportSelected[1])

                fechaPart1String = moment(fechaPart1).add(1,'days').format('MMMM/DD/YYYY')
                fechaPart2String = moment(fechaPart2).add(1,'days').format('MMMM/DD/YYYY')

                arreglotemporal.push(fechaPart1String);
                arreglotemporal.push(fechaPart2String);

                return arreglotemporal.join(' ~ ');
            }
        }
    },

    mounted(){
        const params = {
            idHighchart: this.idHighchart,
            title: "Temperature and Humidity",
            labelX: "Temperatura / Humedad",
            series:[
                {
                    data: [],
                    color: 'blue',
                    name: "Temperature"
                },
                {
                    data: [],
                    color: 'red',
                    name: "Humidity"
                },
            ]
        };
        //console.log("ESTA ES LA POSICION ACTUAL:");
        //console.log(this.posicion);
        this.$emit("makeHighchart", params);

        if(this.posicion.latitud == 0 && this.posicion.longitud == 0){
            this.banderaMapa = 0;
            //console.log("NO TIENE POSICION");
        }else{
            //console.log("SI TIENE POSICION");
            this.banderaMapa = 1;
            this.$emit("getMapa",this.posicion);
        }
    },
    
    updated(){
        if(this.posicion.latitud == 0 && this.posicion.longitud == 0){
            this.banderaMapa = 0;
            //console.log("NO TIENE POSICION");
        }else{
            //console.log("SI TIENE POSICION");
            this.banderaMapa = 1;
            this.$emit("getMapa",this.posicion);
        }
    },
    methods: {
        switchchange(){
            this.$emit("cambioSwitch");
        },

        showLastReport(dateReport){
            //return moment(dateReport).format("YYYY-MM-DD HH:mm:ss")
            return moment(dateReport).locale('en').format("MMMM DD YYYY HH:mm:ss")
        },
        save(){
            console.log("GUARDANDO FECHA");
            var rango = []
            rango.push(this.dateRangeReportSelected)
            this.$emit("getRango", rango);
            this.modalDatePicker = false;
            //this.dateRangeReportSelected.splice(0)
        },

        updateConfiguration(){
            this.loadingFlagFormConfig = true;
            this.modalDeviceConfig = false;
            var stringConfig = JSON.stringify(this.energyConfig);
            console.log(stringConfig);
            this.device.configuracion = stringConfig;
            this.$emit("updateConfiguration");
            this.$emit("consultaTemporal"); 
            //this.$emit("consultaTemporal");
            //console.log(this.device.address);
            //console.log(this.local_idTimeZone);
        },

        

        openAlertForm(){
            this.modalAlertConfig = true;
            this.estatus_value = this.energyAlertConfig.alertEnable;

            console.log("show response");
            console.log(this.energyAlertConfig);

            // if(this.energyAlertConfig.emails.length == 3){
            //     this.email1 = this.energyAlertConfig.emails[0];
            //     this.email2 = this.energyAlertConfig.emails[1];
            //     this.email3 = this.energyAlertConfig.emails[2];
            // }else if(this.energyAlertConfig.emails.length == 2){
            //     this.email1 = this.energyAlertConfig.emails[0];
            //     this.email2 = this.energyAlertConfig.emails[1];
            //     this.email3 = "";
            // }else if(this.energyAlertConfig.emails.length == 1){
            //     this.email1 = this.energyAlertConfig.emails[0];
            //     this.email2 = "";
            //     this.email3 = "";
            // }else if(this.energyAlertConfig.emails.length == 0){
            //     this.email1 = "";
            //     this.email2 = "";
            //     this.email3 = "";
            // }
        },

        consultarDeviceAlerts(){
            this.loadingFlagTableAlerts = true;
            this.$emit("getDeviceAlerts",this.device);
        },

        assignTimeZone(event){
            this.$emit("assignTimeZone",event);
        },

        test1(){
            //console.log("BANDERA POSICION");
            //console.log(this.device);
            console.log("ESTA ES LA POSICION ACTUAL:");
            console.log(this.posicion);
        },

        getRealData(){
            //console.log("BANDERA VOLTAGE");
            //console.log(this.energyConfig.voltage);
            //console.log(this.device);
            //console.log(this.idTimeZone);
            //console.log("consultando");
            //this.$emit("consultaTemporal");
        },
        
        cerrar(){ 
            console.log("Cerrando");  
            this.$emit("consultaTemporal");      
            this.modalDeviceConfig = false;
        },

        cerrarAlerts(){
            this.$emit("consultaTemporal");
            this.modalAlertConfig = false;
             this.closeModalAlerts("")
        },

        cerrarTableAlerts(){
            console.log("Cerrando"); 
            this.modalSeeAlerts = false;
        },
        ...mapActions(['closeModalAlerts']),
    }
}
</script>