var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"height-100"},[_c('v-app',{attrs:{"id":"loginface"}},[_c('v-img',{attrs:{"src":require("@/assets/bg-1.jpg")}},[_c('v-row',{staticClass:"pb-5"}),_c('v-row',{staticClass:"pt-6",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"pt-6",attrs:{"lg":"4","sm":"12"}},[_c('v-card',{staticClass:"elevation-12 justify-center ma-5"},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-spacer'),_c('v-toolbar-title',[_c('strong',[_vm._v("LOGIN")])]),(_vm.loadingLogin)?_c('v-progress-circular',{staticClass:"my-2 ml-3",attrs:{"indeterminate":"","color":"white"}}):_vm._e(),_c('v-spacer')],1),_c('v-card-title',{staticClass:"text-h5 justify-center"},[_c('font-awesome-icon',{style:({ color: '#1976d2' }),attrs:{"icon":"lock","size":"3x"}})],1),_c('form',{attrs:{"data-vv-scope":"login"},on:{"submit":function($event){$event.preventDefault();return _vm.submit('login')}}},[_c('v-card-text',{},[_c('div',{staticClass:"px-4"},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:{
                                            'form-control': true,
                                            'is-invalid': _vm.errors.has(
                                                'authenticationData.user'
                                            ),
                                        },attrs:{"name":"user","label":"User","type":"text","rules":[_vm.rules.required]},model:{value:(_vm.authenticationData.user),callback:function ($$v) {_vm.$set(_vm.authenticationData, "user", $$v)},expression:"authenticationData.user"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                                            _vm.errors.has(
                                                'authenticationData.user'
                                            )
                                        ),expression:"\n                                            errors.has(\n                                                'authenticationData.user'\n                                            )\n                                        "}],staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.errors.first( "authenticationData.user" )))])],1),_c('div',{staticClass:"px-4"},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:{
                                            'form-control': true,
                                            'is-invalid': _vm.errors.has(
                                                'authenticationData.password'
                                            ),
                                        },attrs:{"append-icon":_vm.show2
                                                ? 'mdi-eye'
                                                : 'mdi-eye-off',"name":"password","label":"Password","type":_vm.show2 ? 'text' : 'password',"rules":[_vm.rules.required]},on:{"click:append":function($event){_vm.show2 = !_vm.show2}},model:{value:(
                                            _vm.authenticationData.password
                                        ),callback:function ($$v) {_vm.$set(_vm.authenticationData, "password", $$v)},expression:"\n                                            authenticationData.password\n                                        "}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                                            _vm.errors.has(
                                                'authenticationData.password'
                                            )
                                        ),expression:"\n                                            errors.has(\n                                                'authenticationData.password'\n                                            )\n                                        "}],staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.errors.first( "authenticationData.password" )))])],1),(_vm.showMsgAuth)?_c('div',{staticClass:"px-4"},[_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.msgAuth))])]):_vm._e(),_c('div',{staticClass:"px-4"},[_c('v-checkbox',{attrs:{"label":"Remember Credentials"},model:{value:(
                                            _vm.authenticationData.rememberme
                                        ),callback:function ($$v) {_vm.$set(_vm.authenticationData, "rememberme", $$v)},expression:"\n                                            authenticationData.rememberme\n                                        "}})],1)]),_c('v-card-actions',{staticClass:"pa-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","type":"submit"}},[_vm._v("Sign In")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }