const TableMixin = {
    data() {
        return {
            params : {
                idHighchart: this.idHighchart,
                title: "Bidirectinal Energy",
                labelX: "KW",  
                height: 280,          
                series:[
                    {
                        data: [],
                        showInLegend: false,                     
                    },
                    {
                        data: [],
                        showInLegend: false, 
                    },
                    {
                        data: [],
                        showInLegend: false, 
                    },
                    {
                        data: [],
                        showInLegend: false, 
                    },
                    {
                        data: [],
                        showInLegend: false, 
                    },
                ]
            },
            params2 : {
                idHighchart: 'chartConsumed',
                title: "Energy Consumed",
                labelX: "KW",
                backgroundColor:'#03153d',     
                height: 280,      
                series:[
                    {
                        data: [],
                        showInLegend: false,                     
                    },
                    {
                        data: [],
                        showInLegend: false, 
                    },
                    {
                        data: [],
                        showInLegend: false, 
                    },
                    {
                        data: [],
                        showInLegend: false, 
                    },
                    {
                        data: [],
                        showInLegend: false, 
                    },
                ]
            }
        }
    },
}


export default TableMixin;