<template>
    <div class="grey lighten-4 height-100">
        <Header />
        <v-row class="mb-0">
            <v-col>
                <v-breadcrumbs :items="breadcrumItems">
                    <template v-slot:divider>
                        <v-icon>mdi-chevron-right</v-icon>
                    </template>
                </v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row class="mt-0">
            <v-col lg="7" sm="12" class="pt-0">
                <v-row class="flex-row justify-end">
                    <v-col lg="6" sm="12">
                        <v-select
                            outlined
                            :items="devices"
                            v-model="selected.imei"
                            item-text="name"
                            item-value="imei"
                            label="Devices"
                            @change="assignSelected($event)"
                        />
                    </v-col>
                </v-row>                
            </v-col>
            <v-col lg="5" sm="12">
                <v-btn color="primary" text>See Energy</v-btn>
            </v-col>
        </v-row>
        <FlowWater
            v-if="selected.deviceSelected != null && selected.deviceSelected.idSensor == 41"
            :dateRangeReportSelected="dateRangeReportSelected"
            :device="selected.deviceSelected"
        />
        <Sonometer
            v-if="selected.deviceSelected != null && selected.deviceSelected.idSensor == 2"
            :dateRangeReportSelected="dateRangeReportSelected"
            :device="selected.deviceSelected"
            :totalReceived="totalReceivedData"
            :sonometerLevel="sonometerLevel"
            :decibeles="sonometerDecibeles"
        />
        
    </div>
</template>
<script>
// Components
import Header from '@/components/Header'

// Devices
import FlowWater from '@/components/Devices/FlowWater'
import Sonometer from '@/components/Devices/Sonometer'
import AirQuality from '@/components/Devices/AirQuality'
import Events from '@/components/Devices/Events'
import HumedadTierra from '@/components/Devices/HumedadTierra'
import TemperatureHumidity from '@/components/Devices/TemperatureHumidity'

// Mixins
import DeviceMixin from '@/mixins/DeviceMixin'

// Services
import DeviceService from '@/services/DeviceService'
export default {
    components: {
        Header,
        FlowWater,
        Sonometer,
        AirQuality,
        Events,
        HumedadTierra,
        TemperatureHumidity
    },
    mixins: [
        DeviceMixin,
        DeviceService
    ]
}
</script>
<style>
.height-100{
    height: 100% !important;
}
.showBorders{
    border: 1px solid rgba(0, 0, 0, 0.12) ;
}
.no-hover-action:hover{
    background-color: white !important;
}
</style>