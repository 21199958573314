import moment from 'moment';
import Swal from 'sweetalert2'
import DeviceService from "@/services/DeviceService";
import GraphicDeviceMixin from "@/mixins/GraphicsMixin";
import { mapGetters,mapActions } from 'vuex';

const DeviceMixin = {
    mixins: [DeviceService,GraphicDeviceMixin],
    data(){
        return {
            //Variables solicitudes asincronas
            requestExecutiveRequest:[],
            banderaRequestAsincron: false,
            loadingFlagTableRequest: false,
            contadorRequest: 0,
            arrayAuxiliar: [],
            loadingFlagTableSensorRequest: false,
            showReport: true,
            loadingsRequestGraf: false,

            arrayObjectsAGraficas: [],
            arraypruebas:[],

            //Variables conteo / Clasificacion de sensores
            energyTotalSensor: 0,
            sonometerTotalSensor: 0,
            airqualityTotalSensor: 0,
            gaslevelTotalSensor: 0,
            eventsTotalSensor: 0,
            touchtagTotalSensor: 0,
            humedadtierraTotalSensor: 0,
            piranometroTotalSensor: 0,
            temperatureHumidityTotalSensor: 0,
            estacionMeteorologicaTotalSensor: 0,
            phTotalSensor: 0,
            ecTotalSensor: 0,
            FlowWaterTotalSensor: 0,
            DcMax1500TotalSensor: 0,
            conteoSensoresDiferentes: 0,
            
            
            arregloSensoresDiferentes: [],
            arregloSensoresDiferentes2: [],

            arregloSensoresEnergy: [],
            arregloSensoresSonometer: [],
            arregloSensoresAirQuality: [],
            arregloSensoresGasLevel: [],
            arregloSensoresEvents: [],
            arregloSensoresTouchTag: [],
            arregloSensoresHumedadTierra: [],
            arregloSensoresPiranometer: [],
            arregloSensoresTemperatureH: [],
            arregloSensoresEstacionM: [],
            arregloSensoresPH: [],
            arregloSensoresEC: [],
            arregloSensoresFlowWater: [],
            arregloSensoresDcMax1500: [],
            chartBidirectional: [],
            chartEnergyBdnal: [],
            chartVoltage: [],
            
            
            //VARIABLES AlERTAS
            itemsNavigationStatic: [],
            itemsNavigation: [
                { title: 'Notification 1', text: 'Dispositivo con Imei: 0004a30b002293ab Tardo en reportar mas de 4 horas. ', icon: 'mdi-alert-decagram', color: 'red'},
                { title: 'Notification 2', text: 'Dispositivo con Imei: 0004A30B00EB85E0 Tardo en reportar mas de 4 horas. ', icon: 'mdi-alert-decagram', color: 'red'},
                { title: 'Notification 3', text: 'Dispositivo con Imei: 369e45facb46bb76 Tardo en reportar mas de 4 horas. ', icon: 'mdi-alert-decagram', color: 'red'},
            ],

            arrayAlerts: [],

            dataDeviceAlerts:[],

            arreglozero: [],

            variablesGlobales:{
                loadingEstado: false,
            },
            totalReceivedData: 0,
            colorText: '',
            dataSensorPositionDevices: [],
            arrayDataFechaGps:[],

            assignedFlag: 0,
            timezones_list: [],
            timezone_data: {
                objeto: '',
                id: '',
                name: '',
                offset: ''
            },

            idTimeTest: 2,

            contadorLoadSwitch: 0,

            user_global: '',
            drawer: false,

            //HEADER
            dashboardFlag: 1,
            executiveFlag: 1,
            
            //FLAGS DE LOADINGS
            loadingFlagSelect: true,
            loadingFlagMain: true,
            loadingFlag: true,
            loadingFlagHistorico: false,
            loadingFlagDataTable: true,
            loadingFlagChartReport: false,
            loadingFlagTableFlow: true,
            loadingFlagTableMonthlyFlow: true,
            loadingFlagTableAlerts: true,
            loadingFlagFormAlerts: false,
            loadingFlagFormConfig:false,
            loadingFlagNavigation: false,
            seeEnergyFlag: 0,

            switchFlag: true,
            campanaFlag: '',
            
            //POSICION PARA MAPA

            posicion: {
                latitud: 0.0,
                longitud: 0.0,
                havePosition: "NO"
            },

            //ENERGY VARIABLES
            energyData:{
                powerNow: '',
                todayEnergy: '',
                monthlyEnergy: '',
                carbonOffset: '',
                treesPlanted: '',
                income: ''
            },

            energyConfig:{
                KWhPrice: 0,
                currency: '',
                phases: '',
                voltage: '',
            },

            energyAlertConfig:{
                emails: [],
                alertEnable: '',
                amp1Max: '',
                amp1Min: '',
                amp2Max: '',
                amp2Min: '',
                amp3Max: '',
                amp3Min: '',
                kwh1Max: '',
                kwh1Min: '',
                kwh2Max: '',
                kwh2Min: '',
                kwh3Max: '',
                kwh3Min: '',
                tempMax: '',
                tempMin: ''
            },

            arrayDataTemp: [],
            arrayDataKwh1: [],
            arrayDataKwh2: [],
            arrayDataKwh3: [],
            arrayDataAmp1: [],
            arrayDataAmp2: [],
            arrayDataAmp3: [],

            //SONOMETER VARIABLES
            sonometerLevel: {
                lowLevel: '',
                normalLevel: '',
                highLevel: '',
                alerts: "",
                mail: "",
            },

            sonometerAlertConfig:{
                emails: [],
                alertEnable: ''
            },

            arrayDataSonometer:[],
            sonometerDecibeles: 0,
            users: [],

            // AIR QUALITY VARIABLES

            airQualityData: {
                quality: '',
                tvoc: ''
            },

            airQualityConfig:{
                alerts: "",
                mail: "",
            },

            airQualityAlertConfig:{
                alertEnable: '',
                emails: []
            },

            arrayDataAirQuality:[],
            arrayDataAirTvoc:[],

            //GAS LEVEL VARIABLES
            gasLevel:{
                lowLevel: '',
                normalLevel: '',
                highLevel: '',
                alerts: "",
                mail: "",
            },

            gasLevelAlertConfig:{
                alertEnable: '',
                emails: []
            },

            arrayDataGasLevel:[],
            gLevel: 0,

            //EVENTS VARIABLES

            eventsData: {
                ev1: '',
                ev2: '',
                ev3: '',
                ev4: ''
            },

            eventsAlertsConfig:{
                alertEnable: '',
                emails: []
            },

            arrayDataEvento1:[],
            arrayDataEvento2:[],
            arrayDataEvento3:[],
            arrayDataEvento4:[],

            //TOUCH TAG VARIABLES

            touchTagData: {
                temperature: '',
                pitch: '',
                roll: ''
            },

            touchAlertConfig: {
                alertEnable: '',
                emails: []
            },

            arrayDataTemperatureTG: [],
            arrayDataPitchTG: [],
            arrayDataRollTG: [],

            //TEMPERATURE AND HUMIDITY VARIABLES

            temperatureAndHumidityData: {
                temperature: '',
                humidity: ''
            },

            arrayDataTemperature: [],
            arrayDataHumidity: [],
            arrayTempHum :[],

            temperatureAndHumidityAlertConfig: {
                alertEnable: '',
                emails: [],
                tempMin: '',
                tempMax: ''
            },

            //PIRANOMETRO VARIABLES

            piranometerData:{
                wattmetro: '',
                temperature: ''
            },

            piranometerAlertConfig:{
                alertEnable: '',
                emails: []
            },

            arrayDataWattmetroPir:[],
            arrayDataTemperaturaPir:[],

            //PH VARIABLES

            phData:{
                Temperatura: '',
                nivel: ''
            },

            phConfig:{
                alerts: "",
                mail: "",
            },

            phAlertConfig: {
                emails: [],
                alertEnable: ''
            },

            arrayDataTemperaturaPh: [],
            arrayDataNivel: [],

            //EC VARIABLES

            ecData:{
                Temperatura: '',
                uScm: ''
            },

            ecConfig:{
                alerts: "",
                mail: ""
            },

            ecAlertConfig: {
                alertEnable: '1',
                emails: []
            },

            arrayDataTemperaturaEc: [],
            arrayDatauScm: [],
            
            //ESTACION METEOROLOGICA

            estacionMeteorologicaData:{
                wind_speed: '',
                rain_volume: '',
                temperature: '',
                direction_wind: '',
                relative_humidity: '',
                pascal_pressure: ''
            },

            estacionMeteorologicaAlertConfig:{
                alertEnable: '',
                emails: []
            },

            arrayDataWindSpeedEM: [],
            arrayDataRainVolumeEM: [],
            arrayDataTemperaturaEM: [],
            arrayDataDirectionWindEM: [],
            arrayDataRelativeHumidityEM: [],
            arrayDataPascalPressureEM: [],

            //FLOW WATER

            flowWaterData:{
                lts_minute: '',
                lts_total: '',
                total_litter: '',
                today_litters: '',
                monthly_litters: ''
            },

            flowWaterAlertConfig:{
                alertEnable: '',
                emails: []
            },

            flowAlertConfigTemporal:{
                alertEnable: '',
                emails: []
            },

            arrayDataLtsMinuteWF: [],
            arrayDataLtsTotalWF: [],
            dataHistoricLiters: [],
            dataMonthlyLiters: [],

            // DCMAX1500
            DcMaxData:{
                powerNow: 0,
                todayEnergy: 0,
                monthlyEnergy: 0
            },
            Bidireccional:{},            
            arrChannels: {
                channel1: [],
                channel2: [],
                channel3: [],
                channel4: [],
                power: []
            },

            //VALIDATIONS

            rules: {
                required: value => !!value || 'Required.',
                counter: value => value.length <= 20 || 'Max 100 characters',
                email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail (format = test@example.com).'
                },
            },

            //MODALS
            modalActualizarConfiguracion: false,
            modalActualizarAlerts: false,
            
            devices: [],
            dataSensorPosition: [],
            selected: {
                imei: "",
                deviceSelected: null,
                idTimeZone: 0
            },

            assets: {
                imei: "",
                deviceSelected: null,
                fechaFrom : "",
                fechaTo: "",
                idTimeZone: 0
            },

            breadcrumItems: [
                {
                    text: "Home",
                    disabled: false
                },
                {
                    text: "Dashboard",
                    disabled: false
                },
                {
                    text: 'Alerts',
                    disabled: true
                },
                {
                    text: 'Docs',
                    disabled: true
                }
            ],
            dateRangeReportSelected: [],
            rangoFechasExecutive: [],
            arrayDevicesSeleccionados: [],
            typeConsulta: 0,

            variableIntervalo: '',
            actualDateRange: [this.restDays(this.getActualDate(),0),this.getActualDate()]
        }
    },

    computed:{
        ...mapGetters({
            mensaje : 'getMensaje', 
            user_now : 'getUser',         
        }), 
    },
    
    beforeCreate(){
    },

    created(){
        this.campanaFlag = localStorage.bellNotification;
    },

    watch:{
        "selected.imei":function(){
            this.assignSelectedInicio();
        },
    },

    methods: {
        ...mapActions(['signIn','attemp','actionSetUser']),
        getActualDate(){
            Number.prototype.padLeft = function(base,chr){
                var  len = (String(base || 10).length - String(this).length)+1;
                return len > 0? new Array(len).join(chr || '0')+this : this;
            }
            let d = new Date,
            dformat = [d.getFullYear(),
                (d.getMonth()+1).padLeft(),
                d.getDate().padLeft()].join('-');
            return dformat;
        },
        restDays(date, days) {
            Number.prototype.padLeft = function(base,chr){
                var  len = (String(base || 10).length - String(this).length)+1;
                return len > 0? new Array(len).join(chr || '0')+this : this;
            }
            var result = new Date(date);
            result.setDate(result.getDate() - days);
            let dformat = [result.getFullYear(),
            (result.getMonth()+1).padLeft(),
            result.getDate().padLeft()].join('-');
            return dformat;
        },
        executeMixinFunction(){                      
            this.getDevicesUser();
            this.getTimeZones();            
            this.recargarDatos();
            this.recargarConsultaAlertas();
        },
        mountedAlertMixin(){
            this.getDevicesUser();
        },

        getDevices(){
            this.devices = [];
            const params = {
                user_id: 120
                
            };
            this.indexDevices(params).then((result) => {
                this.devices = result.data;
                this.selected.deviceSelected = this.devices[0];
                this.selected.deviceSelected.datos = JSON.parse(this.selected.deviceSelected.datos);
                this.selected.imei = this.devices[0].imei;
                this.getDataSensor(this.selected.imei);
            }).catch((err) => {
                console.log(err);
            });
        },

        recargarDatos(){
            console.log("Pagina Refrescada");
            //this.variableIntervalo = setInterval(this.assignSelectedInicio, 10000);
            setInterval(this.assignSelectedInicio, 180000);
        },

        llamarIntervalo(){
        
        },

        pararIntervalo(){
            clearInterval(this.variableIntervalo);
        },

        recargarConsultaAlertas(){
            //this.getLastUserAlertsStatic();
            this.getLastUserAlerts();
            setInterval(this.getLastUserAlerts, 60000)
        },

        numFormat(number){
            return Number.parseFloat((number/1000).toFixed(4));
        },


        //Funcion para seleccion de fechas (dashboard components, reportes ejecutivos y alertas)
        getRango(rango){    
            // alert('GetRango')
            // console.log('Rango nuevo:');
            // console.log(rango);
            // console.log(rango[0]);
            this.dateRangeReportSelected = rango[0]; //Rango de drleccion           
            this.selected.fechaFrom = this.dateRangeReportSelected[0];
            this.selected.fechaTo = this.dateRangeReportSelected[1];

            localStorage.setItem('Rango', rango[0]);
            let imeiB64=window.btoa(this.selected.imei);
            this.$router.push({path: `/dashboard/${this.selected.fechaFrom}+${this.selected.fechaTo}/${imeiB64}`});
            
            var fechaFromv = new Date(this.selected.fechaFrom);
            var fechaTov = new Date(this.selected.fechaTo);
            var todayDate = new Date();
            var diff_time_today = todayDate.getTime() - fechaFromv.getTime();
            var diff_days_today = diff_time_today / (1000 * 3600 * 24);

            var diff_time = fechaTov.getTime() - fechaFromv.getTime()
            var diff_days = diff_time / (1000 * 3600 * 24);

            if( this.selected.fechaTo!=this.actualDateRange[1]){
                this.switchFlag=false;
            }

            if(this.dateRangeReportSelected.length < 2){
                Swal.fire({
                    title: '¡Missing Range!',
                    icon: 'warning',
                    text: 'Please choose a date range (two dates)',
                });
            }else{
                if(Date.parse(this.selected.fechaFrom) > Date.parse(this.selected.fechaTo)){
                    console.log(`FECHA 1 ${this.selected.fechaFrom}
                     ES MAYOR A FECHA 2 ${this.selected.fechaTo}`);
                    Swal.fire({
                        title: '¡Range Error!',
                        icon: 'warning',
                        text: 'First date greater than the second date',
                    });
                }else{
                    if(diff_days >= 90){
                        Swal.fire({
                            title: '¡Range Greater than 3 Months!',
                            icon: 'warning',
                            text: 'Please check with your system administrator for querys longer than 3 months'
                        });
                    }else{
                        if(diff_days_today >= 90){
                            Swal.fire({
                                title: '¡Consultation is more than 3 months ago!',
                                icon: 'warning',
                                text: 'Please check with your system administrator for querys longer than 3 months',
                            });
                        }else{
                            // this.switchFlag = false;
                            this.loadingFlagHistorico = true;
                            this.getDataSensorPositionHistorico(this.selected, 0);
                        }
                    }
                }
            }
        },

        getRangoDCMax(data){              
            let rango = data.rango
            this.dateRangeReportSelected = rango[0]; //Rango de drleccion
            // console.log("rango");
            // console.log(this.dateRangeReportSelected);
            this.selected.fechaFrom = this.dateRangeReportSelected[0];
            this.selected.fechaTo = this.dateRangeReportSelected[1];
            
            var fechaFromv = new Date(this.selected.fechaFrom);
            var fechaTov = new Date(this.selected.fechaTo);
            var todayDate = new Date();
            var diff_time_today = todayDate.getTime() - fechaFromv.getTime();
            var diff_days_today = diff_time_today / (1000 * 3600 * 24);

            var diff_time = fechaTov.getTime() - fechaFromv.getTime()
            var diff_days = diff_time / (1000 * 3600 * 24);

                       

            if(this.dateRangeReportSelected.length < 2){
                Swal.fire({
                    title: '¡Missing Range!',
                    icon: 'warning',
                    text: 'Please choose a date range (two dates)',
                });
            }else{
                if(Date.parse(this.selected.fechaFrom) > Date.parse(this.selected.fechaTo)){
                    Swal.fire({
                        title: '¡Range Error!',
                        icon: 'warning',
                        text: 'First date greater than the second date',
                    });
                }else{
                    if(diff_days >= 90){
                        Swal.fire({
                            title: '¡Range Greater than 3 Months!',
                            icon: 'warning',
                            text: 'Please check with your system administrator for querys longer than 3 months'
                        });
                    }else{
                        if(diff_days_today >= 90){
                            Swal.fire({
                                title: '¡Consultation is more than 3 months ago!',
                                icon: 'warning',
                                text: 'Please check with your system administrator for querys longer than 3 months',
                            });
                        }else{
                            this.switchFlag = false;
                            this.loadingFlagHistorico = true;   
                            
                            this.getDataSensorPositionHistorico(this.selected, data.channel, data?.selected, data?.chart_id);
                        }
                    }
                }
            }
        },

        //Cambio de swithc para consultas en tiempo real o estaticas
        cambioSwitch(){
            if(this.switchFlag == true){
                console.log("CAMBIO A FALSO");
                this.switchFlag = false;
            }else if(this.switchFlag == false){
                console.log("CAMBIO A TRUE");
                this.switchFlag = true;
                let fechaURL=this.$route.params.date.split('+');
                // let deviceImei = localStorage.getItem('Imei') || 'undefined'
                if(fechaURL[1]==this.actualDateRange[1]){
                    this.getRango([[fechaURL[0],fechaURL[1]]]);
                }else{
                    this.getRango([[this.actualDateRange[0],this.actualDateRange[1]]]);
                }
                // this.getDataSensorPositionHistorico(this.selected, 0);
            }
        },

        //No se usa
        getDevicesUser(){
            this.devices = [];
            const params = {
                user_id: localStorage.idUser,
                user_name: localStorage.user_p,
                user_token: localStorage.token_p 
            }; 
            this.db_indexDevicesUser(params).then((result) => {
                var cantidadEnergySensors = 0;
                var cantidadSonometerSensors = 0;
                var cantidadAirQualitySensors = 0;
                var cantidadGasLevelSensors = 0;
                var cantidadEventsSensors = 0;
                var cantidadTouchTagSensors = 0;
                var cantidadHumedadTierraSensors = 0;
                var cantidadPiranometroSensors = 0;
                var cantidadTemperatureHumiditySensors = 0;
                var cantidadEstacionSensors = 0;
                var cantidadPHSensors = 0;
                var cantidadECSensors = 0;
                var cantidadFlowWaterSensors = 0;
                var cantidadDcMax1500 = 0;

                var arregloSensoresDiferentesTemporal = []
                var arregloSensoresDiferentesTemporal2 = []

                var arregloSensoresEnergyTemporal = []
                var arregloSensoresSonometerTemporal = []
                var arregloSensoresAirQualityTemporal = []
                var arregloSensoresGasLevelTemporal = []
                var arregloSensoresEventsTemporal = []
                var arregloSensoresTouchTagTemporal = []
                var arregloSensoresHumedadTierraTemporal = []
                var arregloSensoresPiranometerTemporal = []
                var arregloSensoresTemperatureHTemporal = []
                var arregloSensoresEstacionMTemporal = []
                var arregloSensoresPHTemporal = []
                var arregloSensoresECTemporal = []
                var arregloSensoresFlowWaterTemporal = []
                var arregloSensoresDcMax1500Temporal = [];

                var conteoSensores = 0;

                this.devices = result.data[0];
                cantidadEnergySensors= result.data[1];

                this.devices.forEach(element => {
                    if(element.idSensor == 1){
                        arregloSensoresEnergyTemporal.push(element);
                    }else if(element.idSensor == 2){
                        cantidadSonometerSensors++;
                        arregloSensoresSonometerTemporal.push(element);
                    }else if(element.idSensor == 3){
                        cantidadAirQualitySensors++;
                        arregloSensoresAirQualityTemporal.push(element);
                    }else if(element.idSensor == 7){
                        cantidadGasLevelSensors++;
                        arregloSensoresGasLevelTemporal.push(element);
                    }else if(element.idSensor == 8){
                        cantidadEventsSensors++;
                        arregloSensoresEventsTemporal.push(element);
                    }else if(element.idSensor == 11){
                        cantidadTouchTagSensors++;
                        arregloSensoresTouchTagTemporal.push(element);
                    }else if(element.idSensor == 29){
                        cantidadHumedadTierraSensors++;
                        arregloSensoresHumedadTierraTemporal.push(element);
                    }else if(element.idSensor == 30){
                        cantidadPiranometroSensors++;
                        arregloSensoresPiranometerTemporal.push(element);
                    }else if(element.idSensor == 31){
                        cantidadTemperatureHumiditySensors++;
                        arregloSensoresTemperatureHTemporal.push(element);
                    }else if(element.idSensor == 32){
                        cantidadEstacionSensors++;
                        arregloSensoresEstacionMTemporal.push(element);
                    }else if(element.idSensor == 36){
                        cantidadPHSensors++;
                        arregloSensoresPHTemporal.push(element);
                    }else if(element.idSensor == 37){
                        cantidadECSensors++;
                        arregloSensoresECTemporal.push(element);
                    }else if(element.idSensor == 41){
                        cantidadFlowWaterSensors++;
                        arregloSensoresFlowWaterTemporal.push(element);
                    }else if(element.idSensor == 47){
                        cantidadDcMax1500 ++;
                        arregloSensoresDcMax1500Temporal.push(element);
                    }
                });

                this.arregloSensoresEnergy = arregloSensoresEnergyTemporal;
                this.arregloSensoresSonometer = arregloSensoresSonometerTemporal;
                this.arregloSensoresAirQuality = arregloSensoresAirQualityTemporal;
                this.arregloSensoresGasLevel = arregloSensoresGasLevelTemporal;
                this.arregloSensoresEvents = arregloSensoresEventsTemporal;
                this.arregloSensoresTouchTag = arregloSensoresTouchTagTemporal;
                this.arregloSensoresHumedadTierra = arregloSensoresHumedadTierraTemporal;
                this.arregloSensoresPiranometer = arregloSensoresPiranometerTemporal;
                this.arregloSensoresTemperatureH = arregloSensoresTemperatureHTemporal;
                this.arregloSensoresEstacionM = arregloSensoresEstacionMTemporal;
                this.arregloSensoresPH = arregloSensoresPHTemporal;
                this.arregloSensoresEC = arregloSensoresECTemporal;
                this.arregloSensoresFlowWater = arregloSensoresFlowWaterTemporal;
                this.arregloSensoresDcMax1500 = arregloSensoresDcMax1500Temporal;

                this.energyTotalSensor = cantidadEnergySensors;
                this.sonometerTotalSensor = cantidadSonometerSensors;
                this.airqualityTotalSensor = cantidadAirQualitySensors;
                this.gaslevelTotalSensor = cantidadGasLevelSensors;
                this.eventsTotalSensor = cantidadEventsSensors;
                this.touchtagTotalSensor = cantidadTouchTagSensors;
                this.humedadtierraTotalSensor = cantidadHumedadTierraSensors;
                this.piranometroTotalSensor = cantidadPiranometroSensors;
                this.temperatureHumidityTotalSensor = cantidadTemperatureHumiditySensors;
                this.estacionMeteorologicaTotalSensor = cantidadEstacionSensors;
                this.phTotalSensor = cantidadPHSensors;
                this.ecTotalSensor = cantidadECSensors;
                this.FlowWaterTotalSensor = cantidadFlowWaterSensors;
                this.DcMax1500TotalSensor = cantidadDcMax1500;

                if(this.energyTotalSensor > 0){
                    conteoSensores++;
                    var arrt = {
                        nombreTipo: "Energy",
                        idSensor: 1
                    }
                    arregloSensoresDiferentesTemporal.push(arrt);
                    arregloSensoresDiferentesTemporal2.push(arrt);
                }
                if(this.sonometerTotalSensor > 0){
                    conteoSensores++;
                    var arrt = {
                        nombreTipo: "Sonometer",
                        idSensor: 2
                    }
                    arregloSensoresDiferentesTemporal.push(arrt);
                    arregloSensoresDiferentesTemporal2.push(arrt);
                }
                if(this.airqualityTotalSensor > 0){
                    conteoSensores++;
                    var arrt = {
                        nombreTipo: "Air Quality",
                        idSensor: 3
                    }
                    arregloSensoresDiferentesTemporal.push(arrt);
                    arregloSensoresDiferentesTemporal2.push(arrt);
                }
                if(this.gaslevelTotalSensor > 0){
                    conteoSensores++;
                    var arrt = {
                        nombreTipo: "Gas Level",
                        idSensor: 7
                    }
                    arregloSensoresDiferentesTemporal.push(arrt);
                    arregloSensoresDiferentesTemporal2.push(arrt);
                }
                if(this.eventsTotalSensor > 0){
                    conteoSensores++;
                    var arrt = {
                        nombreTipo: "Events",
                        idSensor: 8
                    }
                    arregloSensoresDiferentesTemporal.push(arrt);
                    arregloSensoresDiferentesTemporal2.push(arrt);
                }
                if(this.touchtagTotalSensor > 0){
                    conteoSensores++;
                    var arrt = {
                        nombreTipo: "Touch Tag",
                        idSensor: 11
                    }
                    arregloSensoresDiferentesTemporal.push(arrt);
                    arregloSensoresDiferentesTemporal2.push(arrt);
                }
                if(this.humedadtierraTotalSensor > 0){
                    conteoSensores++;
                    var arrt = {
                        nombreTipo: "Humedad en Tierra",
                        idSensor: 29
                    }
                    arregloSensoresDiferentesTemporal.push(arrt);
                    arregloSensoresDiferentesTemporal2.push(arrt);
                }
                if(this.piranometroTotalSensor > 0){
                    conteoSensores++;
                    var arrt = {
                        nombreTipo: "Piranometer",
                        idSensor: 30
                    }
                    arregloSensoresDiferentesTemporal.push(arrt);
                    arregloSensoresDiferentesTemporal2.push(arrt);
                }
                if(this.temperatureHumidityTotalSensor > 0){
                    conteoSensores++;
                    var arrt = {
                        nombreTipo: "Temperature and Humidity",
                        idSensor: 31
                    }
                    arregloSensoresDiferentesTemporal.push(arrt);
                    arregloSensoresDiferentesTemporal2.push(arrt);
                }
                if(this.estacionMeteorologicaTotalSensor > 0){
                    conteoSensores++;
                    var arrt = {
                        nombreTipo: "Estacion Meteorologica",
                        idSensor: 32
                    }
                    arregloSensoresDiferentesTemporal.push(arrt);
                    arregloSensoresDiferentesTemporal2.push(arrt);
                }
                if(this.phTotalSensor > 0){
                    conteoSensores++;
                    var arrt = {
                        nombreTipo: "PH",
                        idSensor: 36
                    }
                    arregloSensoresDiferentesTemporal.push(arrt);
                    arregloSensoresDiferentesTemporal2.push(arrt);
                }
                if(this.ecTotalSensor > 0){
                    conteoSensores++;
                    var arrt = {
                        nombreTipo: "EC",
                        idSensor: 37
                    }
                    arregloSensoresDiferentesTemporal.push(arrt);
                    arregloSensoresDiferentesTemporal2.push(arrt);
                }
                if(this.FlowWaterTotalSensor > 0){
                    conteoSensores++;
                    var arrt = {
                        nombreTipo: "Flow Water",
                        idSensor: 41
                    }
                    arregloSensoresDiferentesTemporal.push(arrt);
                    arregloSensoresDiferentesTemporal2.push(arrt);
                }
                if(this.DcMax1500TotalSensor > 0){
                    conteoSensores ++;
                    var arrt = {
                        nombreTipo: "Dc Max 1500",
                        idSensor: 47
                    }
                    arregloSensoresDiferentesTemporal.push(arrt);
                    arregloSensoresDiferentesTemporal2.push(arrt);
                }

                var arrtOtro = {
                    nombreTipo: "All",
                    idSensor: ''
                }

                arregloSensoresDiferentesTemporal2.push(arrtOtro);

                this.arregloSensoresDiferentes2 = arregloSensoresDiferentesTemporal2;
                this.arregloSensoresDiferentes = arregloSensoresDiferentesTemporal;
                this.conteoSensoresDiferentes = conteoSensores;

                this.selected.deviceSelected = this.devices[0];
                this.selected.imei = this.devices[0].imei;

                this.user_global = params.user_name;
                this.loadingFlagMain = false;
                this.loadingFlagSelect = false;
                
                if(cantidadEnergySensors >= 1){
                    this.seeEnergyFlag = 1;
                }else{
                    this.seeEnergyFlag = 0;
                }
            }).catch((err) => {
                console.log(err);
                if(err.response.status == 401){
                    localStorage.idUser = ''; 
                    localStorage.session_p = 0;
                    localStorage.token_p = '';
                    this.$router.push({path: '/'});
                    console.log("ERROR DE AUTENTICACION");
                }
            });
        },

        //Consulta de datos de dispositivo al mostrar datos iniciales (antes de recargar intervalo)
        async getAssetsUser(event){
            // alert('getAssetsUser')
            this.devices = [];
            const params = {
                user_id: localStorage.idUser,
                user_name: localStorage.user_p
            };
            await this.db_indexDevicesUser(params).then((result) => {
                var cantidadEnergySensors = 0;
                this.devices = result.data[0];
                cantidadEnergySensors= result.data[1];
                // alert('Respuesta de getAssetsUser');
                this.assets.deviceSelected = this.devices[0];
                this.assets.imei = this.devices[0].imei;
                this.user_global = params.user_name;
                if(cantidadEnergySensors >= 1){
                    this.seeEnergyFlag = 1;
                }else{
                    this.seeEnergyFlag = 0;
                }
                // this.assignSelected(event);
                return 'ok';
            }).catch((err) => {
                console.log(err);
                if(err.response.status == 401){
                    localStorage.idUser = ''; 
                    localStorage.session_p = 0;
                    localStorage.token_p = '';
                    this.$router.push({path: '/'});
                    console.log("ERROR DE AUTENTICACION");
                }
            });
        },

        //para recargar barra de clasificacion de sensores
        getDevicesUserBar(){
            const params = {
                user_id: localStorage.idUser,
                user_name: localStorage.user_p 
            };

            this.db_indexDevicesUser(params).then((result) => {
                var cantidadEnergySensors = 0;
                var cantidadSonometerSensors = 0;
                var cantidadAirQualitySensors = 0;
                var cantidadGasLevelSensors = 0;
                var cantidadEventsSensors = 0;
                var cantidadTouchTagSensors = 0;
                var cantidadHumedadTierraSensors = 0;
                var cantidadPiranometroSensors = 0;
                var cantidadTemperatureHumiditySensors = 0;
                var cantidadEstacionSensors = 0;
                var cantidadPHSensors = 0;
                var cantidadECSensors = 0;
                var cantidadFlowWaterSensors = 0;
                var cantidadDcMax1500 = 0;

                var arregloSensoresDiferentesTemporal = []
                var arregloSensoresDiferentesTemporal2 = []

                var arregloSensoresEnergyTemporal = []
                var arregloSensoresSonometerTemporal = []
                var arregloSensoresAirQualityTemporal = []
                var arregloSensoresGasLevelTemporal = []
                var arregloSensoresEventsTemporal = []
                var arregloSensoresTouchTagTemporal = []
                var arregloSensoresHumedadTierraTemporal = []
                var arregloSensoresPiranometerTemporal = []
                var arregloSensoresTemperatureHTemporal = []
                var arregloSensoresEstacionMTemporal = []
                var arregloSensoresPHTemporal = []
                var arregloSensoresECTemporal = []
                var arregloSensoresFlowWaterTemporal = []
                var arregloSensoresDcMax1500Temporal = []

                var conteoSensores = 0;
                var devicesBar = result.data[0];
                cantidadEnergySensors= result.data[1];

                devicesBar.forEach(element => {
                    if(element.idSensor == 1){
                        arregloSensoresEnergyTemporal.push(element);
                    }else if(element.idSensor == 2){
                        cantidadSonometerSensors++;
                        arregloSensoresSonometerTemporal.push(element);
                    }else if(element.idSensor == 3){
                        cantidadAirQualitySensors++;
                        arregloSensoresAirQualityTemporal.push(element);
                    }else if(element.idSensor == 7){
                        cantidadGasLevelSensors++;
                        arregloSensoresGasLevelTemporal.push(element);
                    }else if(element.idSensor == 8){
                        cantidadEventsSensors++;
                        arregloSensoresEventsTemporal.push(element);
                    }else if(element.idSensor == 11){
                        cantidadTouchTagSensors++;
                        arregloSensoresTouchTagTemporal.push(element);
                    }else if(element.idSensor == 29){
                        cantidadHumedadTierraSensors++;
                        arregloSensoresHumedadTierraTemporal.push(element);
                    }else if(element.idSensor == 30){
                        cantidadPiranometroSensors++;
                        arregloSensoresPiranometerTemporal.push(element);
                    }else if(element.idSensor == 31){
                        cantidadTemperatureHumiditySensors++;
                        arregloSensoresTemperatureHTemporal.push(element);
                    }else if(element.idSensor == 32){
                        cantidadEstacionSensors++;
                        arregloSensoresEstacionMTemporal.push(element);
                    }else if(element.idSensor == 36){
                        cantidadPHSensors++;
                        arregloSensoresPHTemporal.push(element);
                    }else if(element.idSensor == 37){
                        cantidadECSensors++;
                        arregloSensoresECTemporal.push(element);
                    }else if(element.idSensor == 41){
                        cantidadFlowWaterSensors++;
                        arregloSensoresFlowWaterTemporal.push(element);
                    }else if(element.idSensor == 47){
                        cantidadDcMax1500++;
                        arregloSensoresDcMax1500Temporal.push(element);
                    }
                });

                this.arregloSensoresEnergy = arregloSensoresEnergyTemporal;
                this.arregloSensoresSonometer = arregloSensoresSonometerTemporal;
                this.arregloSensoresAirQuality = arregloSensoresAirQualityTemporal;
                this.arregloSensoresGasLevel = arregloSensoresGasLevelTemporal;
                this.arregloSensoresEvents = arregloSensoresEventsTemporal;
                this.arregloSensoresTouchTag = arregloSensoresTouchTagTemporal;
                this.arregloSensoresHumedadTierra = arregloSensoresHumedadTierraTemporal;
                this.arregloSensoresPiranometer = arregloSensoresPiranometerTemporal;
                this.arregloSensoresTemperatureH = arregloSensoresTemperatureHTemporal;
                this.arregloSensoresEstacionM = arregloSensoresEstacionMTemporal;
                this.arregloSensoresPH = arregloSensoresPHTemporal;
                this.arregloSensoresEC = arregloSensoresECTemporal;
                this.arregloSensoresFlowWater = arregloSensoresFlowWaterTemporal;
                this.arregloSensoresDcMax1500 = arregloSensoresDcMax1500Temporal;

                this.energyTotalSensor = cantidadEnergySensors;
                this.sonometerTotalSensor = cantidadSonometerSensors;
                this.airqualityTotalSensor = cantidadAirQualitySensors;
                this.gaslevelTotalSensor = cantidadGasLevelSensors;
                this.eventsTotalSensor = cantidadEventsSensors;
                this.touchtagTotalSensor = cantidadTouchTagSensors;
                this.humedadtierraTotalSensor = cantidadHumedadTierraSensors;
                this.piranometroTotalSensor = cantidadPiranometroSensors;
                this.temperatureHumidityTotalSensor = cantidadTemperatureHumiditySensors;
                this.estacionMeteorologicaTotalSensor = cantidadEstacionSensors;
                this.phTotalSensor = cantidadPHSensors;
                this.ecTotalSensor = cantidadECSensors;
                this.FlowWaterTotalSensor = cantidadFlowWaterSensors;
                this.DcMax1500TotalSensor = cantidadDcMax1500

                if(this.energyTotalSensor > 0){
                    conteoSensores++;
                    var arrt = {
                        nombreTipo: "Energy",
                        idSensor: 1
                    }
                    arregloSensoresDiferentesTemporal.push(arrt);
                    arregloSensoresDiferentesTemporal2.push(arrt);
                }
                if(this.sonometerTotalSensor > 0){
                    conteoSensores++;
                    var arrt = {
                        nombreTipo: "Sonometer",
                        idSensor: 2
                    }
                    arregloSensoresDiferentesTemporal.push(arrt);
                    arregloSensoresDiferentesTemporal2.push(arrt);
                }
                if(this.airqualityTotalSensor > 0){
                    conteoSensores++;
                    var arrt = {
                        nombreTipo: "Air Quality",
                        idSensor: 3
                    }
                    arregloSensoresDiferentesTemporal.push(arrt);
                    arregloSensoresDiferentesTemporal2.push(arrt);
                }
                if(this.gaslevelTotalSensor > 0){
                    conteoSensores++;
                    var arrt = {
                        nombreTipo: "Gas Level",
                        idSensor: 7
                    }
                    arregloSensoresDiferentesTemporal.push(arrt);
                    arregloSensoresDiferentesTemporal2.push(arrt);
                }
                if(this.eventsTotalSensor > 0){
                    conteoSensores++;
                    var arrt = {
                        nombreTipo: "Events",
                        idSensor: 8
                    }
                    arregloSensoresDiferentesTemporal.push(arrt);
                    arregloSensoresDiferentesTemporal2.push(arrt);
                }
                if(this.touchtagTotalSensor > 0){
                    conteoSensores++;
                    var arrt = {
                        nombreTipo: "Touch Tag",
                        idSensor: 11
                    }
                    arregloSensoresDiferentesTemporal.push(arrt);
                    arregloSensoresDiferentesTemporal2.push(arrt);
                }
                if(this.humedadtierraTotalSensor > 0){
                    conteoSensores++;
                    var arrt = {
                        nombreTipo: "Humedad en Tierra",
                        idSensor: 29
                    }
                    arregloSensoresDiferentesTemporal.push(arrt);
                    arregloSensoresDiferentesTemporal2.push(arrt);
                }
                if(this.piranometroTotalSensor > 0){
                    conteoSensores++;
                    var arrt = {
                        nombreTipo: "Piranometer",
                        idSensor: 30
                    }
                    arregloSensoresDiferentesTemporal.push(arrt);
                    arregloSensoresDiferentesTemporal2.push(arrt);
                }
                if(this.temperatureHumidityTotalSensor > 0){
                    conteoSensores++;
                    var arrt = {
                        nombreTipo: "Temperature and Humidity",
                        idSensor: 31
                    }
                    arregloSensoresDiferentesTemporal.push(arrt);
                    arregloSensoresDiferentesTemporal2.push(arrt);
                }
                if(this.estacionMeteorologicaTotalSensor > 0){
                    conteoSensores++;
                    var arrt = {
                        nombreTipo: "Estacion Meteorologica",
                        idSensor: 32
                    }
                    arregloSensoresDiferentesTemporal.push(arrt);
                    arregloSensoresDiferentesTemporal2.push(arrt);
                }
                if(this.phTotalSensor > 0){
                    conteoSensores++;
                    var arrt = {
                        nombreTipo: "PH",
                        idSensor: 36
                    }
                    arregloSensoresDiferentesTemporal.push(arrt);
                    arregloSensoresDiferentesTemporal2.push(arrt);
                }
                if(this.ecTotalSensor > 0){
                    conteoSensores++;
                    var arrt = {
                        nombreTipo: "EC",
                        idSensor: 37
                    }
                    arregloSensoresDiferentesTemporal.push(arrt);
                    arregloSensoresDiferentesTemporal2.push(arrt);
                }
                if(this.FlowWaterTotalSensor > 0){
                    conteoSensores++;
                    var arrt = {
                        nombreTipo: "Flow Water",
                        idSensor: 41
                    }
                    arregloSensoresDiferentesTemporal.push(arrt);
                    arregloSensoresDiferentesTemporal2.push(arrt);
                }
                if(this.DcMax1500TotalSensor > 0){
                    conteoSensores++;
                    var arrt = {
                        nombreTipo: "DCMAX 1500",
                        idSensor: 47
                    }
                    arregloSensoresDiferentesTemporal.push(arrt);
                    arregloSensoresDiferentesTemporal2.push(arrt);
                }

                var arrtOtro = {
                    nombreTipo: "All",
                    idSensor: ''
                }

                arregloSensoresDiferentesTemporal2.push(arrtOtro);

                this.arregloSensoresDiferentes2 = arregloSensoresDiferentesTemporal2;
                this.arregloSensoresDiferentes = arregloSensoresDiferentesTemporal;
                this.conteoSensoresDiferentes = conteoSensores;

            }).catch((err) => {
                console.log(err);
                if(err.response.status == 401){
                    localStorage.idUser = ''; 
                    localStorage.session_p = 0;
                    localStorage.token_p = '';
                    this.$router.push({path: '/'});
                    console.log("ERROR DE AUTENTICACION");
                }
            });

        },
        
        //Asignacion de variables al seleccionar un equipo en dashboard
        async assignSelected(event){
            // alert('assignSelected')
            localStorage.setItem('Imei', event);
            let imeiB64 = window.btoa(event);
            this.$router.push({path: `/dashboard/${this.selected.fechaFrom}+${this.selected.fechaTo}/${imeiB64}`});

            this.switchFlag = true; //Para cuando seleccione otro sensor resetear switch de carga
            this.loadingFlag = true;
            this.loadingFlagHistorico = true;
            //this.loadingFlagSelect = true;
            let device = this.devices.find(device => device.imei == event);
            this.selected.deviceSelected = device;
            
            await this.getAssetsUser();
            let idSensor = this.assets.deviceSelected?.idSensor;
            
            // let idSensor = 31;
            // console.log(this.assets.deviceSelected.idSensor);
            // console.log(JSON.parse(this.assets).deviceSelected);
            let fechaGps = this.assets.deviceSelected?.fechaGps;
            this.colorText = this.lastUpdate(fechaGps);
            this.assets.imei = this.assets.deviceSelected?.imei;

            if(this.selected.deviceSelected.posicion == null || this.selected.deviceSelected.posicion == "" || this.selected.deviceSelected.posicion == ''){
                this.posicion.latitud = 0.0;
                this.posicion.longitud = 0.0;
            }else{
                var jsonPosicion= JSON.parse(this.selected.deviceSelected.posicion);
                if(jsonPosicion.hasOwnProperty('lat')){
                    this.posicion.latitud = this.calcularCoordenada(jsonPosicion.lat.toString());
                }else{
                    this.posicion.latitud = 0.0;
                }
                if(jsonPosicion.hasOwnProperty('long')){
                    this.posicion.longitud = this.calcularCoordenada(jsonPosicion.long.toString());
                }else{
                    this.posicion.longitud = 0.0;
                }
            } 
            if(idSensor == 1){ //ENERGY
                this.assignEnergyVariables(this.assets.deviceSelected);
                if(this.switchFlag == true){
                    this.getDataSensorPosition(this.assets);
                }else{
                    this.loadingFlagHistorico = false
                    this.loadingFlag = false;
                    //this.loadingFlagChartReport = false;
                }
            }else if(idSensor == 2){ //SONOMETRO
                var jsonConfiguracion = JSON.parse(this.assets.deviceSelected.configuracion);
                var jsonConfiguracionAlert = JSON.parse(this.assets.deviceSelected.alertConfig);
                var jsonDatos = JSON.parse(this.assets.deviceSelected.datos);

                this.sonometerLevel.lowLevel = jsonConfiguracion.lowLevel;
                this.sonometerLevel.normalLevel =jsonConfiguracion.normalLevel
                this.sonometerLevel.highLevel = jsonConfiguracion.highLevel
                this.sonometerLevel.alerts = jsonConfiguracion.alerts;
                this.sonometerLevel.mail = jsonConfiguracion.mail;
                this.sonometerDecibeles = jsonDatos.sonometer;

                if(jsonConfiguracionAlert.hasOwnProperty('alertEnable')){
                    //console.log("HAY ALERT ENABLE");
                    this.sonometerAlertConfig.alertEnable = jsonConfiguracionAlert.alertEnable;
                }else{
                    //console.log("NO HAY ALERT ENABLE");
                    this.sonometerAlertConfig.alertEnable = 'NO'
                }

                if(jsonConfiguracionAlert.hasOwnProperty('emails')){
                    //console.log("HAY EMAILS");
                    this.sonometerAlertConfig.emails = jsonConfiguracionAlert.emails;
                }else{
                    //console.log("NO HAY EMAILS");
                    this.sonometerAlertConfig.emails = []
                }

                if (typeof this.sonometerLevel.lowLevel == 'undefined') {
                    this.sonometerLevel.lowLevel = null;
                }
                if (typeof this.sonometerLevel.normalLevel == 'undefined') {
                    this.sonometerLevel.normalLevel = null;
                }
                if (typeof this.sonometerLevel.highLevel == 'undefined') {
                    this.sonometerLevel.highLevel = null;
                }
                if (typeof this.sonometerDecibeles == 'undefined') {
                    this.sonometerDecibeles = null;
                }
                if (typeof this.sonometerLevel.alerts == 'undefined') {
                    this.sonometerLevel.alerts = null;
                }
                if (typeof this.sonometerLevel.mail == 'undefined') {
                    this.sonometerLevel.mail = null;
                }
                if(this.switchFlag == true){
                    this.getDataSensorPosition(this.assets);
                    console.log("ACTUALIZADO");
                }else{
                    this.loadingFlagHistorico = false
                    this.loadingFlag = false;
                    //this.loadingFlagChartReport = false;
                    console.log("NO ACTUALIZADO");
                }
            }else if(idSensor == 3){ //AIR QUALITY
                var jsonConfiguracion = JSON.parse(this.assets.deviceSelected.configuracion);
                var jsonConfiguracionAlert = JSON.parse(this.assets.deviceSelected.alertConfig);
                var jsonDatos = JSON.parse(this.assets.deviceSelected.datos);

                this.airQualityConfig.alerts = jsonConfiguracion.alerts;
                this.airQualityConfig.mail = jsonConfiguracion.mail;
                this.airQualityData.quality = jsonDatos.quality;
                this.airQualityData.tvoc = jsonDatos.tvoc;

                if(jsonConfiguracionAlert.hasOwnProperty('alertEnable')){
                    //console.log("HAY ALERT ENABLE");
                    this.airQualityAlertConfig.alertEnable = jsonConfiguracionAlert.alertEnable;
                }else{
                    //console.log("NO HAY ALERT ENABLE");
                    this.airQualityAlertConfig.alertEnable = 'NO'
                }

                if(jsonConfiguracionAlert.hasOwnProperty('emails')){
                    //console.log("HAY EMAILS");
                    this.airQualityAlertConfig.emails = jsonConfiguracionAlert.emails;
                }else{
                    //console.log("NO HAY EMAILS");
                    this.airQualityAlertConfig.emails = []
                }
                
                if (typeof this.airQualityData.quality == 'undefined') {
                    this.airQualityData.quality = null;
                }
                if (typeof this.airQualityData.tvoc == 'undefined') {
                    this.airQualityData.tvoc = null;
                }
                if (typeof this.airQualityConfig.alerts == 'undefined') {
                    this.airQualityConfig.alerts = null;
                }
                if (typeof this.airQualityConfig.mail == 'undefined') {
                    this.airQualityConfig.mail = null;
                }
                if(this.switchFlag == true){
                    this.getDataSensorPosition(this.assets);
                    console.log("ACTUALIZADO");
                }else{
                    this.loadingFlagHistorico = false
                    this.loadingFlag = false;
                    //this.loadingFlagChartReport = false;
                    console.log("NO ACTUALIZADO");
                }
            }else if(idSensor == 7){ //GAS LEVEL
                var jsonConfiguracion = JSON.parse(this.assets.deviceSelected.configuracion);
                var jsonConfiguracionAlert = JSON.parse(this.assets.deviceSelected.alertConfig);
                var jsonDatos = JSON.parse(this.assets.deviceSelected.datos);

                this.gasLevel.lowLevel = jsonConfiguracion.lowLevel;
                this.gasLevel.normalLevel =jsonConfiguracion.normalLevel
                this.gasLevel.highLevel = jsonConfiguracion.highLevel
                this.gasLevel.alerts = jsonConfiguracion.alerts;
                this.gasLevel.mail = jsonConfiguracion.mail;
                this.gLevel = jsonDatos.level;

                if(jsonConfiguracionAlert.hasOwnProperty('alertEnable')){
                    //console.log("HAY ALERT ENABLE");
                    this.gasLevelAlertConfig.alertEnable = jsonConfiguracionAlert.alertEnable;
                }else{
                    //console.log("NO HAY ALERT ENABLE");
                    this.gasLevelAlertConfig.alertEnable = 'NO'
                }

                if(jsonConfiguracionAlert.hasOwnProperty('emails')){
                    //console.log("HAY EMAILS");
                    this.gasLevelAlertConfig.emails = jsonConfiguracionAlert.emails;
                }else{
                    //console.log("NO HAY EMAILS");
                    this.gasLevelAlertConfig.emails = []
                }

                if (typeof this.gasLevel.lowLevel == 'undefined') {
                    this.gasLevel.lowLevel = null;
                }
                if (typeof this.gasLevel.normalLevel == 'undefined') {
                    this.gasLevel.normalLevel = null;
                }
                if (typeof this.gasLevel.highLevel == 'undefined') {
                    this.gasLevel.highLevel = null;
                }
                if (typeof this.gLevel == 'undefined') {
                    this.gLevel = null;
                }
                if (typeof this.gasLevel.alerts == 'undefined') {
                    this.gasLevel.alerts = null;
                }
                if (typeof this.gasLevel.mail == 'undefined') {
                    this.gasLevel.mail = null;
                }
                if(this.switchFlag == true){
                    this.getDataSensorPosition(this.assets);
                    console.log("ACTUALIZADO");
                }else{
                    this.loadingFlagHistorico = false
                    this.loadingFlag = false;
                    //this.loadingFlagChartReport = false;
                    console.log("NO ACTUALIZADO");
                }
            }else if(idSensor == 8){ //EVENTOS
                var jsonConfiguracion = JSON.parse(this.assets.deviceSelected.configuracion);
                var jsonConfiguracionAlert = JSON.parse(this.assets.deviceSelected.alertConfig);
                var jsonDatos = JSON.parse(this.assets.deviceSelected.datos);
                var numeroVariables = Object.keys(jsonDatos).length
                this.eventsData.ev1 = jsonDatos.ev1;
                this.eventsData.ev2 = jsonDatos.ev2;
                this.eventsData.ev3 = jsonDatos.ev3;
                this.eventsData.ev4 = jsonDatos.ev4;

                if(jsonConfiguracionAlert.hasOwnProperty('alertEnable')){
                    //console.log("HAY ALERT ENABLE");
                    this.eventsAlertsConfig.alertEnable = jsonConfiguracionAlert.alertEnable;
                }else{
                    //console.log("NO HAY ALERT ENABLE");
                    this.eventsAlertsConfig.alertEnable = 'NO'
                }

                if(jsonConfiguracionAlert.hasOwnProperty('emails')){
                    //console.log("HAY EMAILS");
                    this.eventsAlertsConfig.emails = jsonConfiguracionAlert.emails;
                }else{
                    //console.log("NO HAY EMAILS");
                    this.eventsAlertsConfig.emails = []
                }

                if (typeof this.eventsData.ev1 == 'undefined') {
                    this.eventsData.ev1 = null;
                }
                if (typeof this.eventsData.ev2 == 'undefined') {
                    this.eventsData.ev2 = null;
                }
                if (typeof this.eventsData.ev3 == 'undefined') {
                    this.eventsData.ev3 = null;
                }
                if (typeof this.eventsData.ev4 == 'undefined') {
                    this.eventsData.ev4 = null;
                }
                if(this.switchFlag == true){
                    this.getDataSensorPosition(this.assets);
                    console.log("ACTUALIZADO");
                }else{
                    this.loadingFlagHistorico = false
                    this.loadingFlag = false;
                    //this.loadingFlagChartReport = false;
                    console.log("NO ACTUALIZADO");
                }
            }else if(idSensor == 11){ //TOUCH TAG
                var jsonConfiguracion = JSON.parse(this.assets.deviceSelected.configuracion);
                var jsonConfiguracionAlert = JSON.parse(this.assets.deviceSelected.alertConfig);
                var jsonDatos = JSON.parse(this.assets.deviceSelected.datos);
                this.touchTagData.temperature = jsonDatos.temperature;
                this.touchTagData.pitch = jsonDatos.pitch;
                this.touchTagData.roll = jsonDatos.roll;

                if(jsonConfiguracionAlert.hasOwnProperty('alertEnable')){
                    //console.log("HAY ALERT ENABLE");
                    this.touchAlertConfig.alertEnable = jsonConfiguracionAlert.alertEnable;
                }else{
                    //console.log("NO HAY ALERT ENABLE");
                    this.touchAlertConfig.alertEnable = 'NO'
                }

                if(jsonConfiguracionAlert.hasOwnProperty('emails')){
                    //console.log("HAY EMAILS");
                    this.touchAlertConfig.emails = jsonConfiguracionAlert.emails;
                }else{
                    //console.log("NO HAY EMAILS");
                    this.touchAlertConfig.emails = []
                }

                if (typeof this.touchTagData.temperature == 'undefined') {
                    this.touchTagData.temperature = null;
                }
                if (typeof this.touchTagData.pitch == 'undefined') {
                    this.touchTagData.pitch = null;
                }
                if (typeof this.touchTagData.roll == 'undefined') {
                    this.touchTagData.roll = null;
                }
                if(this.switchFlag == true){
                    this.getDataSensorPosition(this.assets);
                    console.log("ACTUALIZADO");
                }else{
                    this.loadingFlagHistorico = false
                    this.loadingFlag = false;
                    //this.loadingFlagChartReport = false;
                    console.log("NO ACTUALIZADO");
                }
            }else if(idSensor == 29){ //HUMEDAD EN TIERRA
                var jsonConfiguracion = JSON.parse(this.assets.deviceSelected.configuracion);
                var jsonConfiguracionAlert = JSON.parse(this.assets.deviceSelected.alertConfig);
                var jsonDatos = JSON.parse(this.assets.deviceSelected.datos);
                this.temperatureAndHumidityData.temperature = jsonDatos.temperature;
                this.temperatureAndHumidityData.humidity = jsonDatos.humidity;

                if(jsonConfiguracionAlert.hasOwnProperty('alertEnable')){
                    //console.log("HAY ALERT ENABLE");
                    this.temperatureAndHumidityAlertConfig.alertEnable = jsonConfiguracionAlert.alertEnable;
                }else{
                    //console.log("NO HAY ALERT ENABLE");
                    this.temperatureAndHumidityAlertConfig.alertEnable = 'NO'
                }

                if(jsonConfiguracionAlert.hasOwnProperty('emails')){
                    //console.log("HAY EMAILS");
                    this.temperatureAndHumidityAlertConfig.emails = jsonConfiguracionAlert.emails;
                }else{
                    //console.log("NO HAY EMAILS");
                    this.temperatureAndHumidityAlertConfig.emails = []
                }

                if (typeof this.temperatureAndHumidityData.temperature == 'undefined') {
                    this.temperatureAndHumidityData.temperature = null;
                }
                if (typeof this.temperatureAndHumidityData.humidity == 'undefined') {
                    this.temperatureAndHumidityData.humidity = null;
                }

                if(this.switchFlag == true){
                    this.getDataSensorPosition(this.assets);
                    console.log("ACTUALIZADO");
                }else{
                    this.loadingFlagHistorico = false
                    this.loadingFlag = false;
                    //this.loadingFlagChartReport = false;
                    console.log("NO ACTUALIZADO");
                }

            }else if(idSensor == 30){ //PIRANOMETRO
                var jsonConfiguracion = JSON.parse(this.assets.deviceSelected.configuracion);
                var jsonConfiguracionAlert = JSON.parse(this.assets.deviceSelected.alertConfig);
                var jsonDatos = JSON.parse(this.assets.deviceSelected.datos);
                this.piranometerData.temperature = jsonDatos.temperature;
                this.piranometerData.wattmetro = jsonDatos.wattmetro;

                if(jsonConfiguracionAlert.hasOwnProperty('alertEnable')){
                    //console.log("HAY ALERT ENABLE");
                    this.piranometerAlertConfig.alertEnable = jsonConfiguracionAlert.alertEnable;
                }else{
                    //console.log("NO HAY ALERT ENABLE");
                    this.piranometerAlertConfig.alertEnable = 'NO'
                }

                if(jsonConfiguracionAlert.hasOwnProperty('emails')){
                    //console.log("HAY EMAILS");
                    this.piranometerAlertConfig.emails = jsonConfiguracionAlert.emails;
                }else{
                    //console.log("NO HAY EMAILS");
                    this.piranometerAlertConfig.emails = []
                }

                if (typeof this.piranometerData.temperature == 'undefined') {
                    this.piranometerData.temperature = null;
                }
                if (typeof this.piranometerData.wattmetro == 'undefined') {
                    this.piranometerData.wattmetro = null;
                }
                if(this.switchFlag == true){
                    // this.getDataSensorPosition(this.assets);
                    this.getDataSensorPositionHistorico(this.assets, 0);
                    console.log("ACTUALIZADO");
                    if(this.selected.fechaTo!=this.actualDateRange[1]){
                        this.switchFlag=false;
                    }
                }else{
                    this.loadingFlagHistorico = false
                    this.loadingFlag = false;
                    //this.loadingFlagChartReport = false;
                    console.log("NO ACTUALIZADO");
                }
            }else if(idSensor == 31){ //TEMPERATURE AND HUMIDITY
                // alert('Temperatura y humedad')
                var jsonConfiguracion = JSON.parse(this.assets.deviceSelected.configuracion);
                var jsonConfiguracionAlert = JSON.parse(this.assets.deviceSelected.alertConfig);
                var jsonDatos = JSON.parse(this.assets.deviceSelected.datos);
                // this.temperatureAndHumidityData.temperature = jsonDatos.temperature;
                this.temperatureAndHumidityData.temperature = jsonDatos.temperature;
                this.temperatureAndHumidityData.humidity = jsonDatos.humidity;
                // if(this.selected.fechaTo!=this.actualDateRange[1]){
                //     this.switchFlag=false;
                // }
                // if(this.switchFlag==true){
                //     // alert(this.dateRangeReportSelected.length)
                //     if(this.dateRangeReportSelected.length==0){
                //         this.getRango([[this.actualDateRange[0],this.actualDateRange[1]]]);
                //     }else{
                //         this.getRango([[this.dateRangeReportSelected[0], this.dateRangeReportSelected[1]]]);
                //     }
                // }
                

                if(jsonConfiguracionAlert.hasOwnProperty('alertEnable')){
                    //console.log("HAY ALERT ENABLE");
                    this.temperatureAndHumidityAlertConfig.alertEnable = jsonConfiguracionAlert.alertEnable;
                }else{
                    //console.log("NO HAY ALERT ENABLE");
                    this.temperatureAndHumidityAlertConfig.alertEnable = 'NO'
                }

                if(jsonConfiguracionAlert.hasOwnProperty('emails')){
                    //console.log("HAY EMAILS");
                    this.temperatureAndHumidityAlertConfig.emails = jsonConfiguracionAlert.emails;
                }else{
                    //console.log("NO HAY EMAILS");
                    this.temperatureAndHumidityAlertConfig.emails = []
                }

                if(jsonConfiguracionAlert.hasOwnProperty('tempMin')){
                    //console.log("HAY EMAILS");
                    this.temperatureAndHumidityAlertConfig.tempMin = jsonConfiguracionAlert.tempMin;
                }else{
                    //console.log("NO HAY EMAILS");
                    this.temperatureAndHumidityAlertConfig.tempMin = 0
                }

                if(jsonConfiguracionAlert.hasOwnProperty('tempMax')){
                    //console.log("HAY EMAILS");
                    this.temperatureAndHumidityAlertConfig.tempMax = jsonConfiguracionAlert.tempMax;
                }else{
                    //console.log("NO HAY EMAILS");
                    this.temperatureAndHumidityAlertConfig.tempMax = 0
                }

                if (typeof this.temperatureAndHumidityData.temperature == 'undefined') {
                    this.temperatureAndHumidityData.temperature = null;
                }
                if (typeof this.temperatureAndHumidityData.humidity == 'undefined') {
                    this.temperatureAndHumidityData.humidity = null;
                }
                if(this.switchFlag == true){
                    // this.getDataSensorPosition(this.assets);
                    this.getDataSensorPositionHistorico(this.selected, 0);
                    console.log("ACTUALIZADO");
                    if(this.selected.fechaTo!=this.actualDateRange[1]){
                        this.switchFlag=false;
                    }
                    // if(this.switchFlag==true){
                    //     // alert(this.dateRangeReportSelected.length)
                    //     if(this.dateRangeReportSelected.length==0){
                    //         this.getRango([[this.actualDateRange[0],this.actualDateRange[1]]]);
                    //     }else{
                    //         this.getRango([[this.dateRangeReportSelected[0], this.dateRangeReportSelected[1]]]);
                    //     }
                    // }
                }else{
                    this.loadingFlagHistorico = false
                    this.loadingFlag = false;
                    //this.loadingFlagChartReport = false;
                    console.log("NO ACTUALIZADO");
                }
            }else if(idSensor == 32){ //ESTACION METEOROLOGICA
                var jsonConfiguracion = JSON.parse(this.assets.deviceSelected.configuracion);
                var jsonConfiguracionAlert = JSON.parse(this.assets.deviceSelected.alertConfig);
                var jsonDatos = JSON.parse(this.assets.deviceSelected.datos);

                this.estacionMeteorologicaData.wind_speed = jsonDatos.wind_speed;
                this.estacionMeteorologicaData.rain_volume = jsonDatos.rain_volume;
                this.estacionMeteorologicaData.temperature = jsonDatos.temperature;
                this.estacionMeteorologicaData.direction_wind = jsonDatos.direction_wind;
                this.estacionMeteorologicaData.relative_humidity = jsonDatos.relative_humidity;
                this.estacionMeteorologicaData.pascal_pressure = jsonDatos.pascal_pressure;

                if(jsonConfiguracionAlert.hasOwnProperty('alertEnable')){
                    //console.log("HAY ALERT ENABLE");
                    this.estacionMeteorologicaAlertConfig.alertEnable = jsonConfiguracionAlert.alertEnable;
                }else{
                    //console.log("NO HAY ALERT ENABLE");
                    this.estacionMeteorologicaAlertConfig.alertEnable = 'NO'
                }

                if(jsonConfiguracionAlert.hasOwnProperty('emails')){
                    //console.log("HAY EMAILS");
                    this.estacionMeteorologicaAlertConfig.emails = jsonConfiguracionAlert.emails;
                }else{
                    //console.log("NO HAY EMAILS");
                    this.estacionMeteorologicaAlertConfig.emails = []
                }
                
                if (typeof this.estacionMeteorologicaData.wind_speed == 'undefined') {
                    this.estacionMeteorologicaData.wind_speed = null;
                }
                if (typeof this.estacionMeteorologicaData.rain_volume == 'undefined') {
                    this.estacionMeteorologicaData.rain_volume = null;
                }

                if (typeof this.estacionMeteorologicaData.temperature == 'undefined') {
                    this.estacionMeteorologicaData.temperature = null;
                }
                if (typeof this.estacionMeteorologicaData.direction_wind == 'undefined') {
                    this.estacionMeteorologicaData.direction_wind = null;
                }
                if (typeof this.estacionMeteorologicaData.relative_humidity == 'undefined') {
                    this.estacionMeteorologicaData.relative_humidity = null;
                }
                if (typeof this.estacionMeteorologicaData.pascal_pressure == 'undefined') {
                    this.estacionMeteorologicaData.pascal_pressure = null;
                }
                if(this.switchFlag == true){
                    this.getDataSensorPosition(this.assets);
                    console.log("ACTUALIZADO");
                }else{
                    this.loadingFlagHistorico = false
                    this.loadingFlag = false;
                    //this.loadingFlagChartReport = false;
                    console.log("NO ACTUALIZADO");
                }
            }else if(idSensor == 36){ //PH
                var jsonConfiguracion = JSON.parse(this.assets.deviceSelected.configuracion);
                var jsonConfiguracionAlert = JSON.parse(this.assets.deviceSelected.alertConfig);
                var jsonDatos = JSON.parse(this.assets.deviceSelected.datos);

                this.phConfig.alerts = jsonConfiguracion.alerts;
                this.phConfig.mail = jsonConfiguracion.mail;
                this.phData.Temperatura = jsonDatos.Temperatura;
                this.phData.nivel = jsonDatos.nivel;

                if(jsonConfiguracionAlert.hasOwnProperty('alertEnable')){
                    //console.log("HAY ALERT ENABLE");
                    this.phAlertConfig.alertEnable = jsonConfiguracionAlert.alertEnable;
                }else{
                    //console.log("NO HAY ALERT ENABLE");
                    this.phAlertConfig.alertEnable = 'NO'
                }

                if(jsonConfiguracionAlert.hasOwnProperty('emails')){
                    //console.log("HAY EMAILS");
                    this.phAlertConfig.emails = jsonConfiguracionAlert.emails;
                }else{
                    //console.log("NO HAY EMAILS");
                    this.phAlertConfig.emails = []
                }

                if (typeof this.phData.Temperatura == 'undefined') {
                    this.phData.Temperatura = null;
                }
                if (typeof this.phData.nivel == 'undefined') {
                    this.phData.nivel = null;
                }

                if (typeof this.phConfig.alerts == 'undefined') {
                    this.phConfig.alerts = null;
                }
                if (typeof this.phConfig.mail == 'undefined') {
                    this.phConfig.mail = null;
                }
                if(this.switchFlag == true){
                    this.getDataSensorPosition(this.assets);
                    console.log("ACTUALIZADO");
                }else{
                    this.loadingFlagHistorico = false
                    this.loadingFlag = false;
                    //this.loadingFlagChartReport = false;
                    console.log("NO ACTUALIZADO");
                }
            }else if(idSensor == 37){ //EC
                var jsonConfiguracion = JSON.parse(this.assets.deviceSelected.configuracion);
                var jsonConfiguracionAlert = JSON.parse(this.assets.deviceSelected.alertConfig);
                var jsonDatos = JSON.parse(this.assets.deviceSelected.datos);

                this.ecConfig.alerts = jsonConfiguracion.alerts;
                this.ecConfig.mail = jsonConfiguracion.mail;
                this.ecData.Temperatura = jsonDatos.Temperatura;
                this.ecData.uScm = jsonDatos.uScm;

                if(jsonConfiguracionAlert.hasOwnProperty('alertEnable')){
                    //console.log("HAY ALERT ENABLE");
                    this.ecAlertConfig.alertEnable = jsonConfiguracionAlert.alertEnable;
                }else{
                    //console.log("NO HAY ALERT ENABLE");
                    this.ecAlertConfig.alertEnable = 'NO'
                }

                if(jsonConfiguracionAlert.hasOwnProperty('emails')){
                    //console.log("HAY EMAILS");
                    this.ecAlertConfig.emails = jsonConfiguracionAlert.emails;
                }else{
                    //console.log("NO HAY EMAILS");
                    this.ecAlertConfig.emails = []
                }

                console.log("BANDERA BACK ALERTAS 1");
                console.log(this.ecAlertConfig);

                if (typeof this.ecData.Temperatura == 'undefined') {
                    this.ecData.Temperatura = null;
                }
                if (typeof this.ecData.uScm == 'undefined') {
                    this.ecData.uScm = null;
                }

                if (typeof this.ecConfig.alerts == 'undefined') {
                    this.ecConfig.alerts = null;
                }
                if (typeof this.ecConfig.mail == 'undefined') {
                    this.ecConfig.mail = null;
                }
                if(this.switchFlag == true){
                    this.getDataSensorPosition(this.assets);
                    console.log("ACTUALIZADO");
                }else{
                    this.loadingFlagHistorico = false
                    this.loadingFlag = false;
                    //this.loadingFlagChartReport = false;
                    console.log("NO ACTUALIZADO");
                }
            }else if(idSensor == 41){ //FLOWWATER
                var jsonConfiguracion = JSON.parse(this.assets.deviceSelected.configuracion);
                var jsonConfiguracionAlert = JSON.parse(this.assets.deviceSelected.alertConfig);
                var jsonDatos = JSON.parse(this.assets.deviceSelected.datos);

                this.flowWaterData.lts_minute = jsonDatos.lts_minute;
                this.flowWaterData.lts_total = jsonDatos.lts_total;
                this.flowWaterData.total_litter = this.assets.deviceSelected.total_litter;
                this.flowWaterData.today_litters = this.assets.deviceSelected.today_litters;
                this.flowWaterData.monthly_litters = this.assets.deviceSelected.monthly_litters;

                if(jsonConfiguracionAlert.hasOwnProperty('alertEnable')){
                    //console.log("HAY ALERT ENABLE");
                    this.flowWaterAlertConfig.alertEnable = jsonConfiguracionAlert.alertEnable;
                    this.flowAlertConfigTemporal.alertEnable = jsonConfiguracionAlert.alertEnable;
                }else{
                    //console.log("NO HAY ALERT ENABLE");
                    this.flowWaterAlertConfig.alertEnable = 'NO'
                    this.flowAlertConfigTemporal.alertEnable = 'NO';
                }

                if(jsonConfiguracionAlert.hasOwnProperty('emails')){
                    //console.log("HAY EMAILS");
                    this.flowWaterAlertConfig.emails = jsonConfiguracionAlert.emails;
                    this.flowAlertConfigTemporal.emails = jsonConfiguracionAlert.emails;
                }else{
                    //console.log("NO HAY EMAILS");
                    this.flowWaterAlertConfig.emails = []
                    this.flowAlertConfigTemporal.emails = []
                }

                var objectTemporal = {
                    alertEnable: this.flowWaterAlertConfig.alertEnable,
                    emails: this.flowWaterAlertConfig.emails
                }                
                

                if (typeof this.flowWaterData.lts_minute == 'undefined') {
                    this.flowWaterData.lts_minute = null;
                }
                if (typeof this.flowWaterData.lts_total == 'undefined') {
                    this.flowWaterData.lts_total = null;
                }
                if(this.switchFlag == true){
                    this.getDataSensorPosition(this.assets);
                    console.log("ACTUALIZADO");
                }else{
                    this.loadingFlagHistorico = false
                    this.loadingFlag = false;
                    console.log("NO ACTUALIZADO");
                }
            }
            else if(idSensor == 47){ //DCMAX1500
            
                var jsonConfiguracion = (this.assets.deviceSelected != null) ? JSON.parse(this.assets.deviceSelected.configuracion) : null;
                var jsonConfiguracionAlert = (this.assets.deviceSelected != null) ? JSON.parse(this.assets.deviceSelected.alertConfig) : null;
                var jsonDatos = (this.assets.deviceSelected != null) ? JSON.parse(this.assets.deviceSelected.datos) : null;
                                
                this.DcMaxData.powerNow = (this.assets.deviceSelected != null) ? this.assets.deviceSelected.power_now : 0;
                this.DcMaxData.todayEnergy = (this.assets.deviceSelected != null) ? this.assets.deviceSelected.today_energy : 0;
                this.DcMaxData.monthlyEnergy = (this.assets.deviceSelected != null) ? this.assets.deviceSelected.monthly_energy : 0;

               
                if (typeof this.DcMaxData.powerNow == 'undefined') {
                    this.DcMaxData.powerNow = null;
                }
                if (typeof this.DcMaxData.todayEnergy == 'undefined') {
                    this.DcMaxData.todayEnergy = null;
                }
                if (typeof this.DcMaxData.monthlyEnergy == 'undefined') {
                    this.DcMaxData.monthlyEnergy = null;
                }
                if(this.switchFlag == true){
                    this.getDataSensorPosition(this.assets);
                    console.log("ACTUALIZADO");
                }else{
                    this.loadingFlagHistorico = false
                    this.loadingFlag = false;
                    //this.loadingFlagChartReport = false;
                    console.log("NO ACTUALIZADO");
                }
            }else if(idSensor == 48){ //Bidiriccional
                console.log("first+++++++++++++");
                console.log(this.selected.deviceSelected)

                // this.Bidireccional.powerNow = (this.selected.deviceSelected != null) ? this.selected.deviceSelected.power_now : 0;
                // this.Bidireccional.todayEnergy = (this.selected.deviceSelected != null) ? this.selected.deviceSelected.today_energy : 0;
                // this.Bidireccional.monthlyEnergy = (this.selected.deviceSelected != null) ? this.selected.deviceSelected.monthly_energy : 0;
            }
        },

        

        //Carga de datos al seleccionas equipo en dasbioard en el navigation viewer lateral
        assignSelectedPanel(equiposend){
            let device = this.devices.find(device => device.imei == equiposend.imei);
            this.selected.deviceSelected = device;
            this.selected.imei = device.imei;
            this.drawer = false;
        },        
        

        


        
        //Reporte ejecutivo sensorposition 
        async getDataSensorPositionHistoricoExecutive(dispositivo){
            this.loadingFlagChartReport = true
            this.dataSensorPositionDevices = [];

            var arreglotemporalRegistrosSensor = [];

            await this.db_getDataSensorPositionHistorico(dispositivo).then((result) => {

                    this.dataSensorPositionDevices = result.data[1]; 
                    //-------
                    this.dataSensorPositionDevices.forEach(element => {
                        var jsonvar = JSON.parse(element.datos)
                        var arrtemporal;
                        if(dispositivo.idSensor == 1){
                            var tempv = jsonvar.temp;
                            var kwh1v = jsonvar.kwh1;
                            var kwh2v = jsonvar.kwh2;
                            var kwh3v = jsonvar.kwh3;
                            var amp1v = jsonvar.amp1;
                            var amp2v = jsonvar.amp2;
                            var amp3v = jsonvar.amp3;
                            arrtemporal = {
                                imei: dispositivo.imei,
                                idSensor: dispositivo.idSensor,
                                fechaGps: moment(element.fechaGps).locale('es').format("DD MMMM YYYY HH:mm:ss"),
                                temp: tempv,
                                kwh1: kwh1v,
                                kwh2: kwh2v,
                                kwh3: kwh3v,
                                amp1: amp1v,
                                amp2: amp2v,
                                amp3: amp3v
                            }
                        }else if(dispositivo.idSensor == 2){
                            var decibeles = jsonvar.sonometer
                            arrtemporal = {
                                imei: dispositivo.imei,
                                idSensor: dispositivo.idSensor,
                                fechaGps: moment(element.fechaGps).locale('es').format("DD MMMM YYYY HH:mm:ss"),
                                decibels: decibeles
                            }
                        }else if(dispositivo.idSensor == 3){
                            var tvocvar = jsonvar.tvoc;
                            var qualityvar = jsonvar.quality;
                            arrtemporal = {
                                imei: dispositivo.imei,
                                idSensor: dispositivo.idSensor,
                                fechaGps: moment(element.fechaGps).locale('es').format("DD MMMM YYYY HH:mm:ss"),
                                tvoc: tvocvar,
                                quality: qualityvar
                            }
                        }else if(dispositivo.idSensor == 7){
                            var levelv = jsonvar.level;
                            arrtemporal = {
                                imei: dispositivo.imei,
                                idSensor: dispositivo.idSensor,
                                fechaGps: moment(element.fechaGps).locale('es').format("DD MMMM YYYY HH:mm:ss"),
                                level: levelv,
                            }
                        }else if(dispositivo.idSensor == 8){
                            var evento1 = jsonvar.ev1;
                            var evento2 = jsonvar.ev2;
                            var evento3 = jsonvar.ev3;
                            var evento4 = jsonvar.ev4;
                            arrtemporal = {
                                imei: dispositivo.imei,
                                idSensor: dispositivo.idSensor,
                                fechaGps: moment(element.fechaGps).locale('es').format("DD MMMM YYYY HH:mm:ss"),
                                ev1: evento1,
                                ev2: evento2,
                                ev3: evento3,
                                ev4: evento4
                            }
                        }else if(dispositivo.idSensor == 11){
                            var temperaturav = jsonvar.temperature;
                            var pitchv = jsonvar.pitch;
                            var rollv = jsonvar.roll;
                            arrtemporal = {
                                imei: dispositivo.imei,
                                idSensor: dispositivo.idSensor,
                                fechaGps: moment(element.fechaGps).locale('es').format("DD MMMM YYYY HH:mm:ss"),
                                temperature: temperaturev,
                                pitch: pitchv,
                                roll: rollv
                            }
                        }else if(dispositivo.idSensor == 29){
                            var temperatura = jsonvar.temperature;
                            var humedad = jsonvar.humidity;
                            arrtemporal = {
                                imei: dispositivo.imei,
                                idSensor: dispositivo.idSensor,
                                fechaGps: moment(element.fechaGps).locale('es').format("DD MMMM YYYY HH:mm:ss"),
                                temperature: temperatura,
                                humidity: humedad
                            }
                        }else if(dispositivo.idSensor == 30){
                            var temperaturav = jsonvar.temperature;
                            var wattmetrov = jsonvar.wattmetro;
                            arrtemporal = {
                                imei: dispositivo.imei,
                                idSensor: dispositivo.idSensor,
                                fechaGps: moment(element.fechaGps).locale('es').format("DD MMMM YYYY HH:mm:ss"),
                                temperature: temperaturav,
                                wattmetro: wattmetrov
                            }
                        }else if(dispositivo.idSensor == 31){
                            var temperatura = jsonvar.temperature;
                            var humedad = jsonvar.humidity;
                            arrtemporal = {
                                imei: dispositivo.imei,
                                idSensor: dispositivo.idSensor,
                                fechaGps: moment(element.fechaGps).locale('es').format("DD MMMM YYYY HH:mm:ss"),
                                temperature: temperatura,
                                humidity: humedad
                            }
                        }else if(dispositivo.idSensor == 32){
                            var windspeedv = jsonvar.wind_speed;
                            var rainvolumev = jsonvar.rain_volume;
                            var temperaturev = jsonvar.temperature;
                            var directionwindv = jsonvar.direction_wind;
                            var relativehumidityv = jsonvar.relative_humidity;
                            var pascalpressurev = jsonvar.pascal_pressure;

                            arrtemporal = {
                                imei: dispositivo.imei,
                                idSensor: dispositivo.idSensor,
                                fechaGps: moment(element.fechaGps).locale('es').format("DD MMMM YYYY HH:mm:ss"),
                                wind_speed: windspeedv,
                                rain_volume: rainvolumev,
                                temperature: temperaturev,
                                direction_wind: directionwindv,
                                relative_humidity: relativehumidityv,
                                pascal_pressure: pascalpressurev
                            }

                        }else if(dispositivo.idSensor == 36){
                            var Temperaturav = jsonvar.Temperatura;
                            var nivelv = jsonvar.nivel;
                            arrtemporal = {
                                imei: dispositivo.imei,
                                idSensor: dispositivo.idSensor,
                                fechaGps: moment(element.fechaGps).locale('es').format("DD MMMM YYYY HH:mm:ss"),
                                Temperatura: Temperaturav,
                                nivel: nivelv
                            }
                        }else if(dispositivo.idSensor == 37){
                            var Temperaturav = jsonvar.Temperatura;
                            var uScmv = jsonvar.uScm;
                            arrtemporal = {
                                imei: dispositivo.imei,
                                idSensor: dispositivo.idSensor,
                                fechaGps: moment(element.fechaGps).locale('es').format("DD MMMM YYYY HH:mm:ss"),
                                Temperatura: Temperaturav,
                                uScm: uScmv
                            }
                        }else if(dispositivo.idSensor == 41){
                            var ltsMinutev = jsonvar.lts_minute;
                            var ltsTotalv = jsonvar.lts_total;
                            arrtemporal = {
                                imei: dispositivo.imei,
                                idSensor: dispositivo.idSensor,
                                fechaGps: moment(element.fechaGps).locale('es').format("DD MMMM YYYY HH:mm:ss"),
                                lts_minute: ltsMinutev,
                                lts_total: ltsTotalv
                            }
                        }else if(dispositivo.idSensor == 47){
                            let p1 = jsonvar.p1;
                            let p2 = jsonvar.p2;
                            let p3 = jsonvar.p3;
                            let p4 = jsonvar.p4;

                            let powerNow = parseFloat(p1) + parseFloat(p2) + parseFloat(p3) + parseFloat(p4);
                            arrtemporal = {
                                imei: dispositivo.imei,
                                idSensor: dispositivo.idSensor,
                                fechaGps: moment(element.fechaGps).locale('es').format("DD MMMM YYYY HH:mm:ss"),
                                p1,
                                p2,
                                p3,
                                p4,
                                powerNow
                            }
                        }else if(dispositivo.idSensor == 48){

                            // console.log("bidi")
                            // console.log("bidi")
                            // console.log("bidi")
                            console.log("bidi")
                            console.log(jsonvar);

                            let c1 = jsonvar.current_1
                            let c2 = jsonvar.current_2
                            let c3 = jsonvar.current_3

                            arrtemporal = {
                                imei: dispositivo.imei,
                                idSensor: dispositivo.idSensor,
                                fechaGps: moment(element.fechaGps).locale('es').format("DD MMMM YYYY HH:mm:ss"),
                                curren_1:c1,
                                curren_2:c2,
                                curren_3:c3,
                            }

                            console.log("bbbbbb");

                        }
                        arreglotemporalRegistrosSensor.push(arrtemporal);
                    });
                    //-------

                    var CantidadDatos = 0;

                    CantidadDatos = arreglotemporalRegistrosSensor.length;
                    
                    var requestTemporal = {
                        imei: dispositivo.imei,
                        name: dispositivo.name,
                        idSensor: dispositivo.idSensor,
                        fechaRequest: moment().locale('es').format("DD MMMM YYYY HH:mm:ss"),
                        status: 1,
                        requestLink: 1,
                        dataCount: CantidadDatos
                    }

                    console.log("BANDERA REQUEST ------");
                    console.log(requestTemporal);

                    this.requestExecutiveRequest.push(requestTemporal);

                    /*var requestTemporal = {
                        imei: dispositivo.imei,
                        name: dispositivo.name,
                        fechaRequest: moment().format("YYYY-MM-DD h:mm:ss"),
                        status: 1,
                        requestLink: 1,
                    }*/

                    //let device = this.devices.find(device => device.imei == equiposend.imei);
                    /*let equipoIndex = this.requestExecutiveRequest.findIndex(equipo => equipo.imei == dispositivo.imei);
                    var indexTemporal = equipoIndex;
                    this.requestExecutiveRequest[indexTemporal] = requestTemporal;

                    console.log("BANDERA REQUEST CUMPLIDA");
                    console.log(this.requestExecutiveRequest[indexTemporal]);*/
                    var arrayAuxiliarv = {
                        imei: dispositivo.imei,
                        idSensor: dispositivo.idSensor,
                        name: dispositivo.name,
                        datos: arreglotemporalRegistrosSensor
                    }

                    this.arrayAuxiliar.push(arrayAuxiliarv);

                    console.log("BANDERA DATOS AUCILIARES ----- XXXXXXXXXX");
                    console.log(this.arrayAuxiliar);
                
                    this.arrayDataEjemplo.push(arreglotemporalRegistrosSensor);
                    this.loadingFlagDataTable = false;

                    console.log("DATOS DE EQUIPOS ORIGINALES: ");
                    console.log(this.dataSensorPositionDevices);
                    console.log("------------------");
                    console.log("DATOS DE EQUIPOS TRANSFORMADOS: ");
                    console.log(arreglotemporalRegistrosSensor);
                    console.log("------------------");

                    var totalRegistros = result.data[0];
                    var idsensor = dispositivo.idSensor;
                    var idgraf = dispositivo.imei; //USAMOS IMEI COMO ID UNICO PARA GRAFICAS Y QUE NO SE EMPALMEN DEL MISMO TIPO
                    if(idsensor == 1){
                        var i = 0
                        var arrayTemp = [];
                        var arrayKwh1 = [];
                        var arrayKwh2 = [];
                        var arrayKwh3 = [];
                        var arrayAmp1 = [];
                        var arrayAmp2 = [];
                        var arrayAmp3 = [];
                        var arrayFechaGps = [];
                        
                        var contT = 0;
                        var contKwh1 = 0;
                        var contKwh2 = 0;
                        var contKwh3 = 0;
                        var contAmp1 = 0;
                        var contAmp2 = 0;
                        var contAmp3 = 0;
                        var contFecha = 0;
                        for(i=0; i < totalRegistros; i++){
                            var dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            var fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            contT = arrayTemp.push(dataJson.temp);
                            contKwh1 = arrayKwh1.push(dataJson.kwh1);
                            contKwh2 = arrayKwh2.push(dataJson.kwh2);
                            contKwh3 = arrayKwh3.push(dataJson.kwh3);
                            contAmp1 = arrayAmp1.push(dataJson.amp1);
                            contAmp2 = arrayAmp2.push(dataJson.amp2);
                            contAmp3 = arrayAmp3.push(dataJson.amp3);
                            contFecha = arrayFechaGps.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));
                        }
                        this.arrayDataTemp = arrayTemp;
                        this.arrayDataKwh1 = arrayKwh1;
                        this.arrayDataKwh2 = arrayKwh2;
                        this.arrayDataKwh3 = arrayKwh3;
                        this.arrayDataAmp1 = arrayAmp1;
                        this.arrayDataAmp2 = arrayAmp2;
                        this.arrayDataAmp3 = arrayAmp3;
                        this.arrayDataFechaGps = arrayFechaGps;
                        var idChart = idgraf;

                        //ALMACENAMOS COMO OBJETO LOS DATOS DEL DISPOSITIVO A GRAFICAR 
                        var objetoGrafica = {
                            arrayDataTemp: this.arrayDataTemp,
                            arrayDataKwh1: this.arrayDataKwh1,
                            arrayDataKwh2: this.arrayDataKwh2,
                            arrayDataKwh3: this.arrayDataKwh3,
                            arrayDataAmp1: this.arrayDataAmp1,
                            arrayDataAmp2: this.arrayDataAmp2,
                            arrayDataAmp3: this.arrayDataAmp3,
                            arrayDataFechaGps: this.arrayDataFechaGps,
                            idGrafica: idChart
                        }
                        this.arrayObjectsAGraficas.push(objetoGrafica);
                        //this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 2){ // SONOMETRO
                        var i = 0
                        var arrayDesibeles = [];
                        var arrayFechaGps = [];
                        var contDes = 0;
                        var contFecha = 0;
                        for(i=0; i < totalRegistros; i++){
                            var dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            var fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            contDes = arrayDesibeles.push(dataJson.sonometer);
                            contFecha = arrayFechaGps.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));
                        }
                        this.arrayDataSonometer = arrayDesibeles;
                        this.arrayDataFechaGps = arrayFechaGps;
                        var idChart = idgraf;

                        //ALMACENAMOS COMO OBJETO LOS DATOS DEL DISPOSITIVO A GRAFICAR 
                        var objetoGrafica = {
                            arrayDataSonometer: this.arrayDataSonometer,
                            arrayDataFechaGps: this.arrayDataFechaGps,
                            idGrafica: idChart
                        }
                        this.arrayObjectsAGraficas.push(objetoGrafica);
                        //this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 3){ //AIR QUALITY
                        var i = 0
                        var arrayQuality = [];
                        var arrayTvoc = [];
                        var arrayFechaGps = [];
                        var contA = 0;
                        var contT = 0;
                        var contFecha = 0;
                        for(i=0; i < totalRegistros;i++){
                            var dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            var fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            contA = arrayQuality.push(dataJson.quality);
                            contT = arrayTvoc.push(dataJson.tvoc); 
                            contFecha = arrayFechaGps.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));
                        }
                        this.arrayDataAirQuality = arrayQuality;
                        this.arrayDataAirTvoc = arrayTvoc;
                        this.arrayDataFechaGps = arrayFechaGps;
                        var idChart = idgraf;

                        //ALMACENAMOS COMO OBJETO LOS DATOS DEL DISPOSITIVO A GRAFICAR 
                        var objetoGrafica = {
                            arrayDataAirQuality: this.arrayDataAirQuality,
                            arrayDataAirTvoc: this.arrayDataAirTvoc,
                            arrayDataFechaGps: this.arrayDataFechaGps,
                            idGrafica: idChart
                        }
                        this.arrayObjectsAGraficas.push(objetoGrafica);

                        //this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 7){ // GAS LEVEL
                        var i = 0
                        var arrayLevel = [];
                        var arrayFechaGps = [];
                        var contLev = 0;
                        var contFecha = 0;
                        for(i=0; i < totalRegistros; i++){
                            var dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            var fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            contLev = arrayLevel.push(dataJson.level);
                            contFecha = arrayFechaGps.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));
                        }
                        this.arrayDataGasLevel = arrayLevel;
                        this.arrayDataFechaGps = arrayFechaGps;
                        var idChart = idgraf;

                        //ALMACENAMOS COMO OBJETO LOS DATOS DEL DISPOSITIVO A GRAFICAR 
                        var objetoGrafica = {
                            arrayDataGasLevel: this.arrayDataGasLevel,
                            arrayDataFechaGps: this.arrayDataFechaGps,
                            idGrafica: idChart
                        }
                        this.arrayObjectsAGraficas.push(objetoGrafica);

                        //this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 8){ //EVENTOS
                        var i = 0
                        var arrayEv1 = [];
                        var arrayEv2 = [];
                        var arrayEv3 = [];
                        var arrayEv4 = [];
                        var arrayFechaGps = [];
                        var contEv1 = 0;
                        var contEv2 = 0;
                        var contEv3 = 0;
                        var contEv4 = 0;
                        var contFecha = 0;
                        for(i=0; i < totalRegistros;i++){
                            var dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            var fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            contEv1 = arrayEv1.push(dataJson.ev1);
                            contEv2 = arrayEv2.push(dataJson.ev2);
                            contEv3 = arrayEv3.push(dataJson.ev3);
                            contEv4 = arrayEv4.push(dataJson.ev4);
                            contFecha = arrayFechaGps.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));
                        }
                        this.arrayDataEvento1 = arrayEv1;
                        this.arrayDataEvento2 = arrayEv2;
                        this.arrayDataEvento3 = arrayEv3;
                        this.arrayDataEvento4 = arrayEv4;
                        this.arrayDataFechaGps = arrayFechaGps;
                        var idChart = idgraf;

                        //ALMACENAMOS COMO OBJETO LOS DATOS DEL DISPOSITIVO A GRAFICAR 
                        var objetoGrafica = {
                            arrayDataEvento1: this.arrayDataEvento1,
                            arrayDataEvento2: this.arrayDataEvento2,
                            arrayDataEvento3: this.arrayDataEvento3,
                            arrayDataEvento4: this.arrayDataEvento4,
                            arrayDataFechaGps: this.arrayDataFechaGps,
                            idGrafica: idChart
                        }
                        this.arrayObjectsAGraficas.push(objetoGrafica);

                        //this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 11){ //TOUCH TAG
                        var i = 0
                        var arraytemp = [];
                        var arrayPitch = [];
                        var arrayRoll = [];
                        var arrayFechaGps = [];
                        var contT = 0;
                        var contP = 0;
                        var contR = 0;
                        var contFecha = 0;
                        for(i=0; i < totalRegistros;i++){
                            var dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            var fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            contT = arraytemp.push(dataJson.temperature);
                            contP = arrayPitch.push(dataJson.pitch);
                            contR = arrayRoll.push(dataJson.roll);
                            contFecha = arrayFechaGps.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));
                        }
                        this.arrayDataTemperature = arraytemp;
                        this.arrayDataPitchTG = arrayPitch;
                        this.arrayDataRollTG = arrayRoll;
                        this.arrayDataFechaGps = arrayFechaGps;
                        var idChart = idgraf;

                        //ALMACENAMOS COMO OBJETO LOS DATOS DEL DISPOSITIVO A GRAFICAR 
                        var objetoGrafica = {
                            arrayDataTemperature: this.arrayDataTemperature,
                            arrayDataPitchTG: this.arrayDataPitchTG,
                            arrayDataRollTG: this.arrayDataRollTG,
                            arrayDataFechaGps: this.arrayDataFechaGps,
                            idGrafica: idChart
                        }
                        this.arrayObjectsAGraficas.push(objetoGrafica);

                        //this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 29){ //HUMEDAD EN TIERRA
                        var i = 0
                        var arraytemp = [];
                        var arrayhumidity = [];
                        var arrayFechaGps = [];
                        var contT = 0;
                        var contH = 0;
                        var contFecha = 0;
                        for(i=0; i < totalRegistros;i++){
                            var dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            var fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            contT = arraytemp.push(dataJson.temperature);
                            contH = arrayhumidity.push(dataJson.humidity);
                            contFecha = arrayFechaGps.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));
                        }
                        this.arrayDataTemperature = arraytemp;
                        this.arrayDataHumidity = arrayhumidity;
                        this.arrayDataFechaGps = arrayFechaGps;

                        var idChart = idgraf;

                        //ALMACENAMOS COMO OBJETO LOS DATOS DEL DISPOSITIVO A GRAFICAR 
                        var objetoGrafica = {
                            arrayDataTemperature: this.arrayDataTemperature,
                            arrayDataHumidity: this.arrayDataHumidity,
                            arrayDataFechaGps: this.arrayDataFechaGps,
                            idGrafica: idChart
                        }
                        this.arrayObjectsAGraficas.push(objetoGrafica);
                        //this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 30){ //PIRANOMETRO
                        var i = 0
                        var arraytemp = [];
                        var arraywattmetro = [];
                        var arrayFechaGps = [];
                        var contT = 0;
                        var contW = 0;
                        var contFecha = 0;
                        for(i=0; i < totalRegistros;i++){
                            var dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            var fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            contT = arraytemp.push(dataJson.temperature);
                            contW = arraywattmetro.push(dataJson.wattmetro);
                            contFecha = arrayFechaGps.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));
                        }
                        this.arrayDataTemperaturaPir = arraytemp;
                        this.arrayDataWattmetroPir = arraywattmetro;
                        this.arrayDataFechaGps = arrayFechaGps;
                        var idChart = idgraf;

                        var objetoGrafica = {
                            arrayDataTemperaturaPir: this.arrayDataTemperaturaPir,
                            arrayDataWattmetroPir: this.arrayDataWattmetroPir,
                            arrayDataFechaGps: this.arrayDataFechaGps,
                            idGrafica: idChart
                        }
                        this.arrayObjectsAGraficas.push(objetoGrafica);

                        //this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 31){ //TEMPERATURA  Y HUMEDAD Y TIERRA
                        var i = 0
                        var arraytemp = [];
                        var arrayhumidity = [];
                        var arrayFechaGps = [];
                        var contT = 0;
                        var contH = 0;
                        var contFecha = 0;
                        for(i=0; i < totalRegistros;i++){
                            var dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            var fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            contT = arraytemp.push(dataJson.temperature);
                            contH = arrayhumidity.push(dataJson.humidity);
                            contFecha = arrayFechaGps.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));
                        }
                        this.arrayDataTemperature = arraytemp;
                        this.arrayDataHumidity = arrayhumidity;
                        this.arrayDataFechaGps = arrayFechaGps;
                        var idChart = idgraf;

                        var objetoGrafica = {
                            arrayDataTemperature: this.arrayDataTemperature,
                            arrayDataHumidity: this.arrayDataHumidity,
                            arrayDataFechaGps: this.arrayDataFechaGps,
                            idGrafica: idChart
                        }
                        this.arrayObjectsAGraficas.push(objetoGrafica);

                        //this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 32){ //ESTACION METEORLOGOICA
                        var i = 0
                        var arraytemp = [];
                        var arraywindspeed = [];
                        var arrayrainvolume = [];
                        var arraydirectionwind = [];
                        var arrayrelativehumidity = [];
                        var arraypascalpressure = [];
                        var arrayFechaGps = [];
                        var contT = 0;
                        var contN = 0;
                        var contFecha = 0;
                        for(i=0; i < totalRegistros;i++){
                            var dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            var fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            arraytemp.push(dataJson.temperature);
                            arraywindspeed.push(dataJson.wind_speed);
                            arrayrainvolume.push(dataJson.rain_volume);
                            arraydirectionwind.push(dataJson.direction_wind);
                            arrayrelativehumidity.push(dataJson.relative_humidity);
                            arraypascalpressure.push(dataJson.pascal_pressure);
                            arrayFechaGps.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));
                        }
                        this.arrayDataWindSpeedEM = arraywindspeed;
                        this.arrayDataRainVolumeEM = arrayrainvolume;
                        this.arrayDataTemperaturaEM = arraytemp;
                        this.arrayDataDirectionWindEM = arraydirectionwind;
                        this.arrayDataRelativeHumidityEM = arrayrelativehumidity;
                        this.arrayDataPascalPressureEM = arraypascalpressure;
                        this.arrayDataFechaGps = arrayFechaGps;

                        var idChart = idgraf;

                        var objetoGrafica = {
                            arrayDataWindSpeedEM: this.arrayDataWindSpeedEM,
                            arrayDataRainVolumeEM: this.arrayDataRainVolumeEM,
                            arrayDataTemperaturaEM: this.arrayDataTemperaturaEM,
                            arrayDataDirectionWindEM: this.arrayDataDirectionWindEM,
                            arrayDataRelativeHumidityEM: this.arrayDataRelativeHumidityEM,
                            arrayDataPascalPressureEM: this.arrayDataPascalPressureEM,
                            arrayDataFechaGps: this.arrayDataFechaGps,
                            idGrafica: idChart
                        }
                        this.arrayObjectsAGraficas.push(objetoGrafica);
                        //this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 36){ //PH
                        var i = 0
                        var arraytemp = [];
                        var arraynivel = [];
                        var arrayFechaGps = [];
                        var contT = 0;
                        var contN = 0;
                        var contFecha = 0;
                        for(i=0; i < totalRegistros;i++){
                            var dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            var fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            contT = arraytemp.push(dataJson.Temperatura);
                            contN = arraynivel.push(dataJson.nivel);
                            contFecha = arrayFechaGps.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));
                        }
                        this.arrayDataTemperaturaPh = arraytemp;
                        this.arrayDataNivel = arraynivel;
                        this.arrayDataFechaGps = arrayFechaGps;
                        var idChart = idgraf;

                        var objetoGrafica = {
                            arrayDataTemperaturaPh: this.arrayDataTemperaturaPh,
                            arrayDataNivel: this.arrayDataNivel,
                            arrayDataFechaGps: this.arrayDataFechaGps,
                            idGrafica: idChart
                        }
                        this.arrayObjectsAGraficas.push(objetoGrafica);

                        //this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 37){ //EC
                        var i = 0
                        var arraytemp = [];
                        var arrayuScm = [];
                        var arrayFechaGps = [];
                        var contT = 0;
                        var contuS = 0;
                        var contFecha = 0;
                        for(i=0; i < totalRegistros;i++){
                            var dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            var fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            contT = arraytemp.push(dataJson.Temperatura);
                            contuS = arrayuScm.push(dataJson.uScm);
                            contFecha = arrayFechaGps.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));
                        }
                        this.arrayDataTemperaturaEc = arraytemp;
                        this.arrayDatauScm = arrayuScm;
                        this.arrayDataFechaGps = arrayFechaGps;
                        var idChart = idgraf;

                        var objetoGrafica = {
                            arrayDataTemperaturaEc: this.arrayDataTemperaturaEc,
                            arrayDatauScm: this.arrayDatauScm,
                            arrayDataFechaGps: this.arrayDataFechaGps,
                            idGrafica: idChart
                        }
                        this.arrayObjectsAGraficas.push(objetoGrafica);

                        //this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 41){ //FLOW WATER
                        var i = 0
                        var arrayltsMinute = [];
                        var arrayltsTotal = [];
                        var arrayFechaGps = [];

                        var contlm = 0;
                        var contlt = 0;
                        var contFecha = 0;

                        for(i=0; i < totalRegistros;i++){
                            var dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            var fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            contlm = arrayltsMinute.push(dataJson.lts_minute);
                            contlt = arrayltsTotal.push(dataJson.lts_total);
                            contFecha = arrayFechaGps.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));
                        }

                        this.arrayDataLtsMinuteWF = arrayltsMinute;
                        this.arrayDataLtsTotalWF = arrayltsTotal;
                        this.arrayDataFechaGps = arrayFechaGps;
                        var idChart = idgraf;

                        var objetoGrafica = {
                            arrayDataLtsMinuteWF: this.arrayDataLtsMinuteWF,
                            arrayDataLtsTotalWF: this.arrayDataLtsTotalWF,
                            arrayDataFechaGps: this.arrayDataFechaGps,
                            idGrafica: idChart,
                            
                        }
                        this.arrayObjectsAGraficas.push(objetoGrafica);
                        //this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 47){ // DCMAX1500#####################################################

                        let i = 0
                        let arrCanal1 = [];
                        let arrCanal2 = [];
                        let arrCanal3 = [];
                        let arrCanal4 = [];
                        let arrPowerNow = [];
                        let arrFechas = [];
                        var arrayFechaGps = [];

                        for(i=0; i < totalRegistros;i++){
                            let dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            let fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            fechaTemp = moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss");
                            arrCanal1.push(this.numFormat(dataJson.p1));
                            arrCanal2.push(this.numFormat(dataJson.p2));
                            arrCanal3.push(this.numFormat(dataJson.p3));
                            arrCanal4.push(this.numFormat(dataJson.p4));

                            let powerNow = parseFloat(dataJson.p1) + parseFloat(dataJson.p2) + parseFloat(dataJson.p3) + parseFloat(dataJson.p4);
                            arrPowerNow.push(this.numFormat(powerNow));
                            contFecha = arrFechas.push(fechaTemp);
                            arrayFechaGps.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));
                        }
                        this.arrChannels.channel1 = arrCanal1;
                        this.arrChannels.channel2 = arrCanal2;
                        this.arrChannels.channel3 = arrCanal3;
                        this.arrChannels.channel4 = arrCanal4;
                        this.arrChannels.power = arrPowerNow;
                        this.arrayDataFechaGps = arrayFechaGps;

                        var idChart = idgraf;

                        var objetoGrafica = {
                            arrChannels: this.arrChannels,
                            arrayDataFechaGps: this.arrayDataFechaGps,
                            idGrafica: idChart
                        }
                        this.arrayObjectsAGraficas.push(objetoGrafica);
                        //this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 48){
                        // console.log("aaaaaaaaaa")
                        let current_1 = []
                        let current_2 = []
                        let current_3 = []
                        var arrayFechaGps = [];

                        for(i=0; i < totalRegistros;i++){
                            let dataJson = JSON.parse( this.dataSensorPositionDevices[0].datos )
                            let fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            fechaTemp = moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss");
                            
                            current_1.push( dataJson.current_1 )
                            current_2.push( dataJson.current_2 )
                            current_3.push( dataJson.current_3 )                            
                            arrayFechaGps.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));
                        }

                        let arrayBidi = {}

                        arrayBidi.current_1 = current_1
                        arrayBidi.current_2 = current_2
                        arrayBidi.current_3 = current_3

                        var idChart = idgraf;

                        var objetoGrafica = {
                            arrChannels: arrayBidi,
                            arrayDataFechaGps: arrayFechaGps,
                            idGrafica: idChart
                        }
                        this.arrayObjectsAGraficas.push(objetoGrafica);

                        console.log("HAAAAAAAAASTA AAQUI LLEGO")
                        console.log( this.arrayObjectsAGraficas);
                        console.log("HAAAAAAAAASTA AAQUI LLEGO")
                        

                    }
            }).catch((err) => {
                if(err){
                    this.loadingFlagHistorico = false
                    this.loadingFlag = false;
                    this.loadingFlagChartReport = false;
                }
                if(err.response.status == 401){
                    localStorage.idUser = ''; 
                    localStorage.session_p = 0;
                    localStorage.token_p = '';
                    this.$router.push({path: '/'});
                    console.log("ERROR DE AUTENTICACION");
                }
            });  
        },

        generatPowerDetailGraph(idChart, channel1, channel2, channel3, channel4, arrDates){
            var para = {
                idHighchart: idChart,
                title:"Power",
                labelX: "KW",
                series:[
                    {
                        data: channel1,
                        color: '#CB0000',
                        name: "Channel 1"
                    },
                    {
                        data: channel2,
                        color: '#BA71FF',
                        name: "Channel 2"
                    },
                    {
                        data: channel3,
                        color: '#ED8100',
                        name: "Channel 3"
                    },
                    {
                        data: channel4,
                        color: '#32D3FF',
                        name: "Channel 4"
                    },
                   
                ],
                categories: arrDates
            };

            this.makeHighchart(para);
            this.loadingFlagHistorico = false
            this.loadingFlag = false;
            this.loadingFlagChartReport = false;

        },

        
        
        //GENERA GRAFICA AL DAR CLIC EN ROW EN REPORTES EJECUTIVOS
        generateGraphIndividual(idChart,idsensor,objetoGrafica){
            if(idsensor == 1){
                var para = {
                    idHighchart: idChart,
                    title: "Energy",
                    labelX: "Data",
                    series:[
                        {
                            data: objetoGrafica.arrayDataTemp,
                            color: 'blue',
                            name: "temp",
                        },
                        {
                            data: objetoGrafica.arrayDataKwh1,
                            color: 'red',
                            name: "kwh1",
                        },
                        {
                            data: objetoGrafica.arrayDataKwh2,
                            color: 'yellow',
                            name: "kwh2",
                        },
                        {
                            data: objetoGrafica.arrayDataKwh3,
                            color: 'green',
                            name: "kwh3",
                        },
                        {
                            data: objetoGrafica.arrayDataAmp1,
                            color: 'brown',
                            name: "amp1",
                        },
                        {
                            data: objetoGrafica.arrayDataAmp2,
                            color: 'pink',
                            name: "amp2",
                        },
                        {
                            data: objetoGrafica.arrayDataAmp3,
                            color: 'black',
                            name: "amp3",
                        }
                    ],
                    categories: objetoGrafica.arrayDataFechaGps
                };
            }else if(idsensor == 2){
                var para = {
                    idHighchart: idChart,
                    title: "Sonometro",
                    labelX: "Decibeles",
                    series:[
                        {
                            data: objetoGrafica.arrayDataSonometer,
                            color: 'blue',
                            name: "Decibeles",
                            tooltip:{
                                valueSuffix: ' Decibeles',
                            }
                        }
                    ],
                    categories: objetoGrafica.arrayDataFechaGps
                };
            }else if(idsensor == 3){
                var para = {
                    idHighchart: idChart,
                    title: "Air Quality",
                    labelX: "CO2",
                    series:[
                        {
                            data: objetoGrafica.arrayDataAirQuality,
                            color: 'blue',
                            name: "CO2"
                        },
                        {
                            data: objetoGrafica.arrayDataAirTvoc,
                            color: 'red',
                            name: "TVOC"
                        },
                    ],
                    categories: objetoGrafica.arrayDataFechaGps
                };
            }else if(idsensor == 7){
                var para = {
                    idHighchart: idChart,
                    title: "Gas Level",
                    labelX: "Level",
                    series:[
                        {
                            data: objetoGrafica.arrayDataGasLevel,
                            color: 'blue',
                            name: "PPM",
                            tooltip:{
                                valueSuffix: ' ppm',
                            }
                        }
                    ],
                    categories: objetoGrafica.arrayDataFechaGps
                };
            }else if(idsensor == 8){
                var para = {
                    idHighchart: idChart,
                    title: "Events",
                    labelX: "Events",
                    series:[
                        {
                            data: objetoGrafica.arrayDataEvento1,
                            color: 'blue',
                            name: "Evento 1"
                        },
                        {
                            data: objetoGrafica.arrayDataEvento2,
                            color: 'red',
                            name: "Evento 2"
                        },
                        {
                            data: objetoGrafica.arrayDataEvento3,
                            color: 'yellow',
                            name: "Evento 3"
                        },
                        {
                            data: objetoGrafica.arrayDataEvento4,
                            color: 'green',
                            name: "Evento 4"
                        }
                    ],
                    categories: objetoGrafica.arrayDataFechaGps
                };
            }else if(idsensor == 11){
                var para = {
                    idHighchart: idChart,
                    title: "Touch Tag",
                    labelX: "Pitch / Roll / Temperature",
                    series:[
                        {
                            data: objetoGrafica.arrayDataTemperatureTG,
                            color: 'blue',
                            name: "Temperature",
                            tooltip:{
                                valueSuffix: ' °C',
                            }
                        },
                        {
                            data: objetoGrafica.arrayDataPitchTG,
                            color: 'red',
                            name: "Pitch",
                        },
                        {
                            data: objetoGrafica.arrayDataRollTG,
                            color: 'yellow',
                            name: "Roll",
                        },
                    ],
                    categories: objetoGrafica.arrayDataFechaGps
                };
            }else if(idsensor == 29){
                var para = {
                    idHighchart: idChart,
                    title: "Temperatura en Tierra",
                    labelX: "Temperature / Humidity",
                    series:[
                        {
                            data: objetoGrafica.arrayDataTemperature,
                            color: 'blue',
                            name: "Temperature",
                            tooltip:{
                                valueSuffix: ' °C',
                            }
                        },
                        {
                            data: objetoGrafica.arrayDataHumidity,
                            color: 'red',
                            name: "Humidity",
                            tooltip:{
                                valueSuffix: ' %',
                            }
                        },
                    ],
                    categories: objetoGrafica.arrayDataFechaGps
                };
            }else if(idsensor == 30){
                var para = {
                    idHighchart: idChart,
                    title: "Piranometro",
                    labelX: "(Watt / Meter) / Temperature",
                    series:[
                        {
                            data: objetoGrafica.arrayDataTemperaturaPir,
                            color: 'blue',
                            name: "Temperature",
                            tooltip:{
                                valueSuffix: ' °C',
                            }
                        },
                        {
                            data: objetoGrafica.arrayDataWattmetroPir,
                            color: 'red',
                            name: "Watt/Meter",
                        },
                    ],
                    categories: objetoGrafica.arrayDataFechaGps
                };
            }else if(idsensor == 31){
                var para = {
                    idHighchart: idChart,
                    title: "Temperature and Humidity",
                    labelX: "Temperature / Humidity",
                    series:[
                        {
                            data: objetoGrafica.arrayDataTemperature,
                            color: 'blue',
                            name: "Temperature",
                            tooltip:{
                                valueSuffix: ' °C',
                            }
                        },
                        {
                            data: objetoGrafica.arrayDataHumidity,
                            color: 'red',
                            name: "Humidity",
                            tooltip:{
                                valueSuffix: ' %',
                            }
                        },
                    ],
                    categories: objetoGrafica.arrayDataFechaGps
                };
            }else if(idsensor == 32){
                var para = {
                    idHighchart: idChart,
                    title: "Estacion Meteorologica",
                    labelX: "EM Values",
                    series:[
                        {
                            data: objetoGrafica.arrayDataWindSpeedEM,
                            color: 'blue',
                            name: "Wind Speed",
                            tooltip:{
                                valueSuffix: ' Km/h',
                            }
                        },
                        {
                            data: objetoGrafica.arrayDataRainVolumeEM,
                            color: 'red',
                            name: "Rain Volume",
                            tooltip:{
                                valueSuffix: ' mm',
                            }
                        },
                        {
                            data: objetoGrafica.arrayDataTemperaturaEM,
                            color: 'yellow',
                            name: "Temperature",
                            tooltip:{
                                valueSuffix: ' °C',
                            }
                        },
                        {
                            data: objetoGrafica.arrayDataDirectionWindEM,
                            color: 'green',
                            name: "Direction Wind"
                        },
                        {
                            data: objetoGrafica.arrayDataRelativeHumidityEM,
                            color: 'purple',
                            name: "Relative Humidity",
                            tooltip:{
                                valueSuffix: ' (%RH)',
                            }
                        },
                        {
                            data: objetoGrafica.arrayDataPascalPressureEM,
                            color: 'black',
                            name: "Pascal Pressure"
                        },
                    ],
                    categories: objetoGrafica.arrayDataFechaGps
                };
            }else if(idsensor == 36){
                var para = {
                    idHighchart: idChart,
                    title: "PH",
                    labelX: "Temperature / Level",
                    series:[
                        {
                            data: objetoGrafica.arrayDataTemperaturaPh,
                            color: 'blue',
                            name: "Temperature",
                            tooltip:{
                                valueSuffix: ' °C',
                            }
                        },
                        {
                            data: objetoGrafica.arrayDataNivel,
                            color: 'red',
                            name: "Level"
                        },
                    ],
                    categories: objetoGrafica.arrayDataFechaGps
                };
            }else if(idsensor == 37){
                var para = {
                    idHighchart: idChart,
                    title: "EC",
                    labelX: "Temperature / (uS/cm)",
                    series:[
                        {
                            data: objetoGrafica.arrayDataTemperaturaEc,
                            color: 'blue',
                            name: "Temperature",
                            tooltip:{
                                valueSuffix: ' °C',
                            }
                        },
                        {
                            data: objetoGrafica.arrayDatauScm,
                            color: 'red',
                            name: "uS/cm"
                        },
                    ],
                    categories: objetoGrafica.arrayDataFechaGps
                };
            }else if(idsensor == 41){
                var para = {
                    idHighchart: idChart,
                    title: "Flow Water",
                    labelX: "(L/min) / (Total Lts.)",
                    series:[
                        {
                            data: objetoGrafica.arrayDataLtsMinuteWF,
                            color: 'blue',
                            name: "Liters per Minute",
                            tooltip:{
                                valueSuffix: ' L/min',
                            }
                        },
                        {
                            data: objetoGrafica.arrayDataLtsTotalWF,
                            color: 'red',
                            name: "Total Liters",
                            tooltip:{
                                valueSuffix: ' L',
                            }
                        },
                    ],
                    categories: objetoGrafica.arrayDataFechaGps
                };
            }else if(idsensor == 47){ // DCMAX1500    
                console.log("............  ....  ...................");            
                console.log("objetoGrafica", objetoGrafica);
                var para = {
                    idHighchart: idChart,
                    title: "Energia",
                    labelX: "Kwh",
                    series:[
                        {
                            data: objetoGrafica.arrChannels.channel1,
                            color: '#CB0000',
                            name: "Canal 1",
                            tooltip:{
                                valueSuffix: ' Kwh',
                            }
                        },
                        {
                            data: objetoGrafica.arrChannels.channel2,
                            color: '#BA71FF',
                            name: "Canal 2",
                            tooltip:{
                                valueSuffix: ' Kwh',
                            }
                        },
                        {
                            data: objetoGrafica.arrChannels.channel3,
                            color: '#ED8100',
                            name: "Canal 3",
                            tooltip:{
                                valueSuffix: ' Kwh',
                            }
                        },
                        {
                            data: objetoGrafica.arrChannels.channel4,
                            color: '#32D3FF',
                            name: "Canal 4",
                            tooltip:{
                                valueSuffix: ' Kwh',
                            }
                        },
                        {
                            data: objetoGrafica.arrChannels.power,
                            color: '#000000',
                            name: "Power Now",
                            tooltip:{
                                valueSuffix: ' Kwh',
                            }
                        },
                    ],
                    categories: objetoGrafica.arrayDataFechaGps
                };
            }else if(idsensor == 48){ // BIDI    
                console.log("............  ..aaaaaafffff..  ...................");            
                console.log("objetoGrafica", objetoGrafica);
                var para = {
                    idHighchart: idChart,
                    title: "Energia Bidireccional",
                    labelX: "Kw",
                    series:[
                        {
                            data: objetoGrafica.arrChannels.current_1,
                            color: '#CB0000',
                            name: "Current 1",
                            tooltip:{
                                valueSuffix: ' Kw',
                            }
                        },
                        {
                            data: objetoGrafica.arrChannels.current_2,
                            color: '#BA71FF',
                            name: "Current 2",
                            tooltip:{
                                valueSuffix: ' Kw',
                            }
                        },
                        {
                            data: objetoGrafica.arrChannels.current_3,
                            color: '#ED8100',
                            name: "Current 3",
                            tooltip:{
                                valueSuffix: ' Kw',
                            }
                        },                        
                    ],
                    categories: objetoGrafica.arrayDataFechaGps
                };
            }
           
            this.makeHighchart(para);
            this.loadingFlagHistorico = false
            this.loadingFlag = false;
            this.loadingFlagChartReport = false;
            //this.loadingFlagTableSensorRequest = false;
        },

        //CREAR MAPA
        generateMap(){
            if(this.posicion.latitud == 0 && this.posicion.longitud == 0){
                console.log("NO TIENE POSICION");
            }else{
                console.log("SI TIENE POSICION");
                //this.getMapa(this.posicion);
            }
        },

        //ASINGACION DE TIMEZONES CENTRAL, PACIFICO, NORTE

        assignTimeZone(event){
            let timezone = this.timezones_list.find(timezone => timezone.id == event);
            this.timezone_data.objeto = timezone;
            console.log(this.timezone_data.objeto);
            this.selected.deviceSelected.offset = this.timezone_data.objeto.offset
            this.selected.deviceSelected.zona = this.timezone_data.objeto.name
            //this.timezone_data.offset = this.timezone_data.objeto.offset
            //this.timezone_data.id = this.timezone_data.objeto.id
        },

        //CONSULTA ZONAS HORARIAS DE BD
        getTimeZones(){
            this.getTimezones().then((result) => {
                this.timezones_list = result.data.timezonesData;
                this.timezone_data.objeto = this.timezones_list[0];
                this.timezone_data.id = this.timezones_list[0].id
                //console.log(this.timezone_data.id);
                //console.log(this.timezone_data.objeto);
                //console.log(this.timezones_list);
            }).catch((err) => {
                console.log(err);
            });
        },

        getalerts(){
            this.db_alerts().then((result) =>{
                /*var developmentAlert = this.developmentAlert;
                var arrayTemporalAlert = this.developmentAlert;
                var telComunnicatin = this.developmentComunicationn;*/
                //var alertarray = this.arrayDataTemperaturaEc;
            });
        },

        //ACTUALIZACION DE FORMULARIOS PARA CONFIGURACION Y ALERTS
        updateConfiguration(){
            console.log("DATOS A ENVIAR: ");
            console.log(this.selected.deviceSelected);
            this.updateConfiguracion(this.selected.deviceSelected).then((result) => {
                if (result.data.message === 'name_exist' ){  
                    Swal.fire({
                        title: '¡Aviso!',
                        icon: 'warning',
                        text: 'Device name already exist',
                    });          
                }else{
                    Swal.fire({
                        title: '¡Good Job!',
                        icon: 'success',
                        text: 'Configuration Updated',
                    });
                    this.loadingFlagFormConfig = false;
                    //this.modalActualizarConfiguracion = false;
                }
                //this.getDataNormal(this.selected);
                this.consultaTemporal();
                console.log("actualizado");
            }).catch((err) => {
                if(err.response.status == 401){
                    localStorage.idUser = ''; 
                    localStorage.session_p = 0;
                    localStorage.token_p = '';
                    this.$router.push({path: '/'});
                    console.log("ERROR DE AUTENTICACION");
                }else{
                    Swal.fire({
                        title: '¡Error!',
                        icon: 'error',
                        text: err,
                    });
                    
                }
            });
        },

        updateAlertConfiguration(){            
            this.db_updateAlertConfiguration(this.selected.deviceSelected).then((result) => {
                console.log("alerts actualizado");
                Swal.fire({
                    title: '¡Good Job!',
                    icon: 'success',
                    text: 'Alerts Updated',
                });
                this.loadingFlagFormAlerts = false;
                //this.modalActualizarAlerts = false;
            }).catch((err) => {
                if(err.response.status == 401){
                    localStorage.idUser = ''; 
                    localStorage.session_p = 0;
                    localStorage.token_p = '';
                    this.$router.push({path: '/'});
                    console.log("ERROR DE AUTENTICACION");
                }else{
                    Swal.fire({
                        title: '¡Error!',
                        icon: 'error',
                        text: err,
                    });
                }
            });
        },

        closeAlerts(){
            this.loadingFlagFormAlerts = false;
            this.modalActualizarAlerts = false;
        },

        //MUESTRA NOTIFICACIONES DE ALERTAS EN PANTALLA
        showToast(){
            const toastTestings = {
                hideProgressbar: true,
                singular: true,
                orderLatest: true,
                position: 'bottom-right',
                theme: 'dark',
                warningInfoDuration: 60000
            }
    
            this.$vToastify.setSettings(toastTestings);
            if(this.itemsNavigation.length < 10){
                this.itemsNavigation.forEach(element => {
                    var content = {  
                        title: element.titlePop,
                        body: element.textPop,
                    }
    
                    var alertObject = {
                        idAlert: element.idAl
                    }

                    this.$vToastify.info(content);
    
                    this.db_updateAlertsStatus(alertObject).then((result) => {
    
                    }).catch((err) => {
                        if(err.response.status == 401){
                            localStorage.idUser = ''; 
                            localStorage.session_p = 0;
                            localStorage.token_p = '';
                            this.$router.push({path: '/'});
                            console.log("ERROR DE AUTENTICACION: " + err.response.status);
                        }else{
                            console.log(err);
                        }
                    });
                });
            }else{
                var tamañoItems = this.itemsNavigation.length;
                const content = {  
                    title: 'You Have ' + tamañoItems + ' New Notifications',
                }
                this.$vToastify.warning(content);

                this.itemsNavigation.forEach(element => {
                    var alertObject = {
                        idAlert: element.idAl
                    }
    
                    this.db_updateAlertsStatus(alertObject).then((result) => {
    
                    }).catch((err) => {
                        if(err.response.status == 401){
                            localStorage.idUser = ''; 
                            localStorage.session_p = 0;
                            localStorage.token_p = '';
                            this.$router.push({path: '/'});
                            console.log("ERROR DE AUTENTICACION");
                        }else{
                            console.log(err);
                        }
                    });
                });
            }
        },

        getLastUserAlertsStatic(){
            this.loadingFlagNavigation = true;
            const paramsAlerts = {
                idUser: localStorage.idUser,
                name: localStorage.user_p 
            };

            this.itemsNavigationStatic = [];
            var arreglotemporal_alerts = [];
            var arregloFinalItems = [];
            this.db_getLastUserAlertsStatic(paramsAlerts).then((result) => {
                var resultado = result.data;
                var arreglotemporal_items; 
                var contador = 1;
                arreglotemporal_alerts = resultado;
                arreglotemporal_alerts.forEach(element => {
                    if(element.status == 1){
                        var colorv = 'red'
                    }else{
                        var colorv = 'blue'
                    }
                    arreglotemporal_items = {
                        idAl: element.idAlert,
                        fechaAlerta: element.fechaReplica,
                        title: 'Alerta de Sensor: ' + element.fechaReplica + '.',
                        textImei: 'Imei: ' + element.imei,
                        textName: 'Device Name: ' + element.name,
                        text:  element.parametros,
                        titlePop: 'Alerta de sensor',
                        textPop: 'Dispositivo: ' + element.name + '<br>' + 'Imei: ' + element.imei + '<br>' + "Fecha de Alerta: " + element.fechaReplica,
                        icon: 'mdi-alert-decagram',
                        color: 'blue'
                    }
                    contador++;
                    arregloFinalItems.push(arreglotemporal_items);
                });
                //console.log("BANDERA ALERTS");
                //console.log(arregloFinalItems);
                this.loadingFlagNavigation =  false;
                console.log("ULTIMAS ALERTS ACTUALIZADAS");
                this.itemsNavigationStatic = arregloFinalItems;
            }).catch((err) => {
                console.log(err);
                Swal.fire({
                    title: '¡Error!',
                    icon: 'error',
                    text: err,
                });
            });
        },

        getLastUserAlerts(){
            const paramsAlerts = {
                idUser: localStorage.idUser,
                name: localStorage.user_p 
            };

            this.itemsNavigation = [];
            var arreglotemporal_alerts = [];
            var arregloFinalItems = [];
            this.db_getLastUserAlerts(paramsAlerts).then((result) => {
                var resultado = result.data;
                var arreglotemporal_items; 
                var contador = 1;
                arreglotemporal_alerts = resultado;
                arreglotemporal_alerts.forEach(element => {
                    arreglotemporal_items = {
                        idAl: element.idAlert,
                        fechaAlerta: element.fechaReplica,
                        title: 'Alerta de Sensor: ' + element.fechaReplica + '.',
                        textImei: 'Imei: ' + element.imei,
                        textName: 'Device Name: ' + element.name,
                        text:  element.parametros,
                        titlePop: 'Alerta de sensor',
                        textPop: 'Dispositivo: ' + element.name + '<br>' + 'Imei: ' + element.imei + '<br>' + "Fecha de Alerta: " + element.fechaReplica,
                        icon: 'mdi-alert-decagram',
                        color: 'red'
                    }
                    contador++;
                    arregloFinalItems.push(arreglotemporal_items);
                });
                //console.log("BANDERA ALERTS");
                //console.log(arregloFinalItems);
                this.itemsNavigation = arregloFinalItems;
                if(this.itemsNavigation.length >= 1){
                    localStorage.bellNotification = 1
                    this.campanaFlag = 1;
                }else{
                    
                }
                //this.getLastUserAlertsStatic();
                this.showToast();
            }).catch((err) => {
                if(err.response.status == 401){
                    localStorage.idUser = ''; 
                    localStorage.session_p = 0;
                    localStorage.token_p = '';
                    this.$router.push({path: '/'});
                    console.log("ERROR DE AUTENTICACION");
                }else{
                    console.log(err);
                }
            });
        },

        getUserAlerts2(params){
            this.loadingFlagTableAlerts = true;
            this.arrayAlerts = [];
            var arreglotemporal_alerts = [];
            var arregloFinalItems = [];
            this.db_getUserAlerts(params).then((result) => {
                var resultado = result.data;
                var arreglotemporal_items; 
                var contador = 1;
                arreglotemporal_alerts = resultado;
                arreglotemporal_alerts.forEach(element => {
                    var jsonAlertConfig = JSON.parse(element.alertConfig);
                    // console.log(jsonAlertConfig.emails);
                    var arrayEmails = jsonAlertConfig.emails;
                    var arrayEmailsTemporal = []
                    var stringEmails = '';

                    arrayEmails.forEach(element => {
                        if(element != ""){
                            arrayEmailsTemporal.push(element);
                        }
                    });

                    stringEmails = arrayEmailsTemporal.join(' , ')

                    arreglotemporal_items = {
                        idAlert: element.idAlert,
                        name: element.name,
                        imei: element.imei,
                        fechaReplica: moment(element.fechaReplica).locale('es').format("DD MMMM YYYY HH:mm:ss"),
                        parametros: element.parametros,
                        correosAlertas: stringEmails
                    }
                    arregloFinalItems.push(arreglotemporal_items);
                });
                //console.log("BANDERA ALERTS");
                //console.log(arregloFinalItems);
                this.arrayAlerts = arregloFinalItems;
                this.loadingFlagTableAlerts = false;
            }).catch((err) => {
                if(err.response.status == 401){
                    localStorage.idUser = ''; 
                    localStorage.session_p = 0;
                    localStorage.token_p = '';
                    this.$router.push({path: '/'});
                    console.log("ERROR DE AUTENTICACION");
                }else{
                    console.log(err);
                }
            });
        },

        getUserAlertHistorical(params){
            this.loadingFlagTableAlerts = true;
            this.arrayAlerts = [];
            var arreglotemporal_alerts = [];
            var arregloFinalItems = [];
            this.db_getUserAlertsHistorical(params).then((result) => {
                var resultado = result.data;
                var arreglotemporal_items; 
                var contador = 1;
                arreglotemporal_alerts = resultado;
                arreglotemporal_alerts.forEach(element => {
                    var jsonAlertConfig = JSON.parse(element.alertConfig);

                    var arrayEmails = jsonAlertConfig.emails;
                    var arrayEmailsTemporal = []
                    var stringEmails = '';

                    arrayEmails.forEach(element => {
                        if(element != ""){
                            arrayEmailsTemporal.push(element);
                        }
                    });

                    stringEmails = arrayEmailsTemporal.join(' , ')

                    arreglotemporal_items = {
                        idAlert: element.idAlert,
                        name: element.name,
                        imei: element.imei,
                        fechaReplica: element.fechaReplica,
                        parametros: element.parametros,
                        correosAlertas: stringEmails
                    }
                    arregloFinalItems.push(arreglotemporal_items);
                });
                //console.log("BANDERA ALERTS");
                //console.log(arregloFinalItems);
                this.arrayAlerts = arregloFinalItems;
                this.loadingFlagTableAlerts = false;
            }).catch((err) => {
                if(err.response.status == 401){
                    localStorage.idUser = ''; 
                    localStorage.session_p = 0;
                    localStorage.token_p = '';
                    this.$router.push({path: '/'});
                    console.log("ERROR DE AUTENTICACION");
                }else{
                    Swal.fire({
                        title: '¡Error!',
                        icon: 'error',
                        text: err,
                    });
                }
            });
        },

        getDeviceAlerts(params){
            this.loadingFlagTableAlerts = true;
            this.dataDeviceAlerts = []
            this.db_getDeviceAlerts(params).then((result) => {
                var resultado = result.data;
                //console.log("BANDERA ALERTS DEVICE" );
                //console.log(resultado);
                this.dataDeviceAlerts = resultado;
                this.loadingFlagTableAlerts = false;
            }).catch((err) => {
                if(err.response.status == 401){
                    localStorage.idUser = ''; 
                    localStorage.session_p = 0;
                    localStorage.token_p = '';
                    this.$router.push({path: '/'});
                    console.log("ERROR DE AUTENTICACION");
                }else{
                    Swal.fire({
                        title: '¡Error!',
                        icon: 'error',
                        text: err,
                    });
                }
            });
        },

        //FUNCIONES ESPECIFICAS PARA WATERFLOW

        storeHistoricLiters(objeto){
            this.db_storeHistoricLiters(objeto).then((result) => {
                Swal.fire({
                    title: '¡Good Job!',
                    icon: 'success',
                    text: 'Historic registered',
                });
                console.log("registrado");
                this.getHistoricalLiters(objeto);
                //this.modalHistoricLiter = false;
                //this.consultaTemporal();
                this.getTotalLiters(this.selected.deviceSelected)
            }).catch((err) => {
                if(err.response.status == 401){
                    localStorage.idUser = ''; 
                    localStorage.session_p = 0;
                    localStorage.token_p = '';
                    this.$router.push({path: '/'});
                    console.log("ERROR DE AUTENTICACION");
                }else{
                    Swal.fire({
                        title: '¡Error!',
                        icon: 'error',
                        text: err,
                    });
                }
            });
        },

        getHistoricalLiters(params){
            var arreglotemporal = []
            this.db_getHistoricLiters(params).then((result) => {
                console.log("HISTORICAL LITERS");
                arreglotemporal = result.data;
                this.dataHistoricLiters = arreglotemporal;
                console.log(arreglotemporal);
                this.loadingFlagTableFlow = false;
                /*arreglotemporal.forEach(element => {
                    console.log(element);
                });*/
            }).catch((err) => {
                if(err.response.status == 401){
                    localStorage.idUser = ''; 
                    localStorage.session_p = 0;
                    localStorage.token_p = '';
                    this.$router.push({path: '/'});
                    console.log("ERROR DE AUTENTICACION");
                }else{
                    console.log(err);
                }
            });
        },

        getTotalLiters(params){
            var totalliters_temporal = 0
            this.db_getTotalLiters(params).then((result) => {
                //console.log("TOTAL LITERS DEL EQUIPO: ");
                //console.log(result.data.total_litter);
                this.flowWaterData.total_litter = result.data.total_litter
            }).catch((err) => {
                if(err.response.status == 401){
                    localStorage.idUser = ''; 
                    localStorage.session_p = 0;
                    localStorage.token_p = '';
                    this.$router.push({path: '/'});
                    console.log("ERROR DE AUTENTICACION");
                }else{
                    console.log(err);
                }
            });
        },

        getMonthlyDataLiters(params){
            this.loadingFlagTableMonthlyFlow = true;
            this.dataMonthlyLiters = [];
            var arreglotemporal = [];
            this.db_getMonthlyDataLiters(params).then((result) => {
                console.log("BANDERA DATOS MENSUALES");
                var arreglotemporal2;
                arreglotemporal = result.data;
                arreglotemporal.forEach(element => {
                    var mes_bd_original = element.month;
                    var mes_bd = mes_bd_original - 1;
                    var mes_show;
                    
                    if(mes_bd == 0){
                        mes_show = "Diciembre"
                    }else if(mes_bd == 1){
                        mes_show = "Enero"
                    }else if(mes_bd == 2){
                        mes_show = "Febrero"
                    }else if(mes_bd == 3){
                        mes_show = "Marzo"
                    }else if(mes_bd == 4){
                        mes_show = "Abril"
                    }else if(mes_bd == 5){
                        mes_show = "Mayo"
                    }else if(mes_bd == 6){
                        mes_show = "Junio"
                    }else if(mes_bd == 7){
                        mes_show = "Julio"
                    }else if(mes_bd == 8){
                        mes_show = "Agosto"
                    }else if(mes_bd == 9){
                        mes_show = "Septiembre"
                    }else if(mes_bd == 10){
                        mes_show = "Octubre"
                    }else if(mes_bd == 11){
                        mes_show = "Noviembre"
                    }

                    arreglotemporal2 = {
                        imei: element.imei,
                        fechaGps: element.fechaGps,
                        monthly_litters: element.monthly_litters,
                        year: element.year,
                        month: mes_show
                    }
                    this.dataMonthlyLiters.push(arreglotemporal2);
                });
                //this.dataMonthlyLiters = arreglotemporal;
                //console.log(this.dataMonthlyLiters);
                this.loadingFlagTableMonthlyFlow = false;
            }).catch((err) => {
                if(err.response.status == 401){
                    localStorage.idUser = ''; 
                    localStorage.session_p = 0;
                    localStorage.token_p = '';
                    this.$router.push({path: '/'});
                    console.log("ERROR DE AUTENTICACION");
                }else{
                    console.log(err);
                }
            });
        },

        //FUNCIONES ESPECIFICAS PARA ENERGY

        assignEnergyVariables(sensor){
            var arregloDailyData = [];
            var arregloMonthlyData = [];
            var totalDailyAmp = 0;
            var totalMonthlyAmp = 0;
            var powerNow = 0;
            var dailyEnergy = 0;
            var todayEnergy = 0;
            var monthlyEnergy = 0;
            var carbonOffset = 0;
            var treesPlanted = 0;
            var income = 0;
            var temp = 0;

            var jsonConfiguracion = JSON.parse(sensor.configuracion);
            var jsonConfiguracionAlert = JSON.parse(sensor.alertConfig);
            var jsonDatos = JSON.parse(sensor.datos);
            
            if(Object.keys(jsonDatos).length === 0){
                this.energyData.powerNow = null;
                this.energyData.todayEnergy = null;
                this.energyData.monthlyEnergy = null;
                this.energyData.carbonOffset = null;
                this.energyData.treesPlanted = null;
                this.energyData.income = null;
            }else{
                if (jsonConfiguracion == null) {
                    this.energyConfig.KWhPrice = 0;
                    this.energyConfig.currency = 0;
                    this.energyConfig.phases = 0;
                    this.energyConfig.voltage = 0;                    
                }else{
                    this.energyConfig.KWhPrice = jsonConfiguracion.KWhPrice == null? 0: jsonConfiguracion.KWhPrice;
                    this.energyConfig.currency = jsonConfiguracion.currency
                    this.energyConfig.phases = jsonConfiguracion.phases;
                    this.energyConfig.voltage = jsonConfiguracion.voltage;
                }
                

                if(jsonConfiguracionAlert.hasOwnProperty('alertEnable')){
                    //console.log("HAY ALERT ENABLE");
                    this.energyAlertConfig.alertEnable = jsonConfiguracionAlert.alertEnable;
                }else{
                    //console.log("NO HAY ALERT ENABLE");
                    this.energyAlertConfig.alertEnable = 'NO'
                }

                if(jsonConfiguracionAlert.hasOwnProperty('emails')){
                    //console.log("HAY EMAILS");
                    this.energyAlertConfig.emails = jsonConfiguracionAlert.emails;
                }else{
                    //console.log("NO HAY EMAILS");
                    this.energyAlertConfig.emails = []
                }

                if(jsonConfiguracionAlert.hasOwnProperty('amp1Max')){
                    //console.log("HAY EMAILS");
                    this.energyAlertConfig.amp1Max = jsonConfiguracionAlert.amp1Max;
                }else{
                    //console.log("NO HAY EMAILS");
                    this.energyAlertConfig.amp1Max = 0
                }

                if(jsonConfiguracionAlert.hasOwnProperty('amp2Max')){
                    //console.log("HAY EMAILS");
                    this.energyAlertConfig.amp2Max = jsonConfiguracionAlert.amp2Max;
                }else{
                    //console.log("NO HAY EMAILS");
                    this.energyAlertConfig.amp2Max = 0
                }

                if(jsonConfiguracionAlert.hasOwnProperty('amp3Max')){
                    //console.log("HAY EMAILS");
                    this.energyAlertConfig.amp3Max = jsonConfiguracionAlert.amp3Max;
                }else{
                    //console.log("NO HAY EMAILS");
                    this.energyAlertConfig.amp3Max = 0
                }

                if(jsonConfiguracionAlert.hasOwnProperty('amp1Min')){
                    //console.log("HAY EMAILS");
                    this.energyAlertConfig.amp1Min = jsonConfiguracionAlert.amp1Min;
                }else{
                    //console.log("NO HAY EMAILS");
                    this.energyAlertConfig.amp1Min = 0
                }

                if(jsonConfiguracionAlert.hasOwnProperty('amp2Min')){
                    //console.log("HAY EMAILS");
                    this.energyAlertConfig.amp2Min = jsonConfiguracionAlert.amp2Min;
                }else{
                    //console.log("NO HAY EMAILS");
                    this.energyAlertConfig.amp2Min = 0
                }

                if(jsonConfiguracionAlert.hasOwnProperty('amp3Min')){
                    //console.log("HAY EMAILS");
                    this.energyAlertConfig.amp3Min = jsonConfiguracionAlert.amp3Min;
                }else{
                    //console.log("NO HAY EMAILS");
                    this.energyAlertConfig.amp3Min = 0
                }

                if(jsonConfiguracionAlert.hasOwnProperty('kwh1Max')){
                    //console.log("HAY EMAILS");
                    this.energyAlertConfig.kwh1Max = jsonConfiguracionAlert.kwh1Max;
                }else{
                    //console.log("NO HAY EMAILS");
                    this.energyAlertConfig.kwh1Max = 0
                }

                if(jsonConfiguracionAlert.hasOwnProperty('kwh2Max')){
                    //console.log("HAY EMAILS");
                    this.energyAlertConfig.kwh2Max = jsonConfiguracionAlert.kwh2Max;
                }else{
                    //console.log("NO HAY EMAILS");
                    this.energyAlertConfig.kwh2Max = 0
                }

                if(jsonConfiguracionAlert.hasOwnProperty('kwh3Max')){
                    //console.log("HAY EMAILS");
                    this.energyAlertConfig.kwh3Max = jsonConfiguracionAlert.kwh3Max;
                }else{
                    //console.log("NO HAY EMAILS");
                    this.energyAlertConfig.kwh3Max = 0
                }

                if(jsonConfiguracionAlert.hasOwnProperty('kwh1Min')){
                    //console.log("HAY EMAILS");
                    this.energyAlertConfig.kwh1Min = jsonConfiguracionAlert.kwh1Min;
                }else{
                    //console.log("NO HAY EMAILS");
                    this.energyAlertConfig.kwh1Min = 0
                }

                if(jsonConfiguracionAlert.hasOwnProperty('kwh2Min')){
                    //console.log("HAY EMAILS");
                    this.energyAlertConfig.kwh2Min = jsonConfiguracionAlert.kwh2Min;
                }else{
                    //console.log("NO HAY EMAILS");
                    this.energyAlertConfig.kwh2Min = 0
                }

                if(jsonConfiguracionAlert.hasOwnProperty('kwh3Min')){
                    //console.log("HAY EMAILS");
                    this.energyAlertConfig.kwh3Min = jsonConfiguracionAlert.kwh3Min;
                }else{
                    //console.log("NO HAY EMAILS");
                    this.energyAlertConfig.kwh3Min = 0
                }

                this.db_getDailyMonthlyEnergy(sensor).then((result) => {

                arregloDailyData = result.data.RegistrosDiarios;
                arregloMonthlyData = result.data.RegistrosMensuales;
            
                if(this.energyConfig.phases == 1){
                    arregloDailyData.forEach(element => {
                        var jsonObjectDataDaily = JSON.parse(element.dataDaily);
                        if(jsonObjectDataDaily.amp1 >= 1){
                            totalDailyAmp += jsonObjectDataDaily.kwh1;
                        }
                    });

                    arregloMonthlyData.forEach(element => {
                        var jsonObjectDataMonthly = JSON.parse(element.dataMonthly);
                        if(jsonObjectDataMonthly.amp1 >= 1){
                            totalMonthlyAmp += jsonObjectDataMonthly.kwh1;
                        }

                    });

                    powerNow = this.calcularPowerNow(jsonDatos.amp1,0,0,jsonConfiguracion.voltage);
                    dailyEnergy = (totalDailyAmp / this.energyConfig.phases) / 100;
                    monthlyEnergy = (totalMonthlyAmp / this.energyConfig.phases) / 100;

                    if(jsonDatos.temp > 0){
                        temp = jsonDatos.temp;
                    }else{
                        temp = 'WITHOUT DATA';
                    }
                    
                }else if(this.energyConfig.phases == 2){
                    arregloDailyData.forEach(element => {
                        //console.log(element.dataDaily);
                        var jsonObjectDataDaily = JSON.parse(element.dataDaily);
                        if(jsonObjectDataDaily.amp1 >= 1){
                            totalDailyAmp += jsonObjectDataDaily.kwh1 + jsonObjectDataDaily.kwh2;
                        }
                    });
                    //console.log("BANDERA TOTAL KWH DIARIO: ");
                    //console.log(totalDailyAmp);
                    arregloMonthlyData.forEach(element => {
                        var jsonObjectDataMonthly = JSON.parse(element.dataMonthly);
                        if(jsonObjectDataMonthly.amp1 >= 1){
                            totalMonthlyAmp += jsonObjectDataMonthly.kwh1 + jsonObjectDataMonthly.kwh2;
                        }

                    });
                    //console.log("BANDERA TOTAL KWH MENSUAL: ");
                    //console.log(totalMonthlyAmp);
                    powerNow = this.calcularPowerNow(jsonDatos.amp1,jsonDatos.amp2,0,jsonConfiguracion.voltage);
                    dailyEnergy = (totalDailyAmp / this.energyConfig.phases) / 100;
                    monthlyEnergy = (totalMonthlyAmp / this.energyConfig.phases) / 100;

                    if(jsonDatos.temp > 0){
                        temp = jsonDatos.temp;
                    }else{
                        temp = 'WITHOUT DATA';
                    }
                }else if(this.energyConfig.phases == 3){
                    arregloDailyData.forEach(element => {
                        var jsonObjectDataDaily = JSON.parse(element.dataDaily);
                        if(jsonObjectDataDaily.amp1 >= 1){
                            totalDailyAmp += jsonObjectDataDaily.kwh1 + jsonObjectDataDaily.kwh2 + jsonObjectDataDaily.kwh3;
                        }
                    });

                    arregloMonthlyData.forEach(element => {
                        var jsonObjectDataMonthly = JSON.parse(element.dataMonthly);
                        if(jsonObjectDataMonthly.amp1 >= 1){
                            totalMonthlyAmp += jsonObjectDataMonthly.kwh1 + jsonObjectDataMonthly.kwh2 + jsonObjectDataMonthly.kwh3;
                        }

                    });

                    powerNow = this.calcularPowerNow(jsonDatos.amp1,jsonDatos.amp2,jsonDatos.amp3,jsonConfiguracion.voltage);
                    dailyEnergy = (totalDailyAmp / this.energyConfig.phases) / 100;
                    monthlyEnergy = (totalMonthlyAmp / this.energyConfig.phases) / 100;

                    if(jsonDatos.temp > 0){
                        temp = jsonDatos.temp;
                    }else{
                        temp = null;
                    }
                }
                
                todayEnergy = dailyEnergy;
                carbonOffset = (monthlyEnergy * 0.542/1000)
                
                if(carbonOffset > 0){
                    treesPlanted = ((carbonOffset * 1000) / 0.454) * (1 / 50)
                }

                income = (monthlyEnergy * this.energyConfig.KWhPrice) //AGREGAR CURRENCY EN FRONT         

                this.energyData.powerNow = powerNow;
                this.energyData.todayEnergy = todayEnergy;
                this.energyData.monthlyEnergy = monthlyEnergy;
                this.energyData.carbonOffset = carbonOffset;
                this.energyData.treesPlanted = treesPlanted;
                this.energyData.income = income; 
                
                }).catch((err) => {
                    if(err.response.status == 401){
                        localStorage.idUser = ''; 
                        localStorage.session_p = 0;
                        localStorage.token_p = '';
                        this.$router.push({path: '/'});
                        console.log("ERROR DE AUTENTICACION");
                    }else{
                        console.log(err);
                    }
                });
            }
        },

        calcularPowerNow(amp1,amp2,amp3,voltage){
            var powerNow = (voltage * (amp1 + amp2 + amp3))/1000;
            return powerNow;
        },

        //FUNCIONES AUXILIARES 

        lastUpdate(date){
            let time = moment().diff( date, 'days');
            if(time <= 6){
                return 'color:green'
            }else if( time > 6 ){
                return 'color:red'
            }
        },

        consultaTemporal(){
            //console.log("ESTOS SON LOS DATOS ACTUALES");
            this.getDataNormal(this.selected);
        },

        calcularCoordenada(coordenada){
            var coordenadatem;
            var temporal;
            var degree;
            var signo;

            if(coordenada.substr(0,1) == "-"){
                signo = -1;
                temporal = coordenada.substr(1);
            }else{
                signo = 1;
                temporal = coordenada;
            }

            if(temporal.length == 6){
                var parte1 = temporal.substr(0,1);
                var parte2 = temporal.substr(1);
                coordenadatem = parte1 + "." + parte2;
            }else if(temporal.length == 7){
                var parte1 = temporal.substr(0,2);
                var parte2 = temporal.substr(2);
                coordenadatem = parte1 + "." + parte2;
            }else if(temporal.length == 8){
                var parte1 = temporal.substr(0,3);
                var parte2 = temporal.substr(3);
                coordenadatem = parte1 + "." + parte2;
            }else if(temporal.length == 9){
                coordenadatem = temporal.slice(0,8);
            }else if(temporal.length > 9){
                // var parte1 = temporal.substr(0,3);
                // var parte2 = temporal.substr(12);
                // coordenadatem = parte1 + "." + parte2;

                coordenadatem = temporal.slice(0,9);
            }

            if(signo == -1){
                coordenadatem = "-" + coordenadatem;
            }

            var resultado = parseFloat(coordenadatem);

            return resultado;
        },
    }
};

export default DeviceMixin;