<template>
    <v-main>
        <v-card>
            <v-card-text style="padding: 0 !important">
                <v-tabs dark background-color="blue" show-arrows>
                    <v-tabs-slider color="teal lighten-3"></v-tabs-slider>

                    <v-tab
                        ><v-icon class="ml-2 mr-2" small>mdi-alert</v-icon>
                        Alert</v-tab
                    >
                    <v-tab
                        ><v-icon class="ml-2 mr-2" small>mdi-phone</v-icon> /
                        <v-icon class="ml-2 mr-2" small>mdi-email</v-icon> email
                        and sms
                    </v-tab>
                   
                    <v-tab-item v-for="n in 3" :key="n">
                        <v-row>
                            <v-col v-if="n == 1">
                                <v-card-text>
                                    <div v-for="(tag, index) in tags" :key="index">
                                        <v-row>
                                            <v-col cols="12" class="py-0">
                                                <v-switch
                                                    class="my-1"
                                                    :id="index == 0 ? '0' : index"
                                                    color="info"
                                                    :label="tag"
                                                    v-model="enabledAlert[index]"
                                                    hide-details
                                                    @click="itemAlert(tag, index, enabledAlert[index])"

                                                ></v-switch>
                                            </v-col>
                                        </v-row>
                                        <v-row
                                            :class="
                                                rangeValidation(index)
                                                    ? 'mb-0'
                                                    : 'mb-8'
                                            "
                                        >
                                            <v-col cols="6" class="py-2">                                            
                                                <v-text-field
                                                    class="mt-0"
                                                    :id="'range-' + index"
                                                    label="Minimum"
                                                    v-model="minimumR[index]"
                                                    type="number"
                                                    :disabled="
                                                        !enabledAlert[index] ===
                                                        true
                                                            ? true
                                                            : false
                                                    "
                                                    outlined
                                                    hide-details
                                                    required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="6" class="py-2">
                                                <v-text-field
                                                    class="mt-0"
                                                    :id="'range-' + index"
                                                    label="Maximum"
                                                    v-model="maximumR[index]"
                                                    type="number"
                                                    :disabled="
                                                        !enabledAlert[index] ===
                                                        true
                                                            ? true
                                                            : false
                                                    "
                                                    outlined
                                                    hide-details
                                                    required
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-alert
                                            class="text-center mb-2 py-0"
                                            outlined
                                            v-if="rangeValidation(index)"
                                            color="error"
                                            >Range: Wrong</v-alert
                                        >
                                    </div>
                                </v-card-text>
                            </v-col>

                            <v-col v-if="n == 2">
                                <v-card-text>
                                    <v-text-field
                                        label="Email 1"
                                        v-model="email1"
                                        :rules="
                                            estatus_value == 'NO' &&
                                            email1 == ''
                                                ? true
                                                : [rules.required, rules.email]
                                        "
                                        required
                                    ></v-text-field>
                                    <v-text-field
                                        label="Email 2"
                                        v-model="email2"
                                        :rules="
                                            email2 == '' ? true : [rules.email]
                                        "
                                    ></v-text-field>
                                    <v-text-field
                                        label="Email 3"
                                        v-model="email3"
                                        :rules="
                                            email3 == '' ? true : [rules.email]
                                        "
                                    ></v-text-field>

                                    <h3>
                                        Do you want to receive alerts about the
                                        offline device?
                                    </h3>
                                    <v-checkbox
                                        class="d-flex align-end flex-column"
                                        v-model="unreported"
                                        label="unreported"
                                        color="orange darken-3"
                                        value="orange darken-3"
                                        hide-details
                                    ></v-checkbox>
                                    <v-select
                                        :items="estatus"
                                        :value="estatus.value"
                                        label="Status"
                                        v-model="estatus_value"
                                        required
                                        class="pt-4 px-1 mt-4"
                                    ></v-select>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs>
            </v-card-text>
        </v-card>

        <v-card-actions class="justify-end">
            <v-btn
                text
                color="primary"
                :disabled="!canUpdate()"
                @click="updateAlerts()"
                >Update</v-btn
            >
            <v-progress-circular
                indeterminate
                color="primary"
                size="20"
                v-if="loadingFlagFormAlerts"
            ></v-progress-circular>
        </v-card-actions>
    </v-main>
</template>

<script>
import DeviceService from "@/services/DeviceService";
import { mapGetters, mapActions } from "vuex";

export default {
    mixins: [DeviceService],
    props: {
        rules: Object,
        device: Object,
    },
    data() {
        return {
            alertSelected : [],
            id: null,
            estatus: [
                { text: "Enable", value: "YES" },
                { text: "Disable", value: "NO" },
            ],
            estatus_value: "NO",
            email1: "",
            email2: "",
            email3: "",
            unreported: 0,
            tags: [],
            canUpdateTest: "",
            enabledAlert: [],
            maximumR: [],
            minimumR: [],
            errors: [],
        };
    },
    mounted() {
        this.closeModalAlerts("");
        this.email1 = JSON.parse(this.device.alertConfig).emails[0];
        this.email2 = JSON.parse(this.device.alertConfig).emails[1];
        this.email3 = JSON.parse(this.device.alertConfig).emails[2];
        this.estatus_value = JSON.parse(this.device.alertConfig).alertEnable;
        
        var obj = JSON.parse(this.device.datos);

        var newArray = [];

        let tags = []
        if (this.device.idSensor == 1 || this.device.idSensor == 27 || this.device.idSensor == 33 || this.device.idSensor == 47 ) {
            tags = ["amp1", "amp2", "amp3", "temp"];
        }else if (this.device.idSensor == 48){
            tags = ["current_1","current_2","current_3"];
        }
       
        Object.keys(obj).forEach(function (key, idx) {
            if (tags.includes(key)) {
                newArray.push(key);
            }
        });
        this.tags = newArray.sort();

        // this.device.idSensor == 1 ? this.tags.unshift("powerNow") : "";

        let params = {
            asset_id: this.device.imei,
        };
        this.getDataAlert(params).then((result) => {
            if (result.data.alert == null) {
                this.id = null;
                this.minimumR = [];
                this.maximumR = [];
            } else {
                //

                console.log("########################################");
                console.log(result.data.alert.attribute);
                this.alertSelected = JSON.parse(result.data.alert.attribute)
                let data = result.data.alert;
                this.id = result.data.alert.id;
                this.enabledAlert = JSON.parse(data.attribute);
                this.minimumR = JSON.parse(
                    result.data.alert.attributes_alert
                ).min;
                this.maximumR = JSON.parse(
                    result.data.alert.attributes_alert
                ).max;
            }
        });
    },
    computed: {
        ...mapGetters({
            getUser: "getUser",
        }),
    },
    methods: {
        itemAlert(tag, newIndex, status){
            let index = this.alertSelected.findIndex(el=> el === tag)
            index >= 0? this.alertSelected.splice(index,1) : this.alertSelected.push(tag)

            if(!status){
                this.minimumR[newIndex]= ''
                this.maximumR[newIndex]= ''                
            }

        },
        rangeValidation(index) {
            let result =
                this.enabledAlert[index] === true
                    ? this.minimumR[index] && this.maximumR[index]
                        ? Number(this.minimumR[index]) >=
                          Number(this.maximumR[index])
                        : true
                    : false;

            this.errors[index] = result;
            return result;
        },
        closeModalAlerts_2() {
            this.closeModalAlerts("close");
        },
        canUpdate() {
            let result = true;
            this.errors.forEach((error) => {
                error ? (result = false) : null;
            });
            const pattern =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            this.email1 == ""
                ? this.estatus_value == "YES"
                    ? (result = false)
                    : null
                : pattern.test(this.email1)
                ? null
                : (result = false);
            this.email2 != ""
                ? pattern.test(this.email2)
                    ? null
                    : (result = false)
                : null;
            this.email3 != ""
                ? pattern.test(this.email3)
                    ? null
                    : (result = false)
                : null;
            this.canUpdateTest = result;
            return result;
        },
        updateAlerts() {            
            if (this.email1 == "" || this.estatus_value == "NO") {
                this.estatus_value = "NO";
                this.enabledAlert.forEach((alert, index) => {
                    this.enabledAlert[index] = null;
                });
            }

            let tagsSelected = [];
            this.enabledAlert.forEach((tag, index) => {
                tag
                    ? tagsSelected.push(this.tags[index])
                    : tagsSelected.push(null);
            });
            let maximum = [];
            let minimum = [];

            this.maximumR.forEach((max) => {
                max ? maximum.push(Number(max)) : '';
            });
            this.minimumR.forEach((min) => {
                min ? minimum.push(Number(min)) : '';
            });

            let mails = [];
            mails.push(this.email1);
            mails.push(this.email2);
            mails.push(this.email3);

            let emails = {
                emails: mails,
                alertEnable: this.estatus_value,
            };

            let attributes_alert = {
                min: minimum,
                max: maximum,
            };

            var params = {
                id: this.id,
                type: "rangos",
                email_status: JSON.stringify(emails),
                device_type: this.device.idSensor,
                attribute: JSON.stringify(this.alertSelected),
                attributes_alert: JSON.stringify(attributes_alert),
                status: this.estatus_value,
                user_id: localStorage.idUser,
                notifications: JSON.stringify({
                    sms: "YES",
                    email: "YES",
                    push_notification: "YES",
                }),
                asset_id: this.device.imei,
            };

            if (this.id == null) {
                try {
                    this.saveConfigurationAlert(params).then(() => {
                        this.showMessage("Added successfully");
                    });
                } catch (error) {
                    this.showMessageError(error);
                }
            } else {
                try {
                    this.updateConfigurationAlert(params).then(() => {
                        this.showMessage("Updated successfully");
                    });
                } catch (error) {
                    this.showMessageError(error);
                }
            }
        },
        showMessage(message) {
            this.$vToastify.success(message);
        },
        showMessageError(error) {
            this.$vToastify.error("An Error ocurred: " + error);
        },
        ...mapActions(["closeModalAlerts"]),
    },
};
</script>
