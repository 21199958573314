import moment from 'moment';
const GraphicDeviceMixin = {


    methods: {
        //Carga de datos de grafica de sensorposition del rango de fechas seleccionado por el usuario
        getDataSensorPositionHistorico(dispositivo, channel, itemSelected = "", chart_id = ""){
            // alert('Funcion')
            this.dataSensorPositionDevices = [];
            this.db_getDataSensorPositionHistorico(dispositivo).then((result) => {
                    // console.log("Dispositivo:");
                    // console.log(dispositivo);
                    // console.log(dispositivo.deviceSelected.name);
                    this.dataSensorPositionDevices = result.data[1];
                    var totalRegistros = result.data[0];
                    this.totalReceivedData = result.data[0];
                    var idsensor = dispositivo.deviceSelected.idSensor;
                    if(idsensor == 1){
                        var i = 0
                        var arrayTemp = [];
                        var arrayKwh1 = [];
                        var arrayKwh2 = [];
                        var arrayKwh3 = [];
                        var arrayAmp1 = [];
                        var arrayAmp2 = [];
                        var arrayAmp3 = [];
                        var arrayFechaGps = [];
                        
                        var contT = 0;
                        var contKwh1 = 0;
                        var contKwh2 = 0;
                        var contKwh3 = 0;
                        var contAmp1 = 0;
                        var contAmp2 = 0;
                        var contAmp3 = 0;
                        var contFecha = 0;
                        for(i=0; i < totalRegistros; i++){
                            var dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            var fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            contT = arrayTemp.push(dataJson.temp);
                            contKwh1 = arrayKwh1.push(dataJson.kwh1);
                            contKwh2 = arrayKwh2.push(dataJson.kwh2);
                            contKwh3 = arrayKwh3.push(dataJson.kwh3);
                            contAmp1 = arrayAmp1.push(dataJson.amp1);
                            contAmp2 = arrayAmp2.push(dataJson.amp2);
                            contAmp3 = arrayAmp3.push(dataJson.amp3);
                            contFecha = arrayFechaGps.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));
                        }
                        this.arrayDataTemp = arrayTemp;
                        this.arrayDataKwh1 = arrayKwh1;
                        this.arrayDataKwh2 = arrayKwh2;
                        this.arrayDataKwh3 = arrayKwh3;
                        this.arrayDataAmp1 = arrayAmp1;
                        this.arrayDataAmp2 = arrayAmp2;
                        this.arrayDataAmp3 = arrayAmp3;
                        this.arrayDataFechaGps = arrayFechaGps;
                        var idChart = "energy-highchart";
                        this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 2){ // SONOMETRO
                        var i = 0
                        var arrayDesibeles = [];
                        var arrayFechaGps = [];
                        var contDes = 0;
                        var contFecha = 0;
                        for(i=0; i < totalRegistros; i++){
                            var dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            var fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            contDes = arrayDesibeles.push(dataJson.sonometer);
                            contFecha = arrayFechaGps.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));
                        }
                        this.arrayDataSonometer = arrayDesibeles;
                        this.arrayDataFechaGps = arrayFechaGps;
                        var idChart = "sonometer-highchart";
                        this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 3){ //AIR QUALITY
                        var i = 0
                        var arrayQuality = [];
                        var arrayTvoc = [];
                        var arrayFechaGps = [];
                        var contA = 0;
                        var contT = 0;
                        var contFecha = 0;
                        for(i=0; i < totalRegistros;i++){
                            var dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            var fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            contA = arrayQuality.push(dataJson.quality);
                            contT = arrayTvoc.push(dataJson.tvoc); 
                            contFecha = arrayFechaGps.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));
                        }
                        this.arrayDataAirQuality = arrayQuality;
                        this.arrayDataAirTvoc = arrayTvoc;
                        this.arrayDataFechaGps = arrayFechaGps;
                        var idChart = "airquality-highchart";
                        this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 7){ // GAS LEVEL
                        var i = 0
                        var arrayLevel = [];
                        var arrayFechaGps = [];
                        var contLev = 0;
                        var contFecha = 0;
                        for(i=0; i < totalRegistros; i++){
                            var dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            var fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            contLev = arrayLevel.push(dataJson.level);
                            contFecha = arrayFechaGps.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));
                        }
                        this.arrayDataGasLevel = arrayLevel;
                        this.arrayDataFechaGps = arrayFechaGps;
                        var idChart = "gaslevel-highchart";
                        this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 8){ //EVENTS
                        var i = 0
                        var arrayEv1 = [];
                        var arrayEv2 = [];
                        var arrayEv3 = [];
                        var arrayEv4 = [];
                        var arrayFechaGps = [];
                        var contEv1 = 0;
                        var contEv2 = 0;
                        var contEv3 = 0;
                        var contEv4 = 0;
                        var contFecha = 0;
                        for(i=0; i < totalRegistros;i++){
                            var dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            var fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            contEv1 = arrayEv1.push(dataJson.ev1);
                            contEv2 = arrayEv2.push(dataJson.ev2);
                            contEv3 = arrayEv3.push(dataJson.ev3);
                            contEv4 = arrayEv4.push(dataJson.ev4);
                            contFecha = arrayFechaGps.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));
                        }
                        this.arrayDataEvento1 = arrayEv1;
                        this.arrayDataEvento2 = arrayEv2;
                        this.arrayDataEvento3 = arrayEv3;
                        this.arrayDataEvento4 = arrayEv4;
                        this.arrayDataFechaGps = arrayFechaGps;
                        var idChart = "events-highchart";
                        this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 11){ //TOUCH TAG
                        var i = 0
                        var arraytemp = [];
                        var arrayPitch = [];
                        var arrayRoll = [];
                        var arrayFechaGps = [];
                        var contT = 0;
                        var contP = 0;
                        var contR = 0;
                        var contFecha = 0;
                        for(i=0; i < totalRegistros;i++){
                            var dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            var fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            contT = arraytemp.push(dataJson.temperature);
                            contP = arrayPitch.push(dataJson.pitch);
                            contR = arrayRoll.push(dataJson.roll);
                            contFecha = arrayFechaGps.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));
                        }
                        this.arrayDataTemperature = arraytemp;
                        this.arrayDataPitchTG = arrayPitch;
                        this.arrayDataRollTG = arrayRoll;
                        this.arrayDataFechaGps = arrayFechaGps;
                        var idChart = "touch-tag-highchart";
                        this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 29){
                        var i = 0
                        var arraytemp = [];
                        var arrayhumidity = [];
                        var arrayFechaGps = [];
                        var contT = 0;
                        var contH = 0;
                        var contFecha = 0;
                        for(i=0; i < totalRegistros;i++){
                            var dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            var fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            contT = arraytemp.push(dataJson.temperature);
                            contH = arrayhumidity.push(dataJson.humidity);
                            contFecha = arrayFechaGps.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));
                        }
                        this.arrayDataTemperature = arraytemp;
                        this.arrayDataHumidity = arrayhumidity;
                        this.arrayDataFechaGps = arrayFechaGps;
                        var idChart = "temperature-tierra-highchart";
                        this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 30){ //PIRANOMETRO
                        var i = 0
                        var arraytemp = [];
                        var arraywattmetro = [];
                        var arrayFechaGps = [];
                        var contT = 0;
                        var contW = 0;
                        var contFecha = 0;
                        for(i=0; i < totalRegistros;i++){
                            var dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            // console.log(dataJson.temperature);
                            if(dataJson.temperature=='NaN'){
                                dataJson.temperature = 0;
                            }
                            if(dataJson.wattmetro=='NaN'){
                                dataJson.wattmetro = 0;
                            }
                            var fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            contT = arraytemp.push(dataJson.temperature);
                            contW = arraywattmetro.push(dataJson.wattmetro);
                            contFecha = arrayFechaGps.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));
                        }
                        this.arrayDataTemperaturaPir = arraytemp;
                        this.arrayDataWattmetroPir = arraywattmetro;
                        this.arrayDataFechaGps = arrayFechaGps;
                        var idChart = "piranometer-highchart";
                        this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 31){ //TEMPERATURA  Y HUMEDAD Y TIERRA
                        // alert('humedad')
                        var i = 0
                        var arraytemp = [];
                        var arrayhumidity = [];
                        var arrayFechaGps = [];
                        var contT = 0;
                        var contH = 0;
                        var contFecha = 0;
                        for(i=0; i < totalRegistros;i++){
                            var dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            var fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            if(dataJson.temperature=='NaN'){
                                dataJson.temperature = 0;
                            }
                            if(dataJson.humidity=='NaN'){
                                dataJson.humidity = 0;
                            }
                            contT = arraytemp.push(dataJson.temperature);
                            contH = arrayhumidity.push(dataJson.humidity);
                            contFecha = arrayFechaGps.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));
                        }
                        this.arrayDataTemperature = arraytemp;
                        this.arrayDataHumidity = arrayhumidity;
                        this.arrayDataFechaGps = arrayFechaGps;
                        var idChart = "temperature-humidity-highchart";
                        this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 32){ //ESTACION METEORLOGOICA
                        var i = 0
                        var arraytemp = [];
                        var arraywindspeed = [];
                        var arrayrainvolume = [];
                        var arraydirectionwind = [];
                        var arrayrelativehumidity = [];
                        var arraypascalpressure = [];
                        var arrayFechaGps = [];
                        var contT = 0;
                        var contN = 0;
                        var contFecha = 0;
                        for(i=0; i < totalRegistros;i++){
                            var dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            var fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            arraytemp.push(dataJson.temperature);
                            arraywindspeed.push(dataJson.wind_speed);
                            arrayrainvolume.push(dataJson.rain_volume);
                            arraydirectionwind.push(dataJson.direction_wind);
                            arrayrelativehumidity.push(dataJson.relative_humidity);
                            arraypascalpressure.push(dataJson.pascal_pressure);
                            //arrayFechaGps.push(fechaTemp);
                            arrayFechaGps.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));
                        }
                        this.arrayDataWindSpeedEM = arraywindspeed;
                        this.arrayDataRainVolumeEM = arrayrainvolume;
                        this.arrayDataTemperaturaEM = arraytemp;
                        this.arrayDataDirectionWindEM = arraydirectionwind;
                        this.arrayDataRelativeHumidityEM = arrayrelativehumidity;
                        this.arrayDataPascalPressureEM = arraypascalpressure;
                        this.arrayDataFechaGps = arrayFechaGps;

                        var idChart = "estacion-meteorologica-highchart";
                        this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 36){ //PH
                        var i = 0
                        var arraytemp = [];
                        var arraynivel = [];
                        var arrayFechaGps = [];
                        var contT = 0;
                        var contN = 0;
                        var contFecha = 0;
                        for(i=0; i < totalRegistros;i++){
                            var dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            var fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            contT = arraytemp.push(dataJson.Temperatura);
                            contN = arraynivel.push(dataJson.nivel);
                            contFecha = arrayFechaGps.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));
                        }
                        this.arrayDataTemperaturaPh = arraytemp;
                        this.arrayDataNivel = arraynivel;
                        this.arrayDataFechaGps = arrayFechaGps;
                        var idChart = "ph-highchart";
                        this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 37){ //EC
                        var i = 0
                        var arraytemp = [];
                        var arrayuScm = [];
                        var arrayFechaGps = [];
                        var contT = 0;
                        var contuS = 0;
                        var contFecha = 0;
                        for(i=0; i < totalRegistros;i++){
                            var dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            var fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            contT = arraytemp.push(dataJson.Temperatura);
                            contuS = arrayuScm.push(dataJson.uScm);
                            contFecha = arrayFechaGps.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));
                        }
                        this.arrayDataTemperaturaEc = arraytemp;
                        this.arrayDatauScm = arrayuScm;
                        this.arrayDataFechaGps = arrayFechaGps;
                        var idChart = "ec-highchart";
                        this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 41){ //FLOW WATER
                        var i = 0
                        var arrayltsMinute = [];
                        var arrayltsTotal = [];
                        var arrayFechaGps = [];

                        var contlm = 0;
                        var contlt = 0;
                        var contFecha = 0;

                        for(i=0; i < totalRegistros;i++){
                            var dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            var fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            contlm = arrayltsMinute.push(dataJson.lts_minute);
                            contlt = arrayltsTotal.push(dataJson.lts_total);
                            contFecha = arrayFechaGps.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));
                        }
                        this.arrayDataLtsMinuteWF = arrayltsMinute;
                        this.arrayDataLtsTotalWF = arrayltsTotal;
                        this.arrayDataFechaGps = arrayFechaGps;
                        var idChart = "flowwater-highchart";
                        this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 47){ // DCMAX1500
                        let i = 0
                        let arrCanal1 = [];
                        let arrCanal2 = [];
                        let arrCanal3 = [];
                        let arrCanal4 = [];
                        let arrPowerNow = [];
                        let arrFechas = [];

                        let arrCurrent = [];
                        let arrVolt = [];
                        let arrName = {name1: "", name2:""};
                        

                        for(i=0; i < totalRegistros;i++){
                            let dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            let fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            arrCanal1.push(this.numFormat(dataJson.p1));
                            arrCanal2.push(this.numFormat(dataJson.p2));
                            arrCanal3.push(this.numFormat(dataJson.p3));
                            arrCanal4.push(this.numFormat(dataJson.p4));
                            let powerNow = parseFloat(dataJson.p1) + parseFloat(dataJson.p2) + parseFloat(dataJson.p3) + parseFloat(dataJson.p4);
                            arrPowerNow.push(this.numFormat(powerNow));
                            arrFechas.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));


                            switch (channel) {
                                case 1:
                                    arrCurrent.push( dataJson.v1 )
                                    arrVolt.push( dataJson.i1 )
                                    arrName.name1 = "Voltage 1"
                                    arrName.name2 = "Current 1"
                                break;
                                case 2:
                                    arrCurrent.push( dataJson.v2 )
                                    arrVolt.push( dataJson.i2 )
                                    arrName.name1 = "Voltage 2"
                                    arrName.name2 = "Current 2"
                                break;
                                case 3:
                                    arrCurrent.push( dataJson.v3 )
                                    arrVolt.push( dataJson.i3 )
                                    arrName.name1 = "Voltage 3"
                                    arrName.name2 = "Current 3"
                                break;
                                case 4:
                                    arrCurrent.push( dataJson.v4 )
                                    arrVolt.push( dataJson.i4 )
                                    arrName.name1 = "Voltage 4"
                                    arrName.name2 = "Current 4"
                                break;
                                case 'power':

                                break;
                            
                                default:
                                break;
                            }



                        }                      
                        
                        this.arrayDataFechaGps = arrFechas


                        switch (channel) {
                            case 1:
                                this.arrChannels.channel1 = arrCanal1;  
                                this.arrChannels.channel2 = [] 
                                this.arrChannels.channel3 = [] 
                                this.arrChannels.channel4 = [] 
                                this.arrChannels.power = [] 
                                this.generateDetailGraph("dcmax-highchart_2", arrCurrent, arrVolt, arrName, arrFechas)
                            break;
                            case 2:
                                this.arrChannels.channel2 = arrCanal2;    
                                this.arrChannels.channel1 = [] 
                                this.arrChannels.channel3 = [] 
                                this.arrChannels.channel4 = [] 
                                this.arrChannels.power = []
                                this.generateDetailGraph("dcmax-highchart_2", arrCurrent, arrVolt, arrName, arrFechas)
                            break;
                            case 3:
                                this.arrChannels.channel3 = arrCanal3;  
                                this.arrChannels.channel1 = [] 
                                this.arrChannels.channel2 = [] 
                                this.arrChannels.channel4 = [] 
                                this.arrChannels.power = []
                                this.generateDetailGraph("dcmax-highchart_2", arrCurrent, arrVolt, arrName, arrFechas)
                            break;
                            case 4:
                                this.arrChannels.channel4 = arrCanal4;
                                this.arrChannels.channel1 = [] 
                                this.arrChannels.channel2 = [] 
                                this.arrChannels.channel3 = [] 
                                this.arrChannels.power = []
                                this.generateDetailGraph("dcmax-highchart_2", arrCurrent, arrVolt, arrName, arrFechas)
                            break;
                            case 'power':
                                this.arrChannels.power = arrPowerNow;
                                this.arrChannels.channel1 = []
                                this.arrChannels.channel2 = [] 
                                this.arrChannels.channel3 = [] 
                                this.arrChannels.channel4 = []  
                                this.generatPowerDetailGraph("dcmax-highchart_2", arrCanal1, arrCanal2, arrCanal3, arrCanal4, arrFechas)
                            break;
                        
                            default:
                                break;
                        }                       

                        
                        let idChart = "dcmax-highchart";
                        this.generateGraph(idChart,idsensor);

                    }else if(idsensor == 48){
                        this.arrayDataFechaGps =[]
                        this.chartBidirectional =[]
                        this.chartEnergyBdnal =[]
                        this.chartVoltage = []
                        let current_1 = []
                        let current_2 = []
                        let current_3 = []
                        let voltage_1 = []
                        let voltage_2 = []
                        let voltage_3 = []
                        let consumed = []
                        let arrFechas = [] 
                        
                        
                        console.log("CHANNEL ...... "+channel)
                        console.log("ITEM SELECTED ...... "+itemSelected)

                        for (let a = 0; a < this.dataSensorPositionDevices.length; a++) {
                            const element =  (JSON.parse(this.dataSensorPositionDevices[a].datos));      
                            
                            if (channel == "Consumed") {
                                if (itemSelected == "CURRENTS") {
                                    if ( parseFloat(element.current_1) >= 0) 
                                        current_1.push(element.current_1);
                                    if (parseFloat(element.current_2) >= 0) 
                                        current_2.push(element.current_2);
                                    if (parseFloat(element.current_3) >= 0) 
                                        current_3.push(element.current_3);                                     
                                }else if (itemSelected == "ENERGIES") {
                                    consumed.push(element.consumed);  
                                }
                                                               
                            }else{
                                if (itemSelected == "CURRENTS") {
                                    if (parseFloat(element.current_1) < 0.0)
                                        current_1.push(element.current_1);
                                    if (parseFloat(element.current_2) < 0.0) 
                                        current_2.push(element.current_2);
                                    if (parseFloat(element.current_3) < 0.0) 
                                        current_3.push(element.current_3);
                                }else if (itemSelected == "ENERGIES") {
                                    consumed.push(element.generated);  
                                }   
                            } 
                            
                            voltage_1.push(element.voltage_1);
                            voltage_2.push(element.voltage_2);
                            voltage_3.push(element.voltage_3);
                           
                            arrFechas.push(moment(this.dataSensorPositionDevices[a].fechaGps).format("DD MMMM YYYY HH:mm:ss"));
                            
                        }
                        
                       
                        this.chartBidirectional.push(current_1,current_2,current_3);                    

                        this.chartEnergyBdnal.push(consumed);                        
                        this.chartVoltage.push(voltage_1,voltage_2,voltage_3);
                        this.arrayDataFechaGps = arrFechas
                        let idChart = chart_id;
                        this.generateGraph(idChart,idsensor,itemSelected,channel);

                    }
            }).catch((err) => {
                console.log("*errror al generar ")
                console.log(err);
                if(err){
                    this.loadingFlagHistorico = false
                    this.loadingFlag = false;
                    this.loadingFlagChartReport = false;
                }
                if(err.response?.status == 401){
                    localStorage.idUser = ''; 
                    localStorage.session_p = 0;
                    localStorage.token_p = '';
                    this.$router.push({path: '/'});
                    console.log("ERROR DE AUTENTICACION");
                }
            });  
        },

        //CREAR GRAFICAS
        generateGraph(idChart,idsensor, itemSelected = "", category =""){
            if(idsensor == 1){
                var para = {
                    idHighchart: idChart,
                    title: "Energy",
                    labelX: "Data",
                    series:[
                        {
                            data: this.arrayDataTemp,
                            color: 'blue',
                            name: "temp",
                        },
                        {
                            data: this.arrayDataKwh1,
                            color: 'red',
                            name: "kwh1",
                        },
                        {
                            data: this.arrayDataKwh2,
                            color: 'yellow',
                            name: "kwh2",
                        },
                        {
                            data: this.arrayDataKwh3,
                            color: 'green',
                            name: "kwh3",
                        },
                        {
                            data: this.arrayDataAmp1,
                            color: 'brown',
                            name: "amp1",
                        },
                        {
                            data: this.arrayDataAmp2,
                            color: 'pink',
                            name: "amp2",
                        },
                        {
                            data: this.arrayDataAmp3,
                            color: 'black',
                            name: "amp3",
                        }
                    ],
                    categories: this.arrayDataFechaGps
                };
            }else if(idsensor == 2){
                var para = {
                    idHighchart: idChart,
                    title: "Sonometro",
                    labelX: "Decibeles",
                    series:[
                        {
                            data: this.arrayDataSonometer,
                            color: 'blue',
                            name: "Decibeles",
                            tooltip:{
                                valueSuffix: ' Decibeles',
                            }
                        }
                    ],
                    categories: this.arrayDataFechaGps
                };
            }else if(idsensor == 3){
                var para = {
                    idHighchart: idChart,
                    title: "Air Quality",
                    labelX: "CO2",
                    series:[
                        {
                            data: this.arrayDataAirQuality,
                            color: 'blue',
                            name: "CO2"
                        },
                        {
                            data: this.arrayDataAirTvoc,
                            color: 'red',
                            name: "TVOC"
                        },
                    ],
                    categories: this.arrayDataFechaGps
                };
            }else if(idsensor == 7){
                var para = {
                    idHighchart: idChart,
                    title: "Gas Level",
                    labelX: "Level",
                    series:[
                        {
                            data: this.arrayDataGasLevel,
                            color: 'blue',
                            name: "PPM",
                            tooltip:{
                                valueSuffix: ' ppm',
                            }
                        }
                    ],
                    categories: this.arrayDataFechaGps
                };
            }else if(idsensor == 8){
                var para = {
                    idHighchart: idChart,
                    title: "Events",
                    labelX: "Events",
                    series:[
                        {
                            data: this.arrayDataEvento1,
                            color: 'blue',
                            name: "Evento 1"
                        },
                        {
                            data: this.arrayDataEvento2,
                            color: 'red',
                            name: "Evento 2"
                        },
                        {
                            data: this.arrayDataEvento3,
                            color: 'yellow',
                            name: "Evento 3"
                        },
                        {
                            data: this.arrayDataEvento4,
                            color: 'green',
                            name: "Evento 4"
                        }
                    ],
                    categories: this.arrayDataFechaGps
                };
            }else if(idsensor == 11){
                var para = {
                    idHighchart: idChart,
                    title: "Touch Tag",
                    labelX: "Pitch / Roll / Temperature",
                    series:[
                        {
                            data: this.arrayDataTemperatureTG,
                            color: 'blue',
                            name: "Temperature",
                            tooltip:{
                                valueSuffix: ' °C',
                            }
                        },
                        {
                            data: this.arrayDataPitchTG,
                            color: 'red',
                            name: "Pitch",
                        },
                        {
                            data: this.arrayDataRollTG,
                            color: 'yellow',
                            name: "Roll",
                        },
                    ],
                    categories: this.arrayDataFechaGps
                };
            }else if(idsensor == 29){
                var para = {
                    idHighchart: idChart,
                    title: "Temperatura en Tierra",
                    labelX: "Temperature / Humidity",
                    series:[
                        {
                            data: this.arrayDataTemperature,
                            color: 'blue',
                            name: "Temperature",
                            tooltip:{
                                valueSuffix: ' °C',
                            }
                        },
                        {
                            data: this.arrayDataHumidity,
                            color: 'red',
                            name: "Humidity",
                            tooltip:{
                                valueSuffix: ' %',
                            }
                        },
                    ],
                    categories: this.arrayDataFechaGps
                };
            }else if(idsensor == 30){
                var para = {
                    idHighchart: idChart,
                    title: "Piranometro",
                    labelX: "(Watt / Meter) / Temperature",
                    series:[
                        {
                            data: this.arrayDataTemperaturaPir,
                            color: 'blue',
                            name: "Temperature",
                            tooltip:{
                                valueSuffix: ' °C',
                            }
                        },
                        {
                            data: this.arrayDataWattmetroPir,
                            color: 'red',
                            name: "Watt/Meter",
                        },
                    ],
                    categories: this.arrayDataFechaGps
                };
            }else if(idsensor == 31){
                var para = {
                    idHighchart: idChart,
                    title: "Temperature and Humidity",
                    labelX: "Temperature / Humidity",
                    series:[
                        {
                            data: this.arrayDataTemperature,
                            color: 'blue',
                            name: "Temperature",
                            tooltip:{
                                valueSuffix: ' °C',
                            }
                        },
                        {
                            data: this.arrayDataHumidity,
                            color: 'red',
                            name: "Humidity",
                            tooltip:{
                                valueSuffix: ' %',
                            }
                        },
                    ],
                    categories: this.arrayDataFechaGps
                };
            }else if(idsensor == 32){
                var para = {
                    idHighchart: idChart,
                    title: "Estacion Meteorologica",
                    labelX: "EM Values",
                    series:[
                        {
                            data: this.arrayDataWindSpeedEM,
                            color: 'blue',
                            name: "Wind Speed",
                            tooltip:{
                                valueSuffix: ' Km/h',
                            }
                        },
                        {
                            data: this.arrayDataRainVolumeEM,
                            color: 'red',
                            name: "Rain Volume",
                            tooltip:{
                                valueSuffix: ' mm',
                            }
                        },
                        {
                            data: this.arrayDataTemperaturaEM,
                            color: 'yellow',
                            name: "Temperature",
                            tooltip:{
                                valueSuffix: ' °C',
                            }
                        },
                        {
                            data: this.arrayDataDirectionWindEM,
                            color: 'green',
                            name: "Direction Wind"
                        },
                        {
                            data: this.arrayDataRelativeHumidityEM,
                            color: 'purple',
                            name: "Relative Humidity",
                            tooltip:{
                                valueSuffix: ' (%RH)',
                            }
                        },
                        {
                            data: this.arrayDataPascalPressureEM,
                            color: 'black',
                            name: "Pascal Pressure"
                        },
                    ],
                    categories: this.arrayDataFechaGps
                };
            }else if(idsensor == 36){
                var para = {
                    idHighchart: idChart,
                    title: "PH",
                    labelX: "Temperature / Level",
                    series:[
                        {
                            data: this.arrayDataTemperaturaPh,
                            color: 'blue',
                            name: "Temperature",
                            tooltip:{
                                valueSuffix: ' °C',
                            }
                        },
                        {
                            data: this.arrayDataNivel,
                            color: 'red',
                            name: "Level"
                        },
                    ],
                    categories: this.arrayDataFechaGps
                };
            }else if(idsensor == 37){
                var para = {
                    idHighchart: idChart,
                    title: "EC",
                    labelX: "Temperature / (uS/cm)",
                    series:[
                        {
                            data: this.arrayDataTemperaturaEc,
                            color: 'blue',
                            name: "Temperature",
                            tooltip:{
                                valueSuffix: ' °C',
                            }
                        },
                        {
                            data: this.arrayDatauScm,
                            color: 'red',
                            name: "uS/cm"
                        },
                    ],
                    categories: this.arrayDataFechaGps
                };
            }else if(idsensor == 41){
                var para = {
                    idHighchart: idChart,
                    title: "Flow Water",
                    labelX: "(L/min) / (Total Lts.)",
                    series:[
                        {
                            data: this.arrayDataLtsMinuteWF,
                            color: 'blue',
                            name: "Liters per Minute",
                            tooltip:{
                                valueSuffix: ' L/min',
                            }
                        },
                        {
                            data: this.arrayDataLtsTotalWF,
                            color: 'red',
                            name: "Total Liters",
                            tooltip:{
                                valueSuffix: ' L',
                            }
                        },
                    ],
                    categories: this.arrayDataFechaGps
                };
            }else if(idsensor == 47){ // DCMAX1500
                var para = {
                    idHighchart: idChart,
                    title: "Energy",
                    labelX: "KW",
                    series:[
                        {
                            data: this.arrChannels.channel1,
                            color: '#CB0000',
                            name: "Canal 1",
                            showInLegend: false, 
                            tooltip:{
                                valueSuffix: ' KW',
                            }
                        },
                        {
                            data: this.arrChannels.channel2,
                            color: '#BA71FF',
                            name: "Canal 2",
                            showInLegend: false, 
                            tooltip:{
                                valueSuffix: ' KW',
                            }
                        },
                        {
                            data: this.arrChannels.channel3,
                            color: '#ED8100',
                            name: "Canal 3",
                            showInLegend: false, 
                            tooltip:{
                                valueSuffix: ' KW',
                            }
                        },
                        {
                            data: this.arrChannels.channel4,
                            color: '#32D3FF',
                            name: "Canal 4",
                            showInLegend: false, 
                            tooltip:{
                                valueSuffix: ' KW',
                            }
                        },
                        {
                            data: this.arrChannels.power,
                            color: '#000000',
                            name: "Power Now",
                            showInLegend: false, 
                            tooltip:{
                                valueSuffix: ' KW',
                            }
                        },
                    ],
                    categories: this.arrayDataFechaGps
                };
            }else if(idsensor == 48){ // BIDIRECTIONAL 
                let textColor = category == 'Consumed'? '#fff':'#000'

                if (itemSelected == "CURRENTS") {
                    var para = {
                        idHighchart: idChart,
                        title: "CURRENTS "+ category.toUpperCase(),
                        textColor :textColor,
                        labelX: "KW",   
                        height: 300,                       
                        series:[
                            {
                                data: this.chartBidirectional[0],
                                color: '#3364FF',
                                name: "CURRENT 1",
                                // showInLegend: true, 
                                tooltip:{
                                    valueSuffix: ' KW',
                                }
                            },
                            {
                                data: this.chartBidirectional[1],
                                color: '#52FF33',
                                name: "CURRENT 2",
                                // showInLegend: true, 
                                tooltip:{
                                    valueSuffix: ' KW',
                                }
                            },
                            {
                                data: this.chartBidirectional[2],
                                color: '#56411A',
                                name: "CURRENT 3",
                                // showInLegend: true, 
                                tooltip:{
                                    valueSuffix: ' KW',
                                }
                            }                        
                        ],
                        categories: this.arrayDataFechaGps,
                        legend: {
                            layout: 'proximate',
                            align: 'right',
                            verticalAlign: 'top'
                        },                        
                    };
                }else if (itemSelected == "ENERGIES"){

                    var para = {
                        idHighchart: idChart,
                        title: "ENERGIES "+category.toUpperCase(),
                        labelX: "KW",   
                        textColor :textColor, 
                        height: 300,                      
                        series:[
                            {
                                data: this.chartEnergyBdnal[0],
                                color: '#CB0000',
                                name: "CURRENT 1",
                                tooltip:{
                                    valueSuffix: ' KW',
                                }
                            }                      
                        ],
                        categories: this.arrayDataFechaGps,
                        legend: {
                            layout: 'proximate',
                            align: 'right',
                            verticalAlign: 'top',
                        },
                    };
                }else{                   
                    var para = {
                        idHighchart: idChart,
                        title: "VOLTAGES",
                        labelX: "KW", 
                        textColor :textColor,   
                        height: 300,                         
                        series:[
                            {
                                data: this.chartVoltage[0],
                                color: '#3364FF',
                                name: "VOLTAGE 1",
                                tooltip:{
                                    valueSuffix: ' KW',
                                }
                            },
                            {
                                data: this.chartVoltage[1],
                                color: '#52FF33',
                                name: "VOLTAGE 2",
                                tooltip:{
                                    valueSuffix: ' KW',
                                }
                            },
                            {
                                data: this.chartVoltage[2],
                                color: '#FFB533',
                                name: "VOLTAGE 3",
                                tooltip:{
                                    valueSuffix: ' KW',
                                }
                            }                            
                        ],
                        categories: this.arrayDataFechaGps,
                        legend: {
                            layout: 'proximate',
                            align: 'right',
                            verticalAlign: 'top',
                        },
                    };
                }
            }
            this.makeHighchart(para);
            this.loadingFlagHistorico = false
            this.loadingFlag = false;
            this.loadingFlagChartReport = false;
            //this.loadingFlagTableSensorRequest = false;
        },
        generateDetailGraph(idChart, dataVoltage, dataCurrent,arrName, arrFechas){
            var para = {
                idHighchart: idChart,
                title: arrName.name1+"/"+arrName.name2,
                labelX: "",
                series:[
                    {
                        data: dataVoltage,
                        color: 'blue',
                        name: arrName.name1
                    },
                    {
                        data: dataCurrent,
                        color: 'red',
                        name: arrName.name2
                    },
                ],
                categories: arrFechas
            };
    
            this.makeHighchartDC(para);
            this.loadingFlagHistorico = false
            this.loadingFlag = false;
            this.loadingFlagChartReport = false;
    
        },


    },
    


}

export default GraphicDeviceMixin;