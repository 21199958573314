<template>
    <div class="grey lighten-4 height-100" style="padding-bottom: 5%;">
        <Header :seeEnergyFlag="seeEnergyFlag" :devices="selected"/>

        <v-row class="mt-0 pt-4 mx-0">
            <v-col lg="1" sm="12" cols="12" class="py-0">
                <v-row class="flex-row justify-end my-1">
                    <v-col lg="12" sm="12">
                        <v-card class="" :loading="loadingFlagSelect">
                            <v-card-text class="text-center px-3 py-2">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            class="my-1"
                                            @click="open_navigation()"
                                        >
                                            <v-icon
                                                v-bind="attrs"
                                                v-on="on"
                                                class=""
                                                size="23"
                                                :style="{ color: '#2196f3' }"
                                                >mdi-format-list-bulleted-square
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>User Sensors</span>
                                </v-tooltip>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-col lg="6" sm="12" cols="12" class="py-0">
                <v-row class="flex-row justify-end">
                    <v-col lg="12" sm="12">
                        <v-autocomplete
                            :search-input="searchWord"
                            outlined
                            :items="devices"
                            v-model="selected.imei"
                            item-text="name"
                            item-value="imei"
                            label="Devices"
                            class="pt-4"
                            @change="assignSelected($event)"
                            :loading="loadingFlagSelect"
                        >
                            <template slot="item" slot-scope="data">
                                <span
                                    class="mr-2"
                                    v-if="data.item.idSensor == 47"
                                    ><font-awesome-icon
                                        icon="tachometer-alt"
                                        size="1x"
                                        :style="{ color: 'black' }"
                                    ></font-awesome-icon
                                ></span>
                                <span
                                    class="mr-2"
                                    v-if="data.item.idSensor == 41"
                                    ><font-awesome-icon
                                        icon="tint"
                                        size="1x"
                                        :style="{ color: 'blue' }"
                                    ></font-awesome-icon
                                ></span>
                                <span
                                    class="mr-2"
                                    v-else-if="data.item.idSensor == 37"
                                    ><font-awesome-icon
                                        icon="charging-station"
                                        size="1x"
                                        :style="{ color: 'purple' }"
                                    ></font-awesome-icon
                                ></span>
                                <span
                                    class="mr-2"
                                    v-else-if="data.item.idSensor == 36"
                                    ><font-awesome-icon
                                        icon="eye-dropper"
                                        size="1x"
                                        :style="{ color: 'orange' }"
                                    ></font-awesome-icon
                                ></span>
                                <span
                                    class="mr-2"
                                    v-else-if="data.item.idSensor == 32"
                                    ><font-awesome-icon
                                        icon="cloud-sun-rain"
                                        size="1x"
                                        :style="{ color: '#90cdfd' }"
                                    ></font-awesome-icon
                                ></span>
                                <span
                                    class="mr-2"
                                    v-else-if="data.item.idSensor == 31"
                                    ><font-awesome-icon
                                        icon="temperature-high"
                                        size="1x"
                                        :style="{ color: 'blue' }"
                                    ></font-awesome-icon
                                ></span>
                                <span
                                    class="mr-2"
                                    v-else-if="data.item.idSensor == 30"
                                    ><font-awesome-icon
                                        icon="fire-alt"
                                        size="1x"
                                        :style="{ color: 'orange' }"
                                    ></font-awesome-icon
                                ></span>
                                <span
                                    class="mr-2"
                                    v-else-if="data.item.idSensor == 29"
                                    ><font-awesome-icon
                                        icon="temperature-low"
                                        size="1x"
                                        :style="{ color: 'pink' }"
                                    ></font-awesome-icon
                                ></span>
                                <span
                                    class="mr-2"
                                    v-else-if="data.item.idSensor == 11"
                                    ><font-awesome-icon
                                        icon="mobile"
                                        size="1x"
                                        :style="{ color: '#339af0' }"
                                    ></font-awesome-icon
                                ></span>
                                <span
                                    class="mr-2"
                                    v-else-if="data.item.idSensor == 8"
                                    ><font-awesome-icon
                                        icon="toggle-on"
                                        size="1x"
                                        :style="{ color: 'red' }"
                                    ></font-awesome-icon
                                ></span>
                                <span
                                    class="mr-2"
                                    v-else-if="data.item.idSensor == 7"
                                    ><font-awesome-icon
                                        icon="gas-pump"
                                        size="1x"
                                        :style="{ color: 'green' }"
                                    ></font-awesome-icon
                                ></span>
                                <span
                                    class="mr-2"
                                    v-else-if="data.item.idSensor == 3"
                                    ><font-awesome-icon
                                        icon="wind"
                                        size="1x"
                                        :style="{ color: '#03b3ca' }"
                                    ></font-awesome-icon
                                ></span>
                                <span
                                    class="mr-2"
                                    v-else-if="data.item.idSensor == 2"
                                    ><font-awesome-icon
                                        icon="volume-up"
                                        size="1x"
                                        :style="{ color: '#27c24c' }"
                                    ></font-awesome-icon
                                ></span>
                                <span
                                    class="mr-2"
                                    v-else-if="data.item.idSensor == 1"
                                    ><font-awesome-icon
                                        icon="bolt"
                                        size="1x"
                                        :style="{ color: '#ffb900' }"
                                    ></font-awesome-icon
                                ></span>
                                <span>{{ data.item.name }}</span>
                            </template>
                            <template slot="selection" slot-scope="data">
                                <span
                                    class="mr-2"
                                    v-if="data.item.idSensor == 41"
                                    ><font-awesome-icon
                                        icon="tint"
                                        size="1x"
                                        :style="{ color: 'blue' }"
                                    ></font-awesome-icon
                                ></span>
                                <span
                                    class="mr-2"
                                    v-else-if="data.item.idSensor == 37"
                                    ><font-awesome-icon
                                        icon="charging-station"
                                        size="1x"
                                        :style="{ color: 'purple' }"
                                    ></font-awesome-icon
                                ></span>
                                <span
                                    class="mr-2"
                                    v-else-if="data.item.idSensor == 36"
                                    ><font-awesome-icon
                                        icon="eye-dropper"
                                        size="1x"
                                        :style="{ color: 'orange' }"
                                    ></font-awesome-icon
                                ></span>
                                <span
                                    class="mr-2"
                                    v-else-if="data.item.idSensor == 32"
                                    ><font-awesome-icon
                                        icon="cloud-sun-rain"
                                        size="1x"
                                        :style="{ color: '#90cdfd' }"
                                    ></font-awesome-icon
                                ></span>
                                <span
                                    class="mr-2"
                                    v-else-if="data.item.idSensor == 31"
                                    ><font-awesome-icon
                                        icon="temperature-high"
                                        size="1x"
                                        :style="{ color: 'blue' }"
                                    ></font-awesome-icon
                                ></span>
                                <span
                                    class="mr-2"
                                    v-else-if="data.item.idSensor == 30"
                                    ><font-awesome-icon
                                        icon="fire-alt"
                                        size="1x"
                                        :style="{ color: 'orange' }"
                                    ></font-awesome-icon
                                ></span>
                                <span
                                    class="mr-2"
                                    v-else-if="data.item.idSensor == 29"
                                    ><font-awesome-icon
                                        icon="temperature-low"
                                        size="1x"
                                        :style="{ color: 'pink' }"
                                    ></font-awesome-icon
                                ></span>
                                <span
                                    class="mr-2"
                                    v-else-if="data.item.idSensor == 11"
                                    ><font-awesome-icon
                                        icon="mobile"
                                        size="1x"
                                        :style="{ color: '#339af0' }"
                                    ></font-awesome-icon
                                ></span>
                                <span
                                    class="mr-2"
                                    v-else-if="data.item.idSensor == 8"
                                    ><font-awesome-icon
                                        icon="toggle-on"
                                        size="1x"
                                        :style="{ color: 'red' }"
                                    ></font-awesome-icon
                                ></span>
                                <span
                                    class="mr-2"
                                    v-else-if="data.item.idSensor == 7"
                                    ><font-awesome-icon
                                        icon="gas-pump"
                                        size="1x"
                                        :style="{ color: 'green' }"
                                    ></font-awesome-icon
                                ></span>
                                <span
                                    class="mr-2"
                                    v-else-if="data.item.idSensor == 3"
                                    ><font-awesome-icon
                                        icon="wind"
                                        size="1x"
                                        :style="{ color: '#03b3ca' }"
                                    ></font-awesome-icon
                                ></span>
                                <span
                                    class="mr-2"
                                    v-else-if="data.item.idSensor == 2"
                                    ><font-awesome-icon
                                        icon="volume-up"
                                        size="1x"
                                        :style="{ color: '#27c24c' }"
                                    ></font-awesome-icon
                                ></span>
                                <span
                                    class="mr-2"
                                    v-else-if="data.item.idSensor == 1"
                                    ><font-awesome-icon
                                        icon="bolt"
                                        size="1x"
                                        :style="{ color: '#ffb900' }"
                                    ></font-awesome-icon
                                ></span>
                                <span>{{ data.item.name }}</span>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>
            </v-col>
            <v-col lg="5" sm="12" cols="12" class="py-0">
                <v-row class="flex-row justify-end my-0">
                    <v-col lg="12" sm="12">
                        <v-card :loading="loadingFlagSelect">
                            <v-card-text class="text-center">
                                <v-icon class="mx-5" size="30"
                                    >mdi-account</v-icon
                                >
                                <strong class="mx-5 mt-1">USER: </strong>
                                <span class="mt-1 pr-3">{{ user_global }}</span>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-badge
                                            color="red"
                                            :dot="true"
                                            :value="campanaFlag"
                                            overlap
                                        >
                                            <v-icon
                                                color="blue"
                                                @click="dibujar()"
                                                v-bind="attrs"
                                                v-on="on"
                                                class="mx-3"
                                                >mdi-bell-outline</v-icon
                                            >
                                        </v-badge>
                                    </template>
                                    <span>Alerts</span>
                                </v-tooltip>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row class="px-0 mx-0 mb-4">
            <v-col lg="12" sm="12">
                <Energy
                    v-if="
                        selected.deviceSelected != null &&
                            selected.deviceSelected.idSensor == 1
                    "
                    idHighchart="energy-highchart"
                    :dateRangeReportSelected="dateRangeReportSelected"
                    :device="selected.deviceSelected"
                    :totalReceived="totalReceivedData"
                    :energyConfig="energyConfig"
                    :energyData="energyData"
                    :energyAlertConfig="energyAlertConfig"
                    :dataDeviceAlerts="dataDeviceAlerts"
                    :colorText="colorText"
                    :loadingFlag="loadingFlag"
                    :loadingFlagHistorico="loadingFlagHistorico"
                    :loadingFlagFormAlerts="loadingFlagFormAlerts"
                    :loadingFlagFormConfig="loadingFlagFormConfig"
                    :loadingFlagTableAlerts="loadingFlagTableAlerts"
                    :timezones_list="timezones_list"
                    :idTimeZone="selected.idTimeZone"
                    :posicion="posicion"
                    :rules="rules"
                    :modalActualizarConfiguracion="modalActualizarConfiguracion"
                    :modalActualizarAlerts="modalActualizarAlerts"
                    :switchFlag="switchFlag"
                    @cambioSwitch="cambioSwitch($event)"
                    @consultaTemporal="consultaTemporal()"
                    @assignTimeZone="assignTimeZone($event)"
                    @updateConfiguration="updateConfiguration()"
                    @updateAlertConfiguration="updateAlertConfiguration()"
                    @makeHighchart="makeHighchart($event)"
                    @getMapa="getMapa($event)"
                    @getRango="getRango($event)"                   
                    @getDeviceAlerts="getDeviceAlerts($event)"
                />
                <Sonometer
                    v-if="
                        selected.deviceSelected != null &&
                            selected.deviceSelected.idSensor == 2
                    "
                    idHighchart="sonometer-highchart"
                    :dateRangeReportSelected="dateRangeReportSelected"
                    :device="selected.deviceSelected"
                    :totalReceived="totalReceivedData"
                    :sonometerLevel="sonometerLevel"
                    :sonometerAlertConfig="sonometerAlertConfig"
                    :dataDeviceAlerts="dataDeviceAlerts"
                    :decibeles="sonometerDecibeles"
                    :colorText="colorText"
                    :loadingFlag="loadingFlag"
                    :loadingFlagHistorico="loadingFlagHistorico"
                    :loadingFlagFormAlerts="loadingFlagFormAlerts"
                    :loadingFlagFormConfig="loadingFlagFormConfig"
                    :loadingFlagTableAlerts="loadingFlagTableAlerts"
                    :timezones_list="timezones_list"
                    :idTimeZone="selected.idTimeZone"
                    :posicion="posicion"
                    :rules="rules"
                    :modalActualizarConfiguracion="modalActualizarConfiguracion"
                    :modalActualizarAlerts="modalActualizarAlerts"
                    :switchFlag="switchFlag"
                    @cambioSwitch="cambioSwitch($event)"
                    @consultaTemporal="consultaTemporal()"
                    @assignTimeZone="assignTimeZone($event)"
                    @updateConfiguration="updateConfiguration()"
                    @updateAlertConfiguration="updateAlertConfiguration()"
                    @makeHighchart="makeHighchart($event)"
                    @getRango="getRango($event)"
                    @getMapa="getMapa($event)"
                    @getDeviceAlerts="getDeviceAlerts($event)"
                />
                <AirQuality
                    v-if="
                        selected.deviceSelected != null &&
                            selected.deviceSelected.idSensor == 3
                    "
                    idHighchart="airquality-highchart"
                    :dateRangeReportSelected="dateRangeReportSelected"
                    :device="selected.deviceSelected"
                    :totalReceived="totalReceivedData"
                    :airQualityConfig="airQualityConfig"
                    :airQualityData="airQualityData"
                    :airQualityAlertConfig="airQualityAlertConfig"
                    :dataDeviceAlerts="dataDeviceAlerts"
                    :colorText="colorText"
                    :loadingFlag="loadingFlag"
                    :loadingFlagHistorico="loadingFlagHistorico"
                    :loadingFlagFormAlerts="loadingFlagFormAlerts"
                    :loadingFlagFormConfig="loadingFlagFormConfig"
                    :loadingFlagTableAlerts="loadingFlagTableAlerts"
                    :timezones_list="timezones_list"
                    :idTimeZone="selected.idTimeZone"
                    :posicion="posicion"
                    :rules="rules"
                    :modalActualizarConfiguracion="modalActualizarConfiguracion"
                    :modalActualizarAlerts="modalActualizarAlerts"
                    :switchFlag="switchFlag"
                    @cambioSwitch="cambioSwitch($event)"
                    @consultaTemporal="consultaTemporal()"
                    @assignTimeZone="assignTimeZone($event)"
                    @updateConfiguration="updateConfiguration()"
                    @updateAlertConfiguration="updateAlertConfiguration()"
                    @makeHighchart="makeHighchart($event)"
                    @getRango="getRango($event)"
                    @getMapa="getMapa($event)"
                    @getDeviceAlerts="getDeviceAlerts($event)"
                />
                <GasLevel
                    v-if="
                        selected.deviceSelected != null &&
                            selected.deviceSelected.idSensor == 7
                    "
                    idHighchart="gaslevel-highchart"
                    :dateRangeReportSelected="dateRangeReportSelected"
                    :device="selected.deviceSelected"
                    :totalReceived="totalReceivedData"
                    :gasLevel="gasLevel"
                    :gasLevelAlertConfig="gasLevelAlertConfig"
                    :gLevel="gLevel"
                    :dataDeviceAlerts="dataDeviceAlerts"
                    :colorText="colorText"
                    :loadingFlag="loadingFlag"
                    :loadingFlagHistorico="loadingFlagHistorico"
                    :loadingFlagFormAlerts="loadingFlagFormAlerts"
                    :loadingFlagFormConfig="loadingFlagFormConfig"
                    :loadingFlagTableAlerts="loadingFlagTableAlerts"
                    :timezones_list="timezones_list"
                    :idTimeZone="selected.idTimeZone"
                    :posicion="posicion"
                    :rules="rules"
                    :modalActualizarConfiguracion="modalActualizarConfiguracion"
                    :modalActualizarAlerts="modalActualizarAlerts"
                    :switchFlag="switchFlag"
                    @cambioSwitch="cambioSwitch($event)"
                    @consultaTemporal="consultaTemporal()"
                    @assignTimeZone="assignTimeZone($event)"
                    @updateConfiguration="updateConfiguration()"
                    @updateAlertConfiguration="updateAlertConfiguration()"
                    @makeHighchart="makeHighchart($event)"
                    @getRango="getRango($event)"
                    @getMapa="getMapa($event)"
                    @getDeviceAlerts="getDeviceAlerts($event)"
                />
                <Events
                    v-if="
                        selected.deviceSelected != null &&
                            selected.deviceSelected.idSensor == 8
                    "
                    idHighchart="events-highchart"
                    :dateRangeReportSelected="dateRangeReportSelected"
                    :device="selected.deviceSelected"
                    :totalReceived="totalReceivedData"
                    :eventsData="eventsData"
                    :eventsAlertsConfig="eventsAlertsConfig"
                    :dataDeviceAlerts="dataDeviceAlerts"
                    :colorText="colorText"
                    :loadingFlag="loadingFlag"
                    :loadingFlagHistorico="loadingFlagHistorico"
                    :loadingFlagFormAlerts="loadingFlagFormAlerts"
                    :loadingFlagFormConfig="loadingFlagFormConfig"
                    :loadingFlagTableAlerts="loadingFlagTableAlerts"
                    :timezones_list="timezones_list"
                    :idTimeZone="selected.idTimeZone"
                    :posicion="posicion"
                    :rules="rules"
                    :modalActualizarConfiguracion="modalActualizarConfiguracion"
                    :modalActualizarAlerts="modalActualizarAlerts"
                    :switchFlag="switchFlag"
                    @cambioSwitch="cambioSwitch($event)"
                    @consultaTemporal="consultaTemporal()"
                    @assignTimeZone="assignTimeZone($event)"
                    @updateConfiguration="updateConfiguration()"
                    @updateAlertConfiguration="updateAlertConfiguration()"
                    @makeHighchart="makeHighchart($event)"
                    @getRango="getRango($event)"
                    @getMapa="getMapa($event)"
                    @getDeviceAlerts="getDeviceAlerts($event)"
                />
                <TouchTag
                    v-if="
                        selected.deviceSelected != null &&
                            selected.deviceSelected.idSensor == 11
                    "
                    idHighchart="touch-tag-highchart"
                    :dateRangeReportSelected="dateRangeReportSelected"
                    :device="selected.deviceSelected"
                    :totalReceived="totalReceivedData"
                    :touchTagData="touchTagData"
                    :touchAlertConfig="touchAlertConfig"
                    :dataDeviceAlerts="dataDeviceAlerts"
                    :colorText="colorText"
                    :loadingFlag="loadingFlag"
                    :loadingFlagHistorico="loadingFlagHistorico"
                    :loadingFlagFormAlerts="loadingFlagFormAlerts"
                    :loadingFlagFormConfig="loadingFlagFormConfig"
                    :loadingFlagTableAlerts="loadingFlagTableAlerts"
                    :timezones_list="timezones_list"
                    :idTimeZone="selected.idTimeZone"
                    :posicion="posicion"
                    :rules="rules"
                    :modalActualizarConfiguracion="modalActualizarConfiguracion"
                    :modalActualizarAlerts="modalActualizarAlerts"
                    :switchFlag="switchFlag"
                    @cambioSwitch="cambioSwitch($event)"
                    @consultaTemporal="consultaTemporal()"
                    @assignTimeZone="assignTimeZone($event)"
                    @updateConfiguration="updateConfiguration()"
                    @updateAlertConfiguration="updateAlertConfiguration()"
                    @makeHighchart="makeHighchart($event)"
                    @getMapa="getMapa($event)"
                    @getRango="getRango($event)"
                    @getDeviceAlerts="getDeviceAlerts($event)"
                />
                <HumedadTierra
                    v-if="
                        selected.deviceSelected != null &&
                            selected.deviceSelected.idSensor == 29
                    "
                    idHighchart="temperature-tierra-highchart"
                    :dateRangeReportSelected="dateRangeReportSelected"
                    :device="selected.deviceSelected"
                    :totalReceived="totalReceivedData"
                    :temperatureAndHumidityData="temperatureAndHumidityData"
                    :temperatureAndHumidityAlertConfig="
                        temperatureAndHumidityAlertConfig
                    "
                    :dataDeviceAlerts="dataDeviceAlerts"
                    :colorText="colorText"
                    :loadingFlag="loadingFlag"
                    :loadingFlagHistorico="loadingFlagHistorico"
                    :loadingFlagFormAlerts="loadingFlagFormAlerts"
                    :loadingFlagFormConfig="loadingFlagFormConfig"
                    :loadingFlagTableAlerts="loadingFlagTableAlerts"
                    :timezones_list="timezones_list"
                    :idTimeZone="selected.idTimeZone"
                    :posicion="posicion"
                    :rules="rules"
                    :modalActualizarConfiguracion="modalActualizarConfiguracion"
                    :modalActualizarAlerts="modalActualizarAlerts"
                    :switchFlag="switchFlag"
                    @cambioSwitch="cambioSwitch($event)"
                    @consultaTemporal="consultaTemporal()"
                    @assignTimeZone="assignTimeZone($event)"
                    @updateConfiguration="updateConfiguration()"
                    @updateAlertConfiguration="updateAlertConfiguration()"
                    @makeHighchart="makeHighchart($event)"
                    @getMapa="getMapa($event)"
                    @getRango="getRango($event)"
                    @getDeviceAlerts="getDeviceAlerts($event)"
                />
                <Piranometro
                    v-if="
                        selected.deviceSelected != null &&
                            selected.deviceSelected.idSensor == 30
                    "
                    idHighchart="piranometer-highchart"
                    :dateRangeReportSelected="dateRangeReportSelected"
                    :device="selected.deviceSelected"
                    :totalReceived="totalReceivedData"
                    :piranometerData="piranometerData"
                    :piranometerAlertConfig="piranometerAlertConfig"
                    :dataDeviceAlerts="dataDeviceAlerts"
                    :colorText="colorText"
                    :loadingFlag="loadingFlag"
                    :loadingFlagHistorico="loadingFlagHistorico"
                    :loadingFlagFormAlerts="loadingFlagFormAlerts"
                    :loadingFlagFormConfig="loadingFlagFormConfig"
                    :loadingFlagTableAlerts="loadingFlagTableAlerts"
                    :timezones_list="timezones_list"
                    :idTimeZone="selected.idTimeZone"
                    :posicion="posicion"
                    :rules="rules"
                    :modalActualizarConfiguracion="modalActualizarConfiguracion"
                    :modalActualizarAlerts="modalActualizarAlerts"
                    :switchFlag="switchFlag"
                    @cambioSwitch="cambioSwitch($event)"
                    @consultaTemporal="consultaTemporal()"
                    @assignTimeZone="assignTimeZone($event)"
                    @updateConfiguration="updateConfiguration()"
                    @updateAlertConfiguration="updateAlertConfiguration()"
                    @makeHighchart="makeHighchart($event)"
                    @getRango="getRango($event)"
                    @getMapa="getMapa($event)"
                    @getDeviceAlerts="getDeviceAlerts($event)"
                />

                <TemperatureHumidity
                    v-if="
                        selected.deviceSelected != null &&
                            selected.deviceSelected.idSensor == 31
                    "
                    idHighchart="temperature-humidity-highchart"
                    :variablesGlobales="variablesGlobales"
                    :dateRangeReportSelected="dateRangeReportSelected"
                    :device="selected.deviceSelected"
                    :totalReceived="totalReceivedData"
                    :temperatureAndHumidityData="temperatureAndHumidityData"
                    :temperatureAndHumidityAlertConfig="
                        temperatureAndHumidityAlertConfig
                    "
                    :dataDeviceAlerts="dataDeviceAlerts"
                    :colorText="colorText"
                    :arrayDataTemperature="arrayDataTemperature"
                    :arrayDataHumidity="arrayDataHumidity"
                    :arrayTempHum="arrayTempHum"
                    :typeConsulta="typeConsulta"
                    :loadingFlag="loadingFlag"
                    :loadingFlagHistorico="loadingFlagHistorico"
                    :loadingFlagFormAlerts="loadingFlagFormAlerts"
                    :loadingFlagFormConfig="loadingFlagFormConfig"
                    :loadingFlagTableAlerts="loadingFlagTableAlerts"
                    :timezones_list="timezones_list"
                    :idTimeZone="selected.idTimeZone"
                    :posicion="posicion"
                    :modalActualizarConfiguracion="modalActualizarConfiguracion"
                    :modalActualizarAlerts="modalActualizarAlerts"
                    :switchFlag="switchFlag"
                    @cambioSwitch="cambioSwitch($event)"
                    @consultaTemporal="consultaTemporal()"
                    @assignTimeZone="assignTimeZone($event)"
                    @updateConfiguration="updateConfiguration()"
                    @updateAlertConfiguration="updateAlertConfiguration()"
                    @makeHighchart="makeHighchart($event)"
                    @getMapa="getMapa($event)"
                    @getRango="getRango($event)"
                    @getDeviceAlerts="getDeviceAlerts($event)"
                    @pararIntervalo="pararIntervalo($event)"
                />
                <EstacionMeteorologica
                    v-if="
                        selected.deviceSelected != null &&
                            selected.deviceSelected.idSensor == 32
                    "
                    idHighchart="estacion-meteorologica-highchart"
                    :dateRangeReportSelected="dateRangeReportSelected"
                    :device="selected.deviceSelected"
                    :totalReceived="totalReceivedData"
                    :ecConfig="ecConfig"
                    :estacionMeteorologicaData="estacionMeteorologicaData"
                    :estacionMeteorologicaAlertConfig="
                        estacionMeteorologicaAlertConfig
                    "
                    :dataDeviceAlerts="dataDeviceAlerts"
                    :colorText="colorText"
                    :loadingFlag="loadingFlag"
                    :loadingFlagHistorico="loadingFlagHistorico"
                    :loadingFlagFormAlerts="loadingFlagFormAlerts"
                    :loadingFlagFormConfig="loadingFlagFormConfig"
                    :loadingFlagTableAlerts="loadingFlagTableAlerts"
                    :timezones_list="timezones_list"
                    :idTimeZone="selected.idTimeZone"
                    :posicion="posicion"
                    :rules="rules"
                    :modalActualizarConfiguracion="modalActualizarConfiguracion"
                    :modalActualizarAlerts="modalActualizarAlerts"
                    :switchFlag="switchFlag"
                    @cambioSwitch="cambioSwitch($event)"
                    @consultaTemporal="consultaTemporal()"
                    @assignTimeZone="assignTimeZone($event)"
                    @updateConfiguration="updateConfiguration()"
                    @updateAlertConfiguration="updateAlertConfiguration()"
                    @makeHighchart="makeHighchart($event)"
                    @getMapa="getMapa($event)"
                    @getRango="getRango($event)"
                    @getDeviceAlerts="getDeviceAlerts($event)"
                />
                <Ph
                    v-if="
                        selected.deviceSelected != null &&
                            selected.deviceSelected.idSensor == 36
                    "
                    idHighchart="ph-highchart"
                    :dateRangeReportSelected="dateRangeReportSelected"
                    :device="selected.deviceSelected"
                    :totalReceived="totalReceivedData"
                    :phConfig="phConfig"
                    :phAlertConfig="phAlertConfig"
                    :phData="phData"
                    :dataDeviceAlerts="dataDeviceAlerts"
                    :colorText="colorText"
                    :loadingFlag="loadingFlag"
                    :loadingFlagHistorico="loadingFlagHistorico"
                    :loadingFlagFormAlerts="loadingFlagFormAlerts"
                    :loadingFlagFormConfig="loadingFlagFormConfig"
                    :loadingFlagTableAlerts="loadingFlagTableAlerts"
                    :timezones_list="timezones_list"
                    :idTimeZone="selected.idTimeZone"
                    :posicion="posicion"
                    :rules="rules"
                    :modalActualizarConfiguracion="modalActualizarConfiguracion"
                    :modalActualizarAlerts="modalActualizarAlerts"
                    :switchFlag="switchFlag"
                    @cambioSwitch="cambioSwitch($event)"
                    @consultaTemporal="consultaTemporal()"
                    @assignTimeZone="assignTimeZone($event)"
                    @updateConfiguration="updateConfiguration()"
                    @updateAlertConfiguration="updateAlertConfiguration()"
                    @makeHighchart="makeHighchart($event)"
                    @getMapa="getMapa($event)"
                    @getRango="getRango($event)"
                    @getDeviceAlerts="getDeviceAlerts($event)"
                />
                <Ec
                    v-if="
                        selected.deviceSelected != null &&
                            selected.deviceSelected.idSensor == 37
                    "
                    idHighchart="ec-highchart"
                    :dateRangeReportSelected="dateRangeReportSelected"
                    :device="selected.deviceSelected"
                    :totalReceived="totalReceivedData"
                    :ecConfig="ecConfig"
                    :ecData="ecData"
                    :ecAlertConfig="ecAlertConfig"
                    :dataDeviceAlerts="dataDeviceAlerts"
                    :colorText="colorText"
                    :loadingFlag="loadingFlag"
                    :loadingFlagHistorico="loadingFlagHistorico"
                    :loadingFlagFormAlerts="loadingFlagFormAlerts"
                    :loadingFlagFormConfig="loadingFlagFormConfig"
                    :loadingFlagTableAlerts="loadingFlagTableAlerts"
                    :timezones_list="timezones_list"
                    :idTimeZone="selected.idTimeZone"
                    :posicion="posicion"
                    :rules="rules"
                    :modalActualizarConfiguracion="modalActualizarConfiguracion"
                    :modalActualizarAlerts="modalActualizarAlerts"
                    :switchFlag="switchFlag"
                    @cambioSwitch="cambioSwitch($event)"
                    @consultaTemporal="consultaTemporal()"
                    @assignTimeZone="assignTimeZone($event)"
                    @updateConfiguration="updateConfiguration()"
                    @updateAlertConfiguration="updateAlertConfiguration()"
                    @makeHighchart="makeHighchart($event)"
                    @getMapa="getMapa($event)"
                    @getRango="getRango($event)"
                    @getDeviceAlerts="getDeviceAlerts($event)"
                />
                <FlowWater
                    v-if="
                        selected.deviceSelected != null &&
                            selected.deviceSelected.idSensor == 41
                    "
                    idHighchart="flowwater-highchart"
                    :dateRangeReportSelected="dateRangeReportSelected"
                    :device="selected.deviceSelected"
                    :totalReceived="totalReceivedData"
                    :flowWaterData="flowWaterData"
                    :flowWaterAlertConfig="flowWaterAlertConfig"
                    :flowAlertConfigTemporal="flowAlertConfigTemporal"
                    :dataDeviceAlerts="dataDeviceAlerts"
                    :colorText="colorText"
                    :loadingFlag="loadingFlag"
                    :loadingFlagHistorico="loadingFlagHistorico"
                    :loadingFlagTableFlow="loadingFlagTableFlow"
                    :loadingFlagTableMonthlyFlow="loadingFlagTableMonthlyFlow"
                    :loadingFlagFormAlerts="loadingFlagFormAlerts"
                    :loadingFlagFormConfig="loadingFlagFormConfig"
                    :loadingFlagTableAlerts="loadingFlagTableAlerts"
                    :timezones_list="timezones_list"
                    :idTimeZone="selected.idTimeZone"
                    :posicion="posicion"
                    :rules="rules"
                    :modalActualizarConfiguracion="modalActualizarConfiguracion"
                    :modalActualizarAlerts="modalActualizarAlerts"
                    :dataHistoricLiters="dataHistoricLiters"
                    :dataMonthlyLiters="dataMonthlyLiters"
                    :switchFlag="switchFlag"
                    @cambioSwitch="cambioSwitch($event)"
                    @consultaTemporal="consultaTemporal()"
                    @assignTimeZone="assignTimeZone($event)"
                    @updateConfiguration="updateConfiguration()"
                    @makeHighchart="makeHighchart($event)"
                    @getMapa="getMapa($event)"
                    @getRango="getRango($event)"
                    @storeHistoricLiters="storeHistoricLiters($event)"
                    @getHistoricalLiters="getHistoricalLiters($event)"
                    @getTotalLiters="getTotalLiters($event)"
                    @getMonthlyDataLiters="getMonthlyDataLiters($event)"
                    @updateAlertConfiguration="updateAlertConfiguration()"
                    @getDeviceAlerts="getDeviceAlerts($event)"
                />

                <DcMax 
                    v-if="
                        selected.deviceSelected != null &&
                            selected.deviceSelected.idSensor == 47
                    "
                    idHighchart="dcmax-highchart"
                    idHighchart_2="dcmax-highchart_2"
                    :dateRangeReportSelected="dateRangeReportSelected"
                    :device="selected.deviceSelected"
                    :DcMaxData="DcMaxData"
                    :energyAlertConfig="energyAlertConfig"
                    :dataDeviceAlerts="dataDeviceAlerts"
                    :colorText="colorText"
                    :loadingFlag="loadingFlag"
                    :loadingFlagHistorico="loadingFlagHistorico"
                    :loadingFlagFormAlerts="loadingFlagFormAlerts"
                    :loadingFlagFormConfig="loadingFlagFormConfig"
                    :loadingFlagTableAlerts="loadingFlagTableAlerts"
                    :timezones_list="timezones_list"
                    :idTimeZone="selected.idTimeZone"
                    :posicion="posicion"
                    :rules="rules"
                    :modalActualizarConfiguracion="modalActualizarConfiguracion"
                    :modalActualizarAlerts="modalActualizarAlerts"
                    :switchFlag="switchFlag"
                    @cambioSwitch="cambioSwitch($event)"
                    @consultaTemporal="consultaTemporal()"
                    @assignTimeZone="assignTimeZone($event)"
                    @updateConfiguration="updateConfiguration()"
                    @updateAlertConfiguration="updateAlertConfiguration()"
                    @makeHighchart="makeHighchart($event)"
                    @getMapa="getMapa($event)"
                    @getRango="getRango($event)"
                    @getRangoDCMax="getRangoDCMax($event)"
                    @getDeviceAlerts="getDeviceAlerts($event)"
                    @openAlertModal="openAlertModal"
                />
            </v-col>
            <Bidireccional 
                v-if=" selected.deviceSelected != null && selected.deviceSelected.idSensor == 48"
                idHighchart="bidirectional-highchart"
                :dateRangeReportSelected="dateRangeReportSelected"
                :device="selected.deviceSelected"
                :Bidireccional="Bidireccional"
                :posicion="posicion"
                @getMapa="getMapa($event)"
                @makeHighchart="makeHighchart($event)"
                @makeHighchart2="makeHighchart($event)"
                @getRangoDCMax="getRangoDCMax($event)"
                :rules="rules"
                :switchFlag="switchFlag"
                @cambioSwitch="cambioSwitch($event)"
                :colorText="colorText"
                :loadingFlag="loadingFlag"
                :loadingFlagHistorico="loadingFlagHistorico"
                :loadingFlagFormAlerts="loadingFlagFormAlerts"
                :loadingFlagFormConfig="loadingFlagFormConfig"
                :loadingFlagTableAlerts="loadingFlagTableAlerts"   
            ></Bidireccional>
        </v-row>

        <v-navigation-drawer
            style="position:fixed; top:0; left:0; overflow-y:scroll;"
            v-model="drawer"
            temporary
            class="blue lighten-5"
            width="500"
        >
            <v-list>
                <loading
                    :active="loadingFlagSelect"
                    :is-full-page="false"
                    :color="'#03c9d9'"
                />
                <v-list-item>
                    <v-list-item-avatar width="70">
                        <v-img src="@/assets/logo_blue.png"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>User Sensors</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                    <!--<v-icon color="blue" class="pt-1 mr-6" @click="openHelp()">mdi-help</v-icon>
                    <v-dialog v-model="dialogHelp" transition="dialog-bottom-transition">
                        <template v-slot:default="dialog">
                            <v-card>
                                <v-toolbar color="primary" dark><span class="ml-2">Help</span><v-btn :plain="true" class="ml-auto" @click="closeHelp()"><font-awesome-icon icon="arrow-left" size="2x" :style="{ color: 'white'}"/><span class="mx-2">Regresar a Plataforma Iot</span></v-btn></v-toolbar>
                            </v-card>
                        </template>
                    </v-dialog>-->
                        <v-icon
                            color="blue"
                            class="pt-1"
                            @click="close_navigation()"
                        >
                            mdi-close-thick
                        </v-icon>
                    </v-list-item-icon>
                </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-expansion-panels focusable>
                <v-expansion-panel
                    v-for="(item, i) in arregloSensoresDiferentes"
                    :key="i"
                >
                    <v-expansion-panel-header>
                        <div>
                            <font-awesome-icon
                                v-if="item.idSensor == 1"
                                class="mr-2"
                                icon="bolt"
                                size="1x"
                                :style="{ color: '#ffb900' }"
                            ></font-awesome-icon>
                            <font-awesome-icon
                                v-else-if="item.idSensor == 2"
                                class="mr-2"
                                icon="volume-up"
                                size="1x"
                                :style="{ color: '#27c24c' }"
                            ></font-awesome-icon>
                            <font-awesome-icon
                                v-else-if="item.idSensor == 3"
                                class="mr-2"
                                icon="wind"
                                size="1x"
                                :style="{ color: '#03b3ca' }"
                            ></font-awesome-icon>
                            <font-awesome-icon
                                v-else-if="item.idSensor == 7"
                                class="mr-2"
                                icon="gas-pump"
                                size="1x"
                                :style="{ color: 'green' }"
                            ></font-awesome-icon>
                            <font-awesome-icon
                                v-else-if="item.idSensor == 8"
                                class="mr-2"
                                icon="toggle-on"
                                size="1x"
                                :style="{ color: 'red' }"
                            ></font-awesome-icon>
                            <font-awesome-icon
                                v-else-if="item.idSensor == 11"
                                class="mr-2"
                                icon="mobile"
                                size="1x"
                                :style="{ color: '#339af0' }"
                            ></font-awesome-icon>
                            <font-awesome-icon
                                v-else-if="item.idSensor == 29"
                                class="mr-2"
                                icon="temperature-low"
                                size="1x"
                                :style="{ color: 'pink' }"
                            ></font-awesome-icon>
                            <font-awesome-icon
                                v-else-if="item.idSensor == 30"
                                class="mr-2"
                                icon="fire-alt"
                                size="1x"
                                :style="{ color: 'orange' }"
                            ></font-awesome-icon>
                            <font-awesome-icon
                                v-else-if="item.idSensor == 31"
                                class="mr-2"
                                icon="temperature-high"
                                size="1x"
                                :style="{ color: 'blue' }"
                            ></font-awesome-icon>
                            <font-awesome-icon
                                v-else-if="item.idSensor == 32"
                                class="mr-2"
                                icon="cloud-sun-rain"
                                size="1x"
                                :style="{ color: '#90cdfd' }"
                            ></font-awesome-icon>
                            <font-awesome-icon
                                v-else-if="item.idSensor == 36"
                                class="mr-2"
                                icon="eye-dropper"
                                size="1x"
                                :style="{ color: 'orange' }"
                            ></font-awesome-icon>
                            <font-awesome-icon
                                v-else-if="item.idSensor == 37"
                                class="mr-2"
                                icon="charging-station"
                                size="1x"
                                :style="{ color: 'purple' }"
                            ></font-awesome-icon>
                            <font-awesome-icon
                                v-else-if="item.idSensor == 41"
                                class="mr-2"
                                icon="tint"
                                size="1x"
                                :style="{ color: 'blue' }"
                            ></font-awesome-icon>
                            <font-awesome-icon
                                v-else-if="item.idSensor == 47"
                                class="mr-2"
                                icon="tachometer-alt"
                                size="1x"
                                :style="{ color: 'black' }"
                            ></font-awesome-icon>
                            <span>{{ item.nombreTipo }}</span>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-simple-table v-if="item.idSensor == 1" class="my-2">
                            <template>
                                <tbody>
                                    <tr
                                        style="background-color: transparent !important;"
                                    >
                                        <th style="width:5%;">Status</th>
                                        <th style="width:35%;">Device</th>
                                        <th style="width:60%;" colspan="2">
                                            Time / Date (Last Report)
                                        </th>
                                    </tr>
                                    <tr
                                        class="v-slider__thumb"
                                        v-for="(equipo,
                                        index) in arregloSensoresEnergy"
                                        :key="index"
                                    >
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            <v-icon
                                                class="mx-2"
                                                size="25px"
                                                :color="
                                                    getDifferenceTimeIconColor(
                                                        equipo.fechaGps
                                                    )
                                                "
                                                >{{
                                                    getDifferenceTimeIcon(
                                                        equipo.fechaGps
                                                    )
                                                }}</v-icon
                                            >
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            <strong>{{ equipo.name }}</strong>
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            {{
                                                getDifferenceTime(
                                                    equipo.fechaGps
                                                )
                                            }}
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            {{ formatFecha(equipo.fechaGps) }}
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-simple-table v-if="item.idSensor == 2" class="my-2">
                            <template>
                                <tbody>
                                    <tr
                                        style="background-color: transparent !important;"
                                    >
                                        <th style="width:5%;">Status</th>
                                        <th style="width:35%;">Device</th>
                                        <th style="width:60%;" colspan="2">
                                            Time / Date (Last Report)
                                        </th>
                                    </tr>
                                    <tr
                                        class="v-slider__thumb"
                                        v-for="(equipo,
                                        index) in arregloSensoresSonometer"
                                        :key="index"
                                    >
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            <v-icon
                                                class="mx-2"
                                                size="25px"
                                                :color="
                                                    getDifferenceTimeIconColor(
                                                        equipo.fechaGps
                                                    )
                                                "
                                                >{{
                                                    getDifferenceTimeIcon(
                                                        equipo.fechaGps
                                                    )
                                                }}</v-icon
                                            >
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            <strong>{{ equipo.name }}</strong>
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            {{
                                                getDifferenceTime(
                                                    equipo.fechaGps
                                                )
                                            }}
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            {{ formatFecha(equipo.fechaGps) }}
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-simple-table v-if="item.idSensor == 3" class="my-2">
                            <template>
                                <tbody>
                                    <tr
                                        style="background-color: transparent !important;"
                                    >
                                        <th style="width:5%;">Status</th>
                                        <th style="width:35%;">Device</th>
                                        <th style="width:60%;" colspan="2">
                                            Time / Date (Last Report)
                                        </th>
                                    </tr>
                                    <tr
                                        class="v-slider__thumb"
                                        v-for="(equipo,
                                        index) in arregloSensoresAirQuality"
                                        :key="index"
                                    >
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            <v-icon
                                                class="mx-2"
                                                size="25px"
                                                :color="
                                                    getDifferenceTimeIconColor(
                                                        equipo.fechaGps
                                                    )
                                                "
                                                >{{
                                                    getDifferenceTimeIcon(
                                                        equipo.fechaGps
                                                    )
                                                }}</v-icon
                                            >
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            <strong>{{ equipo.name }}</strong>
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            {{
                                                getDifferenceTime(
                                                    equipo.fechaGps
                                                )
                                            }}
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            {{ formatFecha(equipo.fechaGps) }}
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-simple-table v-if="item.idSensor == 7" class="my-2">
                            <template>
                                <tbody>
                                    <tr
                                        style="background-color: transparent !important;"
                                    >
                                        <th style="width:5%;">Status</th>
                                        <th style="width:35%;">Device</th>
                                        <th style="width:60%;" colspan="2">
                                            Time / Date (Last Report)
                                        </th>
                                    </tr>
                                    <tr
                                        class="v-slider__thumb"
                                        v-for="(equipo,
                                        index) in arregloSensoresGasLevel"
                                        :key="index"
                                    >
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            <v-icon
                                                class="mx-2"
                                                size="25px"
                                                :color="
                                                    getDifferenceTimeIconColor(
                                                        equipo.fechaGps
                                                    )
                                                "
                                                >{{
                                                    getDifferenceTimeIcon(
                                                        equipo.fechaGps
                                                    )
                                                }}</v-icon
                                            >
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            <strong>{{ equipo.name }}</strong>
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            {{
                                                getDifferenceTime(
                                                    equipo.fechaGps
                                                )
                                            }}
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            {{ formatFecha(equipo.fechaGps) }}
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-simple-table v-if="item.idSensor == 8" class="my-2">
                            <template>
                                <tbody>
                                    <tr
                                        style="background-color: transparent !important;"
                                    >
                                        <th style="width:5%;">Status</th>
                                        <th style="width:35%;">Device</th>
                                        <th style="width:60%;" colspan="2">
                                            Time / Date (Last Report)
                                        </th>
                                    </tr>
                                    <tr
                                        class="v-slider__thumb"
                                        v-for="(equipo,
                                        index) in arregloSensoresEvents"
                                        :key="index"
                                    >
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            <v-icon
                                                class="mx-2"
                                                size="25px"
                                                :color="
                                                    getDifferenceTimeIconColor(
                                                        equipo.fechaGps
                                                    )
                                                "
                                                >{{
                                                    getDifferenceTimeIcon(
                                                        equipo.fechaGps
                                                    )
                                                }}</v-icon
                                            >
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            <strong>{{ equipo.name }}</strong>
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            {{
                                                getDifferenceTime(
                                                    equipo.fechaGps
                                                )
                                            }}
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            {{ formatFecha(equipo.fechaGps) }}
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-simple-table v-if="item.idSensor == 11" class="my-2">
                            <template>
                                <tbody>
                                    <tr
                                        style="background-color: transparent !important;"
                                    >
                                        <th style="width:5%;">Status</th>
                                        <th style="width:35%;">Device</th>
                                        <th style="width:60%;" colspan="2">
                                            Time / Date (Last Report)
                                        </th>
                                    </tr>
                                    <tr
                                        class="v-slider__thumb"
                                        v-for="(equipo,
                                        index) in arregloSensoresTouchTag"
                                        :key="index"
                                    >
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            <v-icon
                                                class="mx-2"
                                                size="25px"
                                                :color="
                                                    getDifferenceTimeIconColor(
                                                        equipo.fechaGps
                                                    )
                                                "
                                                >{{
                                                    getDifferenceTimeIcon(
                                                        equipo.fechaGps
                                                    )
                                                }}</v-icon
                                            >
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            <strong>{{ equipo.name }}</strong>
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            {{
                                                getDifferenceTime(
                                                    equipo.fechaGps
                                                )
                                            }}
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            {{ formatFecha(equipo.fechaGps) }}
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-simple-table v-if="item.idSensor == 29" class="my-2">
                            <template>
                                <tbody>
                                    <tr
                                        style="background-color: transparent !important;"
                                    >
                                        <th style="width:5%;">Status</th>
                                        <th style="width:35%;">Device</th>
                                        <th style="width:60%;" colspan="2">
                                            Time / Date (Last Report)
                                        </th>
                                    </tr>
                                    <tr
                                        class="v-slider__thumb"
                                        v-for="(equipo,
                                        index) in arregloSensoresHumedadTierra"
                                        :key="index"
                                    >
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            <v-icon
                                                class="mx-2"
                                                size="25px"
                                                :color="
                                                    getDifferenceTimeIconColor(
                                                        equipo.fechaGps
                                                    )
                                                "
                                                >{{
                                                    getDifferenceTimeIcon(
                                                        equipo.fechaGps
                                                    )
                                                }}</v-icon
                                            >
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            <strong>{{ equipo.name }}</strong>
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            {{
                                                getDifferenceTime(
                                                    equipo.fechaGps
                                                )
                                            }}
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            {{ formatFecha(equipo.fechaGps) }}
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-simple-table v-if="item.idSensor == 30" class="my-2">
                            <template>
                                <tbody>
                                    <tr
                                        style="background-color: transparent !important;"
                                    >
                                        <th style="width:5%;">Status</th>
                                        <th style="width:35%;">Device</th>
                                        <th style="width:60%;" colspan="2">
                                            Time / Date (Last Report)
                                        </th>
                                    </tr>
                                    <tr
                                        class="v-slider__thumb"
                                        v-for="(equipo,
                                        index) in arregloSensoresPiranometer"
                                        :key="index"
                                    >
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            <v-icon
                                                class="mx-2"
                                                size="25px"
                                                :color="
                                                    getDifferenceTimeIconColor(
                                                        equipo.fechaGps
                                                    )
                                                "
                                                >{{
                                                    getDifferenceTimeIcon(
                                                        equipo.fechaGps
                                                    )
                                                }}</v-icon
                                            >
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            <strong>{{ equipo.name }}</strong>
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            {{
                                                getDifferenceTime(
                                                    equipo.fechaGps
                                                )
                                            }}
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            {{ formatFecha(equipo.fechaGps) }}
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-simple-table v-if="item.idSensor == 31" class="my-2">
                            <template>
                                <tbody>
                                    <tr
                                        style="background-color: transparent !important;"
                                    >
                                        <th style="width:5%;">Status</th>
                                        <th style="width:35%;">Device</th>
                                        <th style="width:60%;" colspan="2">
                                            Time / Date (Last Report)
                                        </th>
                                    </tr>
                                    <tr
                                        class="v-slider__thumb"
                                        v-for="(equipo,
                                        index) in arregloSensoresTemperatureH"
                                        :key="index"
                                    >
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            <v-icon
                                                class="mx-2"
                                                size="25px"
                                                :color="
                                                    getDifferenceTimeIconColor(
                                                        equipo.fechaGps
                                                    )
                                                "
                                                >{{
                                                    getDifferenceTimeIcon(
                                                        equipo.fechaGps
                                                    )
                                                }}</v-icon
                                            >
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            <strong>{{ equipo.name }}</strong>
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            {{
                                                getDifferenceTime(
                                                    equipo.fechaGps
                                                )
                                            }}
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            {{ formatFecha(equipo.fechaGps) }}
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-simple-table v-if="item.idSensor == 32" class="my-2">
                            <template>
                                <tbody>
                                    <tr
                                        style="background-color: transparent !important;"
                                    >
                                        <th style="width:5%;">Status</th>
                                        <th style="width:35%;">Device</th>
                                        <th style="width:60%;" colspan="2">
                                            Time / Date (Last Report)
                                        </th>
                                    </tr>
                                    <tr
                                        class="v-slider__thumb"
                                        v-for="(equipo,
                                        index) in arregloSensoresEstacionM"
                                        :key="index"
                                    >
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            <v-icon
                                                class="mx-2"
                                                size="25px"
                                                :color="
                                                    getDifferenceTimeIconColor(
                                                        equipo.fechaGps
                                                    )
                                                "
                                                >{{
                                                    getDifferenceTimeIcon(
                                                        equipo.fechaGps
                                                    )
                                                }}</v-icon
                                            >
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            <strong>{{ equipo.name }}</strong>
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            {{
                                                getDifferenceTime(
                                                    equipo.fechaGps
                                                )
                                            }}
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            {{ formatFecha(equipo.fechaGps) }}
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-simple-table v-if="item.idSensor == 36" class="my-2">
                            <template>
                                <tbody>
                                    <tr
                                        style="background-color: transparent !important;"
                                    >
                                        <th style="width:5%;">Status</th>
                                        <th style="width:35%;">Device</th>
                                        <th style="width:60%;" colspan="2">
                                            Time / Date (Last Report)
                                        </th>
                                    </tr>
                                    <tr
                                        class="v-slider__thumb"
                                        v-for="(equipo,
                                        index) in arregloSensoresPH"
                                        :key="index"
                                    >
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            <v-icon
                                                class="mx-2"
                                                size="25px"
                                                :color="
                                                    getDifferenceTimeIconColor(
                                                        equipo.fechaGps
                                                    )
                                                "
                                                >{{
                                                    getDifferenceTimeIcon(
                                                        equipo.fechaGps
                                                    )
                                                }}</v-icon
                                            >
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            <strong>{{ equipo.name }}</strong>
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            {{
                                                getDifferenceTime(
                                                    equipo.fechaGps
                                                )
                                            }}
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            {{ formatFecha(equipo.fechaGps) }}
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-simple-table v-if="item.idSensor == 37" class="my-2">
                            <template>
                                <tbody>
                                    <tr
                                        style="background-color: transparent !important;"
                                    >
                                        <th style="width:5%;">Status</th>
                                        <th style="width:35%;">Device</th>
                                        <th style="width:60%;" colspan="2">
                                            Time / Date (Last Report)
                                        </th>
                                    </tr>
                                    <tr
                                        class="v-slider__thumb"
                                        v-for="(equipo,
                                        index) in arregloSensoresEC"
                                        :key="index"
                                    >
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            <v-icon
                                                class="mx-2"
                                                size="25px"
                                                :color="
                                                    getDifferenceTimeIconColor(
                                                        equipo.fechaGps
                                                    )
                                                "
                                                >{{
                                                    getDifferenceTimeIcon(
                                                        equipo.fechaGps
                                                    )
                                                }}</v-icon
                                            >
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            <strong>{{ equipo.name }}</strong>
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            {{
                                                getDifferenceTime(
                                                    equipo.fechaGps
                                                )
                                            }}
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            {{ formatFecha(equipo.fechaGps) }}
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-simple-table v-if="item.idSensor == 41" class="my-2">
                            <template>
                                <tbody>
                                    <tr
                                        style="background-color: transparent !important;"
                                    >
                                        <th style="width:5%;">Status</th>
                                        <th style="width:35%;">Device</th>
                                        <th style="width:60%;" colspan="2">
                                            Time / Date (Last Report)
                                        </th>
                                    </tr>
                                    <tr
                                        class="v-slider__thumb"
                                        v-for="(equipo,
                                        index) in arregloSensoresFlowWater"
                                        :key="index"
                                    >
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            <v-icon
                                                class="mx-2"
                                                size="25px"
                                                :color="
                                                    getDifferenceTimeIconColor(
                                                        equipo.fechaGps
                                                    )
                                                "
                                                >{{
                                                    getDifferenceTimeIcon(
                                                        equipo.fechaGps
                                                    )
                                                }}</v-icon
                                            >
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            <strong>{{ equipo.name }}</strong>
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            {{
                                                getDifferenceTime(
                                                    equipo.fechaGps
                                                )
                                            }}
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            {{ formatFecha(equipo.fechaGps) }}
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-simple-table v-if="item.idSensor == 47" class="my-2">
                            <template>
                                <tbody>
                                    <tr
                                        style="background-color: transparent !important;"
                                    >
                                        <th style="width:5%;">Status</th>
                                        <th style="width:35%;">Device</th>
                                        <th style="width:60%;" colspan="2">
                                            Time / Date (Last Report)
                                        </th>
                                    </tr>
                                    <tr
                                        class="v-slider__thumb"
                                        v-for="(equipo,
                                        index) in arregloSensoresDcMax1500"
                                        :key="index"
                                    >
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            <v-icon
                                                class="mx-2"
                                                size="25px"
                                                :color="
                                                    getDifferenceTimeIconColor(
                                                        equipo.fechaGps
                                                    )
                                                "
                                                >{{
                                                    getDifferenceTimeIcon(
                                                        equipo.fechaGps
                                                    )
                                                }}</v-icon
                                            >
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            <strong>{{ equipo.name }}</strong>
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            {{
                                                getDifferenceTime(
                                                    equipo.fechaGps
                                                )
                                            }}
                                        </td>
                                        <td
                                            @click="assignSelectedPanel(equipo)"
                                        >
                                            {{ formatFecha(equipo.fechaGps) }}
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-divider></v-divider>
        </v-navigation-drawer>
        <v-footer
            style="position: absolute; bottom: 0; width: 100%;"
            padless
            color="#2196f3"
            class="mt-6"
        >
            <v-col class="text-center" cols="12">
                <span class="white--text"
                    >Max4 Technologies {{ new Date().getFullYear() }} — Version
                    1.4</span
                >
            </v-col>
        </v-footer>
        <AlertModalComponent 
            :modalAlerts="modalAlerts"
            @openAlertForm="openAlertForm"
        />
    </div>
</template>

<script>
import moment from "moment";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

// Components
import Header from "@/components/Header";
import AlertModalComponent from "@/components/Alerts/AlertModalComponent";

//Devices
import Energy from "@/components/Devices/Energy";
import FlowWater from "@/components/Devices/FlowWater";
import Sonometer from "@/components/Devices/Sonometer";
import AirQuality from "@/components/Devices/AirQuality";
import GasLevel from "@/components/Devices/GasLevel";
import Events from "@/components/Devices/Events";
import TouchTag from "@/components/Devices/TouchTag";
import HumedadTierra from "@/components/Devices/HumedadTierra";
import Piranometro from "@/components/Devices/Piranometro";
import TemperatureHumidity from "@/components/Devices/TemperatureHumidity";
import Ph from "@/components/Devices/Ph";
import Ec from "@/components/Devices/Ec";
import EstacionMeteorologica from "@/components/Devices/EstacionMeteorologica";
import DcMax from "@/components/Devices/DcMax";
import Bidireccional from "@/components/Devices/Bidireccional.vue";

// Mixins
import DeviceMixin from "@/mixins/DeviceMixin";
import DataDeviceMixin from "@/mixins/DataDeviceMixin";
import HelperMixin from "@/mixins/HelperMixin";
import AlertsMixin from "@/mixins/AlertsMixin";

export default {
    components: {
        Header,
        Energy,
        Sonometer,
        AirQuality,
        GasLevel,
        Events,
        TouchTag,
        HumedadTierra,
        Piranometro,
        TemperatureHumidity,
        Ph,
        Ec,
        EstacionMeteorologica,
        FlowWater,
        DcMax,
        Loading,
        AlertModalComponent,
        Bidireccional,
    },
    mixins: [DataDeviceMixin,DeviceMixin, HelperMixin, AlertsMixin],

    data() {
        return {
            fechaURL:[],
        };
    },
    created() {},
    mounted() {

        this.executeMixinFunction();

        if (
            localStorage.idUser == null ||
            localStorage.idUser == "" ||
            localStorage.getItem("idUser") === null
        ) {
            this.$router.push({ path: "/" });
        } else {
            if (localStorage.getItem("sessionTime") === null) {
                this.$router.push({ path: "/" });
            } else {
                var fechaSesion = localStorage.getItem("sessionTime");
                let time = moment().diff(fechaSesion, "minutes");
                if (time > 360) {
                    this.$router.push({ path: "/" });
                } else if (time <= 360) {
                }
            }
        }

        this.sum_notifications();
        this.getDateURL();
    },
    methods: {
        getDateURL(){
            this.fechaURL=this.$route.params.date.split('+');
            localStorage.setItem('Rango', this.fechaURL)
        },
        formatFecha(fecha) {
            return moment(fecha)
                .locale("es")
                .format("DD MMMM YYYY HH:mm:ss");
        },

        getDifferenceTime(fechaGps) {
            let timeDays = moment().diff(fechaGps, "days");
            let timeHours = moment().diff(fechaGps, "hours");
            let timeMinutes = moment().diff(fechaGps, "minutes");

            if (timeMinutes > 0 && timeHours == 0 && timeDays == 0) {
                var cadena = timeMinutes + " Minutes";
                return cadena;
            } else if (timeMinutes > 0 && timeHours > 0 && timeDays == 0) {
                var cadena = timeHours + " Hours";
                return cadena;
            } else if (timeMinutes > 0 && timeHours > 0 && timeDays > 0) {
                var cadena = timeDays + " Days";
                return cadena;
            } else if (timeMinutes == 0 && timeHours == 0 && timeDays == 0) {
                var cadena = "0 Minutes";
                return cadena;
            }
        },

        getDifferenceTimeIcon(fechaGps) {
            let timeDays = moment().diff(fechaGps, "days");
            let timeHours = moment().diff(fechaGps, "hours");
            let timeMinutes = moment().diff(fechaGps, "minutes");

            if (timeMinutes > 0 && timeHours == 0 && timeDays == 0) {
                var cadena = "mdi-checkbox-marked-circle";
                return cadena;
            } else if (timeMinutes > 0 && timeHours > 0 && timeDays == 0) {
                var cadena = "mdi-alert";
                return cadena;
            } else if (timeMinutes > 0 && timeHours > 0 && timeDays > 0) {
                var cadena = "mdi-close-circle";
                return cadena;
            } else if (timeMinutes == 0 && timeHours == 0 && timeDays == 0) {
                var cadena = "mdi-checkbox-marked-circle";
                return cadena;
            }
        },

        getDifferenceTimeIconColor(fechaGps) {
            let timeDays = moment().diff(fechaGps, "days");
            let timeHours = moment().diff(fechaGps, "hours");
            let timeMinutes = moment().diff(fechaGps, "minutes");

            if (timeMinutes > 0 && timeHours == 0 && timeDays == 0) {
                var cadena = "green";
                return cadena;
            } else if (timeMinutes > 0 && timeHours > 0 && timeDays == 0) {
                var cadena = "yellow";
                return cadena;
            } else if (timeMinutes > 0 && timeHours > 0 && timeDays > 0) {
                var cadena = "red";
                return cadena;
            } else if (timeMinutes == 0 && timeHours == 0 && timeDays == 0) {
                var cadena = "green";
                return cadena;
            }
        },

        testRedisign() {
            alert("DEVICE SHOW");
        },

        goToEnergy() {
            var urlv = "https://max4iot.com/platform/energy/";
            window.open(urlv, "_blank");
        },
        dibujar() {
            localStorage.bellNotification = "";
            this.campanaFlag = "";
            this.$router.push({ path: "/alerts" });
            //this.drawer = true;
        },

        open_navigation() {
            this.drawer = true;
        },

        openHelp() {
            this.dialogHelp = true;
        },

        closeHelp() {
            this.dialogHelp = false;
        },

        close_navigation() {
            this.drawer = false;
        },

        delete_notifications() {
            this.itemsNavigationStatic.splice(0);
        },

        sum_notifications() {
            var contador = 0;
            this.itemsNavigation.forEach((element) => {
                contador++;
            });
            this.notifications_number = contador;
        },

        goToGuide() {
            alert("GUIA");
        },
    },

};
</script>

<style>
.height-100 {
    height: 100% !important;
    overflow-x: hidden;
}
.showBorders {
    border: 1px solid rgba(0, 0, 0, 0.12);
}

.showBorders2 {
    border: none;
}

.no-hover-action:hover {
    background-color: white !important;
}
.width-100 {
    width: 100% !important;
}
.map {
    width: 100%;
    height: 20em;
    background-color: #eee;
}
.hightchart {
    width: 100%;
}

.v-slider__thumb {
    cursor: pointer;
    height: 42px;
    width: 42px;
}

.bodyAlerts {
    margin-bottom: 7%;
}

.footerFondo {
    position: absolute;
    bottom: 0;
    width: 100%;
}
</style>