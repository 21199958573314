<template>
    <v-app-bar :elevate-on-scroll="true" class="custom-navbar" :dark="true">
        <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
        <!--<v-toolbar-title>Max4 IoT</v-toolbar-title>
        <v-spacer></v-spacer>-->

        <v-tabs fixed-tabs>
            <!--<v-tab @click="goToDashboard()">Dashboard</v-tab>
            <v-tab @click="goToExecutiveReports()">Executive Reports</v-tab>
            <v-tab @click="goToDocs()">Docs</v-tab>-->
            <v-tab disabled><span class="text-h6"><v-img src="@/assets/logo_white.png" max-height="90" max-width="100"></v-img></span></v-tab>
            <v-tab @click="goToDashboard()">Dashboard<v-icon class="px-2">mdi-desktop-mac-dashboard</v-icon></v-tab>
            <!-- Dashboard anterior:
            <v-tab to="/dashboard">Dashboard <v-icon class="px-2">mdi-desktop-mac-dashboard</v-icon></v-tab> -->
            <!-- <v-tab @click="goToDashboard()">Dashboard Con id <v-icon class="px-2">mdi-desktop-mac-dashboard</v-icon></v-tab> -->
            <v-tab to="/executiveReports">Executive Reports <v-icon class="px-1">mdi-chart-areaspline</v-icon></v-tab>
            <v-tab to="/alerts">Alerts<v-icon class="px-1">mdi-bell</v-icon></v-tab>
            <v-tab v-if="seeEnergyFlag == 1" @click="openDialogEnergy()" v-bind="attrs" v-on="on">
                <v-dialog v-model="dialogEnergy" fullscreen  transition="dialog-bottom-transition">
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" @click="openDialogEnergy()">See Energy</span><v-icon class="px-1">mdi-flash</v-icon>
                    </template>
                    <template v-slot:default="dialog">
                        <v-card height="1000">
                            <v-toolbar color="primary" dark><span class="ml-2">ENERGY</span><v-btn :plain="true" class="ml-auto" @click="cerrarDialogEnergy()"><font-awesome-icon icon="arrow-left" size="2x" :style="{ color: 'white'}"/><span class="mx-2">IoT Platform</span></v-btn></v-toolbar>
                            <iframe id="frameEnergy" :src="urlEnergy" width="100%" height="100%"></iframe>
                        </v-card>
                    </template> 
                </v-dialog>
            </v-tab>
            <!--<v-tab v-if="seeEnergyFlag == 1" @click="goToEnergy()">See Energy<v-icon class="px-1">mdi-flash</v-icon></v-tab>-->
            <v-tab @click="goToDocs()">Docs <v-icon class="px-1">mdi-file-document</v-icon></v-tab>
            <v-tab @click="goToLogin()">Exit<v-icon class="px-1">mdi-logout</v-icon></v-tab>
        </v-tabs>
        <!--<v-btn icon :dark="true">
            <v-icon>mdi-logout</v-icon>
        </v-btn>-->
    </v-app-bar>
</template>
<script>
import Swal from 'sweetalert2'
import DeviceMixin from '@/mixins/DeviceMixin'
import { mapGetters,mapActions } from 'vuex';

import DataDeviceMixin from '@/mixins/DataDeviceMixin'

export default {
    mixins: [
        DeviceMixin,
        DataDeviceMixin
    ],
    props:{
        seeEnergyFlag: Number,
        devices:JSON
    },
    data(){
        return{
            urlEnergy: '',
            dialogEnergy: false,
            publicPath: process.env.BASE_URL,
            banderaSeeEnergy: 0
        }
    },

    created(){

    },

    mounted(){
        this.urlEnergy = 'https://max4iot.com/platform/energy/#/login?user=' + localStorage.user_p + '&contra=' + localStorage.pw_p;  
        // console.log('Prop enviado');
        // console.log(this.devices);      
        // console.log(this.devices.deviceSelected);      
        // console.log(this.devices.deviceSelected.idSensor);      
        // console.log(this.devices.deviceSelected.name);      
    },
    methods:{
        ...mapActions(['signOut']),

        cerrarDialogEnergy(){
            this.dialogEnergy = false;
        },

        openDialogEnergy(){
            this.dialogEnergy = true;
        },

        cleanCampana(){
            this.campanaFlag = ''
            localStorage.platNotification = ''
        },

        goToDashboard(){
            let fechaURL='';
            let fFromVariable='';
            let fToVariable='';
            let actualDateRange = [this.restDays(this.getActualDate(),0),this.getActualDate()];
            let imeiB64 = window.btoa(this.devices.deviceSelected.imei);
            
            if(this.devices.fechaFrom==undefined){
                fechaURL=localStorage.getItem('Rango') || 0;
                if(fechaURL==0){
                    fFromVariable=actualDateRange[0];
                    fToVariable=actualDateRange[1];
                }else{
                    fechaURL=fechaURL.split(',');
                    fFromVariable=fechaURL[0];
                    fToVariable=fechaURL[1];
                }
            }else if(this.devices.fechaFrom!=undefined){
                fFromVariable=this.devices.fechaFrom;
                fToVariable=this.devices.fechaTo;
            }
            // this.$router.push({path: `/otraCosa/${this.devices.fechaFrom}+${this.devices.fechaTo}/${this.devices.deviceSelected.name}`});
            // let imeiToEncrypt = this.devices.deviceSelected.imei

            this.$router.push({path: `/dashboard/${fFromVariable}+${fToVariable}/${imeiB64}`});
            // this.$router.push({path: `/otraCosa/date=${this.devices.fechaFrom}+${this.devices.fechaTo}/${this.devices.deviceSelected.name}`});
            // this.$router.push({path: `/otraCosa/PyroPruebas`});
        },

        goToExecutiveReports(){
            this.$router.push({path: '/executiveReports'});
        },

        goToHistoricalReports(){
            this.$router.push({path: '/historicalReports'});
        },

        goToDocs(){
            var urlv = 'catalog.pdf'
            window.open(urlv,'_blank');
        },

        goToLogin(){
            localStorage.bellNotification = ''
            this.campanaFlag = '';
            Swal.fire({
                title: 'Exit?',
                icon: 'question',
                iconHtml: '',
                showDenyButton: true,
                confirmButtonText: `Yes`,
                denyButtonText: `No`,
                confirmButtonColor: '#28a745',
                denyButtonColor: '#dc3545'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.signOut().then(() => {
                        //this.$session.destroy();
                        Swal.fire({
                            title: 'Closing Session...',
                            icon: 'warning',
                            timer: 1500,
                            showConfirmButton: false,
                            showCancelButton: false
                        }).then((result)=>{
                            this.$router.push({path: '/'});
                        });
                        Swal.showLoading();
                        //this.$router.push({path: '/'});
                    })
                } else if (result.isDenied) {
                    
                }
            })
        },

        goToEnergy(){
            var urlv = 'https://max4iot.com/platform/energy/'
            window.open(urlv,'_blank');
        },
    }
}
</script>
<style scoped>
.custom-navbar{
    background: linear-gradient(90deg, rgba(0,155,224,1) 0%, rgba(76,0,255,1) 100%) !important;
}
</style>