<template>
    <div class="px-1 py-0 mt-0" style="width: 100%;">
        <v-row  >
            <v-col lg="4" sm="12">
                <v-card>                    
                    <loading :active="loadingFlag" :is-full-page="false" :color="'#03c9d9'"/>
                    <v-card-title>Sensor Data</v-card-title>
                    <v-card-text>
                        <v-simple-table>
                            <template>
                                <tbody>
                                    <tr class="no-hover-action">
                                        <th>Device name:</th>
                                        <td>{{device.name}}</td>
                                    </tr>
                                    <tr class="no-hover-action">
                                        <th>Last update:</th>
                                        <td>{{showLastReport(device.fechaGps)}}</td>
                                    </tr>
                                    <tr class="no-hover-action">
                                        <th>Status (Since Last Report):</th>
                                        <td v-if="colorText == 'color:green'"><font-awesome-icon icon="check-circle" size="1x" :style="{ color: 'green' }"></font-awesome-icon><span v-if="totalReceived == 0" class="text--secondary"> (No Data Reported) </span></td>
                                        <td v-if="colorText == 'color:red'"><font-awesome-icon icon="times-circle" size="1x" :style="{ color: 'red' }"></font-awesome-icon></td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                </v-card>

                <v-card>
                    <loading :active="loadingFlag" :is-full-page="false" :color="'#03c9d9'"/>
                    <v-card-title>Map</v-card-title>
                    <v-card-text>                        
                        <v-row class="my-0 pa-3">
                            <div class="mx-auto" id="myMap" style='position:relative;width:100%;height:300px;' v-if="banderaMapa == 1">
                            </div>
                            <div v-else>
                                <span>No Location</span>
                            </div>
                        </v-row>
                    </v-card-text>
                </v-card>

                <v-card>
                    <v-card-title>Configuration and Today Alerts</v-card-title>
                    <v-row class="mr-1">
                        <v-col lg="4" sm="12">
                            <v-card class="ml-3" style="background: #f05050">
                                <div class="pl-3 py-4">
                                    <font-awesome-icon icon="bell" size="2x" :style="{ color: 'white' }"></font-awesome-icon>
                                    <v-icon class="mr-3" size="20" :style="{ color: 'white' }">mdi-cog</v-icon>
                                </div>
                                <div class="pl-2 py-2 blue-grey darken-4">                                    

                                    <v-dialog v-model="modalAlertConfig" transition="dialog-bottom-transition" max-width="600">
                                        <template v-slot:activator="{ on, attrs }">
                                            <span class="white--text mr-2"><a href="" @click.prevent="openAlertForm()" style="color:inherit" v-bind="attrs" v-on="on">Alerts</a></span>
                                        </template>
                                        <template v-slot:default="dialog">
                                            <v-card>                                               
                                                <v-toolbar color="primary" dark><span class="ml-2">Alerts Configuration</span><v-btn :plain="true" class="ml-auto" @click="cerrarAlerts()"><font-awesome-icon icon="times" size="2x" :style="{ color: 'white'}"/></v-btn></v-toolbar>                                                
                                                <Alerts :rules="rules" :device="device"></Alerts>                                                
                                            </v-card>
                                        </template> 
                                    </v-dialog>                                    
                                   
                                    <span><font-awesome-icon icon="chevron-circle-right" size="1x" :style="{ color: 'white' }"></font-awesome-icon></span>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col lg="4" sm="12">
                            <v-card class="ml-1" style="background: #10a510">
                                <div class="pl-3 py-4">
                                    <v-icon class="mr-3" size="35" :style="{ color: 'white' }">mdi-sticker-alert</v-icon>
                                </div>
                                <div class="pl-2 py-2 blue-grey darken-4">
                                    <v-dialog v-model="modalSeeAlerts" persistent transition="dialog-bottom-transition" max-width="600">
                                        <template v-slot:activator="{ on, attrs }">
                                            <span class="white--text mr-2"><a href="" @click.prevent="consultarDeviceAlerts()" style="color:inherit" v-bind="attrs" v-on="on">See Alerts</a></span>
                                        </template>
                                        <template v-slot:default="dialog">
                                            <v-card>
                                                <v-toolbar color="primary" dark><span class="ml-2">Device Alerts</span><v-btn :plain="true" class="ml-auto" @click="cerrarTableAlerts()"><font-awesome-icon icon="times" size="2x" :style="{ color: 'white'}"/></v-btn></v-toolbar>
                                                <v-card-text>
                                                    <v-row>
                                                        <div class="pa-5">
                                                            <template>
                                                                <v-text-field v-model="searchAlerts" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
                                                                <v-data-table dense calculate-widths :items-per-page="5" :headers="headerDeviceAlerts" :items="dataDeviceAlerts" item-key="name" :search="searchAlerts" class="elevation-1" :loading="loadingFlagTableAlerts" loading-text="Loading... Please wait"></v-data-table>
                                                            </template>
                                                        </div>
                                                    </v-row>
                                                </v-card-text>
                                                <v-card-actions class="justify-end">
                                                <v-btn text @click="cerrarTableAlerts()">Cancel</v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </template> 
                                    </v-dialog>
                                    <span><font-awesome-icon icon="chevron-circle-right" size="1x" :style="{ color: 'white' }"></font-awesome-icon></span>
                                </div>
                            </v-card>
                        </v-col>                        
                    </v-row>
                </v-card>
            </v-col>

            <v-col lg="8" sm="12">
                <v-row> 
                    <v-col>
                    
                        <v-card>
                            <v-simple-table >
                                <template>
                                    <tbody>
                                        <loading :active="loadingFlag" :is-full-page="false" :color="'#03c9d9'"/>
                                        <tr class="no-hover-action">
                                            <td class="showBorders pa-10">
                                                <v-row class="d-flex justify-center">
                                                    <div class="pr-10">
                                                        <font-awesome-icon icon="lightbulb" size="3x" :style="{ color: '#03c9d9' }"/>
                                                    </div>
                                                    
                                                    
                                                    <div v-if="Bidireccional.powerNow != null">
                                                        <span><strong class="text-h7 font-weight-black">POWER NOW</strong></span>
                                                        <br>
                                                        <span class="font-weight-light text-subtitle-1">{{Bidireccional.powerNow}} kW</span>
                                                    </div>
                                                    <div v-else>
                                                        <span><strong class="text-h7 font-weight-black">POWER NOW</strong></span>
                                                        <br>
                                                        <span class="font-weight-light text-subtitle-1">0</span>
                                                    </div>
                                                </v-row>
                                            </td>
                                            <td class="showBorders pa-10">
                                                <v-row class="d-flex justify-center">
                                                    <div class="pr-10">
                                                        <font-awesome-icon icon="clock" size="3x" :style="{ color: '#03c9d9' }"/>
                                                    </div>
                                                    <div>
                                                        <h4>TODAY'S ENERGY </h4>
                                                        <span class="m-auto"><strong class="caption font-weight-black">CONSUMED</strong></span>
                                                        <br>
                                                        <span class="font-weight-light text-subtitle-1">{{Bidireccional.todayConsumed}} kWh</span>
                                                    </div>                                                   
                                                </v-row>
                                            </td>
                                            <td class="showBorders pa-10">
                                                <v-row class="d-flex justify-center">
                                                    <div class="pr-10">
                                                        <font-awesome-icon icon="calendar" size="3x" :style="{ color: '#03c9d9' }"/>
                                                    </div>
                                                    <div>
                                                        <h4>MONTHLY ENERGY</h4>
                                                        <span class="m-auto"><strong class="caption font-weight-black">CONSUMED</strong></span>
                                                        <br>
                                                        <span class="font-weight-light text-subtitle-1">{{Bidireccional.monthlyConsumed}} kWh</span>
                                                    </div>
                                                   
                                                </v-row>
                                            </td>
                                        </tr>
                                    </tbody>

                                    <!-- second row -->                                    
                                    <tbody>
                                        <loading :active="loadingFlag" :is-full-page="false" :color="'#03c9d9'"/>
                                        <tr class="no-hover-action">
                                            <td class="showBorders pa-10">
                                                <v-row class="d-flex justify-center">
                                                    <div class="pr-10">
                                                        <font-awesome-icon icon="lightbulb" size="3x" :style="{ color: '#03c9d9' }"/>
                                                    </div>
                                                    <div>
                                                        <span><strong class="text-h7 font-weight-black">POWER FACTOR</strong></span>
                                                        <br>
                                                        <span class="font-weight-light text-subtitle-1">{{Bidireccional.powerFactor}} kW</span>
                                                    </div>                                                   
                                                </v-row>
                                            </td>
                                            <td class="showBorders pa-10">
                                                <v-row class="d-flex justify-center">
                                                    <div class="pr-10">
                                                        <font-awesome-icon icon="clock" size="3x" :style="{ color: '#03c9d9' }"/>
                                                    </div>
                                                    <div>
                                                        <h4>TODAY'S ENERGY </h4>
                                                        <span class="m-auto"><strong class="caption font-weight-black">GENERATED</strong></span>
                                                        <br>
                                                        <span class="font-weight-light text-subtitle-1">{{Bidireccional.todayGenerated}} kWh</span>
                                                    </div>                                                   
                                                </v-row>
                                            </td>
                                            <td class="showBorders pa-10">
                                                <v-row class="d-flex justify-center">
                                                    <div class="pr-10">
                                                        <font-awesome-icon icon="calendar" size="3x" :style="{ color: '#03c9d9' }"/>
                                                    </div>
                                                    <div>
                                                        <h4>MONTHLY ENERGY</h4>
                                                        <span class="m-auto"><strong class="caption font-weight-black">GENERATED</strong></span>
                                                        <br>
                                                        <span class="font-weight-light text-subtitle-1">{{Bidireccional.monthlyGenerated}} kWh</span>
                                                    </div>                                                   
                                                </v-row>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row class="pt-0">
                    <v-col>
                        <v-card>
                            <v-card-text>
                                <v-row>                                   
                                    <v-dialog ref="dialogDatePicker" :return-value.sync="dateRangeReportSelected" v-model="modalDatePicker" width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="mx-2" v-model="formatDate" :label="('Select a date range...'+formatDate)" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker :active-picker="today" :max="today" v-model="dateRangeReportSelected" scrollable range locale="en-US">
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalDatePicker = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="save()">ACCEPT</v-btn>
                                    </v-date-picker>
                                   
                                    </v-dialog>
                                    <v-divider class="mx-4 my-2" vertical></v-divider>
                                    <!-- <v-switch v-model="switchFlag" class="mt-4" @change="switchchange()"></v-switch>
                                    <v-chip v-if="switchFlag == true" class=" mt-4 text--secondary">Real Time</v-chip>
                                    <v-chip v-else class="mt-4 text--secondary">Static Time</v-chip> -->
                                    <button v-if="switchFlag == true" @click="switchchange()" style="background-color:rgb(16, 165, 16); border-radius:20px; height: 50px; width: 11%; margin-top: 1%; margin-right: 1%; color:white;" >Real Time</button>
                                    <button v-else @click="switchchange()" style="background-color:rgb(240, 80, 80); border-radius:20px; height: 50px; width: 11%; margin-top: 1%; margin-right: 1%; color:white;" >Static Time</button>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-card>
                            <v-card-text>

                                <v-card class="d-flex align-end flex-column" color="light">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-combobox
                                                class="mt-2"
                                                v-model="selectedG"
                                                :items="consumed"
                                                label="GENERATED" 
                                                @change="onGeneratedSelection"                                                                                          
                                                outlined
                                                dense
                                            >

                                            <template v-slot:selection="data" class="d-flex align-end flex-column">
                                                <v-chip                                                 
                                                    :key="JSON.stringify(data.item)"
                                                    v-bind="data.attrs"
                                                    :input-value="data.selected"
                                                    :disabled="data.disabled"
                                                    @click:close="data.parent.selectItem(data.item)"
                                                    right
                                                >
                                                    <v-avatar
                                                        class="accent white--text"
                                                        left
                                                        v-text="data.item.slice(0, 1).toUpperCase()"
                                                    ></v-avatar>
                                                    {{ data.item }}
                                                </v-chip>
                                            </template>
                                        
                                        
                                        </v-combobox>
                                        </v-col>
                                    </v-row>
                                    <v-row  class="d-flex align-content-stretch flex-wrap" style="width: 100%; margin-top: -28px;" no-gutters>
                                        <v-col cols="12" class="mt-2">
                                            <div class="hightchart" :id="idHighchart" ref="Bidireccional"></div>
                                        </v-col>                                        
                                    </v-row>                                    
                                </v-card>

                                <v-card class="d-flex align-end flex-column mt-1 mb-6" color="#03153d">
                                    <v-row>
                                        <v-col cols="12" class="mt-2">
                                          
                                            <v-combobox
                                                class="mt-2"
                                                v-model="selected"
                                                :items="generated"
                                                label="CONSUMED"
                                                @change="onConsumedSelection"                                                                                            
                                                outlined
                                                dense                                                
                                                background-color="dark"  
                                                color="#fff" 
                                                dark                                                
                                            >
                                            

                                            <template v-slot:selection="data" class="d-flex align-end flex-column">
                                                <v-chip                                                 
                                                    :key="JSON.stringify(data.item)"
                                                    v-bind="data.attrs"
                                                    :input-value="data.selected"
                                                    :disabled="data.disabled"
                                                    @click:close="data.parent.selectItem(data.item)"
                                                    right
                                                >
                                                    <v-avatar
                                                        class="accent white--text"
                                                        left
                                                        v-text="data.item.slice(0, 1).toUpperCase()"
                                                    ></v-avatar>
                                                    {{ data.item }}
                                                </v-chip>
                                            </template>

                                        </v-combobox>

                                           
                                        </v-col>
                                    </v-row>
                                    <v-row  class="d-flex align-content-stretch flex-wrap" style="width: 100%; margin-top: -28px;" no-gutters>
                                        <v-col cols="12" class="mt-2">
                                            <div class="chartConsumed"  id="chartConsumed" ref="chartConsumed"></div>
                                        </v-col>                                        
                                    </v-row>                                    
                                </v-card>

                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import moment from 'moment';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Alerts from "./component/c_alerts.vue"
import { mapGetters, mapActions } from "vuex";
import TableMixin from "@/mixins/DataTables";

export default {
    mixins:[TableMixin],
    props: {  
        dateRangeReportSelected: Array,      
        device: Object,
        Bidireccional: Object,
        posicion: Object,
        idHighchart: String,
        rules: Object,
        switchFlag: Boolean,
        loadingFlag: Boolean,
        loadingFlagHistorico: Boolean,
        loadingFlagFormAlerts: Boolean,
        loadingFlagFormConfig: Boolean,
    },
    components:{
        Alerts,
        Loading
    },
    watch: {
        dateRangeReportSelected(val){           
        },
        getCloseModal(val){            
            if (val == "close") {
                this.cerrarAlerts()
            }            
        }
    },
    computed:{
        ...mapGetters({
            getCloseModal: 'getCloseModal'
        }),
        formatDate(){
            var arreglotemporal = []
            var fechaPart1
            var fechaPart2
            var fechaPart1String
            var fechaPart2String
            if(this.dateRangeReportSelected.length == 2){
                console.log("BANDERA RANGO");

                fechaPart1 = new Date(this.dateRangeReportSelected[0]);
                fechaPart2 = new Date(this.dateRangeReportSelected[1])

                fechaPart1String = moment(fechaPart1).add(1,'days').format('MMMM/DD/YYYY')
                fechaPart2String = moment(fechaPart2).add(1,'days').format('MMMM/DD/YYYY')

                arreglotemporal.push(fechaPart1String);
                arreglotemporal.push(fechaPart2String);

                return arreglotemporal.join(' ~ ');
            }
        }
        // formatDate(){            
        //     if(this.dateRangeReportSelected.length == 2){
        //         let rangeDate = this.dateRangeReportSelected.join(' ~ ');
        //         return rangeDate
        //     }       
        // }
    },
    data() {
        return {
            modalDatePicker: false,
            today: moment().format("YYYY-MM-DD"),
            banderaMapa: 0,
            items: ['Normal', 'Monitoreo'],
            selectedG:'CURRENTS',
            selected:'CURRENTS',
            consumed: ['CURRENTS', 'ENERGIES'],
            generated: ['CURRENTS', 'ENERGIES', 'VOLTAGES'],
            loadingFlag:false,
            banderaMapa: 0,
            toggle_exclusive: 0,
            typeOfChart: 'Consumed',
            itemSelected: 'CURRENTS',
            modalAlertConfig: false,
            
        }
    },
    mounted(){ 
        this.$emit("makeHighchart", this.params);
        this.$emit("makeHighchart2", this.params2);          

        let rango = []
        let firstDate = moment(this.device.fechaGps).subtract(2, 'd').format('YYYY-MM-DD')
        let secondtDate = moment(this.device.fechaGps).format('YYYY-MM-DD')
        rango.push([firstDate,secondtDate]);

        let data = {
                rango: rango,
                channel: 'Generated',
                selected: this.itemSelected,
                chart_id: 'bidirectional-highchart'
        }        
        this.$emit("getRangoDCMax", data );

        let data2 = {
                rango: rango,
                channel: 'Consumed',
                selected: this.itemSelected,
                chart_id: 'chartConsumed'
        }           

        this.$emit("getRangoDCMax", data2 );


        if(this.posicion.latitud == 0 && this.posicion.longitud == 0){
            this.banderaMapa = 0;
            //console.log("NO TIENE POSICION");
        }else{
            //console.log("SI TIENE POSICION");
            this.banderaMapa = 1;
            this.$emit("getMapa",this.posicion);
        }

    },

    // updated(){
    //     if(this.posicion.latitud == 0 && this.posicion.longitud == 0){
    //         this.banderaMapa = 0;
    //         console.log("NO TIENE POSICION");
    //     }else{
    //         console.log("SI TIENE POSICION");
    //         this.banderaMapa = 1;
    //         this.$emit("getMapa",this.posicion);
    //     }
    // },
    methods: {
        switchchange(){
            this.$emit("cambioSwitch");
        },
        onConsumedSelection(e){
            let rango = []
            rango.push(this.dateRangeReportSelected)             
            let data = {
                rango: rango,
                channel: 'Consumed',
                selected: e,
                chart_id: 'chartConsumed'
            }
            this.$emit("getRangoDCMax", data ); 

        },
        onGeneratedSelection(e){
            let rango = []
            rango.push(this.dateRangeReportSelected)             
            let data = {
                rango: rango,
                channel: 'Generated',
                selected: e,
                chart_id: 'bidirectional-highchart'
            }
            this.$emit("getRangoDCMax", data ); 

        },
        cerrarAlerts(){
            this.$emit("consultaTemporal");
            this.modalAlertConfig = false;
        },
        openAlertForm(){
            this.modalAlertConfig = true;
        },
        showLastReport(dateReport){            
            return moment(dateReport).locale('en').format("MMMM DD YYYY HH:mm:ss")
        },       
        save(){
            var rango = []
            rango.push(this.dateRangeReportSelected)    

            let data = {
                rango: rango,
                channel: 'Generated',
                selected: this.itemSelected,
                chart_id: 'bidirectional-highchart'
            }        
            this.$emit("getRangoDCMax", data );

            let data2 = {
                    rango: rango,
                    channel: 'Consumed',
                    selected: this.itemSelected,
                    chart_id: 'chartConsumed'
            }           

            this.$emit("getRangoDCMax", data2 );



            this.modalDatePicker = false;
        },
    },
}
</script>
<style>
.v-select.v-input--dense .v-chip {
    margin: 3px 4px 0 4px;
}
</style>