<template>
    <v-dialog v-model="modalAlerts" transition="dialog-bottom-transition" max-width="600">
        <v-card>
            <v-toolbar color="primary" dark><span class="ml-2">Alerts Configuration</span><v-btn :plain="true" class="ml-auto" @click="closeAlertModal()"><font-awesome-icon icon="times" size="2x" :style="{ color: 'white'}"/></v-btn></v-toolbar>
            <v-card-text>
                <v-tabs class="mt-2" v-model="tab">
                    <v-tab>Alerts</v-tab>
                    <v-tab>Emails</v-tab>
                    <v-tab>SMS</v-tab>
                </v-tabs>
                <v-tabs-items>

                </v-tabs-items>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    components: {

    },
    props: {
        modalAlerts: Boolean,
    },
    methods: {
        closeAlertModal(){
            this.modalAlerts = false;
        }
    },
    data(){
        return {
            tab: null,
        }
    }
}
</script>