<template>
    <div class="px-1 mt-0">
        <v-row>
            <v-col lg="4" sm="12">
                <v-card>
                    <loading :active="loadingFlag" :is-full-page="false" :color="'#03c9d9'"/>
                    <v-card-title>Sensor Data</v-card-title>
                    <v-card-text>
                        <v-simple-table>
                            <template>
                                <tbody>
                                    <tr class="no-hover-action">
                                        <th>Device name:</th>
                                        <td>{{device.name}}</td>
                                    </tr>
                                    <tr class="no-hover-action">
                                        <th>Last update:</th>
                                        <td>{{showLastReport(device.fechaGps)}}</td>
                                    </tr>
                                    <tr class="no-hover-action">
                                        <th>Status (Since Last Report):</th>
                                        <td v-if="colorText == 'color:green'"><font-awesome-icon icon="check-circle" size="1x" :style="{ color: 'green' }"></font-awesome-icon><span v-if="totalReceived == 0" class="text--secondary"> (No Data Reported) </span></td>
                                        <td v-if="colorText == 'color:red'"><font-awesome-icon icon="times-circle" size="1x" :style="{ color: 'red' }"></font-awesome-icon></td>
                                    </tr>
                                    <tr class="no-hover-action">
                                        <th>Received Data Today:</th>
                                        <td>{{totalReceived}}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
                <v-card>
                    <loading :active="loadingFlag" :is-full-page="false" :color="'#03c9d9'"/>
                    <v-card-title>Map</v-card-title>
                    <v-card-text>
                        <v-row class="my-0 pa-3">
                            <div class="mx-auto" id="myMap" style='position:relative;width:100%;height:300px;' v-if="banderaMapa == 1">
                            </div>
                            <div v-else>
                                <span>No Location</span>
                            </div>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card>
                    <v-card-title>Configuration and Today Alerts</v-card-title>
                    <v-row class="mr-1">
                        <v-col lg="4" sm="12">
                            <v-card class="ml-3" style="background: #f05050">
                                <div class="pl-3 py-4">
                                     <font-awesome-icon icon="bell" size="2x" :style="{ color: 'white' }"></font-awesome-icon>
                                     <v-icon class="mr-3" size="20" :style="{ color: 'white' }">mdi-cog</v-icon>
                                </div>
                                <div class="pl-2 py-2 blue-grey darken-4">
                                     <v-dialog v-model="modalAlertConfig" persistent transition="dialog-bottom-transition" max-width="600">
                                        <template v-slot:activator="{ on, attrs }">
                                            <span class="white--text mr-2"><a href="" @click.prevent="openAlertForm()" style="color:inherit" v-bind="attrs" v-on="on">Alerts</a></span>
                                        </template>
                                        <template v-slot:default="dialog">
                                            <v-card>
                                                <v-toolbar color="primary" dark><span class="ml-2">Alerts Configuration</span><v-btn :plain="true" class="ml-auto" @click="cerrarAlerts()"><font-awesome-icon icon="times" size="2x" :style="{ color: 'white'}"/></v-btn></v-toolbar>
                                                <v-card-text>
                                                    <!--<v-row class="pt-4 px-4">
                                                        <v-text-field v-model="name" :counter="20" label="Temperature (Max)" required class="mr-4"></v-text-field>
                                                        <v-text-field v-model="address" :counter="20" label="Temperature (Min)" required></v-text-field>
                                                    </v-row>
                                                    <v-row class="pt-2 px-4">
                                                        <v-text-field v-model="address" :counter="20" label="Humidity (Max)" required class="mr-4"></v-text-field>
                                                        <v-text-field v-model="address" :counter="20" label="Humidity (Min)" required></v-text-field>
                                                    </v-row>
                                                    <v-select :items="items" label="Alarm Type" required class="pt-4 px-1">
                                                    </v-select>-->
                                                    <v-select :items="estatus" :value="estatus.value" label="Status" v-model="estatus_value" required class="pt-4 px-1 mt-4">
                                                    </v-select>
                                                    <v-text-field label="Email 1" v-model="email1" :rules="[rules.required, rules.email]" required>
                                                    </v-text-field>
                                                    <v-text-field label="Email 2" v-model="email2" :rules="[rules.email]">
                                                    </v-text-field>
                                                    <v-text-field label="Email 3" v-model="email3" :rules="[rules.email]">
                                                    </v-text-field>
                                                </v-card-text>
                                                <v-card-actions class="justify-end">
                                                <v-btn text @click="cerrarAlerts()">Cancel</v-btn>
                                                <v-btn text color="primary" @click="updateAlerts()">Update</v-btn>
                                                <v-progress-circular indeterminate color="primary" size="20" v-if="loadingFlagFormAlerts"></v-progress-circular>
                                                </v-card-actions>
                                            </v-card>
                                        </template> 
                                    </v-dialog>
                                     <span><font-awesome-icon icon="chevron-circle-right" size="1x" :style="{ color: 'white' }"></font-awesome-icon></span>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col lg="4" sm="12">
                            <v-card class="ml-1" style="background: #10a510">
                                <div class="pl-3 py-4">
                                     <v-icon class="mr-3" size="35" :style="{ color: 'white' }">mdi-sticker-alert</v-icon>
                                </div>
                                <div class="pl-2 py-2 blue-grey darken-4">
                                    <v-dialog v-model="modalSeeAlerts" persistent transition="dialog-bottom-transition" max-width="600">
                                        <template v-slot:activator="{ on, attrs }">
                                            <span class="white--text mr-2"><a href="" @click.prevent="consultarDeviceAlerts()" style="color:inherit" v-bind="attrs" v-on="on">See Alerts</a></span>
                                        </template>
                                        <template v-slot:default="dialog">
                                            <v-card>
                                                <v-toolbar color="primary" dark><span class="ml-2">Device Alerts</span><v-btn :plain="true" class="ml-auto" @click="cerrarTableAlerts()"><font-awesome-icon icon="times" size="2x" :style="{ color: 'white'}"/></v-btn></v-toolbar>
                                                <v-card-text>
                                                    <v-row>
                                                        <div class="pa-5">
                                                            <template>
                                                                <v-text-field v-model="searchAlerts" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
                                                                <v-data-table dense calculate-widths :items-per-page="5" :headers="headerDeviceAlerts" :items="dataDeviceAlerts" item-key="name" :search="searchAlerts" class="elevation-1" :loading="loadingFlagTableAlerts" loading-text="Loading... Please wait"></v-data-table>
                                                            </template>
                                                        </div>
                                                    </v-row>
                                                </v-card-text>
                                                <v-card-actions class="justify-end">
                                                <v-btn text @click="cerrarTableAlerts()">Cancel</v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </template> 
                                    </v-dialog>
                                    <span><font-awesome-icon icon="chevron-circle-right" size="1x" :style="{ color: 'white' }"></font-awesome-icon></span>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col lg="4" sm="12">
                            <v-card class="ml-1" style="background: #5d9cec">
                                <div class="pl-3 py-4">
                                     <font-awesome-icon icon="cog" size="2x" :style="{ color: 'white' }"></font-awesome-icon>
                                </div>
                                <div class="pl-2 py-2 blue-grey darken-4">
                                     <v-dialog v-model="modalDeviceConfig" persistent transition="dialog-bottom-transition" max-width="600">
                                        <template v-slot:activator="{ on, attrs }">
                                            <span class="white--text mr-2"><a href="" @click.prevent="getRealData()" style="color:inherit" v-bind="attrs" v-on="on">Config</a></span>
                                        </template>
                                        <template v-slot:default="dialog">
                                            <v-card>
                                                <v-toolbar color="primary" dark><span class="ml-2">Configuration</span><v-btn :plain="true" class="ml-auto" @click="cerrar()"><font-awesome-icon icon="times" size="2x" :style="{ color: 'white'}"/></v-btn></v-toolbar>
                                                <v-card-text>
                                                    <div class="py-6 inline-block"> <span><strong>IMEI: </strong> </span> <span>{{device.imei}}</span> </div>
                                                    <v-text-field v-model="device.name" :counter="50" label="Asset" required></v-text-field>
                                                    <v-text-field v-model="device.address" :counter="50" label="Address" required></v-text-field>
                                                    <v-select :items="timezones_list" item-text="name" item-value="id" label="Offset" v-model="idTimeZone" @change="assignTimeZone($event)" required></v-select>
                                                    <!--<v-text-field v-model="phConfig.alerts" type="number" label="#Alerts" required min="0" max="200"></v-text-field>
                                                    <v-text-field v-model="phConfig.mail" :rules="[rules.required, rules.email]" :counter="40" label="Email (test@example.com)" required></v-text-field>-->
                                                </v-card-text>
                                                <v-card-actions class="justify-end">
                                                <v-btn text @click="cerrar()">Cancel</v-btn>
                                                <v-btn text color="primary" @click="updateConfiguration()">Update</v-btn>
                                                <v-progress-circular indeterminate color="primary" size="20" v-if="loadingFlagFormConfig"></v-progress-circular>
                                                </v-card-actions>
                                            </v-card>
                                        </template> 
                                    </v-dialog>
                                     <span><font-awesome-icon icon="chevron-circle-right" size="1x" :style="{ color: 'white' }"></font-awesome-icon></span>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col lg="8" sm="12">
                <v-row> 
                    <v-col>
                        <v-card>
                            <v-simple-table >
                                <template>
                                    <tbody>
                                        <loading :active="loadingFlag" :is-full-page="false" :color="'#03c9d9'"/>
                                        <tr class="no-hover-action">
                                            <td class="showBorders pa-10">
                                                <v-row class="d-flex justify-center">
                                                    <div class="pr-10">
                                                        <font-awesome-icon icon="thermometer-three-quarters" size="4x" :style="{ color: '#03c9d9' }"/>
                                                    </div>
                                                    <div v-if="phData.Temperatura != null">
                                                        <span><strong class="text-h5 font-weight-black">TEMPERATURE</strong></span>
                                                        <br>
                                                        <span class="font-weight-light text-subtitle-1">{{phData.Temperatura}} C°</span>
                                                    </div>
                                                    <div v-else>
                                                        <span><strong class="text-h5 font-weight-black">TEMPERATURE</strong></span>
                                                        <br>
                                                        <span class="font-weight-light text-subtitle-1">N/A</span>
                                                    </div>
                                                </v-row>
                                            </td>
                                            <td class="showBorders pa-10">
                                                <v-row class="d-flex justify-center">
                                                    <div class="pr-10">
                                                        <font-awesome-icon icon="eye-dropper" size="4x" :style="{ color: '#03c9d9' }"/>
                                                    </div>
                                                    <div v-if="phData.nivel != null">
                                                        <span><strong class="text-h5 font-weight-black">PH</strong></span>
                                                        <br>
                                                        <span class="font-weight-light text-subtitle-1">{{phData.nivel}}</span>
                                                    </div>
                                                    <div v-else>
                                                        <span><strong class="text-h5 font-weight-black">PH</strong></span>
                                                        <br>
                                                        <span class="font-weight-light text-subtitle-1">N/A</span>
                                                    </div>
                                                </v-row>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row class="pt-0">
                    <v-col>
                        <v-card>
                            <v-card-text>
                                <v-row>
                                    <v-dialog ref="dialogDatePicker" :return-value.sync="dateRangeReportSelected" v-model="modalDatePicker" width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="mx-2" v-model="formatDate" label="Select a date range..." prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker :active-picker="today" :max="today" v-model="dateRangeReportSelected" scrollable range locale="en-US">
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalDatePicker = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="save()">ACCEPT</v-btn>
                                    </v-date-picker>
                                    </v-dialog>
                                    <v-divider class="mx-4 my-2" vertical></v-divider>
                                    <!-- <v-switch v-model="switchFlag" class="mt-4" @change="switchchange()"></v-switch>
                                    <v-chip v-if="switchFlag == true" class=" mt-4 text--secondary">Real Time</v-chip>
                                    <v-chip v-else class="mt-4 text--secondary">Static Time</v-chip> -->
                                    <button v-if="switchFlag == true" @click="switchchange()" style="background-color:rgb(16, 165, 16); border-radius:20px; height: 50px; width: 11%; margin-top: 1%; margin-right: 1%; color:white;" >Real Time</button>
                                    <button v-else @click="switchchange()" style="background-color:rgb(240, 80, 80); border-radius:20px; height: 50px; width: 11%; margin-top: 1%; margin-right: 1%; color:white;" >Static Time</button>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-card>
                            <v-card-text>
                                <v-card-text>
                                    <v-row>
                                        <loading :active="loadingFlagHistorico" :is-full-page="false" :color="'#03c9d9'"/>
                                        <v-col>
                                            <div class="hightchart" :id="idHighchart"></div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import moment from 'moment'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Swal from 'sweetalert2'

export default {
    props: {
        phConfig: Object,
        phData: Object,
        phAlertConfig: Object,
        dateRangeReportSelected: Array,
        device: Object,
        totalReceived: Number,
        colorText: String,
        idHighchart: String,
        loadingFlag: Boolean,
        loadingFlagHistorico: Boolean,
        loadingFlagFormAlerts: Boolean,
        loadingFlagFormConfig: Boolean,
        timezones_list: Array,
        idTimeZone: Number,
        posicion: Object,
        rules: Object,
        modalActualizarConfiguracion: Boolean,
        modalActualizarAlerts: Boolean,
        dataDeviceAlerts: Array,
        loadingFlagTableAlerts: Boolean,
        switchFlag: Boolean
    },
    data(){
        return {
            modalDatePicker: false,
            today: moment().format("YYYY-MM-DD"),
            items: ['Normal', 'Monitoreo'],
            
            estatus: [{text:'Enable', value: 'YES'}, { text:'Disable', value: 'NO'}],
            estatus_value: 'NO',
            email1: '',
            email2: '',
            email3: '',

            local_idTimeZone: this.idTimeZone,
            mapCredentials: "Apl2ciQ_uUYkh2WtZrsJAiHsal-ddm0weOKb7u2eTkBNXvaMc4f15x2dN0Tf4hxb",
            mapOptions: {

            },
            mapVisible: true,
            arrayPosicion: {
                latitud: 0.0,
                latitud: 0.0
            },
            banderaMapa: 0,

            modalSeeAlerts: false,
            modalAlertConfig: false,
            modalDeviceConfig: false,
            searchAlerts: '',
            headerDeviceAlerts: [
                { text: 'Date', align: 'start', sortable: true, value: 'fechaReplica', width: '35%'},
                { text: 'Message', align: 'start', sortable: true ,value: 'parametros', width: '65%' },
            ],
        }
    },
    components:{
        Loading
    },
    created(){
        
    },

    computed:{
        formatDate(){
            var arreglotemporal = []
            var fechaPart1
            var fechaPart2
            var fechaPart1String
            var fechaPart2String
            if(this.dateRangeReportSelected.length == 2){
                console.log("BANDERA RANGO");

                fechaPart1 = new Date(this.dateRangeReportSelected[0]);
                fechaPart2 = new Date(this.dateRangeReportSelected[1])

                fechaPart1String = moment(fechaPart1).add(1,'days').format('MMMM/DD/YYYY')
                fechaPart2String = moment(fechaPart2).add(1,'days').format('MMMM/DD/YYYY')

                arreglotemporal.push(fechaPart1String);
                arreglotemporal.push(fechaPart2String);

                return arreglotemporal.join(' ~ ');
            }
        }
    },

    mounted(){
        //console.log("HOLA PH");
        const params = {
            idHighchart: this.idHighchart,
            title: "Temperature and Humidity",
            labelX: "Temperatura / Humedad",
            series:[
                {
                    data: [],
                    color: 'blue',
                    name: "Temperature"
                },
                {
                    data: [],
                    color: 'red',
                    name: "Humidity"
                },
            ]
        };
        //console.log("ESTA ES LA POSICION ACTUAL:");
        //console.log(this.posicion);
        this.$emit("makeHighchart", params);

        if(this.posicion.latitud == 0 && this.posicion.longitud == 0){
            this.banderaMapa = 0;
            //console.log("NO TIENE POSICION");
        }else{
            //console.log("SI TIENE POSICION");
            this.banderaMapa = 1;
            this.$emit("getMapa",this.posicion);
        }
    },
    
    updated(){
        if(this.posicion.latitud == 0 && this.posicion.longitud == 0){
            this.banderaMapa = 0;
            //console.log("NO TIENE POSICION");
        }else{
            //console.log("SI TIENE POSICION");
            this.banderaMapa = 1;
            this.$emit("getMapa",this.posicion);
        }
    },
    methods: {
        switchchange(){
            this.$emit("cambioSwitch");
        },

        showLastReport(dateReport){
            //return moment(dateReport).format("YYYY-MM-DD HH:mm:ss")
            return moment(dateReport).locale('en').format("MMMM DD YYYY HH:mm:ss")
        },
        save(){
            console.log("GUARDANDO FECHA");
            var rango = []
            rango.push(this.dateRangeReportSelected)
            this.$emit("getRango", rango);
            this.modalDatePicker = false;
            //this.dateRangeReportSelected.splice(0)
        },

        updateConfiguration(){
            this.loadingFlagFormConfig = true;
            this.modalDeviceConfig = false;
            var stringConfig = JSON.stringify(this.phConfig);
            this.device.configuracion = stringConfig;
            this.$emit("updateConfiguration");
            this.$emit("consultaTemporal"); 
            //this.$emit("consultaTemporal");
            //console.log(this.device.address);
            //console.log(this.local_idTimeZone);
        },

        updateAlerts(){
            var bandera1Temporal = 0
            var bandera2Temporal = 0
            var bandera3Temporal = 0
            var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

            this.loadingFlagFormAlerts = true;
            this.phAlertConfig.alertEnable = this.estatus_value;
            this.phAlertConfig.emails[0] = this.email1;
            this.phAlertConfig.emails[1] = this.email2;
            this.phAlertConfig.emails[2] = this.email3;

            if(this.phAlertConfig.emails[0] == "" && this.phAlertConfig.emails[1] == "" && this.phAlertConfig.emails[2] == ""){
                Swal.fire({
                    title: '¡Warning!',
                    icon: 'warning',
                    text: 'Please input at least one email to report',
                }); 
                this.loadingFlagFormAlerts = false; 
            }else{
                if(this.phAlertConfig.emails[0] == ''){ 
                    bandera1Temporal = 0;
                }else{
                    if((this.phAlertConfig.emails[0].match(mailformat))){
                        bandera1Temporal = 0;
                    }else{
                        bandera1Temporal = 1;
                    }
                }

                if(this.phAlertConfig.emails[1] == ''){ 
                    bandera2Temporal = 0;
                }else{
                    if((this.phAlertConfig.emails[1].match(mailformat))){
                        bandera2Temporal = 0;
                    }else{
                        bandera1Temporal = 1;
                    }
                }

                if(this.phAlertConfig.emails[2] == ''){ 
                    bandera3Temporal = 0;
                }else{
                    if((this.phAlertConfig.emails[2].match(mailformat))){
                        bandera3Temporal = 0;
                    }else{
                        bandera1Temporal = 1;
                    }
                }

                if(bandera1Temporal == 1 || bandera2Temporal == 1 || bandera3Temporal == 1){
                    Swal.fire({
                        title: '¡Warning!',
                        icon: 'warning',
                        text: 'Incorrect email format',
                    });
                    this.loadingFlagFormAlerts = false;
                }else{
                    var jsonConfiguracion = JSON.parse(this.device.alertConfig);
                    jsonConfiguracion.emails = this.phAlertConfig.emails;
                    jsonConfiguracion.alertEnable = this.phAlertConfig.alertEnable;

                    var stringJsonConfig = JSON.stringify(jsonConfiguracion);
                    this.device.alertConfig = stringJsonConfig;
                    this.modalAlertConfig = false

                    this.$emit("updateAlertConfiguration");
                    this.$emit("consultaTemporal");
                }
            }
        },

        consultarDeviceAlerts(){
            this.loadingFlagTableAlerts = true;
            this.$emit("getDeviceAlerts",this.device);
        },

        assignTimeZone(event){
            this.$emit("assignTimeZone",event);
        },

        test1(){
            //console.log("BANDERA POSICION");
            //console.log(this.device);
            console.log("ESTA ES LA POSICION ACTUAL:");
            console.log(this.posicion);
        },

        getRealData(){
            //console.log(this.device);
            //console.log(this.idTimeZone);
            //console.log("consultando");
            //this.$emit("consultaTemporal");
        },

        openAlertForm(){
            this.modalAlertConfig = true;
            this.estatus_value = this.phAlertConfig.alertEnable;
            if(this.phAlertConfig.emails.length == 3){
                this.email1 = this.phAlertConfig.emails[0];
                this.email2 = this.phAlertConfig.emails[1];
                this.email3 = this.phAlertConfig.emails[2];
            }else if(this.phAlertConfig.emails.length == 2){
                this.email1 = this.phAlertConfig.emails[0];
                this.email2 = this.phAlertConfig.emails[1];
                this.email3 = "";
            }else if(this.phAlertConfig.emails.length == 1){
                this.email1 = this.phAlertConfig.emails[0];
                this.email2 = "";
                this.email3 = "";
            }else if(this.phAlertConfig.emails.length == 0){
                this.email1 = "";
                this.email2 = "";
                this.email3 = "";
            }
        },
        
        cerrar(){ 
            console.log("Cerrando");  
            this.$emit("consultaTemporal");      
            this.modalDeviceConfig = false;
        },

        cerrarAlerts(){
            this.$emit("consultaTemporal");
            this.modalAlertConfig = false;
        },

        cerrarTableAlerts(){
            console.log("Cerrando"); 
            this.modalSeeAlerts = false;
        }
    }
}
</script>