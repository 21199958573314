<template>
    <div class="grey lighten-4 height-100" style="padding-bottom: 5%;">
        <Header :seeEnergyFlag="seeEnergyFlag" :devices="selected"/>
        
        <v-row class="mt-5 pt-1 mx-0">
            <v-col lg="11" sm="12" cols="12">
                <v-row class="flex-row justify-end">
                    <v-col lg="12" sm="12">
                        <v-card>
                            <v-card-text class="py-2">
                                <v-row>
                                    <v-col lg="4" sm="12" cols="12" class="">
                                        <v-card class="my-2">
                                            <v-card-text class="py-1 my-0">
                                                <v-select :items="arregloSensoresDiferentes2" v-model="valorFiltroAlerts" prepend-icon="mdi-filter-outline" item-text="nombreTipo" item-value="idSensor" label="Sensor Type" @change="showTipoSensor($event)" :loading="loadingFlagSelect">
                                                <template slot="item" slot-scope="data">
                                                        <span class="mr-2" v-if="data.item.idSensor == ''"><font-awesome-icon icon="laptop-house" size="1x" :style="{ color: 'grey' }"></font-awesome-icon></span>
                                                        <span class="mr-2" v-else-if="data.item.idSensor == 41"><font-awesome-icon icon="tint" size="1x" :style="{ color: 'blue' }"></font-awesome-icon></span>
                                                        <span class="mr-2" v-else-if="data.item.idSensor == 37"><font-awesome-icon icon="charging-station" size="1x" :style="{ color: 'purple' }"></font-awesome-icon></span>
                                                        <span class="mr-2" v-else-if="data.item.idSensor == 36"><font-awesome-icon icon="eye-dropper" size="1x" :style="{ color: 'orange' }"></font-awesome-icon></span>
                                                        <span class="mr-2" v-else-if="data.item.idSensor == 32"><font-awesome-icon icon="cloud-sun-rain" size="1x" :style="{ color: '#90cdfd' }"></font-awesome-icon></span>
                                                        <span class="mr-2" v-else-if="data.item.idSensor == 31"><font-awesome-icon icon="temperature-high" size="1x" :style="{ color: 'blue' }"></font-awesome-icon></span>
                                                        <span class="mr-2" v-else-if="data.item.idSensor == 30"><font-awesome-icon icon="fire-alt" size="1x" :style="{ color: 'orange' }"></font-awesome-icon></span>
                                                        <span class="mr-2" v-else-if="data.item.idSensor == 29"><font-awesome-icon icon="temperature-low" size="1x" :style="{ color: 'pink' }"></font-awesome-icon></span>
                                                        <span class="mr-2" v-else-if="data.item.idSensor == 11"><font-awesome-icon icon="mobile" size="1x" :style="{ color: '#339af0' }"></font-awesome-icon></span>
                                                        <span class="mr-2" v-else-if="data.item.idSensor == 8"><font-awesome-icon icon="toggle-on" size="1x" :style="{ color: 'red' }"></font-awesome-icon></span>
                                                        <span class="mr-2" v-else-if="data.item.idSensor == 7"><font-awesome-icon icon="gas-pump" size="1x" :style="{ color: 'green' }"></font-awesome-icon></span>
                                                        <span class="mr-2" v-else-if="data.item.idSensor == 3"><font-awesome-icon icon="wind" size="1x" :style="{ color: '#03b3ca' }"></font-awesome-icon></span>
                                                        <span class="mr-2" v-else-if="data.item.idSensor == 2"><font-awesome-icon icon="volume-up" size="1x" :style="{ color: '#27c24c' }"></font-awesome-icon></span>
                                                        <span class="mr-2" v-else-if="data.item.idSensor == 1"><font-awesome-icon icon="bolt" size="1x" :style="{ color: '#ffb900' }"></font-awesome-icon></span> 
                                                        <span>{{data.item.nombreTipo}}</span>
                                                </template>
                                                <template slot="selection" slot-scope="data">
                                                    <span class="mr-2" v-if="data.item.idSensor == ''"><font-awesome-icon icon="laptop-house" size="1x" :style="{ color: 'grey' }"></font-awesome-icon></span>
                                                    <span class="mr-2" v-if="data.item.idSensor == 41"><font-awesome-icon icon="tint" size="1x" :style="{ color: 'blue' }"></font-awesome-icon></span>
                                                    <span class="mr-2" v-else-if="data.item.idSensor == 37"><font-awesome-icon icon="charging-station" size="1x" :style="{ color: 'purple' }"></font-awesome-icon></span>
                                                    <span class="mr-2" v-else-if="data.item.idSensor == 36"><font-awesome-icon icon="eye-dropper" size="1x" :style="{ color: 'orange' }"></font-awesome-icon></span>
                                                    <span class="mr-2" v-else-if="data.item.idSensor == 32"><font-awesome-icon icon="cloud-sun-rain" size="1x" :style="{ color: '#90cdfd' }"></font-awesome-icon></span>
                                                    <span class="mr-2" v-else-if="data.item.idSensor == 31"><font-awesome-icon icon="temperature-high" size="1x" :style="{ color: 'blue' }"></font-awesome-icon></span>
                                                    <span class="mr-2" v-else-if="data.item.idSensor == 30"><font-awesome-icon icon="fire-alt" size="1x" :style="{ color: 'orange' }"></font-awesome-icon></span>
                                                    <span class="mr-2" v-else-if="data.item.idSensor == 29"><font-awesome-icon icon="temperature-low" size="1x" :style="{ color: 'pink' }"></font-awesome-icon></span>
                                                    <span class="mr-2" v-else-if="data.item.idSensor == 11"><font-awesome-icon icon="mobile" size="1x" :style="{ color: '#339af0' }"></font-awesome-icon></span>
                                                    <span class="mr-2" v-else-if="data.item.idSensor == 8"><font-awesome-icon icon="toggle-on" size="1x" :style="{ color: 'red' }"></font-awesome-icon></span>
                                                    <span class="mr-2" v-else-if="data.item.idSensor == 7"><font-awesome-icon icon="gas-pump" size="1x" :style="{ color: 'green' }"></font-awesome-icon></span>
                                                    <span class="mr-2" v-else-if="data.item.idSensor == 3"><font-awesome-icon icon="wind" size="1x" :style="{ color: '#03b3ca' }"></font-awesome-icon></span>
                                                    <span class="mr-2" v-else-if="data.item.idSensor == 2"><font-awesome-icon icon="volume-up" size="1x" :style="{ color: '#27c24c' }"></font-awesome-icon></span>             
                                                    <span class="mr-2" v-else-if="data.item.idSensor == 1"><font-awesome-icon icon="bolt" size="1x" :style="{ color: '#ffb900' }"></font-awesome-icon></span> 
                                                    <span>{{data.item.nombreTipo}}</span>
                                                </template>
                                                </v-select>
                                            </v-card-text>
                                        </v-card>             
                                    </v-col>
                                    <v-col lg="6" sm="12" cols="12" class="">
                                        <v-card class="my-2">
                                            <v-card-text class="py-1 my-0">
                                                <v-dialog ref="dialogDatePicker" v-model="modalDatePicker" width="290px">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field class="px-3 mx-2 py-1 mt-2" v-model="formatDate" label="Select a date range..." prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" clearable>
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker :active-picker="today" :max="today" v-model="rangoFechasAlerts" scrollable range locale="en-US">
                                                    <v-spacer></v-spacer>
                                                    <v-btn text color="primary" @click="modalDatePicker = false">
                                                    Cancel
                                                    </v-btn>
                                                    <v-btn text color="primary" @click="modalDatePicker = false">
                                                    ACCEPT
                                                    </v-btn>
                                                    </v-date-picker>
                                                </v-dialog>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col lg="2" sm="12" cols="12" class="">
                                        <v-card class="my-2">
                                            <v-card-text class="text-center">
                                                <v-row>
                                                    <v-col lg="12" sm="12" cols="12">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn style="width:100%;" class="" color="success" @click="consultarAlertas()" v-bind="attrs" v-on="on"><v-icon size="30">mdi-magnify</v-icon><span>Search</span></v-btn>
                                                            </template>
                                                            <span>Search User Alerts</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card> 
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-col lg="1" sm="12" cols="12">
                <v-row>
                    <v-col lg="12" sm="12" class="">
                        <v-card class="py-4">
                            <v-card-text>
                                <v-row>
                                    <v-col lg="12" sm="12" class="d-flex justify-center">
                                        <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn style="width:100%;" class="mx-2" color="info" @click="refreshAlerts()" v-bind="attrs" v-on="on"><v-icon size="30">mdi-refresh</v-icon><span></span></v-btn>
                                                </template>
                                            <span>Refresh Alerts</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row class="px-3">
            <v-col lg="12" sm="12">
                <v-card class="px-5">
                    <v-card-title>
                    <span>User Alerts</span>
                    <v-divider class="mx-4 my-2" vertical></v-divider>
                    <v-icon class="mx-3" size="30">mdi-account</v-icon>
                    <span class="font-weight-light">{{user_global}}</span>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="searchAlerts" append-icon="mdi-magnify" label="Search" single-line hide-details clearable></v-text-field>
                    </v-card-title>
                    <template>
                        <v-data-table calculate-widths :items-per-page="5" :headers="headerDeviceAlerts" :items="arrayAlerts" item-key="idAlert" :search="searchAlerts" class="elevation-1" :loading="loadingFlagTableAlerts" loading-text="Loading... Please wait">
                            <template v-slot:item.send="{ item }">
                                <v-icon v-if="item.parametros == 'texto prueba'" color="red" size="25">mdi-close-thick</v-icon>
                                <v-icon v-else color="green" size="25">mdi-check-bold</v-icon>
                            </template>
                        </v-data-table>
                    </template>
                </v-card>
            </v-col>
        </v-row>
        <v-footer style="position: absolute; bottom: 0; width: 100%;" padless color="#2196f3" class="mt-6">
            <v-col class="text-center" cols="12">
                <span class="white--text">Max4 Technologies {{ new Date().getFullYear() }} — Version 1.4</span>
            </v-col>
        </v-footer>
    </div>
</template>
<script>
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import moment from 'moment'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Swal from 'sweetalert2';


//Tables
import THTable from '@/components/Reports/TemperatureHumidityReport'
import SonometerTable from '@/components/Reports/SonometerReport'

// Components
import Header from '@/components/Header'

// Devices
import FlowWater from '@/components/Devices/FlowWater'
import Sonometer from '@/components/Devices/Sonometer'
import AirQuality from '@/components/Devices/AirQuality'
import Events from '@/components/Devices/Events'
import HumedadTierra from '@/components/Devices/HumedadTierra'
import TemperatureHumidity from '@/components/Devices/TemperatureHumidity'

// Mixins
import DeviceMixin from '@/mixins/DeviceMixin'
import HelperMixin from '@/mixins/HelperMixin'

// Services
import DeviceService from '@/services/DeviceService'
export default {
    components: {
        Header,
        FlowWater,
        Sonometer,
        AirQuality,
        Events,
        HumedadTierra,
        TemperatureHumidity,
        THTable,
        SonometerTable,
        Loading,
        VueTimepicker
    },
    mixins: [
        DeviceMixin,
        DeviceService,
        HelperMixin
    ],
    data(){
        return{

            modalScheduleReport: false,
            today: moment().format("YYYY-MM-DD"),
            modalTimePicker: false,
            modalDatePicker: false,
            modalCheckbox: false,
            searchAlerts: '',
            valorFiltroAlerts: '',

            rangoFechasAlerts: [],

            headerDeviceAlerts: [
                //{ text: 'Imei', align: 'start', sortable:true, value: 'imei', width: '15%'},
                { text: 'Device Name', align: 'start', sortable:true, value: 'name', width: '20%'},
                { text: 'Date', align: 'start', sortable: true, value: 'fechaReplica', width: '15%'},
                { text: 'Message', align: 'start', sortable: true ,value: 'parametros', width: '40%' },
                { text: 'Emails', align: 'start', sortable: true, value: 'correosAlertas', width: '15%'},
                { text: 'Email Send', align: 'center', sortable: true, value: 'send', width: '10%'}

            ],

            dataDeviceAlerts: [
                {
                    imei: '0004a30b00eb774a',
                    fechaReplica: '2021-08-22 10:30:00',
                    parametros: "Aviso 1"
                },
                {
                    imei: '0004a30b00eb774a',
                    fechaReplica: '2021-08-22 10:40:00',
                    parametros: "Aviso 2"
                },
                {
                    imei: '0004a30b00eb774a',
                    fechaReplica: '2021-08-22 10:50:00',
                    parametros: "Aviso 3"
                },
                {
                    imei: '0004a30b00eb774a',
                    fechaReplica: '2021-08-23 10:20:00',
                    parametros: "Aviso 4"
                },
                {
                    imei: '0004a30b00eb774a',
                    fechaReplica: '2021-08-23 10:20:00',
                    parametros: "Aviso 5"
                },
                {
                    imei: '0004a30b00eb774a',
                    fechaReplica: '2021-08-23 10:20:00',
                    parametros: "Aviso 6"
                }
            ],
        }
    },

    computed:{
        formatDate(){
            var arreglotemporal = []
            var fechaPart1
            var fechaPart2
            var fechaPart1String
            var fechaPart2String
            if(this.rangoFechasAlerts.length == 2){
                console.log("BANDERA RANGO");

                fechaPart1 = new Date(this.rangoFechasAlerts[0]);
                fechaPart2 = new Date(this.rangoFechasAlerts[1])

                fechaPart1String = moment(fechaPart1).add(1,'days').format('MMMM/DD/YYYY')
                fechaPart2String = moment(fechaPart2).add(1,'days').format('MMMM/DD/YYYY')

                arreglotemporal.push(fechaPart1String);
                arreglotemporal.push(fechaPart2String);

                return arreglotemporal.join(' ~ ');
            }
        }
    },

    mounted(){
        if(localStorage.idUser == null || localStorage.idUser == '' || localStorage.getItem("idUser") === null){
            this.$router.push({path: '/'});
        }else{
            if (localStorage.getItem("sessionTime") === null) {
                this.$router.push({path: '/'});
            }else{
                var fechaSesion = localStorage.getItem("sessionTime");
                let time = moment().diff( fechaSesion, 'minutes');
                if(time > 360){
                    this.$router.push({path: '/'});
                }else if( time <= 360){
                    
                }
            }
        }
        
        localStorage.platNotification = ''
        this.campanaFlag = ''
        
        this.itemsNavigation.splice(0);
        const paramsAlerts = {
                idUser: localStorage.idUser,
                name: localStorage.user_p 
            }; 
        this.getUserAlerts2(paramsAlerts);
        this.mountedAlertMixin();
    },

    updated(){

    },

    methods:{
        showTipoSensor(idSensor){
            console.log("BANDERA ID SENSOR");
            console.log(this.valorFiltroAlerts);
        },

        refreshAlerts(){
            this.valorFiltroAlerts = '';
            this.campanaFlag = ''
            localStorage.platNotification = ''
            this.rangoFechasAlerts = [];
            const paramsAlerts = {
                idUser: localStorage.idUser,
                name: localStorage.user_p 
            }; 
            this.getUserAlerts2(paramsAlerts);
        },

        consultarAlertas(){
            var fechaFromv = new Date(this.rangoFechasAlerts[0]);
            var fechaTov = new Date(this.rangoFechasAlerts[1]);
            var todayDate = new Date();
            var diff_time_today = todayDate.getTime() - fechaFromv.getTime();
            var diff_days_today = diff_time_today / (1000 * 3600 * 24);

            var diff_time = fechaTov.getTime() - fechaFromv.getTime()
            var diff_days = diff_time / (1000 * 3600 * 24);

            if(this.rangoFechasAlerts.length < 2){
                    Swal.fire({
                        title: '¡Missing Range!',
                        icon: 'warning',
                        text: 'Please choose a date range (two dates)',
                    });
                }else{
                    if(Date.parse(this.rangoFechasAlerts[0]) > Date.parse(this.rangoFechasAlerts[1])){
                        console.log("FECHA 1 ES MAYOR A FECHA 2");
                        Swal.fire({
                            title: '¡Range Error!',
                            icon: 'warning',
                            text: 'First date greater than the second date',
                        });
                    }else{
                        if(diff_days >= 90){
                            Swal.fire({
                                title: '¡Range Greater than 3 Months!',
                                icon: 'warning',
                                text: 'Please check with your system administrator for querys longer than 3 months',
                            });
                        }else{
                            if(diff_days_today >= 90){
                                Swal.fire({
                                    title: '¡Consultation is more than 3 months ago!',
                                    icon: 'warning',
                                    text: 'Please check with your system administrator for querys longer than 3 months',
                                });
                            }else{
                                //console.log("BANDERA CONSULTA DE ALERTAS");
                                //console.log(this.rangoFechasAlerts);
                                var fromDateTemporal = this.rangoFechasAlerts[0];
                                var toDateTemporal = this.rangoFechasAlerts[1];
                                var fromDateFinal = fromDateTemporal + ' 00:00:00';
                                var toDateFinal = toDateTemporal + ' 23:59:59';
                                var valorFiltro = ''
                                var valorFiltro = this.valorFiltroAlerts;

                                const paramsAlertsHistorical = {
                                    idUser: localStorage.idUser,
                                    name: localStorage.user_p,
                                    fromDate: fromDateFinal,
                                    toDate: toDateFinal,
                                    idSensorFiltro: valorFiltro
                                }; 
                                this.getUserAlertHistorical(paramsAlertsHistorical);
                            }
                        }
                    }
                }


        }

    }
}
</script>
<style>
.height-100{
    height: 100% !important;
}
.showBorders{
    border: 1px solid rgba(0, 0, 0, 0.12) ;
}
.no-hover-action:hover{
    background-color: white !important;
}

.bodyAlerts{
    margin-bottom: 7%;
}

.footerFondo{
    position: absolute;
    bottom: 0;
    width: 100%;

}
</style>