<template>
    <div class="height-100">
        <!--<loading :active="true" :is-full-page="true" :color="'#03c9d9'"/>-->
        <v-app id="loginface">
            <v-img src="~@/assets/bg-1.jpg">
                <v-row class="pb-5"></v-row>
                <v-row class="pt-6" align="center" justify="center">
                    <v-col lg="4" sm="12" class="pt-6">
                        <v-card class="elevation-12 justify-center ma-5">
                            <v-toolbar dark color="primary">
                                <v-spacer></v-spacer>
                                <v-toolbar-title
                                    ><strong>LOGIN</strong></v-toolbar-title
                                >
                                <v-progress-circular
                                    indeterminate
                                    color="white"
                                    class="my-2 ml-3"
                                    v-if="loadingLogin"
                                ></v-progress-circular>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                            <v-card-title class="text-h5 justify-center">
                                <font-awesome-icon
                                    icon="lock"
                                    size="3x"
                                    :style="{ color: '#1976d2' }"
                                ></font-awesome-icon>
                            </v-card-title>
                            <form
                                @submit.prevent="submit('login')"
                                data-vv-scope="login"
                            >
                                <v-card-text class="">
                                    <div class="px-4">
                                        <v-text-field
                                            v-model="authenticationData.user"
                                            name="user"
                                            label="User"
                                            type="text"
                                            v-validate="'required'"
                                            :class="{
                                                'form-control': true,
                                                'is-invalid': errors.has(
                                                    'authenticationData.user'
                                                ),
                                            }"
                                            :rules="[rules.required]"
                                        ></v-text-field>
                                        <span
                                            v-show="
                                                errors.has(
                                                    'authenticationData.user'
                                                )
                                            "
                                            class="invalid-feedback"
                                            >{{
                                                errors.first(
                                                    "authenticationData.user"
                                                )
                                            }}</span
                                        >
                                    </div>
                                    <div class="px-4">
                                        <v-text-field
                                            v-model="
                                                authenticationData.password
                                            "
                                            :append-icon="
                                                show2
                                                    ? 'mdi-eye'
                                                    : 'mdi-eye-off'
                                            "
                                            name="password"
                                            label="Password"
                                            :type="show2 ? 'text' : 'password'"
                                            @click:append="show2 = !show2"
                                            v-validate="'required'"
                                            :class="{
                                                'form-control': true,
                                                'is-invalid': errors.has(
                                                    'authenticationData.password'
                                                ),
                                            }"
                                            :rules="[rules.required]"
                                        ></v-text-field>
                                        <span
                                            v-show="
                                                errors.has(
                                                    'authenticationData.password'
                                                )
                                            "
                                            class="invalid-feedback"
                                            >{{
                                                errors.first(
                                                    "authenticationData.password"
                                                )
                                            }}</span
                                        >
                                    </div>
                                    <div class="px-4" v-if="showMsgAuth">
                                        <span class="red--text">{{
                                            msgAuth
                                        }}</span>
                                    </div>
                                    <div class="px-4">
                                        <v-checkbox
                                            v-model="
                                                authenticationData.rememberme
                                            "
                                            label="Remember Credentials"
                                        ></v-checkbox>
                                    </div>
                                    <!--<div class="px-4">
                                        <v-btn color="primary" type="submit">Login</v-btn> 
                                    </div>-->
                                </v-card-text>
                                <v-card-actions class="pa-4">
                                    <v-spacer></v-spacer>
                                    <!--<v-btn color="primary" to="/dashboard">Login</v-btn>-->
                                    <!--<v-btn color="primary" @click="signInTest()">Login</v-btn>-->
                                    <v-btn color="primary" type="submit"
                                        >Sign In</v-btn
                                    >
                                </v-card-actions>
                            </form>
                            <!--<form @submit.prevent="submit2()">
                                    <div class="px-4">
                                        <v-text-field v-model="authenticationData.user" name="user" label="User" type="text"></v-text-field>
                                    </div>
                                    <div class="px-4">
                                        <v-text-field v-model="authenticationData.password" name="password" label="Password" type="password"></v-text-field>
                                    </div>
                                    <div class="px-4">
                                        <v-checkbox v-model="checkboxRememberme" label="Recordar Credenciales"></v-checkbox>
                                    </div>  
                                    <v-btn color="success" type="submit">Test Submit</v-btn>
                                </form>-->
                        </v-card>
                    </v-col>
                </v-row>
            </v-img>
        </v-app>
    </div>
</template>

<script>
import moment from "moment";
import Loading from "vue-loading-overlay";
import Swal from "sweetalert2";
import Vue from "vue";
import VeeValidate from "vee-validate";
import { mapGetters, mapActions } from "vuex";

// Mixins
import HelperMixin from "@/mixins/HelperMixin";
import DeviceMixin from '@/mixins/DeviceMixin';
// Services
import DeviceService from "@/services/DeviceService";

Vue.use(VeeValidate, {
    fieldsBagName: "formFields", // fix issue with b-table
});

export default {
    name: "Login",
    components: {
        Loading,
    },
    props: {
        source: String,
    },
    mixins: [DeviceService, HelperMixin, DeviceMixin],
    data() {
        return {
            authenticationData: {
                user: "",
                password: "",
                rememberme: false,
                isAdmin: false,
            },

            msgAuth: "test",

            showMsgAuth: false,

            checkboxRememberme: false,

            show2: false,

            rules: {
                required: (value) => !!value || "Required.",
                counter: (value) => value.length <= 20 || "Max 100 characters",
                email: (value) => {
                    const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return (
                        pattern.test(value) ||
                        "Invalid e-mail (format = test@example.com)."
                    );
                },
            },

            loadingLogin: false,

            actualDateRange: [this.restDays(this.getActualDate(),0),this.getActualDate()]
        };
    },

    computed: {
        ...mapGetters({
            mensaje: "getMensaje",
            user_now: "getUser",
            getToken: "getToken",
            mensaje_user: "getMensajeUser",
        }),
    },

    mounted() {
        localStorage.removeItem("vuex");
        if (Object.values(this.$route.query).length == 2) {
            this.authenticationData.user = this.$route.query.user;
            this.authenticationData.password = this.$route.query.pwd;
            this.authenticationData.isAdmin = true;
            this.signOut().then(() => {
                this.submit("login");
            })
        } else {
            if (localStorage.session_p == 0) {
                if (localStorage.check_p == 1) {
                    this.authenticationData.rememberme = true;
                    if (localStorage.user_p && localStorage.pw_p) {
                        this.authenticationData.user = localStorage.user_p;
                        this.authenticationData.password = localStorage.pw_p;
                    }
                }
            } else {
                if (
                    localStorage.idUser == null ||
                    localStorage.idUser == "" ||
                    localStorage.getItem("idUser") === null
                ) {
                    this.$router.push({ path: "/" });
                } else {
                    if (localStorage.getItem("sessionTime") === null) {
                        this.$router.push({ path: "/" });
                    } else {
                        var fechaSesion = localStorage.getItem("sessionTime");
                        let time = moment().diff(fechaSesion, "minutes");
                        if (time <= 360) {
                            this.$router.push({ path: `/dashboard/${this.actualDateRange[0]}+${this.actualDateRange[1]}/undefined` });
                        } else if (time > 360) {
                            this.$router.push({ path: "/" });
                        }
                    }
                }
            }
        }
    },
    methods: {
        ...mapActions(["signIn", "signOut", "attemp"]),

        signInTest() {
            this.$router.push({ path: `/dashboard/${this.actualDateRange[0]}+${this.actualDateRange[1]}/undefined` });
        },

        submit(scope) {
            this.loadingLogin = true;
            this.$validator.validateAll(scope).then((result) => {
                if (result || this.authenticationData.isAdmin) {
                    this.showMsgAuth = false;
                    console.log("Paso");
                    this.signIn(this.authenticationData).then((response) => {
                        if (response == 200) {
                            if (this.authenticationData.rememberme == true) {
                                localStorage.user_p =
                                    this.authenticationData.user;
                                localStorage.pw_p =
                                    this.authenticationData.password;
                                localStorage.check_p = 1;
                                localStorage.session_p = 1;
                            } else {
                                localStorage.clear();
                            }

                            localStorage.idUser = this.user_now.idUser;
                            localStorage.user_p = this.user_now.user;
                            localStorage.token_p = this.getToken;

                            //console.log("BANDERA TOKEN GUARDADO");
                            //console.log(localStorage.token_p);

                            var fechaHoy = moment().format(
                                "YYYY-MM-DD HH:mm:ss"
                            );

                            if (localStorage.getItem("sessionTime") === null) {
                                localStorage.setItem("sessionTime", fechaHoy);
                            } else {
                                localStorage.sessionTime = fechaHoy;
                            }

                            Swal.fire({
                                title:
                                    "¡Welcome " +
                                    this.authenticationData.user +
                                    "!",
                                icon: "success",
                                timer: 2000,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });

                            this.$router.push({ path: `/dashboard/${this.actualDateRange[0]}+${this.actualDateRange[1]}/undefined` });
                        } else if (response == 1) {
                            Swal.fire({
                                title: "User disabled",
                                icon: "warning",
                                text: this.mensaje_user,
                            });
                            this.loadingLogin = false;
                            //this.msgAuth = "Usuario deshabilitado";
                            //this.showMsgAuth = true;
                        } else if (response == 2) {
                            this.loadingLogin = false;
                            this.msgAuth = "Incorrect password";
                            this.showMsgAuth = true;
                        } else if (response == 3) {
                            this.loadingLogin = false;
                            this.msgAuth = "User without privileges";
                            this.showMsgAuth = true;
                        } else if (response == 4) {
                            this.loadingLogin = false;
                            this.msgAuth =
                                "Incorrect data, user does not exist";
                            this.showMsgAuth = true;
                        }
                    });
                    return;
                } else {
                    this.loadingLogin = false;
                    console.log("Correct them errors!");
                    this.msgAuth = "Please, fill both required fields";
                    this.showMsgAuth = true;
                }
            });
        },
        submit2() {
            console.log(this.authenticationData.user);
            console.log(this.authenticationData.password);
        },
    },
};
</script>

<style>
.height-100 {
    height: 100% !important;
}
.bg {
    background-image: url("~@/assets/bg-1.jpg");
    background-size: 100%, 100%;
}
</style>
