import moment from 'moment';
import DeviceService from "@/services/DeviceService";
import DeviceMixin from './DeviceMixin';

const DataDeviceMixin = {
    mixins: [DeviceService, DeviceMixin],
    data(){
        return {
        }
    },
    methods: {
        //Consulta de datos de dispositivo al mostrar datos iniciales al recargar intervalo
        getDataNormal(params){
            this.getDevicesUserBar();
            this.getDataDevice(params).then((result) => {
                var equipoData = result.data;
                this.selected.deviceSelected = equipoData[0]
                // this.selected.deviceSelected.datos=JSON.parse(result.data[0].datos)

                var idSensor = this.selected.deviceSelected.idSensor;
                var jsonConfiguracion = JSON.parse(this.selected.deviceSelected.configuracion);
                var jsonConfiguracionAlert = JSON.parse(this.selected.deviceSelected.alertConfig);
                
                //Carga la grafica para que con la fecha del dia de ayer y hoy
                // alert('Cambio la fecha')
                let actualDateRange = [this.restDays(this.getActualDate(),0),this.getActualDate()];
                // if(this.switchFlag==true && this.dateRangeReportSelected.length==0){
                    // alert('Se llama a getRango')
                    if(this.$route.params.date!=undefined){
                        let fechaURL=this.$route.params.date.split('+');
                        this.getRango([[fechaURL[0],fechaURL[1]]]);
                    }else{
                        this.getRango([[actualDateRange[0],actualDateRange[1]]]);
                    }

                    // let prueba = localStorage.getItem('Rango') || actualDateRange;
                    // let prueba2 = prueba.split(',')
                    // alert(prueba)
                // }

                //Al cancelar o cerrar formulario vuelve a consultar datos de configuracion

                if(idSensor == 1){
                    
                    this.energyConfig.KWhPrice = jsonConfiguracion.KWhPrice;
                    this.energyConfig.currency = jsonConfiguracion.currency
                    this.energyConfig.phases = jsonConfiguracion.phases;
                    this.energyConfig.voltage = jsonConfiguracion.voltage;
                
                }else if(idSensor == 2){
                    
                    this.sonometerLevel.lowLevel = jsonConfiguracion.lowLevel;
                    this.sonometerLevel.normalLevel =jsonConfiguracion.normalLevel
                    this.sonometerLevel.highLevel = jsonConfiguracion.highLevel
                    this.sonometerLevel.alerts = jsonConfiguracion.alerts;
                    this.sonometerLevel.mail = jsonConfiguracion.mail;
                    this.sonometerAlertConfig.alertEnable = jsonConfiguracionAlert.alertEnable;
                    this.sonometerAlertConfig.emails = jsonConfiguracionAlert.emails;

                }else if(idSensor == 3){
                   
                    this.airQualityConfig.alerts = jsonConfiguracion.alerts;
                    this.airQualityConfig.mail = jsonConfiguracion.mail;
                    this.airQualityAlertConfig.alertEnable = jsonConfiguracionAlert.alertEnable;
                    this.airQualityAlertConfig.emails = jsonConfiguracionAlert.emails;
                
                }else if(idSensor == 7){
                    
                    this.gasLevel.lowLevel = jsonConfiguracion.lowLevel;
                    this.gasLevel.normalLevel =jsonConfiguracion.normalLevel
                    this.gasLevel.highLevel = jsonConfiguracion.highLevel
                    this.gasLevel.alerts = jsonConfiguracion.alerts;
                    this.gasLevel.mail = jsonConfiguracion.mail;
                    this.gasLevelAlertConfig.alertEnable = jsonConfiguracionAlert.alertEnable;
                    this.gasLevelAlertConfig.emails = jsonConfiguracionAlert.emails;
                
                }else if(idSensor == 8){
                    
                    this.eventsAlertsConfig.alertEnable = jsonConfiguracionAlert.alertEnable;
                    this.eventsAlertsConfig.emails = jsonConfiguracionAlert.emails;
                
                }else if(idSensor == 11){
                    
                    this.touchAlertConfig.alertEnable = jsonConfiguracionAlert.alertEnable;
                    this.touchAlertConfig.emails = jsonConfiguracionAlert.emails;
                
                }else if(idSensor == 30){
                    
                    this.piranometerAlertConfig.alertEnable = jsonConfiguracionAlert.alertEnable;
                    this.piranometerAlertConfig.emails = jsonConfiguracionAlert.emails;
                
                }else if(idSensor == 31 || idSensor == 29){
                    
                    this.temperatureAndHumidityAlertConfig.alertEnable = jsonConfiguracionAlert.alertEnable;
                    this.temperatureAndHumidityAlertConfig.emails = jsonConfiguracionAlert.emails;
                    this.temperatureAndHumidityAlertConfig.tempMin = jsonConfiguracionAlert.tempMin;
                    this.temperatureAndHumidityAlertConfig.tempMax = jsonConfiguracionAlert.tempMax;

                }else if(idSensor == 32){
                    this.estacionMeteorologicaAlertConfig.alertEnable = jsonConfiguracionAlert.alertEnable;
                    this.estacionMeteorologicaAlertConfig.emails = jsonConfiguracionAlert.emails;
                }else if(idSensor == 36){

                    this.phConfig.alerts = jsonConfiguracion.alerts;
                    this.phConfig.mail = jsonConfiguracion.mail;
                    this.phAlertConfig.alertEnable = jsonConfiguracionAlert.alertEnable;
                    this.phAlertConfig.emails = jsonConfiguracionAlert.emails;
                
                }else if(idSensor == 37){

                    this.ecConfig.alerts = jsonConfiguracion.alerts;
                    this.ecConfig.mail = jsonConfiguracion.mail;
                    this.ecAlertConfig.alertEnable = jsonConfiguracionAlert.alertEnable;
                    this.ecAlertConfig.emails = jsonConfiguracionAlert.emails;

                }else if(idSensor == 41){
                    this.flowWaterData.total_litter = this.selected.deviceSelected.total_litter;
                    this.flowWaterAlertConfig.alertEnable = this.selected.deviceSelected.alertEnable;
                    this.flowWaterAlertConfig.emails = this.selected.deviceSelected.emails;
                }else if(idSensor == 48){

                    console.log("aqui se ponen las configuraciones de los activos.")

                    console.log(this.selected.deviceSelected)
                }

                if(this.selected.deviceSelected.zona == "Central"){
                    this.selected.idTimeZone = 1;
                }else if (this.selected.deviceSelected.zona == "Pacifico"){
                    this.selected.idTimeZone = 2;
                }else if (this.selected.deviceSelected.zona == "Noroeste"){
                    this.selected.idTimeZone = 3;
                }else if (this.selected.deviceSelected.zona == "Sin Zona Horaria"){
                    this.selected.idTimeZone = 4;
                }else{
                    this.selected.idTimeZone = '';
                }

            }).catch((err) => {
                try {
                    if(err.response?.status == 401){
                        localStorage.idUser = ''; 
                        localStorage.session_p = 0;
                        localStorage.token_p = '';
                        this.$router.push({path: '/'});
                        console.log("ERROR DE AUTENTICACION");
                    }                    
                } catch (error) {
                    console.log("ERROR "+err); 
                }
               
            });
        },
        //Carga de datos de grafica de sensorposition del dia de hoy
        getDataSensorPosition(dispositivo){    
            this.dataSensorPositionDevices = [];
            this.db_getDataSensorPosition(dispositivo).then((result) => {
                    this.dataSensorPositionDevices = result.data[1];
                    this.totalReceivedData = result.data[0];
                    var idsensor = dispositivo.deviceSelected.idSensor;
                    // console.log(this.dataSensorPositionDevices);
                    if(idsensor == 1){
                        var i = 0
                        var arrayTemp = [];
                        var arrayKwh1 = [];
                        var arrayKwh2 = [];
                        var arrayKwh3 = [];
                        var arrayAmp1 = [];
                        var arrayAmp2 = [];
                        var arrayAmp3 = [];
                        var arrayFechaGps = [];
                        
                        var contT = 0;
                        var contKwh1 = 0;
                        var contKwh2 = 0;
                        var contKwh3 = 0;
                        var contAmp1 = 0;
                        var contAmp2 = 0;
                        var contAmp3 = 0;
                        var contFecha = 0;
                        for(i=0; i < this.totalReceivedData; i++){
                            var dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            var fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            contT = arrayTemp.push(dataJson.temp);
                            contKwh1 = arrayKwh1.push(dataJson.kwh1);
                            contKwh2 = arrayKwh2.push(dataJson.kwh2);
                            contKwh3 = arrayKwh3.push(dataJson.kwh3);
                            contAmp1 = arrayAmp1.push(dataJson.amp1);
                            contAmp2 = arrayAmp2.push(dataJson.amp2);
                            contAmp3 = arrayAmp3.push(dataJson.amp3);
                            contFecha = arrayFechaGps.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));
                        }
                        this.arrayDataTemp = arrayTemp;
                        this.arrayDataKwh1 = arrayKwh1;
                        this.arrayDataKwh2 = arrayKwh2;
                        this.arrayDataKwh3 = arrayKwh3;
                        this.arrayDataAmp1 = arrayAmp1;
                        this.arrayDataAmp2 = arrayAmp2;
                        this.arrayDataAmp3 = arrayAmp3;
                        this.arrayDataFechaGps = arrayFechaGps;
                        var idChart = "energy-highchart";
                        this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 2){ // SONOMETRO
                        var i = 0
                        var arrayDesibeles = [];
                        var arrayFechaGps = [];
                        var contDes = 0;
                        var contFecha = 0;
                        for(i=0; i < this.totalReceivedData; i++){
                            var dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            var fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            contDes = arrayDesibeles.push(dataJson.sonometer);
                            contFecha = arrayFechaGps.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));
                        }
                        this.arrayDataSonometer = arrayDesibeles;
                        this.arrayDataFechaGps = arrayFechaGps;
                        var idChart = "sonometer-highchart";
                        this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 3){ //AIR QUALITY
                        var i = 0
                        var arrayQuality = [];
                        var arrayTvoc = [];
                        var arrayFechaGps = [];
                        var contA = 0;
                        var contT = 0;
                        var contFecha = 0;
                        for(i=0; i < this.totalReceivedData;i++){
                            var dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            var fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            contA = arrayQuality.push(dataJson.quality);
                            contT = arrayTvoc.push(dataJson.tvoc);
                            contFecha = arrayFechaGps.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));
                        }
                        this.arrayDataAirQuality = arrayQuality;
                        this.arrayDataAirTvoc = arrayTvoc;

                        console.log("DATOS CONSULTADO AIR QUALITY 1");
                        console.log(this.arrayDataAirQuality);
                        console.log(this.arrayDataAirTvoc);

                        this.arrayDataFechaGps = arrayFechaGps;
                        var idChart = "airquality-highchart";
                        this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 7){ // GAS LEVEL
                        var i = 0
                        var arrayLevel = [];
                        var arrayFechaGps = [];
                        var contLev = 0;
                        var contFecha = 0;
                        for(i=0; i < this.totalReceivedData; i++){
                            var dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            var fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            contLev = arrayLevel.push(dataJson.level);
                            contFecha = arrayFechaGps.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));
                        }
                        this.arrayDataGasLevel = arrayLevel;
                        this.arrayDataFechaGps = arrayFechaGps;
                        var idChart = "gaslevel-highchart";
                        this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 8){ //AIR QUALITY
                        var i = 0
                        var arrayEv1 = [];
                        var arrayEv2 = [];
                        var arrayEv3 = [];
                        var arrayEv4 = [];
                        var arrayFechaGps = [];
                        var contEv1 = 0;
                        var contEv2 = 0;
                        var contEv3 = 0;
                        var contEv4 = 0;
                        var contFecha = 0;
                        for(i=0; i < this.totalReceivedData;i++){
                            var dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            var fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            contEv1 = arrayEv1.push(dataJson.ev1);
                            contEv2 = arrayEv2.push(dataJson.ev2);
                            contEv3 = arrayEv3.push(dataJson.ev3);
                            contEv4 = arrayEv4.push(dataJson.ev4);
                            contFecha = arrayFechaGps.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));
                        }
                        this.arrayDataEvento1 = arrayEv1;
                        this.arrayDataEvento2 = arrayEv2;
                        this.arrayDataEvento3 = arrayEv3;
                        this.arrayDataEvento4 = arrayEv4;
                        this.arrayDataFechaGps = arrayFechaGps;
                        var idChart = "events-highchart";
                        this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 11){ //TOUCH TAG
                        var i = 0
                        var arraytemp = [];
                        var arrayPitch = [];
                        var arrayRoll = [];
                        var arrayFechaGps = [];
                        var contT = 0;
                        var contP = 0;
                        var contR = 0;
                        var contFecha = 0;
                        for(i=0; i < this.totalReceivedData;i++){
                            var dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            var fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            contT = arraytemp.push(dataJson.temperature);
                            contP = arrayPitch.push(dataJson.pitch);
                            contR = arrayRoll.push(dataJson.roll);
                            contFecha = arrayFechaGps.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));
                        }
                        this.arrayDataTemperature = arraytemp;
                        this.arrayDataPitchTG = arrayPitch;
                        this.arrayDataRollTG = arrayRoll;
                        this.arrayDataFechaGps = arrayFechaGps;
                        var idChart = "touch-tag-highchart";
                        this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 29){ //HUMEDAD EN TIERRA
                        var i = 0
                        var arraytemp = [];
                        var arrayhumidity = [];
                        var arrayFechaGps = [];
                        var contT = 0;
                        var contH = 0;
                        var contFecha = 0;
                        for(i=0; i < this.totalReceivedData;i++){
                            var dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            var fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            contT = arraytemp.push(dataJson.temperature);
                            contH = arrayhumidity.push(dataJson.humidity);
                            contFecha = arrayFechaGps.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));
                        }
                        this.arrayDataTemperature = arraytemp;
                        this.arrayDataHumidity = arrayhumidity;
                        this.arrayDataFechaGps = arrayFechaGps;
                        var idChart = "temperature-tierra-highchart";
                        this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 30){ //PIRANOMETRO
                        var i = 0
                        var arraytemp = [];
                        var arraywattmetro = [];
                        var arrayFechaGps = [];
                        var contT = 0;
                        var contW = 0;
                        var contFecha = 0;
                        for(i=0; i < this.totalReceivedData;i++){
                            var dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            var fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            contT = arraytemp.push(dataJson.temperature);
                            contW = arraywattmetro.push(dataJson.wattmetro);
                            contFecha = arrayFechaGps.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));
                        }
                        this.arrayDataTemperaturaPir = arraytemp;
                        this.arrayDataWattmetroPir = arraywattmetro;
                        this.arrayDataFechaGps = arrayFechaGps;
                        var idChart = "piranometer-highchart";
                        this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 31){ //TEMPERATURA  Y HUMEDAD Y TIERRA
                        var i = 0
                        var arraytemp = [];
                        var arrayhumidity = [];
                        var arrayFechaGps = [];
                        var contT = 0;
                        var contH = 0;
                        var contFecha = 0;
                        for(i=0; i < this.totalReceivedData;i++){
                            var dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            var fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            contT = arraytemp.push(dataJson.temperature);
                            contH = arrayhumidity.push(dataJson.humidity);
                            contFecha = arrayFechaGps.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));
                        }
                        this.arrayDataTemperature = arraytemp;
                        this.arrayDataHumidity = arrayhumidity;

                        console.log("temperature ............");
                        console.log("temperature ............");
                        console.log(arrayDataTemperature)
                        console.log("Humidity ............");
                        console.log(arrayDataHumidity)

                        this.arrayDataFechaGps = arrayFechaGps;
                        var idChart = "temperature-humidity-highchart";
                        this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 32){ //ESTACION METEORLOGOICA
                        var i = 0
                        var arraytemp = [];
                        var arraywindspeed = [];
                        var arrayrainvolume = [];
                        var arraydirectionwind = [];
                        var arrayrelativehumidity = [];
                        var arraypascalpressure = [];
                        var arrayFechaGps = [];
                        var contT = 0;
                        var contN = 0;
                        var contFecha = 0;
                        for(i=0; i < this.totalReceivedData;i++){
                            var dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            var fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            arraytemp.push(dataJson.temperature);
                            arraywindspeed.push(dataJson.wind_speed);
                            arrayrainvolume.push(dataJson.rain_volume);
                            arraydirectionwind.push(dataJson.direction_wind);
                            arrayrelativehumidity.push(dataJson.relative_humidity);
                            arraypascalpressure.push(dataJson.pascal_pressure);
                            arrayFechaGps.push(moment(fechaTemp, 'YYYY-MM-DD HH:mm:ss').format("DD MMMM YYYY HH:mm:ss"));
                        }
                        this.arrayDataWindSpeedEM = arraywindspeed;
                        this.arrayDataRainVolumeEM = arrayrainvolume;
                        this.arrayDataTemperaturaEM = arraytemp;
                        this.arrayDataDirectionWindEM = arraydirectionwind;
                        this.arrayDataRelativeHumidityEM = arrayrelativehumidity;
                        this.arrayDataPascalPressureEM = arraypascalpressure;
                        this.arrayDataFechaGps = arrayFechaGps;

                        var idChart = "estacion-meteorologica-highchart";
                        this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 36){ //PH
                        var i = 0
                        var arraytemp = [];
                        var arraynivel = [];
                        var arrayFechaGps = [];
                        var contT = 0;
                        var contN = 0;
                        var contFecha = 0;
                        for(i=0; i < this.totalReceivedData;i++){
                            var dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            var fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            contT = arraytemp.push(dataJson.Temperatura);
                            contN = arraynivel.push(dataJson.nivel);
                            contFecha = arrayFechaGps.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));
                        }
                        this.arrayDataTemperaturaPh = arraytemp;
                        this.arrayDataNivel = arraynivel;
                        this.arrayDataFechaGps = arrayFechaGps;
                        var idChart = "ph-highchart";
                        this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 37){ //EC
                        var i = 0
                        var arraytemp = [];
                        var arrayuScm = [];
                        var arrayFechaGps = [];
                        var contT = 0;
                        var contuS = 0;
                        var contFecha = 0;
                        for(i=0; i < this.totalReceivedData;i++){
                            var dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            var fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            contT = arraytemp.push(dataJson.Temperatura);
                            contuS = arrayuScm.push(dataJson.uScm);
                            //contFecha = arrayFechaGps.push(fechaTemp);
                            contFecha = arrayFechaGps.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));
                        }
                        this.arrayDataTemperaturaEc = arraytemp;
                        this.arrayDatauScm = arrayuScm;
                        this.arrayDataFechaGps = arrayFechaGps;
                        var idChart = "ec-highchart";
                        this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 41){ //FLOW WATER
                        var i = 0
                        var arrayltsMinute = [];
                        var arrayltsTotal = [];
                        var arrayFechaGps = [];

                        var contlm = 0;
                        var contlt = 0;
                        var contFecha = 0;

                        for(i=0; i < this.totalReceivedData;i++){
                            var dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            var fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            contlm = arrayltsMinute.push(dataJson.lts_minute);
                            contlt = arrayltsTotal.push(dataJson.lts_total);
                            contFecha = arrayFechaGps.push(moment(fechaTemp).format("DD MMMM YYYY HH:mm:ss"));
                        }

                        this.arrayDataLtsMinuteWF = arrayltsMinute;
                        this.arrayDataLtsTotalWF = arrayltsTotal;
                        this.arrayDataFechaGps = arrayFechaGps;
                        var idChart = "flowwater-highchart";
                        this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 47){ // DCMAX1500
                        console.log("debe iniciar ahora mismo");
                        let arrCanal1 = [];
                        let arrCanal2 = [];
                        let arrCanal3 = [];
                        let arrCanal4 = [];
                        let arrPowerNow = [];
                        let arrFechas = [];

                        for(let i=0; i < totalRegistros;i++){
                            let dataJson = (JSON.parse(this.dataSensorPositionDevices[i].datos));
                            let fechaTemp = this.dataSensorPositionDevices[i].fechaGps;
                            arrCanal1.push(this.numFormat(dataJson.p1));
                            arrCanal2.push(this.numFormat(dataJson.p2));
                            arrCanal3.push(this.numFormat(dataJson.p3));
                            arrCanal4.push(this.numFormat(dataJson.p4));

                            let powerNow = parseFloat(dataJson.p1) + parseFloat(dataJson.p2) + parseFloat(dataJson.p3) + parseFloat(dataJson.p4);
                            arrPowerNow.push(this.numFormat(powerNow));
                            arrFechas.push(fechaTemp);
                        }
                        this.arrChannels.channel1 = arrCanal1;
                        this.arrChannels.channel2 = arrCanal2;
                        this.arrChannels.channel3 = arrCanal3;
                        this.arrChannels.channel4 = arrCanal4;
                        this.arrChannels.power = arrPowerNow;
                        this.arrayDataFechaGps = arrayFechaGps;
                        let idChart = "dcmax-highchart";
                        this.generateGraph(idChart,idsensor);
                    }else if(idsensor == 48){ // Bidireccional
                    
                    }
            }).catch((err) => {
                if(err){
                    this.loadingFlagHistorico = false
                    this.loadingFlag = false;
                    this.loadingFlagChartReport = false;
                }
                if(err.response?.status == 401){
                    localStorage.idUser = ''; 
                    localStorage.session_p = 0;
                    localStorage.token_p = '';
                    this.$router.push({path: '/'});
                    console.log("ERROR DE AUTENTICACION");
                }
            });  
        },

        //Asignacion de variables al seleccionar un equipo en dashboard y recargarse el intervalo de 3 minutos
        assignSelectedInicio(){
            
            //console.log("Bienvenido Assign 2");
            this.loadingFlag = true;
            this.loadingFlagHistorico = true;
            let idSensor = this.selected.deviceSelected.idSensor;
            let fechaGps = this.selected.deviceSelected.fechaGps;
            this.colorText = this.lastUpdate(fechaGps);
            this.selected.imei = this.selected.deviceSelected.imei;
            // console.log('PARA el imei');
            // console.log(this.$route.params.name);
            let imeiLocalStorage = localStorage.getItem('Imei') || 0;
            if(imeiLocalStorage!=0){
                this.selected.imei = imeiLocalStorage;
            }

            if(this.selected.deviceSelected.zona == "Central"){
                this.selected.idTimeZone = 1;
            }else if (this.selected.deviceSelected.zona == "Pacifico"){
                this.selected.idTimeZone = 2;
            }else if (this.selected.deviceSelected.zona == "Noroeste"){
                this.selected.idTimeZone = 3;
            }else if (this.selected.deviceSelected.zona == "Sin Zona Horaria"){
                this.selected.idTimeZone = 4;
            }else{
                this.selected.idTimeZone = '';
            }

            if(this.selected.deviceSelected.posicion == null || this.selected.deviceSelected.posicion == "" || this.selected.deviceSelected.posicion == ''){
                this.posicion.latitud = 0.0;
                this.posicion.longitud = 0.0;    
            }else{
               
                var jsonPosicion= {lat:"0",long:"-10218110"}
                if (this.selected.deviceSelected.hasOwnProperty('posicion')) {
                    jsonPosicion = JSON.parse(this.selected.deviceSelected.posicion);
                }


                if(jsonPosicion.hasOwnProperty('lat') && jsonPosicion.hasOwnProperty('long')){
                    this.posicion.latitud = this.calcularCoordenada(jsonPosicion.lat.toString());
                    this.posicion.longitud = this.calcularCoordenada(jsonPosicion.long.toString());
                    this.posicion.havePosition = "SI"
                }else{
                    this.posicion.latitud = 0.0;
                    this.posicion.longitud = 0.0;
                }
              
            }
            if(idSensor == 1){ //ENERGY
                this.assignEnergyVariables(this.selected.deviceSelected);
                if(this.switchFlag == true){
                    this.dateRangeReportSelected.splice(0);
                    this.getDataSensorPosition(this.selected);
                    console.log("ACTUALIZADO");
                }else{
                    this.loadingFlagHistorico = false
                    this.loadingFlag = false;
                    console.log("NO ACTUALIZADO");
                }
            }else if(idSensor == 2){ //SONOMETRO
                var jsonConfiguracion = JSON.parse(this.selected.deviceSelected.configuracion);
                var jsonConfiguracionAlert = JSON.parse(this.selected.deviceSelected.alertConfig);
                var jsonDatos = JSON.parse(this.selected.deviceSelected.datos);
                this.sonometerLevel.lowLevel = jsonConfiguracion.lowLevel;
                this.sonometerLevel.normalLevel =jsonConfiguracion.normalLevel
                this.sonometerLevel.highLevel = jsonConfiguracion.highLevel
                this.sonometerLevel.alerts = jsonConfiguracion.alerts;
                this.sonometerLevel.mail = jsonConfiguracion.mail;
                this.sonometerDecibeles = jsonDatos.sonometer;

                if(jsonConfiguracionAlert.hasOwnProperty('alertEnable')){
                    this.sonometerAlertConfig.alertEnable = jsonConfiguracionAlert.alertEnable;
                }else{
                    this.sonometerAlertConfig.alertEnable = 'NO'
                }

                this.sonometerAlertConfig.emails = jsonConfiguracionAlert.hasOwnProperty('emails')? jsonConfiguracionAlert.emails: []

                if (typeof this.sonometerLevel.lowLevel == 'undefined') {
                    this.sonometerLevel.lowLevel = null;
                }
                if (typeof this.sonometerLevel.normalLevel == 'undefined') {
                    this.sonometerLevel.normalLevel = null;
                }
                if (typeof this.sonometerLevel.highLevel == 'undefined') {
                    this.sonometerLevel.highLevel = null;
                }
                if (typeof this.sonometerDecibeles == 'undefined') {
                    this.sonometerDecibeles = null;
                }
                if (typeof this.sonometerLevel.alerts == 'undefined') {
                    this.sonometerLevel.alerts = null;
                }
                if (typeof this.sonometerLevel.mail == 'undefined') {
                    this.sonometerLevel.mail = null;
                }
                if(this.switchFlag == true){
                    this.dateRangeReportSelected.splice(0);
                    this.getDataSensorPosition(this.selected);
                    console.log("ACTUALIZADO");
                }else{
                    this.loadingFlagHistorico = false
                    this.loadingFlag = false;
                    //this.loadingFlagChartReport = false;
                    console.log("NO ACTUALIZADO");
                }
            }else if(idSensor == 3){ //AIR QUALITY
                var jsonConfiguracion = JSON.parse(this.selected.deviceSelected.configuracion);
                var jsonConfiguracionAlert = JSON.parse(this.selected.deviceSelected.alertConfig);
                var jsonDatos = JSON.parse(this.selected.deviceSelected.datos);
                this.airQualityConfig.alerts = jsonConfiguracion.alerts;
                this.airQualityConfig.mail = jsonConfiguracion.mail;
                this.airQualityData.quality = jsonDatos.quality;
                this.airQualityData.tvoc = jsonDatos.tvoc;

                if(jsonConfiguracionAlert.hasOwnProperty('alertEnable')){
                    //console.log("HAY ALERT ENABLE");
                    this.airQualityAlertConfig.alertEnable = jsonConfiguracionAlert.alertEnable;
                }else{
                    //console.log("NO HAY ALERT ENABLE");
                    this.airQualityAlertConfig.alertEnable = 'NO'
                }

                if(jsonConfiguracionAlert.hasOwnProperty('emails')){
                    //console.log("HAY EMAILS");
                    this.airQualityAlertConfig.emails = jsonConfiguracionAlert.emails;
                }else{
                    //console.log("NO HAY EMAILS");
                    this.airQualityAlertConfig.emails = []
                }

                if (typeof this.airQualityData.quality == 'undefined') {
                    this.airQualityData.quality = null;
                }
                if (typeof this.airQualityData.tvoc == 'undefined') {
                    this.airQualityData.tvoc = null;
                }
                if (typeof this.airQualityConfig.alerts == 'undefined') {
                    this.airQualityConfig.alerts = null;
                }
                if (typeof this.airQualityConfig.mail == 'undefined') {
                    this.airQualityConfig.mail = null;
                }
                if(this.switchFlag == true){
                    this.dateRangeReportSelected.splice(0);
                    this.getDataSensorPosition(this.selected);
                    console.log("ACTUALIZADO");
                }else{
                    this.loadingFlagHistorico = false
                    this.loadingFlag = false;
                    //this.loadingFlagChartReport = false;
                    console.log("NO ACTUALIZADO");
                }
            }else if(idSensor == 7){ //GAS LEVEL
                var jsonConfiguracion = JSON.parse(this.selected.deviceSelected.configuracion);
                var jsonConfiguracionAlert = JSON.parse(this.selected.deviceSelected.alertConfig);
                var jsonDatos = JSON.parse(this.selected.deviceSelected.datos);

                this.gasLevel.lowLevel = jsonConfiguracion.lowLevel;
                this.gasLevel.normalLevel =jsonConfiguracion.normalLevel
                this.gasLevel.highLevel = jsonConfiguracion.highLevel
                this.gasLevel.alerts = jsonConfiguracion.alerts;
                this.gasLevel.mail = jsonConfiguracion.mail;
                this.gLevel = jsonDatos.level;

                if(jsonConfiguracionAlert.hasOwnProperty('alertEnable')){
                    //console.log("HAY ALERT ENABLE");
                    this.gasLevelAlertConfig.alertEnable = jsonConfiguracionAlert.alertEnable;
                }else{
                    //console.log("NO HAY ALERT ENABLE");
                    this.gasLevelAlertConfig.alertEnable = 'NO'
                }

                if(jsonConfiguracionAlert.hasOwnProperty('emails')){
                    //console.log("HAY EMAILS");
                    this.gasLevelAlertConfig.emails = jsonConfiguracionAlert.emails;
                }else{
                    //console.log("NO HAY EMAILS");
                    this.gasLevelAlertConfig.emails = []
                }

                if (typeof this.gasLevel.lowLevel == 'undefined') {
                    this.gasLevel.lowLevel = null;
                }
                if (typeof this.gasLevel.normalLevel == 'undefined') {
                    this.gasLevel.normalLevel = null;
                }
                if (typeof this.gasLevel.highLevel == 'undefined') {
                    this.gasLevel.highLevel = null;
                }
                if (typeof this.gLevel == 'undefined') {
                    this.gLevel = null;
                }
                if (typeof this.gasLevel.alerts == 'undefined') {
                    this.gasLevel.alerts = null;
                }
                if (typeof this.gasLevel.mail == 'undefined') {
                    this.gasLevel.mail = null;
                }
                if(this.switchFlag == true){
                    this.dateRangeReportSelected.splice(0);
                    this.getDataSensorPosition(this.selected);
                    console.log("ACTUALIZADO");
                }else{
                    this.loadingFlagHistorico = false
                    this.loadingFlag = false;
                    //this.loadingFlagChartReport = false;
                    console.log("NO ACTUALIZADO");
                }
            }else if(idSensor == 8){ //EVENTS
                var jsonConfiguracion = JSON.parse(this.selected.deviceSelected.configuracion);
                var jsonConfiguracionAlert = JSON.parse(this.selected.deviceSelected.alertConfig);
                var jsonDatos = JSON.parse(this.selected.deviceSelected.datos);
                var numeroVariables = Object.keys(jsonDatos).length
                this.eventsData.ev1 = jsonDatos.ev1;
                this.eventsData.ev2 = jsonDatos.ev2;
                this.eventsData.ev3 = jsonDatos.ev3;
                this.eventsData.ev4 = jsonDatos.ev4;

                if(jsonConfiguracionAlert.hasOwnProperty('alertEnable')){
                    //console.log("HAY ALERT ENABLE");
                    this.eventsAlertsConfig.alertEnable = jsonConfiguracionAlert.alertEnable;
                }else{
                    //console.log("NO HAY ALERT ENABLE");
                    this.eventsAlertsConfig.alertEnable = 'NO'
                }

                if(jsonConfiguracionAlert.hasOwnProperty('emails')){
                    //console.log("HAY EMAILS");
                    this.eventsAlertsConfig.emails = jsonConfiguracionAlert.emails;
                }else{
                    //console.log("NO HAY EMAILS");
                    this.eventsAlertsConfig.emails = []
                }

                if (typeof this.eventsData.ev1 == 'undefined') {
                    this.eventsData.ev1 = null;
                }
                if (typeof this.eventsData.ev2 == 'undefined') {
                    this.eventsData.ev2 = null;
                }
                if (typeof this.eventsData.ev3 == 'undefined') {
                    this.eventsData.ev3 = null;
                }
                if (typeof this.eventsData.ev4 == 'undefined') {
                    this.eventsData.ev4 = null;
                }
                if(this.switchFlag == true){
                    this.dateRangeReportSelected.splice(0);
                    this.getDataSensorPosition(this.selected);
                    console.log("ACTUALIZADO");
                }else{
                    this.loadingFlagHistorico = false
                    this.loadingFlag = false;
                    //this.loadingFlagChartReport = false;
                    console.log("NO ACTUALIZADO");
                }
            }else if(idSensor == 11){ //TOUCH TAG
                var jsonConfiguracion = JSON.parse(this.selected.deviceSelected.configuracion);
                var jsonConfiguracionAlert = JSON.parse(this.selected.deviceSelected.alertConfig);
                var jsonDatos = JSON.parse(this.selected.deviceSelected.datos);
                this.touchTagData.temperature = jsonDatos.temperature;
                this.touchTagData.pitch = jsonDatos.pitch;
                this.touchTagData.roll = jsonDatos.roll;

                if(jsonConfiguracionAlert.hasOwnProperty('alertEnable')){
                    //console.log("HAY ALERT ENABLE");
                    this.touchAlertConfig.alertEnable = jsonConfiguracionAlert.alertEnable;
                }else{
                    //console.log("NO HAY ALERT ENABLE");
                    this.touchAlertConfig.alertEnable = 'NO'
                }

                if(jsonConfiguracionAlert.hasOwnProperty('emails')){
                    //console.log("HAY EMAILS");
                    this.touchAlertConfig.emails = jsonConfiguracionAlert.emails;
                }else{
                    //console.log("NO HAY EMAILS");
                    this.touchAlertConfig.emails = []
                }

                if (typeof this.touchTagData.temperature == 'undefined') {
                    this.touchTagData.temperature = null;
                }
                if (typeof this.touchTagData.pitch == 'undefined') {
                    this.touchTagData.pitch = null;
                }
                if (typeof this.touchTagData.roll == 'undefined') {
                    this.touchTagData.roll = null;
                }
                if(this.switchFlag == true){
                    this.dateRangeReportSelected.splice(0);
                    this.getDataSensorPosition(this.selected);
                    console.log("ACTUALIZADO");
                }else{
                    this.loadingFlagHistorico = false
                    this.loadingFlag = false;
                    //this.loadingFlagChartReport = false;
                    console.log("NO ACTUALIZADO");
                }
            }else if(idSensor == 29){ //HUMEDAD EN TIERRA
                var jsonConfiguracion = JSON.parse(this.selected.deviceSelected.configuracion);
                var jsonConfiguracionAlert = JSON.parse(this.selected.deviceSelected.alertConfig);
                var jsonDatos = JSON.parse(this.selected.deviceSelected.datos);
                this.temperatureAndHumidityData.temperature = jsonDatos.temperature;
                this.temperatureAndHumidityData.humidity = jsonDatos.humidity;

                if(jsonConfiguracionAlert.hasOwnProperty('alertEnable')){
                    //console.log("HAY ALERT ENABLE");
                    this.temperatureAndHumidityAlertConfig.alertEnable = jsonConfiguracionAlert.alertEnable;
                }else{
                    //console.log("NO HAY ALERT ENABLE");
                    this.temperatureAndHumidityAlertConfig.alertEnable = 'NO'
                }

                if(jsonConfiguracionAlert.hasOwnProperty('emails')){
                    //console.log("HAY EMAILS");
                    this.temperatureAndHumidityAlertConfig.emails = jsonConfiguracionAlert.emails;
                }else{
                    //console.log("NO HAY EMAILS");
                    this.temperatureAndHumidityAlertConfig.emails = []
                }

                if (typeof this.temperatureAndHumidityData.temperature == 'undefined') {
                    this.temperatureAndHumidityData.temperature = null;
                }
                if (typeof this.temperatureAndHumidityData.humidity == 'undefined') {
                    this.temperatureAndHumidityData.humidity = null;
                }

                if(this.switchFlag == true){
                    this.dateRangeReportSelected.splice(0);
                    this.getDataSensorPosition(this.selected);
                    console.log("ACTUALIZADO");
                }else{
                    this.loadingFlagHistorico = false
                    this.loadingFlag = false;
                    //this.loadingFlagChartReport = false;
                    console.log("NO ACTUALIZADO");
                }
            }else if(idSensor == 30){ //PIRANOMETRO
                var jsonConfiguracion = JSON.parse(this.selected.deviceSelected.configuracion);
                var jsonConfiguracionAlert = JSON.parse(this.selected.deviceSelected.alertConfig);
                var jsonDatos = JSON.parse(this.selected.deviceSelected.datos);
                this.piranometerData.temperature = jsonDatos.temperature;
                this.piranometerData.wattmetro = jsonDatos.wattmetro;

                if(jsonConfiguracionAlert.hasOwnProperty('alertEnable')){
                    //console.log("HAY ALERT ENABLE");
                    this.piranometerAlertConfig.alertEnable = jsonConfiguracionAlert.alertEnable;
                }else{
                    //console.log("NO HAY ALERT ENABLE");
                    this.piranometerAlertConfig.alertEnable = 'NO'
                }

                if(jsonConfiguracionAlert.hasOwnProperty('emails')){
                    //console.log("HAY EMAILS");
                    this.piranometerAlertConfig.emails = jsonConfiguracionAlert.emails;
                }else{
                    //console.log("NO HAY EMAILS");
                    this.piranometerAlertConfig.emails = []
                }

                if (typeof this.piranometerData.temperature == 'undefined') {
                    this.piranometerData.temperature = null;
                }
                if (typeof this.piranometerData.wattmetro == 'undefined') {
                    this.piranometerData.wattmetro = null;
                }
                if(this.switchFlag == true){
                    // this.getDataSensorPosition(this.selected);
                    // alert('Llego hasta aca')
                    this.getDataSensorPositionHistorico(this.selected, 0);
                    console.log("ACTUALIZADO");
                }else{
                    this.loadingFlagHistorico = false
                    this.loadingFlag = false;
                    //this.loadingFlagChartReport = false;
                    console.log("NO ACTUALIZADO");
                }
            }else if(idSensor == 31){ //TEMPERATURE AND HUMIDITY
                var jsonConfiguracion = JSON.parse(this.selected.deviceSelected.configuracion);
                var jsonConfiguracionAlert = JSON.parse(this.selected.deviceSelected.alertConfig);
                var jsonDatos = JSON.parse(this.selected.deviceSelected.datos);
                this.temperatureAndHumidityData.temperature = jsonDatos.temperature;
                this.temperatureAndHumidityData.humidity = jsonDatos.humidity;

                // this.temperatureAndHumidityData.temperature = this.selected.deviceSelected.dato.temperature;
                // this.temperatureAndHumidityData.humidity = this.selected.deviceSelected.datos.humidity;
                
                if(jsonConfiguracionAlert.hasOwnProperty('alertEnable')){
                    //console.log("HAY ALERT ENABLE");
                    this.temperatureAndHumidityAlertConfig.alertEnable = jsonConfiguracionAlert.alertEnable;
                }else{
                    //console.log("NO HAY ALERT ENABLE");
                    this.temperatureAndHumidityAlertConfig.alertEnable = 'NO'
                }

                if(jsonConfiguracionAlert.hasOwnProperty('emails')){
                    //console.log("HAY EMAILS");
                    this.temperatureAndHumidityAlertConfig.emails = jsonConfiguracionAlert.emails;
                }else{
                    //console.log("NO HAY EMAILS");
                    this.temperatureAndHumidityAlertConfig.emails = []
                }

                if(jsonConfiguracionAlert.hasOwnProperty('tempMin')){
                    //console.log("HAY EMAILS");
                    this.temperatureAndHumidityAlertConfig.tempMin = jsonConfiguracionAlert.tempMin;
                }else{
                    //console.log("NO HAY EMAILS");
                    this.temperatureAndHumidityAlertConfig.tempMin = 0
                }

                if(jsonConfiguracionAlert.hasOwnProperty('tempMax')){
                    //console.log("HAY EMAILS");
                    this.temperatureAndHumidityAlertConfig.tempMax = jsonConfiguracionAlert.tempMax;
                }else{
                    //console.log("NO HAY EMAILS");
                    this.temperatureAndHumidityAlertConfig.tempMax = 0
                }

                if (typeof this.temperatureAndHumidityData.temperature == 'undefined') {
                    this.temperatureAndHumidityData.temperature = null;
                }
                if (typeof this.temperatureAndHumidityData.humidity == 'undefined') {
                    this.temperatureAndHumidityData.humidity = null;
                }

                if(this.switchFlag == true){
                    // this.dateRangeReportSelected.splice(0);
                    // this.getDataSensorPosition(this.selected);
                    this.getDataSensorPositionHistorico(this.selected, 0);
                    console.log("ACTUALIZADO");
                }else{
                    this.loadingFlagHistorico = false
                    this.loadingFlag = false;
                    //this.loadingFlagChartReport = false;
                    console.log("NO ACTUALIZADO");
                }
            }else if(idSensor == 32){ //ESTACION METEOROLOGICA
                var jsonConfiguracion = JSON.parse(this.selected.deviceSelected.configuracion);
                var jsonConfiguracionAlert = JSON.parse(this.selected.deviceSelected.alertConfig);
                var jsonDatos = JSON.parse(this.selected.deviceSelected.datos);

                //console.log("BANDERA METEO:");
                //console.log(jsonDatos);

                this.estacionMeteorologicaData.wind_speed = jsonDatos.wind_speed;
                this.estacionMeteorologicaData.rain_volume = jsonDatos.rain_volume;
                this.estacionMeteorologicaData.temperature = jsonDatos.temperature;
                this.estacionMeteorologicaData.direction_wind = jsonDatos.direction_wind;
                this.estacionMeteorologicaData.relative_humidity = jsonDatos.relative_humidity;
                this.estacionMeteorologicaData.pascal_pressure = jsonDatos.pascal_pressure;

                if(jsonConfiguracionAlert.hasOwnProperty('alertEnable')){
                    //console.log("HAY ALERT ENABLE");
                    this.estacionMeteorologicaAlertConfig.alertEnable = jsonConfiguracionAlert.alertEnable;
                }else{
                    //console.log("NO HAY ALERT ENABLE");
                    this.estacionMeteorologicaAlertConfig.alertEnable = 'NO'
                }

                if(jsonConfiguracionAlert.hasOwnProperty('emails')){
                    //console.log("HAY EMAILS");
                    this.estacionMeteorologicaAlertConfig.emails = jsonConfiguracionAlert.emails;
                }else{
                    //console.log("NO HAY EMAILS");
                    this.estacionMeteorologicaAlertConfig.emails = []
                }
                
                if (typeof this.estacionMeteorologicaData.wind_speed == 'undefined') {
                    this.estacionMeteorologicaData.wind_speed = null;
                }
                
                if (typeof this.estacionMeteorologicaData.rain_volume == 'undefined') {
                    this.estacionMeteorologicaData.rain_volume = null;
                }

                if (typeof this.estacionMeteorologicaData.temperature == 'undefined') {
                    this.estacionMeteorologicaData.temperature = null;
                }

                if (typeof this.estacionMeteorologicaData.direction_wind == 'undefined') {
                    this.estacionMeteorologicaData.direction_wind = null;
                }

                if (typeof this.estacionMeteorologicaData.relative_humidity == 'undefined') {
                    this.estacionMeteorologicaData.relative_humidity = null;
                }

                if (typeof this.estacionMeteorologicaData.pascal_pressure == 'undefined') {
                    this.estacionMeteorologicaData.pascal_pressure = null;
                }

                if(this.switchFlag == true){
                    this.dateRangeReportSelected.splice(0);
                    this.getDataSensorPosition(this.selected);
                    console.log("ACTUALIZADO");
                }else{
                    this.loadingFlagHistorico = false
                    this.loadingFlag = false;
                    //this.loadingFlagChartReport = false;
                    console.log("NO ACTUALIZADO");
                }
            }else if(idSensor == 36){ //PH
                var jsonConfiguracion = JSON.parse(this.selected.deviceSelected.configuracion);
                var jsonConfiguracionAlert = JSON.parse(this.selected.deviceSelected.alertConfig);
                var jsonDatos = JSON.parse(this.selected.deviceSelected.datos);
                this.phConfig.alerts = jsonConfiguracion.alerts;
                this.phConfig.mail = jsonConfiguracion.mail;
                this.phData.Temperatura = jsonDatos.Temperatura;
                this.phData.nivel = jsonDatos.nivel;

                if(jsonConfiguracionAlert.hasOwnProperty('alertEnable')){
                    //console.log("HAY ALERT ENABLE");
                    this.phAlertConfig.alertEnable = jsonConfiguracionAlert.alertEnable;
                }else{
                    //console.log("NO HAY ALERT ENABLE");
                    this.phAlertConfig.alertEnable = 'NO'
                }

                if(jsonConfiguracionAlert.hasOwnProperty('emails')){
                    //console.log("HAY EMAILS");
                    this.phAlertConfig.emails = jsonConfiguracionAlert.emails;
                }else{
                    //console.log("NO HAY EMAILS");
                    this.phAlertConfig.emails = []
                }

                if (typeof this.phData.Temperatura == 'undefined') {
                    this.phData.Temperatura = null;
                }
                if (typeof this.phData.nivel == 'undefined') {
                    this.phData.nivel = null;
                }

                if (typeof this.phConfig.alerts == 'undefined') {
                    this.phConfig.alerts = null;
                }
                if (typeof this.phConfig.mail == 'undefined') {
                    this.phConfig.mail = null;
                }
                if(this.switchFlag == true){
                    this.dateRangeReportSelected.splice(0);
                    this.getDataSensorPosition(this.selected);
                    console.log("ACTUALIZADO");
                }else{
                    this.loadingFlagHistorico = false
                    this.loadingFlag = false;
                    //this.loadingFlagChartReport = false;
                    console.log("NO ACTUALIZADO");
                }
            }else if(idSensor == 37){ //EC
                var jsonConfiguracion = JSON.parse(this.selected.deviceSelected.configuracion);
                var jsonDatos = JSON.parse(this.selected.deviceSelected.datos);
                var jsonConfiguracionAlert = JSON.parse(this.selected.deviceSelected.alertConfig);
                
                this.ecConfig.alerts = jsonConfiguracion.alerts;
                this.ecConfig.mail = jsonConfiguracion.mail;
                this.ecData.Temperatura = jsonDatos.Temperatura;
                this.ecData.uScm = jsonDatos.uScm;

                //console.log("BANDERA BACK ALERTAS 2");
                //console.log(this.ecAlertConfig);

                if(jsonConfiguracionAlert.hasOwnProperty('alertEnable')){
                    //console.log("HAY ALERT ENABLE");
                    this.ecAlertConfig.alertEnable = jsonConfiguracionAlert.alertEnable;
                }else{
                    //console.log("NO HAY ALERT ENABLE");
                    this.ecAlertConfig.alertEnable = 'NO'
                }

                if(jsonConfiguracionAlert.hasOwnProperty('emails')){
                    //console.log("HAY EMAILS");
                    this.ecAlertConfig.emails = jsonConfiguracionAlert.emails;
                }else{
                    //console.log("NO HAY EMAILS");
                    this.ecAlertConfig.emails = []
                }

                if (typeof this.ecData.Temperatura == 'undefined') {
                    this.ecData.Temperatura = null;
                }
                if (typeof this.ecData.uScm == 'undefined') {
                    this.ecData.uScm = null;
                }

                if (typeof this.ecConfig.alerts == 'undefined') {
                    this.ecConfig.alerts = null;
                }
                if (typeof this.ecConfig.mail == 'undefined') {
                    this.ecConfig.mail = null;
                }

                if(this.switchFlag == true){
                    this.dateRangeReportSelected.splice(0);
                    this.getDataSensorPosition(this.selected);
                    console.log("ACTUALIZADO");
                }else{
                    this.loadingFlagHistorico = false
                    this.loadingFlag = false;
                    //this.loadingFlagChartReport = false;
                    console.log("NO ACTUALIZADO");
                }
            }else if(idSensor == 41){ //FLOW WATER
                var jsonDatos = JSON.parse(this.selected.deviceSelected.datos);
                var jsonConfiguracionAlert = JSON.parse(this.selected.deviceSelected.alertConfig);
                //console.log("BANDERA ALERT FLOW");
                //console.log(jsonConfiguracionAlert);

                this.flowWaterData.lts_minute = jsonDatos.lts_minute;
                this.flowWaterData.lts_total = jsonDatos.lts_total;
                this.flowWaterData.total_litter = this.selected.deviceSelected.total_litter;
                this.flowWaterData.today_litters = this.selected.deviceSelected.today_litters;
                this.flowWaterData.monthly_litters = this.selected.deviceSelected.monthly_litters;

                if(jsonConfiguracionAlert.hasOwnProperty('alertEnable')){
                    //console.log("HAY ALERT ENABLE");
                    this.flowWaterAlertConfig.alertEnable = jsonConfiguracionAlert.alertEnable;
                    this.flowAlertConfigTemporal.alertEnable = jsonConfiguracionAlert.alertEnable;
                }else{
                    //console.log("NO HAY ALERT ENABLE");
                    this.flowWaterAlertConfig.alertEnable = 'NO'
                    this.flowAlertConfigTemporal.alertEnable = 'NO';
                }

                if(jsonConfiguracionAlert.hasOwnProperty('emails')){
                    //console.log("HAY EMAILS");
                    this.flowWaterAlertConfig.emails = jsonConfiguracionAlert.emails;
                    this.flowAlertConfigTemporal.emails = jsonConfiguracionAlert.emails;
                }else{
                    //console.log("NO HAY EMAILS");
                    this.flowWaterAlertConfig.emails = []
                    this.flowAlertConfigTemporal.emails = []
                }

                
                //console.log("BANDERA BACK 2");
                //console.log(this.flowAlertConfigTemporal);

                if (typeof this.flowWaterData.lts_minute == 'undefined') {
                    this.flowWaterData.lts_minute = null;
                }
                if (typeof this.flowWaterData.lts_total == 'undefined') {
                    this.flowWaterData.lts_total = null;
                }
                if(this.switchFlag == true){
                    this.dateRangeReportSelected.splice(0);
                    this.getDataSensorPosition(this.selected);
                    console.log("ACTUALIZADO");
                }else{
                    this.loadingFlagHistorico = false
                    this.loadingFlag = false;
                    //this.loadingFlagChartReport = false;
                    console.log("NO ACTUALIZADO");
                }
            }else if(idSensor == 47){ //DCMAX1500               
                console.log("🚀 ~ file: DeviceMixin.js ~ line 2539 ~ assignSelectedInicio ~ this.selected.deviceSelected", this.selected.deviceSelected)

                var jsonConfiguracion = (this.selected.deviceSelected != null) ? JSON.parse(this.selected.deviceSelected.configuracion) : null;
                var jsonConfiguracionAlert = (this.selected.deviceSelected != null) ? JSON.parse(this.selected.deviceSelected.alertConfig) : null;
                var jsonDatos = (this.selected.deviceSelected != null) ? JSON.parse(this.selected.deviceSelected.datos) : null;

                

                this.DcMaxData.powerNow = (this.selected.deviceSelected != null) ? this.selected.deviceSelected.power_now : 0;
                this.DcMaxData.todayEnergy = (this.selected.deviceSelected != null) ? this.selected.deviceSelected.today_energy : 0;
                this.DcMaxData.monthlyEnergy = (this.selected.deviceSelected != null) ? this.selected.deviceSelected.monthly_energy : 0;
                

                if (typeof this.DcMaxData.powerNow == 'undefined') {
                    this.DcMaxData.powerNow = null;
                }
                if (typeof this.DcMaxData.todayEnergy == 'undefined') {
                    this.DcMaxData.todayEnergy = null;
                }
                if (typeof this.DcMaxData.monthlyEnergy == 'undefined') {
                    this.DcMaxData.monthlyEnergy = null;
                }
                if(this.switchFlag == true){
                    this.getDataSensorPosition(this.selected);
                    console.log("ACTUALIZADO");
                }else{
                    this.loadingFlagHistorico = false
                    this.loadingFlag = false;
                    //this.loadingFlagChartReport = false;
                    console.log("NO ACTUALIZADO");
                }
            }else if(idSensor == 48){ //BIDIRECCIONAL                
                const data = JSON.parse(this.selected.deviceSelected.datos);
                this.Bidireccional.powerNow         =  data?.power;
                this.Bidireccional.powerFactor      =  data?.factor | 0;
                this.Bidireccional.todayConsumed    =  data?.consumed;
                this.Bidireccional.monthlyConsumed  =  data?.month_consumed;
                this.Bidireccional.todayGenerated   =  data?.generated;
                this.Bidireccional.monthlyGenerated =  data?.month_generated;
                
            }
            this.getDataNormal(this.selected);
        }
        
    }
};

export default DataDeviceMixin;