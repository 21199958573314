var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('v-card',[_c('v-card-text',{staticStyle:{"padding":"0 !important"}},[_c('v-tabs',{attrs:{"dark":"","background-color":"blue","show-arrows":""}},[_c('v-tabs-slider',{attrs:{"color":"teal lighten-3"}}),_c('v-tab',[_c('v-icon',{staticClass:"ml-2 mr-2",attrs:{"small":""}},[_vm._v("mdi-alert")]),_vm._v(" Alert")],1),_c('v-tab',[_c('v-icon',{staticClass:"ml-2 mr-2",attrs:{"small":""}},[_vm._v("mdi-phone")]),_vm._v(" / "),_c('v-icon',{staticClass:"ml-2 mr-2",attrs:{"small":""}},[_vm._v("mdi-email")]),_vm._v(" email and sms ")],1),_vm._l((3),function(n){return _c('v-tab-item',{key:n},[_c('v-row',[(n == 1)?_c('v-col',[_c('v-card-text',_vm._l((_vm.tags),function(tag,index){return _c('div',{key:index},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-switch',{staticClass:"my-1",attrs:{"id":index == 0 ? '0' : index,"color":"info","label":tag,"hide-details":""},on:{"click":function($event){return _vm.itemAlert(tag, index, _vm.enabledAlert[index])}},model:{value:(_vm.enabledAlert[index]),callback:function ($$v) {_vm.$set(_vm.enabledAlert, index, $$v)},expression:"enabledAlert[index]"}})],1)],1),_c('v-row',{class:_vm.rangeValidation(index)
                                                ? 'mb-0'
                                                : 'mb-8'},[_c('v-col',{staticClass:"py-2",attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"mt-0",attrs:{"id":'range-' + index,"label":"Minimum","type":"number","disabled":!_vm.enabledAlert[index] ===
                                                    true
                                                        ? true
                                                        : false,"outlined":"","hide-details":"","required":""},model:{value:(_vm.minimumR[index]),callback:function ($$v) {_vm.$set(_vm.minimumR, index, $$v)},expression:"minimumR[index]"}})],1),_c('v-col',{staticClass:"py-2",attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"mt-0",attrs:{"id":'range-' + index,"label":"Maximum","type":"number","disabled":!_vm.enabledAlert[index] ===
                                                    true
                                                        ? true
                                                        : false,"outlined":"","hide-details":"","required":""},model:{value:(_vm.maximumR[index]),callback:function ($$v) {_vm.$set(_vm.maximumR, index, $$v)},expression:"maximumR[index]"}})],1)],1),(_vm.rangeValidation(index))?_c('v-alert',{staticClass:"text-center mb-2 py-0",attrs:{"outlined":"","color":"error"}},[_vm._v("Range: Wrong")]):_vm._e()],1)}),0)],1):_vm._e(),(n == 2)?_c('v-col',[_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Email 1","rules":_vm.estatus_value == 'NO' &&
                                        _vm.email1 == ''
                                            ? true
                                            : [_vm.rules.required, _vm.rules.email],"required":""},model:{value:(_vm.email1),callback:function ($$v) {_vm.email1=$$v},expression:"email1"}}),_c('v-text-field',{attrs:{"label":"Email 2","rules":_vm.email2 == '' ? true : [_vm.rules.email]},model:{value:(_vm.email2),callback:function ($$v) {_vm.email2=$$v},expression:"email2"}}),_c('v-text-field',{attrs:{"label":"Email 3","rules":_vm.email3 == '' ? true : [_vm.rules.email]},model:{value:(_vm.email3),callback:function ($$v) {_vm.email3=$$v},expression:"email3"}}),_c('h3',[_vm._v(" Do you want to receive alerts about the offline device? ")]),_c('v-checkbox',{staticClass:"d-flex align-end flex-column",attrs:{"label":"unreported","color":"orange darken-3","value":"orange darken-3","hide-details":""},model:{value:(_vm.unreported),callback:function ($$v) {_vm.unreported=$$v},expression:"unreported"}}),_c('v-select',{staticClass:"pt-4 px-1 mt-4",attrs:{"items":_vm.estatus,"value":_vm.estatus.value,"label":"Status","required":""},model:{value:(_vm.estatus_value),callback:function ($$v) {_vm.estatus_value=$$v},expression:"estatus_value"}})],1)],1):_vm._e()],1)],1)})],2)],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":"","color":"primary","disabled":!_vm.canUpdate()},on:{"click":function($event){return _vm.updateAlerts()}}},[_vm._v("Update")]),(_vm.loadingFlagFormAlerts)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"20"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }