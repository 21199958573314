
import moment from "moment";
import Highchart from "highcharts";
require("highcharts/modules/exporting")(Highchart);
require("highcharts/modules/export-data")(Highchart);
require('highcharts/modules/no-data-to-display')(Highchart)
require("moment")(Highchart);
require("moment-timezone")(Highchart);
const HelperMixin = {
    methods: {   
        
        
        
        makeHighchart(params){             
            Highchart.chart(params.idHighchart, {
                time: {
                    timezone: "America/Mexico_City"
                },
                chart: {
                    zoomType: "x",
                    backgroundColor: params?.backgroundColor,
                    height: params?.height,
                    
                },
                credits: {
                    enabled: false,
                },
                exporting: {
                    allowHTML: true,
                },
                title: {
                    text: params.title,
                    style: {
                        fontFamily: 'monospace',
                        color: params?.textColor
                    }
                },
                xAxis: {
                    title: {
                        text: "DATE",
                        style: {
                            fontFamily: 'monospace',
                            color: params?.textColor
                        }
                    },
                    categories: params.categories,
                    style: {
                        fontFamily: 'monospace',
                        color: params?.textColor
                    },
                    
                    labels: {
                        formatter: function() {
                            return moment(this.value).format("HH:mm");
                        },
                        style: {
                            fontFamily: 'monospace',
                            color: params?.textColor
                        }
                    },
                },
                yAxis: {
                    title: {
                        text: params.labelX,
                        style: {
                            fontFamily: 'monospace',
                            color: params?.textColor
                        },
                    },
                    
                },
                series: params.series,

                lang: {
                    noData: "No sensor data to display",
                },
                noData: {
                    style: {
                        fontWeight: '',
                        fontSize: '20px',
                        color: params?.textColor
                    }
                },
                legend: params.legend === undefined?{}:params.legend,

                exporting: {
                    filename: params.title
                }
            })
        },

        makeHighchartDC(params){
            Highchart.chart(params.idHighchart, {
                time: {
                    timezone: "America/Mexico_City"
                },
                chart: {
                    zoomType: "x",
                },
                credits: {
                    enabled: false,
                },
                exporting: {
                    allowHTML: true,
                },
                title: {
                    text: params.title,
                },
                xAxis: {
                    title: {
                        text: "Tiempo",
                    },
                    categories: params.categories,
                    
                    labels: {
                        formatter: function() {
                            return moment(this.value).format("HH:mm");
                        },
                    },
                },
                yAxis: {
                    title: {
                        text: params.labelX,
                    },
                },

                tooltip: {
                    formatter: function(d){
                        var rV = this.x + " <br/>";
                        var val_original = this.y.toFixed(2);
                        var val_final = val_original.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        rV += '<span style="color:' + this.series.color + '">\u25CF</span> ' + this.series.name + ': ' + '<b>' + val_final + '</b>' + '<br/>';          
                        return rV;
                    },
                    
                },

                series: params.series,

                lang: {
                    noData: "No sensor data to display",
                },
                noData: {
                    style: {
                        fontWeight: '',
                        fontSize: '20px',
                    }
                },

                exporting: {
                    filename: params.title
                }
            })
        },

        createChartInstance(){
            Highchart.chart('grafica', {
                chart: {
                    height: 300
                },
                xAxis: {
                    categories: []
                },
                series: [{
                    data: []
                }]
            });
        },

        destroyHighchart(){
            const chart = Highchart.chart('grafica', {
                chart: {
                    height: 300
                },
                xAxis: {
                    categories: []
                },
                series: [{
                    data: []
                }]
            });

            chart.destroy();
            //Highchart.chart("grafica").destroy;
        },

        makeHighchartExample(params){
            Highchart.chart(params.idHighchart, {
                time: {
                    timezone: "America/Mexico_City"
                },
                chart: {
                    zoomType: "x",
                },
                credits: {
                    enabled: false,
                },
                exporting: {
                    allowHTML: true,
                },
                title: {
                    text: params.title,
                },
                xAxis: {
                    title: {
                        text: "Tiempo",
                    },
                    labels: {
                        formatter: function() {
                            return moment(this.value).format("HH:mm");
                        },
                    },
                },
                yAxis: {
                    title: {
                        text: params.labelX,
                    },
                },
                series: params.series
            })
        },

        getMapa(coordenadas){     
            // console.log('Coordenadas');
            // console.log(coordenadas);       
            var latitud = coordenadas.latitud;
            var longitud = coordenadas.longitud;
            // var latitud =21.880107 ;
            // var longitud =-102.275843;
            // 21.880107, -102.275843
            var map = new Microsoft.Maps.Map('#myMap', {
                credentials: 'Apl2ciQ_uUYkh2WtZrsJAiHsal-ddm0weOKb7u2eTkBNXvaMc4f15x2dN0Tf4hxb',
                center: new Microsoft.Maps.Location(coordenadas.latitud, coordenadas.longitud),
                mapTypeId: Microsoft.Maps.MapTypeId.road,
                zoom: 15,
                disablePanning: true,
                disableScrollWheelZoom: true,
                disableZooming: true,
                showLocateMeButton: false,
                showMapTypeSelector: false
            });
            //Create a font pushpin of a truck. "&#xf0d1;" => "\uf0d1". List of icon hex values: http://fontawesome.io/3.2.1/cheatsheet/
            var pin = this.createFontPushpin(map.getCenter(), '\uf041', 'FontAwesome', 35, 'red', latitud, longitud);

            //Add the pushpin to the map
            map.entities.push(pin);
        },
        
        createFontPushpin(location, text, fontName, fontSizePx, color, latitud, longitud){
            var c = document.createElement('canvas');
            var ctx = c.getContext('2d');

            //Define font style
            var font = fontSizePx + 'px ' + fontName;
            ctx.font = font

            //Resize canvas based on sie of text.
            var size = ctx.measureText(text);
            c.width = size.width;
            c.height = fontSizePx;

            //Reset font as it will be cleared by the resize.
            ctx.font = font;
            ctx.textBaseline = 'top';
            ctx.fillStyle = color;

            ctx.fillText(text, 0, 0);

            return new Microsoft.Maps.Pushpin(location, {
                icon: c.toDataURL(),
                anchor: new Microsoft.Maps.Point(c.width / 2, c.height / 2), //Align center of pushpin with location.
                title: 'Location',
                subTitle: 'Lat: ' + latitud + " , " + 'Long: ' + longitud,
            });
        },
    }
};

export default HelperMixin;