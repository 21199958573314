import { mapGetters, mapActions } from "vuex";

const AlertsMixin = {
    data: () => {
        return {
            modalAlerts: false,
            estatus_value: null,
        }
    },
    computed: {
        ...mapGetters({
            alertConfig: ""
        })
    },
    methods: {
        updateAlerts(){
            var bandera1Temporal = 0
            var bandera2Temporal = 0
            var bandera3Temporal = 0
            var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

            this.loadingFlagFormAlerts = true;
            this.alertConfig.alertEnable = this.estatus_value;
            this.alertConfig.emails[0] = this.email1;
            this.alertConfig.emails[1] = this.email2;
            this.alertConfig.emails[2] = this.email3;

            if(this.alertConfig.emails[0] == "" && this.alertConfig.emails[1] == "" && this.alertConfig.emails[2] == ""){
                Swal.fire({
                    title: '¡Warning!',
                    icon: 'warning',
                    text: 'Please input at least one email to report',
                }); 
                this.loadingFlagFormAlerts = false; 
            }else{

                if(this.alertConfig.emails[0] == ''){ 
                    bandera1Temporal = 0;
                }else{
                    if((this.alertConfig.emails[0].match(mailformat))){
                        bandera1Temporal = 0;
                    }else{
                        bandera1Temporal = 1;
                    }
                }

                if(this.alertConfig.emails[1] == ''){ 
                    bandera2Temporal = 0;
                }else{
                    if((this.alertConfig.emails[1].match(mailformat))){
                        bandera2Temporal = 0;
                    }else{
                        bandera1Temporal = 1;
                    }
                }

                if(this.alertConfig.emails[2] == ''){ 
                    bandera3Temporal = 0;
                }else{
                    if((this.alertConfig.emails[2].match(mailformat))){
                        bandera3Temporal = 0;
                    }else{
                        bandera1Temporal = 1;
                    }
                }

                if(bandera1Temporal == 1 || bandera2Temporal == 1 || bandera3Temporal == 1){
                    Swal.fire({
                        title: '¡Warning!',
                        icon: 'warning',
                        text: 'Incorrect email format',
                    });
                    this.loadingFlagFormAlerts = false;
                }else{
                    var jsonConfiguracion = JSON.parse(this.device.alertConfig);
                    jsonConfiguracion.emails = this.alertConfig.emails;
                    jsonConfiguracion.alertEnable = this.alertConfig.alertEnable;

                    var stringJsonConfig = JSON.stringify(jsonConfiguracion);
                    this.device.alertConfig = stringJsonConfig;
                    this.modalAlertConfig = false;
                    this.$emit("updateAlertConfiguration");
                    this.$emit("consultaTemporal");
                }
            }
        },

        openAlertModal(){
            console.log("Entra en mi funcion");
            this.modalAlerts = true;
            // this.estatus_value = this.alertConfig.alertEnable;
            // if(this.alertConfig.emails.length == 3){
            //     this.email1 = this.alertConfig.emails[0];
            //     this.email2 = this.alertConfig.emails[1];
            //     this.email3 = this.alertConfig.emails[2];
            // }else if(this.alertConfig.emails.length == 2){
            //     this.email1 = this.alertConfig.emails[0];
            //     this.email2 = this.alertConfig.emails[1];
            //     this.email3 = "";
            // }else if(this.alertConfig.emails.length == 1){
            //     this.email1 = this.alertConfig.emails[0];
            //     this.email2 = "";
            //     this.email3 = "";
            // }else if(this.alertConfig.emails.length == 0){
            //     this.email1 = "";
            //     this.email2 = "";
            //     this.email3 = "";
            // }
        },

        closeAlertModal(){
            this.modalAlerts = false;
        }
    }
}

export default AlertsMixin;