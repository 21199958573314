<template>
    <div class="grey lighten-4 height-100" style="padding-bottom: 5%;">
        <Header :seeEnergyFlag="seeEnergyFlag" :devices="selected"/>
        <v-row class="mt-5 pt-1 mx-0">
            <v-col lg="8" sm="12" cols="12">
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col lg="6" sm="12">
                                <v-card>
                                    <v-card-text class="py-2">
                                        <v-select :items="arregloSensoresDiferentes2" v-model="valorFiltro" prepend-icon="mdi-filter-outline" item-text="nombreTipo" item-value="idSensor" label="Sensor Type (Select Devices Filter)" @change="changeFiltro1()" :loading="loadingFlagSelect">
                                            <template slot="item" slot-scope="data">
                                                    <span class="mr-2" v-if="data.item.idSensor == ''"><font-awesome-icon icon="laptop-house" size="1x" :style="{ color: 'grey' }"></font-awesome-icon></span>
                                                    <span class="mr-2" v-else-if="data.item.idSensor == 41"><font-awesome-icon icon="tint" size="1x" :style="{ color: 'blue' }"></font-awesome-icon></span>
                                                    <span class="mr-2" v-else-if="data.item.idSensor == 37"><font-awesome-icon icon="charging-station" size="1x" :style="{ color: 'purple' }"></font-awesome-icon></span>
                                                    <span class="mr-2" v-else-if="data.item.idSensor == 36"><font-awesome-icon icon="eye-dropper" size="1x" :style="{ color: 'orange' }"></font-awesome-icon></span>
                                                    <span class="mr-2" v-else-if="data.item.idSensor == 32"><font-awesome-icon icon="cloud-sun-rain" size="1x" :style="{ color: '#90cdfd' }"></font-awesome-icon></span>
                                                    <span class="mr-2" v-else-if="data.item.idSensor == 31"><font-awesome-icon icon="temperature-high" size="1x" :style="{ color: 'blue' }"></font-awesome-icon></span>
                                                    <span class="mr-2" v-else-if="data.item.idSensor == 30"><font-awesome-icon icon="fire-alt" size="1x" :style="{ color: 'orange' }"></font-awesome-icon></span>
                                                    <span class="mr-2" v-else-if="data.item.idSensor == 29"><font-awesome-icon icon="temperature-low" size="1x" :style="{ color: 'pink' }"></font-awesome-icon></span>
                                                    <span class="mr-2" v-else-if="data.item.idSensor == 11"><font-awesome-icon icon="mobile" size="1x" :style="{ color: '#339af0' }"></font-awesome-icon></span>
                                                    <span class="mr-2" v-else-if="data.item.idSensor == 8"><font-awesome-icon icon="toggle-on" size="1x" :style="{ color: 'red' }"></font-awesome-icon></span>
                                                    <span class="mr-2" v-else-if="data.item.idSensor == 7"><font-awesome-icon icon="gas-pump" size="1x" :style="{ color: 'green' }"></font-awesome-icon></span>
                                                    <span class="mr-2" v-else-if="data.item.idSensor == 3"><font-awesome-icon icon="wind" size="1x" :style="{ color: '#03b3ca' }"></font-awesome-icon></span>
                                                    <span class="mr-2" v-else-if="data.item.idSensor == 2"><font-awesome-icon icon="volume-up" size="1x" :style="{ color: '#27c24c' }"></font-awesome-icon></span>
                                                    <span class="mr-2" v-else-if="data.item.idSensor == 1"><font-awesome-icon icon="bolt" size="1x" :style="{ color: '#ffb900' }"></font-awesome-icon></span> 
                                                    <span>{{data.item.nombreTipo}}</span>
                                            </template>
                                            <template slot="selection" slot-scope="data">
                                                <span class="mr-2" v-if="data.item.idSensor == ''"><font-awesome-icon icon="laptop-house" size="1x" :style="{ color: 'grey' }"></font-awesome-icon></span>
                                                <span class="mr-2" v-if="data.item.idSensor == 41"><font-awesome-icon icon="tint" size="1x" :style="{ color: 'blue' }"></font-awesome-icon></span>
                                                <span class="mr-2" v-else-if="data.item.idSensor == 37"><font-awesome-icon icon="charging-station" size="1x" :style="{ color: 'purple' }"></font-awesome-icon></span>
                                                <span class="mr-2" v-else-if="data.item.idSensor == 36"><font-awesome-icon icon="eye-dropper" size="1x" :style="{ color: 'orange' }"></font-awesome-icon></span>
                                                <span class="mr-2" v-else-if="data.item.idSensor == 32"><font-awesome-icon icon="cloud-sun-rain" size="1x" :style="{ color: '#90cdfd' }"></font-awesome-icon></span>
                                                <span class="mr-2" v-else-if="data.item.idSensor == 31"><font-awesome-icon icon="temperature-high" size="1x" :style="{ color: 'blue' }"></font-awesome-icon></span>
                                                <span class="mr-2" v-else-if="data.item.idSensor == 30"><font-awesome-icon icon="fire-alt" size="1x" :style="{ color: 'orange' }"></font-awesome-icon></span>
                                                <span class="mr-2" v-else-if="data.item.idSensor == 29"><font-awesome-icon icon="temperature-low" size="1x" :style="{ color: 'pink' }"></font-awesome-icon></span>
                                                <span class="mr-2" v-else-if="data.item.idSensor == 11"><font-awesome-icon icon="mobile" size="1x" :style="{ color: '#339af0' }"></font-awesome-icon></span>
                                                <span class="mr-2" v-else-if="data.item.idSensor == 8"><font-awesome-icon icon="toggle-on" size="1x" :style="{ color: 'red' }"></font-awesome-icon></span>
                                                <span class="mr-2" v-else-if="data.item.idSensor == 7"><font-awesome-icon icon="gas-pump" size="1x" :style="{ color: 'green' }"></font-awesome-icon></span>
                                                <span class="mr-2" v-else-if="data.item.idSensor == 3"><font-awesome-icon icon="wind" size="1x" :style="{ color: '#03b3ca' }"></font-awesome-icon></span>
                                                <span class="mr-2" v-else-if="data.item.idSensor == 2"><font-awesome-icon icon="volume-up" size="1x" :style="{ color: '#27c24c' }"></font-awesome-icon></span>             
                                                <span class="mr-2" v-else-if="data.item.idSensor == 1"><font-awesome-icon icon="bolt" size="1x" :style="{ color: '#ffb900' }"></font-awesome-icon></span> 
                                                <span>{{data.item.nombreTipo}}</span>
                                            </template>
                                        </v-select>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col lg="6" sm="12">
                                <v-card>
                                    <v-card-text class="py-2">
                                        <v-select :items="itemsFiltroLastReport" v-model="valorFiltroLastReport" @change="changeFiltro2()" prepend-icon="mdi-filter-outline" item-text="text" item-value="value" label="Last Time Reported (Select Devices Filter)" :loading="loadingFlagSelect">
                                        </v-select>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col lg="12" sm="12">
                                <v-card>
                                    <v-card-text class="py-4">
                                        <v-row class="">
                                            <!--<v-icon class="mx-5" size="30">mdi-devices</v-icon>-->
                                            <v-combobox class="px-6" v-model="select" :items="filteredSensorData" item-text="name" item-value="imei" prepend-icon="mdi-devices" label="Select Devices" multiple chips deletable-chips hide-selected clearable :loading="loadingFlagSelect">
                                                <template slot="item" slot-scope="data">
                                                    <span class="mr-2" v-if="data.item.idSensor == 41"><font-awesome-icon icon="tint" size="1x" :style="{ color: 'blue' }"></font-awesome-icon></span>
                                                    <span class="mr-2" v-if="data.item.idSensor == 37"><font-awesome-icon icon="charging-station" size="1x" :style="{ color: 'purple' }"></font-awesome-icon></span>
                                                    <span class="mr-2" v-else-if="data.item.idSensor == 36"><font-awesome-icon icon="eye-dropper" size="1x" :style="{ color: 'orange' }"></font-awesome-icon></span>
                                                    <span class="mr-2" v-else-if="data.item.idSensor == 32"><font-awesome-icon icon="cloud-sun-rain" size="1x" :style="{ color: '#90cdfd' }"></font-awesome-icon></span>
                                                    <span class="mr-2" v-else-if="data.item.idSensor == 31"><font-awesome-icon icon="temperature-high" size="1x" :style="{ color: 'blue' }"></font-awesome-icon></span>
                                                    <span class="mr-2" v-else-if="data.item.idSensor == 30"><font-awesome-icon icon="fire-alt" size="1x" :style="{ color: 'orange' }"></font-awesome-icon></span>
                                                    <span class="mr-2" v-else-if="data.item.idSensor == 29"><font-awesome-icon icon="temperature-low" size="1x" :style="{ color: 'pink' }"></font-awesome-icon></span>
                                                    <span class="mr-2" v-else-if="data.item.idSensor == 11"><font-awesome-icon icon="mobile" size="1x" :style="{ color: '#339af0' }"></font-awesome-icon></span>
                                                    <span class="mr-2" v-else-if="data.item.idSensor == 8"><font-awesome-icon icon="toggle-on" size="1x" :style="{ color: 'red' }"></font-awesome-icon></span>
                                                    <span class="mr-2" v-else-if="data.item.idSensor == 7"><font-awesome-icon icon="gas-pump" size="1x" :style="{ color: 'green' }"></font-awesome-icon></span>
                                                    <span class="mr-2" v-else-if="data.item.idSensor == 3"><font-awesome-icon icon="wind" size="1x" :style="{ color: '#03b3ca' }"></font-awesome-icon></span>
                                                    <span class="mr-2" v-else-if="data.item.idSensor == 2"><font-awesome-icon icon="volume-up" size="1x" :style="{ color: '#27c24c' }"></font-awesome-icon></span>
                                                    <span class="mr-2" v-else-if="data.item.idSensor == 1"><font-awesome-icon icon="bolt" size="1x" :style="{ color: '#ffb900' }"></font-awesome-icon></span> 
                                                    <span>{{data.item.name}}</span>
                                                </template>
                                            </v-combobox>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col lg="4" sm="12" cols="12">
                <v-card>
                    <v-card-text>
                        <v-row>
                    <v-col lg="12" sm="12" cols="12">
                        <v-card>
                            <v-card-text class="py-2">
                                <v-dialog ref="dialogDatePicker" v-model="modalDatePicker" width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field class="px-3" v-model="formatDate" label="Select a date range..." prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on">
                                    </v-text-field>
                                    </template>
                                    <v-date-picker :active-picker="today" :max="today" v-model="rangoFechasExecutive" scrollable range  locale="en-US">
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="modalDatePicker = false">Cancel</v-btn>
                                    <v-btn text color="primary" @click="modalDatePicker = false">ACCEPT</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-card-text>
                        </v-card>
                    </v-col>
                        </v-row>
                        <v-row>
                            <v-col lg="12" sm="12" cols="12">
                                <v-card>
                                    <v-card-text class="py-6">
                                        <v-row>
                                            <v-col lg="12" sm="12" class="d-flex justify-center">
                                                <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn style="width:100%" class="pa-6" color="success" @click="generateReports()" v-bind="attrs" v-on="on"><v-icon size="30">mdi-file-chart-outline</v-icon>Generate Report</v-btn>
                                                        </template>
                                                    <span>Generate Report</span>
                                                </v-tooltip>
                                            </v-col>
                                            <!--<v-col lg="6" sm="12" class="d-flex justify-center">
                                                <v-dialog v-model="modalScheduleReport" transition="dialog-bottom-transition" max-width="600">
                                                        <template v-slot:activator="{ on: dialog, attrs }">
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on: tooltip }">
                                                                    <v-btn style="" class="" color="success" @click="openScheduleForm()" v-bind="attrs" v-on="{...tooltip, ...dialog}"><v-icon size="30">mdi-clock-outline</v-icon></v-btn>
                                                                </template>
                                                                <span>Schedule Report</span>
                                                            </v-tooltip>
                                                        </template>
                                                        <template v-slot:default="dialog">
                                                        <v-card>
                                                            <v-toolbar color="primary" dark><span class="ml-2">Schedule Report</span><v-btn :plain="true" class="ml-auto" @click="cerrar()"><font-awesome-icon icon="times" size="2x" :style="{ color: 'white'}"/></v-btn></v-toolbar>
                                                            <v-card-text class="pa-6">
                                                                <v-chip class="pr-2 mr-3 my-4">Dias de reporte</v-chip>
                                                                <v-row class="mx-1">
                                                                    <v-checkbox class="mr-4" v-model="dayGlobal" label="Lunes" value="1"></v-checkbox>
                                                                    <v-checkbox class="mr-4" v-model="dayGlobal" label="Martes" value="2"></v-checkbox>
                                                                    <v-checkbox class="mr-4" v-model="dayGlobal" label="Miercoles" value="3"></v-checkbox>
                                                                    <v-checkbox class="mr-4" v-model="dayGlobal" label="Jueves" value="4"></v-checkbox>
                                                                    <v-checkbox class="mr-4" v-model="dayGlobal" label="Viernes" value="5"></v-checkbox>
                                                                    <v-checkbox class="mr-4" v-model="dayGlobal" label="Sabado" value="6"></v-checkbox>
                                                                    <v-checkbox class="mr-4" v-model="dayGlobal" label="Domingo" value="7"></v-checkbox>
                                                                </v-row>
                                                                <div class="mt-3">
                                                                    <v-dialog ref="dialogTimePicker" v-model="modalTimePicker" width="290px">
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-text-field class="" v-model="timeGlobal" label="Selecciona un horario..." append-icon="mdi-clock" readonly v-bind="attrs" v-on="on">
                                                                            </v-text-field>
                                                                        </template>
                                                                        <v-time-picker v-model="timeGlobal" color="green lighten-1" header-color="primary">
                                                                            <v-spacer></v-spacer>
                                                                            <v-btn text color="primary" @click="modalTimePicker = false">Cancel</v-btn>
                                                                            <v-btn text color="primary" @click="modalTimePicker = false">ACEPTAR</v-btn>
                                                                        </v-time-picker>
                                                                    </v-dialog>   
                                                                </div>
                                                                <v-combobox class="" v-model="selectReport" :items="devices" item-text="name" item-value="imei" label="Seleccione Dispositivos" multiple chips deletable-chips hide-selected clearable>
                                                                    <template slot="item" slot-scope="data">
                                                                        <span class="mr-2" v-if="data.item.idSensor == 41"><font-awesome-icon icon="tint" size="1x" :style="{ color: 'blue' }"></font-awesome-icon></span>
                                                                        <span class="mr-2" v-if="data.item.idSensor == 37"><font-awesome-icon icon="charging-station" size="1x" :style="{ color: 'purple' }"></font-awesome-icon></span>
                                                                        <span class="mr-2" v-else-if="data.item.idSensor == 36"><font-awesome-icon icon="eye-dropper" size="1x" :style="{ color: 'orange' }"></font-awesome-icon></span>
                                                                        <span class="mr-2" v-else-if="data.item.idSensor == 32"><font-awesome-icon icon="cloud-sun-rain" size="1x" :style="{ color: '#90cdfd' }"></font-awesome-icon></span>
                                                                        <span class="mr-2" v-else-if="data.item.idSensor == 31"><font-awesome-icon icon="temperature-high" size="1x" :style="{ color: 'blue' }"></font-awesome-icon></span>
                                                                        <span class="mr-2" v-else-if="data.item.idSensor == 30"><font-awesome-icon icon="fire-alt" size="1x" :style="{ color: 'orange' }"></font-awesome-icon></span>
                                                                        <span class="mr-2" v-else-if="data.item.idSensor == 29"><font-awesome-icon icon="temperature-low" size="1x" :style="{ color: 'pink' }"></font-awesome-icon></span>
                                                                        <span class="mr-2" v-else-if="data.item.idSensor == 11"><font-awesome-icon icon="mobile" size="1x" :style="{ color: '#339af0' }"></font-awesome-icon></span>
                                                                        <span class="mr-2" v-else-if="data.item.idSensor == 8"><font-awesome-icon icon="toggle-on" size="1x" :style="{ color: 'red' }"></font-awesome-icon></span>
                                                                        <span class="mr-2" v-else-if="data.item.idSensor == 7"><font-awesome-icon icon="gas-pump" size="1x" :style="{ color: 'green' }"></font-awesome-icon></span>
                                                                        <span class="mr-2" v-else-if="data.item.idSensor == 3"><font-awesome-icon icon="wind" size="1x" :style="{ color: '#03b3ca' }"></font-awesome-icon></span>
                                                                        <span class="mr-2" v-else-if="data.item.idSensor == 2"><font-awesome-icon icon="volume-up" size="1x" :style="{ color: '#27c24c' }"></font-awesome-icon></span>
                                                                        <span class="mr-2" v-else-if="data.item.idSensor == 1"><font-awesome-icon icon="bolt" size="1x" :style="{ color: '#ffb900' }"></font-awesome-icon></span> 
                                                                        <span>{{data.item.name}}</span>
                                                                    </template>
                                                                </v-combobox>
                                                                <v-text-field label="Email 1" v-model="email1" :rules="[rules.required, rules.email]" required></v-text-field>
                                                                <v-text-field label="Email 2" v-model="email2" :rules="[rules.email]"></v-text-field>
                                                                <v-text-field label="Email 3" v-model="email3" :rules="[rules.email]"></v-text-field>
                                                                <v-select :items="statusReport_list" item-text="status" item-value="val" label="Status" v-model="statusRGlobal" @change="testSelect3()" required></v-select>
                                                            </v-card-text>
                                                            <v-card-actions class="justify-end">
                                                                <v-btn text @click="cerrar()">Cancelar</v-btn>
                                                                <v-btn text color="primary" @click="saveReport()">PROGRAMAR</v-btn>
                                                            </v-card-actions>
                                                        </v-card>
                                                        </template> 
                                                </v-dialog>
                                            </v-col>-->
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="mt-5 pt-1 mx-0">
            <v-col lg="12" sm="12">
                <v-card class="pa-4">
                    <v-card-title>
                        <strong>Request List</strong>
                        <v-progress-circular indeterminate color="primary" size="20" v-if="loadingFlagTableRequest" class="ml-5"></v-progress-circular>
                        <span v-if="loadingFlagTableRequest" style="font-size:12px" class="blue-grey--text ml-4">Making Request... Please wait</span>
                    </v-card-title>
                    <template>
                        <v-data-table calculate-widths :items-per-page="5" :headers="pruebaHeaderRequestTable" :items="requestExecutiveRequest" item-key="imei" class="elevation-1" :loading="loadingFlagTableRequest" loading-text="Making Request... Please wait">
                            <template v-slot:item.status="{ item }">
                                <v-icon v-if="item.status == 0" color="red" size="25">mdi-close-thick</v-icon>
                                <v-icon v-else color="green" size="25">mdi-check-bold</v-icon>
                                <span v-if="item.dataCount == 0" class="blue-grey--text ml-5">(No Data to Display)</span>
                            </template>
                            <template v-slot:item.requestLink="{ item }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on" v-if="item.dataCount == 0" disabled><v-icon color="red" size="25">mdi-eye</v-icon></v-btn>
                                        <v-btn v-bind="attrs" v-on="on" v-else @click="generarGrafica(item.idSensor,item.imei,item.name)"><v-icon color="green" size="25">mdi-eye</v-icon></v-btn>
                                    </template>
                                    <span>See Sensor Data</span>
                                </v-tooltip>
                            </template>
                            <template v-slot:body="{items}">
                                <tbody>
                                    <tr :class="key === selectedRow ? 'custom-highlight-row' : ''" v-for="(item, key) in items" :key="item.imei">
                                        <td>{{item.name}}</td>
                                        <td>{{item.fechaRequest}}</td>
                                        <td>
                                            <v-icon v-if="item.status == 0" color="red" size="25">mdi-close-thick</v-icon>
                                            <v-icon v-else color="green" size="25">mdi-check-bold</v-icon>
                                            <span v-if="item.dataCount == 0" class="blue-grey--text ml-5">(No Data to Display)</span>
                                        </td>
                                        <td class="text-center">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn v-bind="attrs" v-on="on" v-if="item.dataCount == 0" disabled><v-icon color="red" size="25">mdi-eye</v-icon></v-btn>
                                                    <v-btn v-bind="attrs" v-on="on" v-else @click="generarGrafica(item.idSensor,item.imei,item.name,key)"><v-icon color="green" size="25">mdi-eye</v-icon></v-btn>
                                                </template>
                                                <span>See Sensor Data</span>
                                            </v-tooltip>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-data-table>
                    </template>
                </v-card>
            </v-col>

           
        </v-row>

        <!--<v-row class="mt-0">
            <v-col lg="6" sm="12" class="pt-5" cols="12">
                <v-row class="flex-row justify-end">
                    <v-col lg="8" sm="12">
                        <v-btn style="width:100%" class="mx-3 pr-5" color="success" @click="showDate()">Generate Report</v-btn>
                    </v-col>
                </v-row>                
            </v-col>
            <v-col lg="6" sm="12" cols="12">
                <v-col lg="8" sm="12">
                    <v-dialog v-model="modalScheduleReport" transition="dialog-bottom-transition" max-width="600">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn style="width:100%" color="success" @click="openScheduleForm()" v-bind="attrs" v-on="on">Schedule Report</v-btn>
                            </template>
                            <template v-slot:default="dialog">
                            <v-card>
                                <v-toolbar color="primary" dark><span class="ml-2">Schedule Report</span><v-btn :plain="true" class="ml-auto" @click="cerrar()"><font-awesome-icon icon="times" size="2x" :style="{ color: 'white'}"/></v-btn></v-toolbar>
                                <v-card-text class="pa-6">
                                    <v-chip class="pr-2 mr-3 my-4">Dias de reporte</v-chip>
                                    <v-row class="mx-1">
                                        <v-checkbox class="mr-4" v-model="dayGlobal" label="Lunes" value="1"></v-checkbox>
                                        <v-checkbox class="mr-4" v-model="dayGlobal" label="Martes" value="2"></v-checkbox>
                                        <v-checkbox class="mr-4" v-model="dayGlobal" label="Miercoles" value="3"></v-checkbox>
                                        <v-checkbox class="mr-4" v-model="dayGlobal" label="Jueves" value="4"></v-checkbox>
                                        <v-checkbox class="mr-4" v-model="dayGlobal" label="Viernes" value="5"></v-checkbox>
                                        <v-checkbox class="mr-4" v-model="dayGlobal" label="Sabado" value="6"></v-checkbox>
                                        <v-checkbox class="mr-4" v-model="dayGlobal" label="Domingo" value="7"></v-checkbox>
                                    </v-row>
                                    <div class="mt-3">
                                        <v-dialog ref="dialogTimePicker" v-model="modalTimePicker" width="290px">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field class="" v-model="timeGlobal" label="Selecciona un horario..." append-icon="mdi-clock" readonly v-bind="attrs" v-on="on">
                                                </v-text-field>
                                            </template>
                                            <v-time-picker v-model="timeGlobal" color="green lighten-1" header-color="primary">
                                                <v-spacer></v-spacer>
                                                <v-btn text color="primary" @click="modalTimePicker = false">Cancel</v-btn>
                                                <v-btn text color="primary" @click="modalTimePicker = false">ACEPTAR</v-btn>
                                            </v-time-picker>
                                        </v-dialog>   
                                    </div>
                                    <v-combobox class="" v-model="selectReport" :items="devices" item-text="name" item-value="imei" label="Seleccione Dispositivos" multiple chips deletable-chips hide-selected clearable>
                                        <template slot="item" slot-scope="data">
                                            <span class="mr-2" v-if="data.item.idSensor == 41"><font-awesome-icon icon="tint" size="1x" :style="{ color: 'blue' }"></font-awesome-icon></span>
                                            <span class="mr-2" v-if="data.item.idSensor == 37"><font-awesome-icon icon="charging-station" size="1x" :style="{ color: 'purple' }"></font-awesome-icon></span>
                                            <span class="mr-2" v-else-if="data.item.idSensor == 36"><font-awesome-icon icon="eye-dropper" size="1x" :style="{ color: 'orange' }"></font-awesome-icon></span>
                                            <span class="mr-2" v-else-if="data.item.idSensor == 32"><font-awesome-icon icon="cloud-sun-rain" size="1x" :style="{ color: '#90cdfd' }"></font-awesome-icon></span>
                                            <span class="mr-2" v-else-if="data.item.idSensor == 31"><font-awesome-icon icon="temperature-high" size="1x" :style="{ color: 'blue' }"></font-awesome-icon></span>
                                            <span class="mr-2" v-else-if="data.item.idSensor == 30"><font-awesome-icon icon="fire-alt" size="1x" :style="{ color: 'orange' }"></font-awesome-icon></span>
                                            <span class="mr-2" v-else-if="data.item.idSensor == 29"><font-awesome-icon icon="temperature-low" size="1x" :style="{ color: 'pink' }"></font-awesome-icon></span>
                                            <span class="mr-2" v-else-if="data.item.idSensor == 11"><font-awesome-icon icon="mobile" size="1x" :style="{ color: '#339af0' }"></font-awesome-icon></span>
                                            <span class="mr-2" v-else-if="data.item.idSensor == 8"><font-awesome-icon icon="toggle-on" size="1x" :style="{ color: 'red' }"></font-awesome-icon></span>
                                            <span class="mr-2" v-else-if="data.item.idSensor == 7"><font-awesome-icon icon="gas-pump" size="1x" :style="{ color: 'green' }"></font-awesome-icon></span>
                                            <span class="mr-2" v-else-if="data.item.idSensor == 3"><font-awesome-icon icon="wind" size="1x" :style="{ color: '#03b3ca' }"></font-awesome-icon></span>
                                            <span class="mr-2" v-else-if="data.item.idSensor == 2"><font-awesome-icon icon="volume-up" size="1x" :style="{ color: '#27c24c' }"></font-awesome-icon></span>
                                            <span class="mr-2" v-else-if="data.item.idSensor == 1"><font-awesome-icon icon="bolt" size="1x" :style="{ color: '#ffb900' }"></font-awesome-icon></span> 
                                            <span>{{data.item.name}}</span>
                                        </template>
                                    </v-combobox>
                                    <v-text-field label="Email 1" v-model="email1" :rules="[rules.required, rules.email]" required></v-text-field>
                                    <v-text-field label="Email 2" v-model="email2" :rules="[rules.email]"></v-text-field>
                                    <v-text-field label="Email 3" v-model="email3" :rules="[rules.email]"></v-text-field>
                                    <v-select :items="statusReport_list" item-text="status" item-value="val" label="Status" v-model="statusRGlobal" @change="testSelect3()" required></v-select>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                    <v-btn text @click="cerrar()">Cancelar</v-btn>
                                    <v-btn text color="primary" @click="saveReport()">PROGRAMAR</v-btn>
                                </v-card-actions>
                            </v-card>
                        </template> 
                    </v-dialog>
                </v-col>
            </v-col>
        </v-row>-->

        <v-row class="pt-0 mx-0" v-if="showReport == true">
            <v-col lg="12" sm="12">
                <v-card class="pa-4">
                    <loading :active="loadingFlagTableSensorRequest" :is-full-page="false" :color="'#03c9d9'"/> 
                    <v-card-title><strong>Device Data</strong></v-card-title>
                    <v-card-text>
                        <v-row class="py-4">
                    <v-col lg="6" sm="12">
                        <template class="pt-4">
                                <v-toolbar flat class="blue lighten-2 white--text"> 
                                    <v-toolbar-title><strong>{{deviceName}}</strong></v-toolbar-title>
                                </v-toolbar>
                                <v-data-table
                                :headers="arrayHeaderTable"
                                :items="arrayDataTable"
                                :items-per-page="5"
                                :footer-props="{
                                itemsPerPageOptions:[5,10,15,60]
                                }"
                                loading-text="Loading... Please wait"
                                class="elevation-1"
                                style="width: 100%"
                                > 
                                </v-data-table>
                        </template>
                    </v-col>
                    <v-col lg="6" sm="12">
                        <v-card>
                                <v-card-text>
                                    <v-card-text>
                                        <v-row>
                                            <div class="hightchart" id="grafica" style="width: 100%"></div>
                                        </v-row>
                                    </v-card-text>
                                </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
           
        <v-row class="pt-0 mx-4">
            
            
            <v-col lg="12" sm="12">
                <div v-for="(equipo, index) in arrayImeiEjemplo" :key="index">
                    <v-row class="py-4">
                        <v-col lg="6" sm="12">
                            <template class="pt-4">
                                <v-toolbar flat class="blue lighten-2 white--text"> 
                                    <v-toolbar-title><strong>{{equipo.name}}</strong></v-toolbar-title>
                                </v-toolbar>
                                <v-data-table
                                id="tablaEjecutiva"
                                :headers="arrayHeaderEjemplo[equipo.typeHeader]"
                                :items="arrayDataEjemplo[index]"
                                :items-per-page="5"
                                :loading="loadingFlagDataTable"
                                :footer-props="{
                                itemsPerPageOptions:[5,10,15,60]
                                }"
                                loading-text="Loading... Please wait"
                                class="elevation-1"
                                style="width: 100%"
                                > 
                                </v-data-table>
                            </template>
                        </v-col>

                        
                        <v-col lg="6" sm="12">
                            
                            <v-card>
                                <v-card-text>
                                    <v-card-text>
                                        <v-row>
                                            
                                            <loading :active="loadingFlagChartReport" :is-full-page="false" :color="'#03c9d9'"/>
                                            <div class="hightchart" :id="equipo.typeGraph" style="width: 100%"></div>
                                        </v-row>
                                    </v-card-text>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>
        
        <v-footer ref="abajo" style="position: absolute; bottom: 0; width: 100%;" padless color="#2196f3" class="mt-6">
            <v-col class="text-center" cols="12">
                <span class="white--text">Max4 Technologies {{ new Date().getFullYear() }} — Version 1.4</span>
            </v-col>
        </v-footer>
    </div>
</template>
<script>
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import Swal from 'sweetalert2'
import moment from 'moment'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';


//Tables
import THTable from '@/components/Reports/TemperatureHumidityReport'
import SonometerTable from '@/components/Reports/SonometerReport'

// Components
import Header from '@/components/Header'

// Devices
import FlowWater from '@/components/Devices/FlowWater'
import Sonometer from '@/components/Devices/Sonometer'
import AirQuality from '@/components/Devices/AirQuality'
import Events from '@/components/Devices/Events'
import HumedadTierra from '@/components/Devices/HumedadTierra'
import TemperatureHumidity from '@/components/Devices/TemperatureHumidity'

// Mixins
import DeviceMixin from '@/mixins/DeviceMixin'
import HelperMixin from '@/mixins/HelperMixin'

// Services
import DeviceService from '@/services/DeviceService'
export default {
    components: {
        Header,
        FlowWater,
        Sonometer,
        AirQuality,
        Events,
        HumedadTierra,
        TemperatureHumidity,
        THTable,
        SonometerTable,
        Loading,
        VueTimepicker
    },
    mixins: [
        DeviceMixin,
        DeviceService,
        HelperMixin
    ],
    data(){
        return{

            modalScheduleReport: false,
            today: moment().format("YYYY-MM-DD"),
            modalTimePicker: false,
            modalDatePicker: false,
            modalCheckbox: false,

            itemsSelectPrueba: ["Energy", "Eventos", "AirQuality"],
            valorFiltro: '',
            valorFiltroLastReport: 7,

            banderaFiltro1: false,
            banderaFiltro2: false,

            selectedRow: null,

            itemsFiltroLastReport: [
                {text: 'All Devices', value: 0},
                {text: 'Devices Reported In Last 7 Days', value: 7},
                {text: 'Devices Reported In Last 15 Days', value: 15},
                {text: 'Devices Reported In Last 30 Days', value: 31}
            ],

            select: [],

            equiposArray:[],

            //PRUEBAS TABLAS
            pruebaHeaderRequestTable:[
                { text: 'Device Name', align: 'start', sortable:true, value: 'name', width: '20%'},
                { text: 'Completed Request Date', align: 'start', sortable: true, value: 'fechaRequest', width: '30%'},
                { text: 'Request Status', align: 'start', sortable: true ,value: 'status', width: '20%' },
                { text: 'Request Data', align: 'center', sortable: true, value: 'requestLink', width: '30%'}
            ],

            pruebaDataRequestTable:[
                {
                    imei: 1,
                    name: 'Device 1',
                    fechaRequest: '2021-10-04 16:40:00',
                    status: 1,
                    requestLink: 0,
                    resquestData: [1,2,3,4,5]
                },
                {
                    imei: 2,
                    name: 'Device 2',
                    fechaRequest: '2021-10-04 16:40:00',
                    status: 0,
                    requestLink: 0,
                    resquestData: [1,2,3,4,5]
                },
                {
                    imei: 3,
                    name: 'Device 3',
                    fechaRequest: '2021-10-04 16:40:00',
                    status: 1,
                    requestLink: 0,
                    resquestData: [1,2,3,4,5]
                }
            ],

            arrayHeaderTable: [],
            arrayDataTable: [],
            deviceName: 'Sensor',

            //HEADER DEPENDIENDO DE LOS DISPOSITIVOS
            arrayHeaderEjemplo:[
                //TABLA 0 ENERGY
                [{
                    text: 'FECHA',
                    align: 'start',
                    sortable: true,
                    value: 'fechaGps'
                },
                {
                    text: 'Temp',
                    align: 'start',
                    sortable: true,
                    value: 'temp'
                },
                {
                    text: 'Kwh 1',
                    align: 'start',
                    sortable: true,
                    value: 'kwh1'
                },
                {
                    text: 'Kwh 2',
                    align: 'start',
                    sortable: true,
                    value: 'kwh2'
                },
                {
                    text: 'Kwh 3',
                    align: 'start',
                    sortable: true,
                    value: 'kwh3'
                },
                {
                    text: 'Amp 1',
                    align: 'start',
                    sortable: true,
                    value: 'amp1'
                },
                {
                    text: 'Amp 2',
                    align: 'start',
                    sortable: true,
                    value: 'amp2'
                },
                {
                    text: 'Amp 3',
                    align: 'start',
                    sortable: true,
                    value: 'amp3'
                }],
                //TABLA 1 SONOMETRO
                [{
                    text: 'FECHA',
                    align: 'start',
                    sortable: true,
                    value: 'fechaGps'
                },
                {
                    text: 'DECIBELES',
                    align: 'start',
                    sortable: true,
                    value: 'decibels'
                }],
                //TABLA 2 AIR QUAlITY
                [{
                    text: 'FECHA',
                    align: 'start',
                    sortable: true,
                    value: 'fechaGps'
                },
                {
                    text: 'CO2',
                    align: 'start',
                    sortable: true,
                    value: 'quality'
                },
                {
                    text: 'TVOC',
                    align: 'start',
                    sortable: true,
                    value: 'tvoc'
                }],        
                //TABLA 3 GAS LEVEL 
                [{
                    text: 'FECHA',
                    align: 'start',
                    sortable: true,
                    value: 'fechaGps'
                },
                {
                    text: 'LEVEL (ppm)',
                    align: 'start',
                    sortable: true,
                    value: 'level'
                }],
                //TABLA 4 EVENTOS 
                [{
                    text: 'FECHA',
                    align: 'start',
                    sortable: true,
                    value: 'fechaGps'
                },
                {
                    text: 'EVENTO 1',
                    align: 'start',
                    sortable: true,
                    value: 'ev1'
                },
                {
                    text: 'EVENTO 2',
                    align: 'start',
                    sortable: true,
                    value: 'ev2'
                },
                {
                    text: 'EVENTO 3',
                    align: 'start',
                    sortable: true,
                    value: 'ev3'
                },
                {
                    text: 'EVENTO 4',
                    align: 'start',
                    sortable: true,
                    value: 'ev4'
                }],
                //TABLA 5 TOUCH TAG
                [{
                    text: 'FECHA',
                    align: 'start',
                    sortable: true,
                    value: 'fechaGps'
                },
                {
                    text: 'Temperature (°C)',
                    align: 'start',
                    sortable: true,
                    value: 'temperature'
                },
                {
                    text: 'Pitch',
                    align: 'start',
                    sortable: true,
                    value: 'pitch'
                },
                {
                    text: 'Roll',
                    align: 'start',
                    sortable: true,
                    value: 'roll'
                }],
                //TABLA 6 HUMEDDAD EN TIERRA
                [{
                    text: 'FECHA',
                    align: 'start',
                    sortable: false,
                    value: 'fechaGps'
                },
                {
                    text: 'TEMPERATURA (C°)',
                    align: 'start',
                    sortable: false,
                    value: 'temperature'
                },
                {
                    text: 'HUMEDAD (%)',
                    align: 'start',
                    sortable: false,
                    value: 'humidity'
                }],
                //TABLA 7 PIRANOMETRO
                [{
                    text: 'FECHA',
                    align: 'start',
                    sortable: false,
                    value: 'fechaGps'
                },
                {
                    text: 'TEMPERATURA (C°)',
                    align: 'start',
                    sortable: false,
                    value: 'temperature'
                },
                {
                    text: 'WATT / METRO',
                    align: 'start',
                    sortable: false,
                    value: 'wattmetro'
                }],
                //TABLA 8 TEMPERATURA Y HUMEDAD
                [{
                    text: 'FECHA',
                    align: 'start',
                    sortable: true,
                    value: 'fechaGps'
                },
                {
                    text: 'TEMPERATURA',
                    align: 'start',
                    sortable: true,
                    value: 'temperature'
                },
                {
                    text: 'HUMEDAD',
                    align: 'start',
                    sortable: true,
                    value: 'humidity'
                }],
                //TABLA 9 ESTACION METEOROLOGICA
                [{
                    text: 'FECHA',
                    align: 'start',
                    sortable: true,
                    value: 'fechaGps'
                },
                {
                    text: 'WIND SPEED (Km/hr)',
                    align: 'start',
                    sortable: true,
                    value: 'wind_speed'
                },
                {
                    text: 'RAIN VOLUME (mm)',
                    align: 'start',
                    sortable: true,
                    value: 'rain_volume'
                },
                {
                    text: 'TEMPERATURE (°C)',
                    align: 'start',
                    sortable: true,
                    value: 'temperature'
                },
                {
                    text: 'DIRECTION WIND',
                    align: 'start',
                    sortable: true,
                    value: 'direction_wind'
                },
                {
                    text: 'RELATIVE HUMIDITY (%RH)',
                    align: 'start',
                    sortable: true,
                    value: 'relative_humidity'
                },
                {
                    text: 'PASCAL PRESSURE',
                    align: 'start',
                    sortable: true,
                    value: 'pascal_pressure'
                }],
                //TABLA 10 PH
                [{
                    text: 'FECHA',
                    align: 'start',
                    sortable: true,
                    value: 'fechaGps'
                },
                {
                    text: 'TEMPERATURA',
                    align: 'start',
                    sortable: true,
                    value: 'Temperatura'
                },
                {
                    text: 'NIVEL',
                    align: 'start',
                    sortable: true,
                    value: 'nivel'
                }],
                //TABLA 11 EC
                [{
                    text: 'FECHA',
                    align: 'start',
                    sortable: true,
                    value: 'fechaGps'
                },
                {
                    text: 'TEMPERATURA (°C)',
                    align: 'start',
                    sortable: true,
                    value: 'Temperatura'
                },
                {
                    text: 'uS/cm',
                    align: 'start',
                    sortable: true,
                    value: 'uScm'
                }],
                //TABLA 12 FLUJO
                [{
                    text: 'FECHA',
                    align: 'start',
                    sortable: true,
                    value: 'fechaGps'
                },
                {
                    text: 'LITERS PER MINUTE',
                    align: 'start',
                    sortable: true,
                    value: 'lts_minute'
                },
                {
                    text: 'TOTAL LITERS',
                    align: 'start',
                    sortable: true,
                    value: 'lts_total'
                }],
                //TABLA DC MAX
                [
                    {
                        text: 'FECHA',
                        align: 'start',
                        sortable: true,
                        value: 'fechaGps'
                    },
                    {
                        text: 'CANAL 1',
                        align: 'start',
                        sortable: true,
                        value: 'p1'
                    },
                    {
                        text: 'CANAL 2',
                        align: 'start',
                        sortable: true,
                        value: 'p2'
                    },
                    {
                        text: 'CANAL 3',
                        align: 'start',
                        sortable: true,
                        value: 'p3'
                    },
                    {
                        text: 'CANAL 4',
                        align: 'start',
                        sortable: true,
                        value: 'p4'
                    },
                    {
                        text: 'POWER NOW',
                        align: 'start',
                        sortable: true,
                        value: 'powerNow'
                    },
                ]
            ],

            arrayDataEjemplo:[], //ARREGLO SE LLENARA DESDE EL DEVICE MIXIN
            arrayDataEjemploTemporal: [],

            arrayImeiEjemplo:[],
            arrayImeiEjemploTemporal:[],  

            json_data: [
                {
                    name: "Tony Peña",
                    city: "New York",
                    country: "United States",
                    birthdate: "1978-03-15",
                    phone: {
                    mobile: "1-541-754-3010",
                    landline: "(541) 754-3010",
                    },
                },
                {
                    name: "Thessaloniki",
                    city: "Athens",
                    country: "Greece",
                    birthdate: "1987-11-23",
                    phone: {
                    mobile: "+1 855 275 5071",
                    landline: "(2741) 2621-244",
                    },
                },
            ],

            //VARIABLES PARA REPORTES PROGRAMADOS

            selectReport: [],

            arregloImeisReport: [],
            arregloDaysReport: [],
            arregloEmailsReport: [],

            scheduleData:{
                periodicity: '',
                configuration: '',
                enable: '',
                idUser: '',
                time: ''
            },

            scheduleConfiguration: {
                days: '',
                imeis: '',
                emails: ''
            },


            horarioElegido: null,
            statusRGlobal: '',
            rangeGlobal: '',
            timeGlobal: '',
            dayGlobal: [],
            email1: '',
            email2: '',
            email3: '',

            periodicity_list:[
                {dia: 'Lunes', val: '1'},
                {dia: 'Martes', val: '2'},
                {dia: 'Miercoles', val: '3'}, 
                {dia: 'Jueves', val: '4'}, 
                {dia: 'Viernes', val: '5'}, 
                {dia: 'Sabado', val: '6'}, 
                {dia: 'Domingo', val: '7'}
            ],

            rangeDays_list: [1,2,3,4,5,6,7],

            statusReport_list: [
                {status: 'Enable', val: 'YES'},
                {status: 'Disable', val: 'NO'},
            ]
        }
    },

    watch:{
        "valorFiltro":function(){
            this.loadingFlagSelect = true
            setTimeout(this.apagado,1000);
        },

        "valorFiltroLastReport":function(){
            this.loadingFlagSelect = true
            setTimeout(this.apagado,1000);
        }
    },

    computed:{
        formatDate(){
            var arreglotemporal = []
            var fechaPart1
            var fechaPart2
            var fechaPart1String
            var fechaPart2String
            if(this.rangoFechasExecutive.length == 2){
                console.log("BANDERA RANGO");

                fechaPart1 = new Date(this.rangoFechasExecutive[0]);
                fechaPart2 = new Date(this.rangoFechasExecutive[1])

                fechaPart1String = moment(fechaPart1).add(1,'days').format('MMMM/DD/YYYY')
                fechaPart2String = moment(fechaPart2).add(1,'days').format('MMMM/DD/YYYY')

                arreglotemporal.push(fechaPart1String);
                arreglotemporal.push(fechaPart2String);

                return arreglotemporal.join(' ~ ');
            }
        },

        filteredSensorData(){
            var fechaHoy = moment().format("YYYY-MM-DD HH:mm:ss");
            var valorFiltroAux = this.valorFiltro
            var valorFiltroTime = this.valorFiltroLastReport;

            if(this.valorFiltroLastReport == 0 && this.valorFiltro == ''){
                return this.devices;
            }else{
                if(this.valorFiltro != '' && this.valorFiltroLastReport != 0){
                    let filteredSensor = this.devices.filter(function(item){
                    let time = moment().diff( item.fechaGps, 'days');
                    return time < valorFiltroTime && item.idSensor === valorFiltroAux;
                    //return item.idSensor === this.valorFiltroAux
                    });
                    return filteredSensor;
                }else if(this.valorFiltro == '' && this.valorFiltroLastReport != 0){
                    let filteredSensor = this.devices.filter(function(item){
                    let time = moment().diff( item.fechaGps, 'days');
                    return time < valorFiltroTime;
                    //return item.idSensor === this.valorFiltroAux
                    });
                    return filteredSensor;
                }else if(this.valorFiltro != '' && this.valorFiltroLastReport == 0){
                    let filteredSensor = this.devices.filter(function(item){
                    let time = moment().diff( item.fechaGps, 'days');
                    return item.idSensor === valorFiltroAux;
                    //return item.idSensor === this.valorFiltroAux
                    });
                    return filteredSensor;
                }
            }
        },

        filteredSensorDataTest(){
            if(this.valorFiltro == ''){
                return this.devices;
            }else{
                return this.devices.filter(item => item.idSensor === this.valorFiltro)
            }
        }
    },

    mounted(){
        if(localStorage.idUser == null || localStorage.idUser == '' || localStorage.getItem("idUser") === null){
            this.$router.push({path: '/'});
        }else{
            if (localStorage.getItem("sessionTime") === null) {
                this.$router.push({path: '/'});
            }else{
                var fechaSesion = localStorage.getItem("sessionTime");
                let time = moment().diff( fechaSesion, 'minutes');
                if(time > 360){
                    this.$router.push({path: '/'});
                }else if( time <= 360){
                    
                }
            }
        }

        this.itemsNavigation.splice(0);
        //this.dashboardFlag = 0,
        //this.executiveFlag = 1;
        this.mountedAlertMixin();

        console.log("************************aaaa**********************");
        console.log();
        console.log();
        console.log(this.arrayImeiEjemplo);
        console.log();
        console.log();
        console.log();

    },

    updated(){
        /*this.arrayImeiEjemplo.forEach(element => {
            var identSensor = element.idSensor;
            if(identSensor == 2){
                var params = {
                    idHighchart: element.typeGraph,
                    title: element.name,
                    labelX: "Decibeles",
                    series:[
                        {
                            data: this.arrayDataSonometer,
                            color: 'blue',
                            name: "Decibeles"
                        },
                    ],
                    categories: this.arrayDataFechaGps
                };
            }else if(identSensor == 3){
                var params = {
                    idHighchart: element.typeGraph,
                    title: element.name,
                    labelX: "CO2",
                    series:[
                        {
                            data: this.arrayDataAirQuality,
                            color: 'blue',
                            name: "CO2"
                        }
                    ],
                    categories: this.arrayDataFechaGps
                };
            }else if(identSensor == 8){
                var params = {
                    idHighchart: element.typeGraph,
                    title: element.name,
                    labelX: "Events",
                    series:[
                        {
                            data: this.arrayDataEvento1,
                            color: 'blue',
                            name: "Evento 1"
                        },
                        {
                            data: this.arrayDataEvento2,
                            color: 'red',
                            name: "Evento 2"
                        },
                        {
                            data: this.arrayDataEvento3,
                            color: 'yellow',
                            name: "Evento 3"
                        },
                        {
                            data: this.arrayDataEvento4,
                            color: 'green',
                            name: "Evento 4"
                        }
                    ],
                    categories: this.arrayDataFechaGps
                };
            }else if(identSensor == 31){
                var params = {
                    idHighchart: element.typeGraph,
                    title: element.name,
                    labelX: "Temperatura / Humedad",
                    series:[
                        {
                            data: this.arrayDataTemperature,
                            color: 'blue',
                            name: "Temperature"
                        },
                        {
                            data: this.arrayDataHumidity,
                            color: 'red',
                            name: "Humidity"
                        },
                    ],
                    categories: this.arrayDataFechaGps
                };
            }else if(identSensor == 29){
                var params = {
                    idHighchart: element.typeGraph,
                    title: element.name,
                    labelX: "Temperatura / Humedad",
                    series:[
                        {
                            data: this.arrayDataTemperature,
                            color: 'blue',
                            name: "Temperature"
                        },
                        {
                            data: this.arrayDataHumidity,
                            color: 'red',
                            name: "Humidity"
                        },
                    ],
                    categories: this.arrayDataFechaGps
                };
            }
            /*const params = {
                idHighchart: element.typeGraph,
                title: element.name,
                labelX: "Temperatura / Humedad",
                series:[
                    {
                        data: [1,2,3],
                        color: 'blue',
                        name: "Temperature"
                    },
                    {
                        data: [4,3,8],
                        color: 'red',
                        name: "Humidity"
                    },
                ]
            };
            this.makeHighchartExample(params);
            this.makeHighchart(params);
        });*/
    },

    methods:{

        apagado(){
            this.loadingFlagSelect = false
        },

        goToBottom(){
            var element = this.$refs['abajo'];
            var top = element.offsetTop;
            window.scrollTo(0, top);
        },

        changeFiltro1(){
            console.log("BANDERA CHANGE1");
            console.log(this.banderaFiltro1);
        },

        changeFiltro2(){
            console.log("BANDERA CHANGE2");
            console.log(this.banderaFiltro2);
        },

        rowSelect(idx){
            this.selectedRow = idx;
        },

        formatDatePicker(fecha){
            
        },

        showTipoSensor(idSensor){
            console.log("BANDERA ID SENSOR");
            console.log(this.valorFiltro);
        },


        showDate(){
            this.loadingFlagTableRequest = true
            this.loadingFlagDataTable = true
            this.arrayDataEjemplo = [];
            this.requestExecutiveRequest = [];
            var arrayHeadertemporal = [];

            var fechaFromv = new Date(this.rangoFechasExecutive[0]);
            var fechaTov = new Date(this.rangoFechasExecutive[1]);
            var todayDate = new Date();
            var diff_time_today = todayDate.getTime() - fechaFromv.getTime();
            var diff_days_today = diff_time_today / (1000 * 3600 * 24);

            var diff_time = fechaTov.getTime() - fechaFromv.getTime()
            var diff_days = diff_time / (1000 * 3600 * 24);

            if(this.select.length < 1){
                Swal.fire({
                    title: '¡No devices selected!',
                    icon: 'warning',
                    text: 'Please choose at least one device.',
                });
            }else{
                if(this.rangoFechasExecutive.length < 2){
                    Swal.fire({
                        title: '¡Missing Range!',
                        icon: 'warning',
                        text: 'Please choose a date range (two dates)',
                    });
                }else{
                    if(Date.parse(this.rangoFechasExecutive[0]) > Date.parse(this.rangoFechasExecutive[1])){
                        console.log("FECHA 1 ES MAYOR A FECHA 2");
                        Swal.fire({
                            title: '¡Range Error!',
                            icon: 'warning',
                            text: 'First date greater than the second date',
                        });
                    }else{
                        if(diff_days >= 90){
                            Swal.fire({
                                title: '¡Range Greater than 3 Months!',
                                icon: 'warning',
                                text: 'Please check with your system administrator for querys longer than 3 months'
                            });
                        }else{
                            if(diff_days_today >= 90){
                                Swal.fire({
                                    title: '¡Consultation is more than 3 months ago!',
                                    icon: 'warning',
                                    text: 'Please check with your system administrator for querys longer than 3 months',
                                });
                            }else{
                                this.select.forEach(element => {
                                    var tipoGrafica;
                                    var tipoheader;
                                    if(element.idSensor == 1){ //ENERGY
                                        tipoheader = 0
                                        tipoGrafica = element.imei
                                    }else if(element.idSensor == 2){ //SONOMETRO
                                        tipoheader = 1
                                        tipoGrafica = element.imei
                                    }else if(element.idSensor == 3){ //AIR QUALITY
                                        tipoheader = 2
                                        tipoGrafica = element.imei
                                    }else if(element.idSensor == 7){ //GAS LEVEL
                                        tipoheader = 3
                                        tipoGrafica = element.imei
                                    }else if(element.idSensor == 8){ //EVENTS
                                        tipoheader = 4
                                        tipoGrafica = element.imei
                                    }else if(element.idSensor == 11){ //TOUCH TAG
                                        tipoheader = 5
                                        tipoGrafica = element.imei
                                    }else if(element.idSensor == 29){ //HUMEDAD EN TIERRA
                                        tipoheader = 6
                                        tipoGrafica = element.imei
                                    }else if(element.idSensor == 30){ //PIRANOMETRO
                                        tipoheader = 7
                                        tipoGrafica = element.imei
                                    }else if(element.idSensor == 31){ //TEMPERATURA Y HUMEDAD
                                        tipoheader = 8
                                        tipoGrafica = element.imei
                                    }else if(element.idSensor == 32){ //ESTACION METEOROLOGICA
                                        tipoheader = 9
                                        tipoGrafica = element.imei
                                    }else if(element.idSensor == 36){ //PH
                                        tipoheader = 10
                                        tipoGrafica = element.imei
                                    }else if(element.idSensor == 37){ //EC
                                        tipoheader = 11
                                        tipoGrafica = element.imei
                                    }else if(element.idSensor == 41){ //FLUJO
                                        tipoheader = 12
                                        tipoGrafica = element.imei
                                    }else if(element.idSensor == 48){ //BID
                                        tipoheader = 14
                                        tipoGrafica = element.imei
                                    }

                                    var arrtemporal = {
                                        imei: element.imei,
                                        name: element.name,
                                        idSensor: element.idSensor,
                                        fechaFrom: this.rangoFechasExecutive[0],
                                        fechaTo: this.rangoFechasExecutive[1]
                                    }
                                    var arrtemporal2 = {
                                        imei: element.imei,
                                        idSensor: element.idSensor,
                                        name: element.name,
                                        typeHeader: tipoheader,
                                        typeGraph: tipoGrafica
                                    }

                                    this.equiposArray.push(arrtemporal);
                                    //PARA EL TIPO DE HEADER A IMPRIMIR EN LA TABLA
                                    arrayHeadertemporal.push(arrtemporal2);
                                    });

                                //ALMACENAMOS IMEIS PARA USARLOS DE INDEX EN LA IMPRESION DE TABLAS
                                this.arrayImeiEjemplo = arrayHeadertemporal;
                                this.equiposArray.forEach(element => {
                                    this.getDataSensorPositionHistoricoExecutive(element);
                                });

                                this.equiposArray = [];
                            }
                        }
                    }
                }
                                
            }
        },

        async generateReports(){

            //var idGrafica = "grafica"
            //this.destroyHighchart(idGrafica);

            //this.loadingFlagTableSensorRequest2 = true;
            this.arrayAuxiliar = []
            this.selectedRow = 0;
            this.loadingFlagTableRequest = true
            this.loadingFlagDataTable = true
            this.arrayDataEjemplo = [];
            this.requestExecutiveRequest = [];
            var arrayHeadertemporal = [];
            this.arrayObjectsAGraficas = [];

            this.arrayHeaderTable = [];
            this.arrayDataTable = [];
            this.deviceName = 'Sensor'

            var fechaFromv = new Date(this.rangoFechasExecutive[0]);
            var fechaTov = new Date(this.rangoFechasExecutive[1]);
            var todayDate = new Date();
            var diff_time_today = todayDate.getTime() - fechaFromv.getTime();
            var diff_days_today = diff_time_today / (1000 * 3600 * 24);

            var diff_time = fechaTov.getTime() - fechaFromv.getTime()
            var diff_days = diff_time / (1000 * 3600 * 24);

            if(this.select.length < 1){
                Swal.fire({
                    title: '¡No devices selected!',
                    icon: 'warning',
                    text: 'Please choose at least one device.',
                });
                this.loadingFlagTableRequest = false
                this.loadingFlagDataTable = false
                this.destroyHighchart();
            }else{
                if(this.rangoFechasExecutive.length < 2){
                    Swal.fire({
                        title: '¡Missing Range!',
                        icon: 'warning',
                        text: 'Please choose a date range (two dates)',
                    });
                    this.loadingFlagTableRequest = false
                    this.loadingFlagDataTable = false
                    this.destroyHighchart();
                }else{
                    if(Date.parse(this.rangoFechasExecutive[0]) > Date.parse(this.rangoFechasExecutive[1])){
                        console.log("FECHA 1 ES MAYOR A FECHA 2");
                        Swal.fire({
                            title: '¡Range Error!',
                            icon: 'warning',
                            text: 'First date greater than the second date',
                        });
                        this.loadingFlagTableRequest = false
                        this.loadingFlagDataTable = false
                        this.destroyHighchart();
                    }else{
                        if(diff_days >= 90){
                            Swal.fire({
                                title: '¡Range Greater than 3 Months!',
                                icon: 'warning',
                                text: 'Please check with your system administrator for querys longer than 3 months'
                            });
                            this.loadingFlagTableRequest = false
                            this.loadingFlagDataTable = false
                            this.destroyHighchart();
                        }else{
                            if(diff_days_today >= 90){
                                Swal.fire({
                                    title: '¡Consultation is more than 3 months ago!',
                                    icon: 'warning',
                                    text: 'Please check with your system administrator for querys longer than 3 months',
                                });
                                this.loadingFlagTableRequest = false
                                this.loadingFlagDataTable = false
                                this.destroyHighchart();
                            }else{
                                if(this.showReport == false){
                                    this.showReport = true;
                                }else{
                                    this.destroyHighchart();
                                }

                                this.select.forEach(element => {
                                    var tipoGrafica;
                                    var tipoheader;
                                    if(element.idSensor == 1){ //ENERGY
                                        tipoheader = 0
                                        tipoGrafica = element.imei
                                    }else if(element.idSensor == 2){ //SONOMETRO
                                        tipoheader = 1
                                        tipoGrafica = element.imei
                                    }else if(element.idSensor == 3){ //AIR QUALITY
                                        tipoheader = 2
                                        tipoGrafica = element.imei
                                    }else if(element.idSensor == 7){ //GAS LEVEL
                                        tipoheader = 3
                                        tipoGrafica = element.imei
                                    }else if(element.idSensor == 8){ //EVENTS
                                        tipoheader = 4
                                        tipoGrafica = element.imei
                                    }else if(element.idSensor == 11){ //TOUCH TAG
                                        tipoheader = 5
                                        tipoGrafica = element.imei
                                    }else if(element.idSensor == 29){ //HUMEDAD EN TIERRA
                                        tipoheader = 6
                                        tipoGrafica = element.imei
                                    }else if(element.idSensor == 30){ //PIRANOMETRO
                                        tipoheader = 7
                                        tipoGrafica = element.imei
                                    }else if(element.idSensor == 31){ //TEMPERATURA Y HUMEDAD
                                        tipoheader = 8
                                        tipoGrafica = element.imei
                                    }else if(element.idSensor == 32){ //ESTACION METEOROLOGICA
                                        tipoheader = 9
                                        tipoGrafica = element.imei
                                    }else if(element.idSensor == 36){ //PH
                                        tipoheader = 10
                                        tipoGrafica = element.imei
                                    }else if(element.idSensor == 37){ //EC
                                        tipoheader = 11
                                        tipoGrafica = element.imei
                                    }else if(element.idSensor == 41){ //FLUJO
                                        tipoheader = 12
                                        tipoGrafica = element.imei
                                    }else if(element.idSensor == 47){ //DCMAX
                                        tipoheader = 13
                                        tipoGrafica = element.imei                                    
                                    }else if(element.idSensor == 48){ //BIDI
                                        tipoheader = 14
                                        tipoGrafica = element.imei
                                    }

                                    var arrtemporal = {
                                        imei: element.imei,
                                        name: element.name,
                                        idSensor: element.idSensor,
                                        fechaFrom: this.rangoFechasExecutive[0],
                                        fechaTo: this.rangoFechasExecutive[1]
                                    }
                                    var arrtemporal2 = {
                                        imei: element.imei,
                                        idSensor: element.idSensor,
                                        name: element.name,
                                        typeHeader: tipoheader,
                                        typeGraph: tipoGrafica
                                    }

                                    this.equiposArray.push(arrtemporal);
                                    //PARA EL TIPO DE HEADER A IMPRIMIR EN LA TABLA
                                    arrayHeadertemporal.push(arrtemporal2);
                                    });

                                //ALMACENAMOS IMEIS PARA USARLOS DE INDEX EN LA IMPRESION DE TABLAS
                                this.arrayImeiEjemploTemporal = arrayHeadertemporal;

                                console.log("IMEIS");
                                console.log(this.arrayImeiEjemplo);

                                console.log("EQUIPOS CONSULTADOS: ");
                                console.log(this.equiposArray);

                                console.log("BANDERA ASYNC");

                                await Promise.all(this.equiposArray.map(async (i) =>{
                                    //console.log(i);
                                    await this.getDataSensorPositionHistoricoExecutive(i);
                                }));

                                this.loadingFlagTableRequest = false;

                                /*this.equiposArray.forEach(element => {
                                    this.getDataSensorPositionHistoricoExecutive(element);
                                });*/

                                /*Promise.all(this.equiposArray).then(element => {
                                    this.getDataSensorPositionHistoricoExecutive(element);
                                });*/

                                this.equiposArray = [];

                                var arregloImpresionInicial = this.arrayAuxiliar;

                                this.generarGraficaInicial(arregloImpresionInicial[0].idSensor,arregloImpresionInicial[0].imei,arregloImpresionInicial[0].name);
                            }
                        }
                    }
                }
                                
            }
        },

        mensajePromise(){
            console.log("Datos Obtenidos");
        },

        generarGrafica(idSensor, imei, name, key){
            this.selectedRow = key;
            //this.destroyHighchart();
            //window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
            var headerTemporal = []
            var dataTemporal = []
            var idChart = "grafica";

            if(idSensor == 1){
                headerTemporal = this.arrayHeaderEjemplo[0];
            }else if(idSensor == 2){
                headerTemporal = this.arrayHeaderEjemplo[1];
            }else if(idSensor == 3){
                headerTemporal = this.arrayHeaderEjemplo[2];
            }else if(idSensor == 7){
                headerTemporal = this.arrayHeaderEjemplo[3];
            }else if(idSensor == 8){
                headerTemporal = this.arrayHeaderEjemplo[4];
            }else if(idSensor == 11){
                headerTemporal = this.arrayHeaderEjemplo[5];
            }else if(idSensor == 29){
                headerTemporal = this.arrayHeaderEjemplo[6];
            }else if(idSensor == 30){
                headerTemporal = this.arrayHeaderEjemplo[7];
            }else if(idSensor == 31){
                headerTemporal = this.arrayHeaderEjemplo[8];
            }else if(idSensor == 32){
                headerTemporal = this.arrayHeaderEjemplo[9];
            }else if(idSensor == 36){
                headerTemporal = this.arrayHeaderEjemplo[10];
            }else if(idSensor == 37){
                headerTemporal = this.arrayHeaderEjemplo[11];
            }else if(idSensor == 41){
                headerTemporal = this.arrayHeaderEjemplo[12];
            }else if(idSensor == 47){
                headerTemporal = this.arrayHeaderEjemplo[13];
            }

            this.arrayHeaderTable = headerTemporal;
            this.deviceName = name;
            dataTemporal = this.arrayAuxiliar.find(equipo => equipo.imei == imei);
            this.arrayDataTable = dataTemporal.datos;

            var objetoGrafica = this.arrayObjectsAGraficas.find(equipo => equipo.idGrafica == imei)
            
            
            try {
                this.generateGraphIndividual(idChart,idSensor,objetoGrafica);
                /*Swal.fire({
                    title: 'Generando Grafica...',
                    icon: 'warning',
                    timer: 1500,
                    showConfirmButton: false,
                    showCancelButton: false
                })*/
                let timerInterval
                Swal.fire({
                title: 'Generating Graph',
                timer: 2000,
                didOpen: () => {
                    Swal.showLoading()
                    const b = Swal.getHtmlContainer().querySelector('b')
                    timerInterval = setInterval(() => {
                    b.textContent = Swal.getTimerLeft()
                    }, 100)
                },
                willClose: () => {
                    clearInterval(timerInterval)
                }
                }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                    console.log('I was closed by the timer')
                }
                })
            } catch (error) {
                Swal.fire({
                    title: 'Error',
                    icon: 'error',
                    text: error,
                });
            }
            //this.irAElemento();
        },

        generarGraficaInicial(idSensor, imei, name){
            //this.destroyHighchart();
            //window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
            var headerTemporal = []
            var dataTemporal = []
            var idChart = "grafica";

            if(idSensor == 1){
                headerTemporal = this.arrayHeaderEjemplo[0];
            }else if(idSensor == 2){
                headerTemporal = this.arrayHeaderEjemplo[1];
            }else if(idSensor == 3){
                headerTemporal = this.arrayHeaderEjemplo[2];
            }else if(idSensor == 7){
                headerTemporal = this.arrayHeaderEjemplo[3];
            }else if(idSensor == 8){
                headerTemporal = this.arrayHeaderEjemplo[4];
            }else if(idSensor == 11){
                headerTemporal = this.arrayHeaderEjemplo[5];
            }else if(idSensor == 29){
                headerTemporal = this.arrayHeaderEjemplo[6];
            }else if(idSensor == 30){
                headerTemporal = this.arrayHeaderEjemplo[7];
            }else if(idSensor == 31){
                headerTemporal = this.arrayHeaderEjemplo[8];
            }else if(idSensor == 32){
                headerTemporal = this.arrayHeaderEjemplo[9];
            }else if(idSensor == 36){
                headerTemporal = this.arrayHeaderEjemplo[10];
            }else if(idSensor == 37){
                headerTemporal = this.arrayHeaderEjemplo[11];
            }else if(idSensor == 41){
                headerTemporal = this.arrayHeaderEjemplo[12];
            }else if(idSensor == 47){
                headerTemporal = this.arrayHeaderEjemplo[13];            
            }else if(idSensor == 48){
                headerTemporal = this.arrayHeaderEjemplo[14];
            }

            this.arrayHeaderTable = headerTemporal;
            this.deviceName = name;
            dataTemporal = this.arrayAuxiliar.find(equipo => equipo.imei == imei);
            this.arrayDataTable = dataTemporal.datos;

            var objetoGrafica = this.arrayObjectsAGraficas.find(equipo => equipo.idGrafica == imei)

            console.log("aaaa3333333333 ....... .... ... .. ");
            console.log(this.arrayObjectsAGraficas);

            this.generateGraphIndividual(idChart,idSensor,objetoGrafica);
            //this.irAElemento();
        },

        irAElemento(){
           document.getElementById("tablaEjecutiva").scrollIntoView({
                behavior: "smooth"
            });
        },

        openScheduleForm(){
            var params = {
                idUser: localStorage.idUser
            };
            this.modalScheduleReport = true;
            /*this.db_getScheduleReport(params).then((result) => {
                //console.log(result.data.Reporte);
                var resultadoObject = result.data.Reporte;
                var stringresultado = result.data.Mensaje;
                if(stringresultado == 'report_exist'){
                    console.log("HAY DATOS");
                    var reportConfiguration = JSON.parse(resultadoObject.configuration);
                    this.scheduleData.periodicity = resultadoObject.periodicity;
                    this.scheduleData.configuration.days = reportConfiguration.days;
                    this.scheduleData.configuration.imeis = reportConfiguration.imeis;
                    this.scheduleData.configuration.emails = reportConfiguration.emails;
                    this.scheduleData.enable = resultadoObject.enable;
                    this.scheduleData.idUser = resultadoObject.idUser;
                    this.scheduleData.time = resultadoObject.time; 

                    console.log("BANDERA DATOS DE REPORTE: ");
                    console.log(this.scheduleData);
                }else{
                    console.log("NO HAY DATOS");
                }
                
            }).catch((err) =>{
                console.log(err);
            });*/
        },

        cerrar(){      
            this.modalScheduleReport = false;
            this.clearScheduleForm();
        },

        clearScheduleForm(){
            this.scheduleConfiguration.days = ''
            this.scheduleConfiguration.imeis = ''
            this.scheduleConfiguration.emails = ''

            this.scheduleData.periodicity = '',
            this.scheduleData.configuration = ''
            this.scheduleData.enable = ''
            this.scheduleData.idUser = ''
            this.scheduleData.time = ''

            this.selectReport = []
            this.statusRGlobal = ''
            this.rangeGlobal = ''
            this.timeGlobal =  ''
            this.dayGlobal = []
            this.email1 =  ''
            this.email2 =  ''
            this.email3 = ''
        },

        saveReport(){
            var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            var tiempo_temporal
            var estado_temporal
            var arregloImeis_temporal = []
            var arregloDays_temporal = []
            var arregloEmails_temporal = []

            /*console.log("DATOS A GUARDAR EN BD: ");
            console.log("Dias a reportar: ");
            console.log(this.dayGlobal);
            console.log("Hora a reportar: ");
            console.log(this.timeGlobal);
            console.log("Devices: ");
            console.log(this.selectReport);
            console.log("CORREOS: ");
            console.log(this.email1);
            console.log(this.email2);
            console.log(this.email3);
            console.log("ESTATUS: ");
            console.log(this.statusRGlobal);*/

            if(this.dayGlobal.length > 0){
                if(this.timeGlobal != ""){
                    if(this.selectReport.length > 0){
                        if(this.email1 != ""){
                            if(this.statusRGlobal != ""){

                                arregloDays_temporal = this.dayGlobal;
                                tiempo_temporal = this.timeGlobal;

                                this.selectReport.forEach(element =>{
                                    arregloImeis_temporal.push(element.imei);
                                });

                                arregloEmails_temporal.push(this.email1);

                                if(this.email2 != ""){
                                    arregloEmails_temporal.push(this.email2);
                                }

                                if(this.email3 != ""){
                                    arregloEmails_temporal.push(this.email3);
                                }

                                estado_temporal = this.statusRGlobal

                                //-----------

                                this.scheduleData.periodicity = 1;
                                this.scheduleConfiguration.days = arregloDays_temporal;
                                this.scheduleConfiguration.imeis = arregloImeis_temporal;
                                this.scheduleConfiguration.emails = arregloEmails_temporal;
                                var jsonConfig = JSON.stringify(this.scheduleConfiguration);

                                this.scheduleData.configuration = jsonConfig;

                                this.scheduleData.enable = estado_temporal;
                                this.scheduleData.idUser = localStorage.idUser;
                                this.scheduleData.time = tiempo_temporal;

                                console.log("DATOS A ENVIAR: ");
                                console.log(this.scheduleData);
                                console.log(this.scheduleConfiguration);

                                this.db_storeScheduleReport(this.scheduleData).then((result) => {
                                    Swal.fire({
                                            title: '¡Good Job!',
                                            icon: 'success',
                                            text: 'Scheduled Report',
                                    });
                                    this.modalScheduleReport = false;
                                    console.log("programado");
                                    this.clearScheduleForm();
                                }).catch((err) => {
                                    console.log(err);
                                    Swal.fire({
                                        title: '¡Error!',
                                        icon: 'error',
                                        text: err,
                                    });
                                });
                            }else{
                                Swal.fire({
                                    title: 'Warning!',
                                    icon: 'warning',
                                    text: 'Please choose a status report',
                                }); 
                            }
                        }else{
                            Swal.fire({
                                title: 'Warning!',
                                icon: 'warning',
                                text: 'Please input at least one email to report',
                            }); 
                        }
                    }else{
                        Swal.fire({
                            title: '¡Warning!',
                            icon: 'warning',
                            text: 'Please choose at least one device',
                        });
                    }
                }else{
                    Swal.fire({
                        title: '¡Warnign!',
                        icon: 'warning',
                        text: 'Please choose an hour and minutes to report',
                    });
                }
            }else{
                Swal.fire({
                    title: '¡Warnign!',
                    icon: 'warning',
                    text: 'Please choose a day to report',
                });
            }

            /*
            var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if(this.email1.match(mailformat)){
                console.log("EMAIL 1 VALIDO");
                arregloEmailsReport_temporal.push(this.email1);
            }else{
                console.log("EMAIL 1 NO VALIDO");
            }

            if(this.email2.match(mailformat)){
                console.log("EMAIL 2 VALIDO");
                arregloEmailsReport_temporal.push(this.email2);
            }else{
                console.log("EMAIL 2 NO VALIDO");
            }

            if(this.email3.match(mailformat)){
                console.log("EMAIL 3 VALIDO");
                arregloEmailsReport_temporal.push(this.email3);
            }else{
                console.log("EMAIL 3 NO VALIDO");
            }
            */
        },

        //TESTS

        testSelect(){
            console.log(this.dayGlobal);
        },

        testSelect2(){
            console.log(this.rangeGlobal);
        },

        testSelect3(){
            console.log(this.statusRGlobal);
        }

    }
}
</script>
<style>
.height-100{
    height: 100% !important;
}
.showBorders{
    border: 1px solid rgba(0, 0, 0, 0.12) ;
}
.no-hover-action:hover{
    background-color: white !important;
}

.custom-highlight-row{
  background-color: rgb(100, 181, 246)
}

.bodyAlerts{
    margin-bottom: 7%;
}

.footerFondo{
    position: absolute;
    bottom: 0;
    width: 100%;

}
</style>