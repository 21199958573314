import axios from 'axios';
const DeviceService = {
    methods: {
        indexDevices(params){
            return axios.post("/platform/devices", params);
        },
        
        /*db_indexDevicesUser(params){
            return axios.post("auth/indexDevicesUser", params);
        },*/

        db_indexDevicesUser(params){
            return axios.post("auth/indexDevicesUser", params, {
                headers: {
                    'Authorization': `Bearer ` + localStorage.token_p
                }
            });
        },

        getDataDevice(params){
            return axios.post("auth/getDataDevice", params, {
                headers: {
                    'Authorization': `Bearer ` + localStorage.token_p
                }
            });
        },

        db_getDataSensorPosition(params){
            return axios.post("auth/getDataSensorPosition", params, {
                headers: {
                    'Authorization': `Bearer ` + localStorage.token_p
                }
            });
        },

        db_getDataSensorPositionHistorico(params){
            // alert('Servicio')
            // console.log(params);
            return axios.post("auth/getDataSensorPositionHistorico", params, {
                headers: {
                    'Authorization': `Bearer ` + localStorage.token_p
                }
            });
        },

        getTimezones(){
            return axios.get("auth/getTimezones");
        },

        updateConfiguracion(params){
            return axios.post("auth/updateConfiguracion", params, {
                headers: {
                    'Authorization': `Bearer ` + localStorage.token_p
                }
            });
        },

        db_updateAlertConfiguration(params){
            return axios.post("auth/updateAlertConfiguration", params, {
                headers: {
                    'Authorization': `Bearer ` + localStorage.token_p
                }
            });
        },

        db_storeHistoricLiters(params){
            return axios.post("auth/storeHistoricLiters", params, {
                headers: {
                    'Authorization': `Bearer ` + localStorage.token_p
                }
            });
        },

        db_updateHistoricLiters(params){ //no se usa
            return axios.post("auth/updateHistoricLiters", params);
        },

        db_getHistoricLiters(params){
            return axios.post("auth/getHistoricLiters", params, {
                headers: {
                    'Authorization': `Bearer ` + localStorage.token_p
                }
            });
        },

        db_getTotalLiters(params){
            return axios.post("auth/getTotalLiters", params, {
                headers: {
                    'Authorization': `Bearer ` + localStorage.token_p
                }
            });
        },

        db_getMonthlyDataLiters(params){
            return axios.post("auth/getMonthlyDataLiters", params, {
                headers: {
                    'Authorization': `Bearer ` + localStorage.token_p
                }
            });
        },

        db_getDailyMonthlyEnergy(params){
            return axios.post("auth/getDailyMonthlyEnergy", params, {
                headers: {
                    'Authorization': `Bearer ` + localStorage.token_p
                }
            });
        },

        db_storeScheduleReport(params){
            return axios.post("auth/storeScheduleReport", params);
        },

        db_getScheduleReport(params){
            return axios.post("auth/getScheduleReport", params);
        },

        db_getUserAlerts(params){
            return axios.post("auth/getUserAlerts",params, {
                headers: {
                    'Authorization': `Bearer ` + localStorage.token_p
                }
            });
        },

        db_getUserAlertsHistorical(params){
            return axios.post("auth/getUserAlertsHistorical",params, {
                headers: {
                    'Authorization': `Bearer ` + localStorage.token_p
                }
            });
        },

        db_getLastUserAlerts(params){
            return axios.post("auth/getLastUserAlerts",params, {
                headers: {
                    'Authorization': `Bearer ` + localStorage.token_p
                }
            });
        },

        db_getLastUserAlertsStatic(params){
            return axios.post("auth/getLastUserAlertsStatic",params);
        },

        db_getSpecificAlert(params){
            return axios.post("auth/getSpecificAlert",params);
        },

        db_updateAlertsStatus(params){
            return axios.post("auth/updateAlertsStatus",params, {
                headers: {
                    'Authorization': `Bearer ` + localStorage.token_p
                }
            });
        },

        db_getDeviceAlerts(params){
            return axios.post("auth/getDeviceAlerts",params, {
                headers: {
                    'Authorization': `Bearer ` + localStorage.token_p
                }
            });
        },

        indexSensorPosition(params){
            return axios.post("/platform/sensor/position", params);
        },

        saveConfigurationAlert(param){
           return axios.post("/config_alerts", param);
        },

        updateConfigurationAlert(param){
           return axios.post("/modify_config_alerts", param);
        },


        getDataAlert(param){
           return axios.post("/get_data_alert_device", param);
        }
    }
};

export default DeviceService;